import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { Nullable } from "src/utils/typescript";

export enum Field {
  // Hacker Application
  FULL_NAME = "full_name",
  SCHOOL = "school",
  PROGRAM = "program",
  GRAD = "grad",
  LONG_ANSWER_5 = "long5",

  // ACCOUNT
  AVATAR = "avatar",
  SAVED_EVENTS = "saved_events",

  // RSVP
  LEGAL_NAME = "legal_name",
  PREFERRED_NAME = "preferred_name",
  PHONE_NUMBER = "phone_number",
  LOCATION = "location",
  ADDRESS_LINE_ONE = "address_line_one",
  ADDRESS_LINE_TWO = "address_line_two",
  CITY = "city",
  PROVINCE_STATE = "province_state",
  COUNTRY = "country",
  POSTAL_CODE = "postal_code",
  AGE_CONSENT = "age_consent",
  TERMS_CONDITIONS = "terms_conditions",
  SHARING_OPT_OUT = "sharing_opt_out",
  WAIVER_CONSENT = "waiver_consent",
  RECORDING_CONSENT = "recording_consent",
  SHIRT_TYPE = "shirt_type",
  SHIRT_SIZE = "shirt_size",
  SWAG_PICKUP = "swag_pickup",
  APPAREL_ACCOMODATION = "apparel_accomodation",
  NETWORKING = "networking",
  MENTORSHIP_INTEREST = "mentorship_interest",
  MENTORSHIP_TOPICS = "mentorship_topics",
  TECH_TALKS = "tech_talks",
  WICS_COFFEE_CHAT = "wics_coffee_chat",
  RESUME = "resume",
  RECRUITMENT_CONSENT = "recruitment_consent",
  CITIZENSHIP = "citizenship",
  PERMANENT_RESIDENT = "permanent_resident",
  INTERNSHIP_OPPORTUNITIES = "internship_opportunities",
  FULL_TIME_OPPORTUNITIES = "full_time_opportunities",
  COFFEE_CHAT = "coffee_chat",
  COMPANY_INTEREST = "company_interest",

  // RSVP Page Stages
  PERSONAL_RSVP_STAGE = "personal_rsvp_stage",
  EVENT_RSVP_STAGE = "event_rsvp_stage",
  CAREER_RSVP_STAGE = "career_rsvp_stage",
  RSVP_DEADLINE = "rsvp_deadline",

  // Displayed congrats modal
  DISPLAYED_MODAL = "displayed_modal",

  // Day-Of-Event
  INTERESTS = "interests",
  BADGES = "badges",
  DISCORD_ID = "discord_id",
}

export enum HackerGrad {
  LEVEL_OF_STUDY = "level_of_study",
  GRADUATING_YEAR = "graduating_year",
}

export enum PageStage {
  NOT_STARTED = "not_started",
  COMPLETED = "completed",
}

export type RSVPData = {
  // Personal Information
  [Field.LEGAL_NAME]: Nullable<string>;
  [Field.PREFERRED_NAME]: Nullable<string>;
  [Field.PHONE_NUMBER]: Nullable<string>;
  [Field.LOCATION]: Nullable<string>;

  [Field.ADDRESS_LINE_ONE]: Nullable<string>;
  [Field.ADDRESS_LINE_TWO]: Nullable<string>;
  [Field.CITY]: Nullable<string>;
  [Field.PROVINCE_STATE]: Nullable<string>;
  [Field.COUNTRY]: Nullable<string>;
  [Field.POSTAL_CODE]: Nullable<string>;

  [Field.AGE_CONSENT]: Nullable<boolean>; // 18 years old as of January 15, 2021
  [Field.TERMS_CONDITIONS]: Nullable<boolean>; // agree to terms and conditions
  [Field.SHARING_OPT_OUT]: Nullable<boolean>; // if the hacker opted out of sharing info with MLH
  [Field.WAIVER_CONSENT]: Nullable<boolean>;
  [Field.RECORDING_CONSENT]: Nullable<boolean>;

  // Event Information
  [Field.SHIRT_TYPE]: Nullable<string>;
  [Field.SHIRT_SIZE]: Nullable<string>;
  [Field.SWAG_PICKUP]: Nullable<boolean>;
  [Field.APPAREL_ACCOMODATION]: Nullable<string>; // apparel accomodation requests (ex. hijab-friendly shirt) [REMOVED]
  [Field.NETWORKING]: Nullable<string[]>;
  [Field.MENTORSHIP_INTEREST]: Nullable<boolean>; // if they would be interested in being a mentor for beginner hackers
  [Field.MENTORSHIP_TOPICS]: Nullable<string[]>;
  [Field.TECH_TALKS]: Nullable<string[]>;
  [Field.WICS_COFFEE_CHAT]: Nullable<boolean>; // if they are eligible for and would like to sign up for a WiCS coffee chat

  // Career
  [Field.RESUME]: Nullable<HackerAPIFile>;
  [Field.RECRUITMENT_CONSENT]: Nullable<boolean>; // consent to prpovide data to sponsors for recruitment
  [Field.CITIZENSHIP]: Nullable<boolean>; // canadian citizen
  [Field.PERMANENT_RESIDENT]: Nullable<boolean>; // permanent resident of canada
  [Field.INTERNSHIP_OPPORTUNITIES]: Nullable<boolean>; // looking for internship opportunities
  [Field.FULL_TIME_OPPORTUNITIES]: Nullable<boolean>; // looking for full-time opportunities
  [Field.COFFEE_CHAT]: Nullable<string[]>;
  [Field.COMPANY_INTEREST]: Nullable<string[]>;

  // Account
  [Field.AVATAR]: Nullable<string>;
  [Field.SAVED_EVENTS]: Nullable<string[]>;
  [Field.INTERESTS]: Nullable<string[]>;
  [Field.BADGES]: Nullable<string[]>;
  [Field.DISCORD_ID]: Nullable<string[]>;
};

export enum HackerStage {
  UNDER_REVIEW = 1519,
  CHECKED_IN = 1515,
  CONFIRMED = 1514,
  IN_PROGRESS = 1513,
  SUBMITTED = 1512,
  WITHDRAWN = 1511,
  REVIEWED = 1510,
  UNQUALIFIED = 1509,
  WAITLISTED = 1508,
  NOT_ACCEPTED = 1507,
  ACCEPTED = 1506,
  EXPIRED = 1505,
  AUTO_ACCEPTED = 1504,
  DEFAULT_STAGE = -1,
}

export type TAnswer = Nullable<
  | string
  | string[]
  | number
  | Record<string, string>
  | boolean
  | JSON
  | HackerAPIFile
>;

export type TAnswers = RSVPData & Record<string, TAnswer>;
