/**
 * A version of useMilestoneState that tracks flag (boolean T/F) milestones only.
 * Because it only tracks true/false, we can provide a function that submits
 * the milestone as we don't need to be aware of any data.
 */
import { useState } from "react";

import * as Milestones from "./types";

import { useMilestonesContext } from ".";

export const useMilestoneFlag = <Milestone extends Milestones.Milestone>(
  defaultMilestone: Milestone,
  validationErrorString = "Invalid submission.",
  submitSuccessString = "Successfully submitted!"
) => {
  const { isUpdating, updateError, completeMilestone } = useMilestonesContext();

  const [isComplete, setComplete] = useState(defaultMilestone.complete);
  const [localError, setLocalError] = useState<string | null>(null);
  const [localSuccess, setLocalSuccess] = useState<string | null>(null);

  const toggleComplete = () => setComplete((prev) => !prev);

  const onSubmit = async () => {
    if (!isComplete) {
      setLocalError(validationErrorString);
      return;
    } else {
      setLocalError(null);
    }

    await completeMilestone(defaultMilestone.id);

    setLocalSuccess(submitSuccessString);
  };

  return {
    isComplete,
    onChange: toggleComplete,
    onSubmit,
    loading: isUpdating,
    error: localError || updateError,
    success: localSuccess,
  };
};
