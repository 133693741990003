import React from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";

// Based on src\shared\components\Accordion\Arrow.tsx

interface ArrowProps {
  isOpen: boolean;
}

const ArrowContainer = styled.div<{ isOpen: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
  svg {
    transition: transform 100ms;
    transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
  }
`;

const Arrow: React.FC<ArrowProps> = ({ isOpen }) => {
  return (
    <ArrowContainer isOpen={isOpen}>
      <Icon name="chevron" />
    </ArrowContainer>
  );
};

export default Arrow;
