/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../api/types.generated";
export type GetSponsorPerkClaimsQueryVariables = Types.Exact<{
  companyClaimId: Types.Scalars["String"];
}>;

export type GetSponsorPerkClaimsQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<Types.Claim, "id" | "name" | "stage_id"> & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetSponsorPerkClaimsDocument = gql`
  query GetSponsorPerkClaims($companyClaimId: String!) {
    claims(
      where: {
        pipeline_id: { equals: 1431 }
        stage_id: { not: { equals: 1480 } }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "company_claim_id" } } } }
              { value: { equals: $companyClaimId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetSponsorPerkClaimsQuery__
 *
 * To run a query within a React component, call `useGetSponsorPerkClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorPerkClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorPerkClaimsQuery({
 *   variables: {
 *      companyClaimId: // value for 'companyClaimId'
 *   },
 * });
 */
export function useGetSponsorPerkClaimsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorPerkClaimsQuery,
    GetSponsorPerkClaimsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorPerkClaimsQuery,
    GetSponsorPerkClaimsQueryVariables
  >(GetSponsorPerkClaimsDocument, baseOptions);
}
export function useGetSponsorPerkClaimsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorPerkClaimsQuery,
    GetSponsorPerkClaimsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorPerkClaimsQuery,
    GetSponsorPerkClaimsQueryVariables
  >(GetSponsorPerkClaimsDocument, baseOptions);
}
export type GetSponsorPerkClaimsQueryHookResult = ReturnType<
  typeof useGetSponsorPerkClaimsQuery
>;
export type GetSponsorPerkClaimsLazyQueryHookResult = ReturnType<
  typeof useGetSponsorPerkClaimsLazyQuery
>;
export type GetSponsorPerkClaimsQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorPerkClaimsQuery,
  GetSponsorPerkClaimsQueryVariables
>;
