import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { HourglassImg } from "src/static/img";

import Footnote from "./shared/footnote";

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background: rgba(14, 52, 96, 0.2);
`;

const BoldSpan = styled.span`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.bodyBig}px;
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  display: inline-block;
  font-weight: 800;
`;

const Img = styled.img`
  width: 600px;
  position: absolute;
  left: 500px;
  bottom: -80px;
  z-index: -1;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Container = styled.div`
  max-width: 600px;
`;

export default () => {
  return (
    <OuterContainer>
      <Img src={HourglassImg} alt="" aria-hidden />
      <Text mods="hmobile heading">
        Oh no, it seems your RSVP deadline has passed{" "}
        <span role="img" aria-label="hourglass emoji">
          ⌛
        </span>
      </Text>

      <Container>
        <Text>
          The last day to confirm your spot was{" "}
          <BoldSpan>December 20th, 2020 at 11:59pm EST</BoldSpan>.
        </Text>
        <Separator />
      </Container>
      <Footnote />
    </OuterContainer>
  );
};
