import {
  Text,
  Spacer,
  TextInput,
  Flex,
  Button,
  Spinner,
} from "@hackthenorth/north";
import React from "react";
import { useState } from "react";
import styled from "styled-components";

import {
  Card,
  PageWrapper,
  Checkbox,
  Link,
  OutlineDocument,
  Divider,
} from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { isPhoneNumber } from "src/utils/validation";

import { ContentCard, Popup } from "../LayoutComponents";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";

const TERMS_CONDITIONS_SHARE_ID = "20d67af7-f701-4fbc-ab69-6082fa40cf52";

const isPartialPhoneNumber = (val: string) =>
  val !== null && val.match(/^\+?[\d .-]*(x[\d .-]*)?$/);

const CityGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
`;

const ErrorText = styled(Text).attrs({ mods: "error" })`
  margin: 0 0 0 24px !important;
`;

const CheckboxErrorText = styled(Text).attrs({ mods: "error" })`
  display: inline-block !important;
  margin: 12px 0 0 0 !important;
`;

const LongTextCard = styled(Card).attrs({ color: "tanSecondary" })`
  max-height: 600px;
  overflow-y: auto;

  p,
  li {
    line-height: 20px;
  }
  ul {
    margin: 0;
  }
  h4 {
    margin-top: 1em;
  }
`;

const PersonalPage = () => {
  const {
    updateResponses,
    isLoading,
    isReadOnly,
    navigateToRSVP,
    navigateNext,
  } = useHackerContext();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );
  const [showErrors, setShowErrors] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // Valid only if they've entered something
  const isLegalFullNameValid = responsesState[Field.LEGAL_NAME] !== "";
  const isPhoneNumberValid = isPhoneNumber(responsesState[Field.PHONE_NUMBER]);
  const isLocationValid = responsesState[Field.LOCATION] !== "";
  const isAddressLine1Valid = responsesState[Field.ADDRESS_LINE_ONE] !== "";
  const isCityValid = responsesState[Field.CITY] !== "";
  const isCountryValid = responsesState[Field.COUNTRY] !== "";
  const isPostalZipCodeValid = responsesState[Field.POSTAL_CODE] !== "";

  const isTermsAndConditionsValid = !!responsesState[Field.TERMS_CONDITIONS]; // make sure they agree
  const isGivesRecordingConsentValid =
    responsesState[Field.RECORDING_CONSENT] !== null;
  const isShareOptOutValid = responsesState[Field.SHARING_OPT_OUT] !== null;

  const ErrorTextComponent = showErrors && (
    <ErrorText>This field is required</ErrorText>
  );

  const PhoneNumberErrorTextComponent = showErrors && (
    <ErrorText>Please enter a valid phone number</ErrorText>
  );

  const CheckboxErrorTextComponent = showErrors && (
    <CheckboxErrorText>This field is required</CheckboxErrorText>
  );

  const getMods = (isFieldValid: boolean): string =>
    !isFieldValid && showErrors ? "error" : "";

  const onSave = async () => {
    setShowErrors(true);

    if (isValid) {
      await updateResponses({
        ...responsesState,
        [Field.PERSONAL_RSVP_STAGE]: PageStage.COMPLETED,
      });
      navigateNext();
    } else {
      window.scrollTo(0, 0);
      setShowErrorModal(true);
    }
  };

  return (
    <PageWrapper
      pageTitle="Personal Information"
      onBlur={() => setShowErrorModal(false)}
    >
      <Text mods="big medium" className="personal-info-description">
        To get started, please tell us more about yourself and agree to our
        terms and conditions.
      </Text>
      <Spacer height={46} />
      <ContentCard>
        <Text mods="h2 heading">General</Text>
        <Spacer height={16} />
        <Text mods="medium">Full Legal Name</Text>
        <Spacer height={10} />
        <TextInput
          disabled={isReadOnly}
          mods={getMods(isLegalFullNameValid)}
          placeholder="Enter your full legal name"
          value={responsesState[Field.LEGAL_NAME]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResponsesState(Field.LEGAL_NAME, e.target.value)
          }
        />
        {!isLegalFullNameValid && ErrorTextComponent}
        <Spacer height={32} />
        <Text mods="medium">Preferred full name (optional)</Text>
        <Spacer height={8} />
        <Text mods="italic">
          This will be displayed to other participants at the event.
        </Text>
        <Spacer height={10} />
        <TextInput
          disabled={isReadOnly}
          maxLength={32}
          placeholder="Enter your preferred full name"
          value={responsesState[Field.PREFERRED_NAME]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResponsesState(Field.PREFERRED_NAME, e.target.value)
          }
        />
        <Spacer height={32} />
        <Text mods="medium">Phone number</Text>
        <Spacer height={8} />
        <Text mods="italic">
          Please include your country code if it isn&apos;t +1.
        </Text>
        <Spacer height={10} />
        <TextInput
          disabled={isReadOnly}
          maxLength={30}
          mods={getMods(isPhoneNumberValid)}
          placeholder="Enter your phone number"
          value={responsesState[Field.PHONE_NUMBER]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (isPartialPhoneNumber(e.target.value)) {
              setResponsesState(Field.PHONE_NUMBER, e.target.value);
            }
          }}
        />
        {!isPhoneNumberValid && PhoneNumberErrorTextComponent}
        <Spacer height={32} />
        <Text mods="medium">Where will you be hacking from?</Text>
        <Spacer height={10} />
        <TextInput
          // We don't want autocomplete because it overrides the prefilled value with just a city (rather than City, Country)
          // Chrome ignores autocomplete="off", workaround from https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
          autoComplete="chrome-off"
          disabled={isReadOnly}
          mods={getMods(isLocationValid)}
          placeholder="Please enter city and country (ex. Waterloo, Canada)"
          value={responsesState[Field.LOCATION]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResponsesState(Field.LOCATION, e.target.value)
          }
        />
        {!isLocationValid && ErrorTextComponent}
        <Spacer height={53} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">Mailing Address</Text>
        <Text>
          Your mailing address will be used to send you swag. Please ensure the
          address is accurate and that you will be able to receive mail sent to
          this address around the time of the Hack the North. If your mailing
          address changes after filling this out, please let us know via email.
          <br />
          <Text mods="bold">Note:</Text> We are not responsible for missing,
          lost or damaged items.
        </Text>
        <Spacer height={32} />
        <Text mods="medium">Address line 1</Text>
        <Spacer height={10} />
        <TextInput
          disabled={isReadOnly}
          mods={getMods(isAddressLine1Valid)}
          placeholder="Street number and name"
          value={responsesState[Field.ADDRESS_LINE_ONE]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResponsesState(Field.ADDRESS_LINE_ONE, e.target.value)
          }
        />
        {!isAddressLine1Valid && ErrorTextComponent}
        <Spacer height={32} />
        <Text mods="medium">Address line 2 (optional)</Text>
        <Spacer height={10} />
        <TextInput
          disabled={isReadOnly}
          placeholder="Apt unit, etc."
          value={responsesState[Field.ADDRESS_LINE_TWO]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setResponsesState(Field.ADDRESS_LINE_TWO, e.target.value)
          }
        />
        <Spacer height={32} />
        <CityGrid>
          <div>
            <Text mods="medium">City</Text>
            <Spacer height={10} />
            <TextInput
              disabled={isReadOnly}
              mods={getMods(isCityValid)}
              placeholder="Enter your city"
              value={responsesState[Field.CITY]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setResponsesState(Field.CITY, e.target.value)
              }
            />
            {!isCityValid && ErrorTextComponent}
          </div>
          <div>
            <Text mods="medium">Province/State</Text>
            <Spacer height={10} />
            <TextInput
              disabled={isReadOnly}
              placeholder="Enter your province or state (if applicable)"
              value={responsesState[Field.PROVINCE_STATE]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setResponsesState(Field.PROVINCE_STATE, e.target.value)
              }
            />
          </div>
          <div>
            <Text mods="medium">Country</Text>
            <Spacer height={10} />
            <TextInput
              disabled={isReadOnly}
              mods={getMods(isCountryValid)}
              placeholder="Enter your country"
              value={responsesState[Field.COUNTRY]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setResponsesState(Field.COUNTRY, e.target.value)
              }
            />
            {!isCountryValid && ErrorTextComponent}
          </div>
          <div>
            <Text mods="medium">Postal/Zip code</Text>
            <Spacer height={10} />
            <TextInput
              disabled={isReadOnly}
              mods={getMods(isPostalZipCodeValid)}
              placeholder="Enter your postal/zip code"
              value={responsesState[Field.POSTAL_CODE]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setResponsesState(Field.POSTAL_CODE, e.target.value)
              }
            />
            {!isPostalZipCodeValid && ErrorTextComponent}
          </div>
        </CityGrid>
        <Spacer height={53} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">Agreement</Text>
        <Spacer height={10} />
        <Text>
          We’re pleased to extend to you an invitation to Hack the North 2020++!
          Before you join in the fun, we would like you to read, understand and
          agree to the Terms and Conditions as well as the Code of Conduct below
          in order to participate in the event.
        </Text>
        <Spacer height={24} />
        <LongTextCard>
          <OutlineDocument shareId={TERMS_CONDITIONS_SHARE_ID} noTitle />
        </LongTextCard>
        <Spacer height={32} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.TERMS_CONDITIONS]}
            onChange={(newVal: boolean) =>
              setResponsesState(Field.TERMS_CONDITIONS, newVal)
            }
          />
          <Spacer width={10} />
          <Text>
            I agree to the Terms and Conditions and Code of Conduct stated
            above. I additionally agree to the&nbsp;
            <Link href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">
              MLH Code of Conduct
            </Link>
            , the&nbsp;
            <Link href="https://github.com/MLH/mlh-policies/tree/master/prize-terms-and-conditions">
              MLH Contest Terms and Conditions
            </Link>
            , and the&nbsp;
            <Link href="https://mlh.io/privacy">MLH Privacy Policy</Link>.
          </Text>
        </Flex>
        {!isTermsAndConditionsValid && CheckboxErrorTextComponent}
        <Spacer height={32} />
        <Text mods="h2 heading">Consent</Text>
        <Spacer height={10} />
        <Text mods="bold">Recording</Text>
        <Spacer height={10} />
        <Text>
          I acknowledge that by participating in any interactive sessions during
          the event [or pre-event], or participating in the event Discord server
          or Hopin, my voice or video may be recorded for organizational and
          promotional purposes. If I do not consent to this recording, I will
          keep myself muted and keep my video off during sessions or while on
          Discord and Hopin.
        </Text>
        <Spacer height={16} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.RECORDING_CONSENT] === true}
            onChange={(newVal: boolean) =>
              setResponsesState(Field.RECORDING_CONSENT, newVal)
            }
          />
          <Spacer width={16} />
          <Text>
            I understand and consent to my voice and video being recorded
          </Text>
        </Flex>
        <Spacer height={16} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.RECORDING_CONSENT] === false}
            onChange={(newVal: boolean) =>
              setResponsesState(Field.RECORDING_CONSENT, !newVal)
            }
          />
          <Spacer width={16} />
          <Text>
            No, I do not consent and I will keep my mic muted and my video off
          </Text>
        </Flex>
        {!isGivesRecordingConsentValid && CheckboxErrorTextComponent}
        <Spacer height={32} />
        <Text mods="bold">Information sharing with MLH</Text>
        <Spacer height={10} />
        <Text>
          We share your name, email addresses, and school with Major League
          Hacking (MLH) for marketing and school ranking purposes in accordance
          with{" "}
          <Link href="https://mlh.io/privacy">MLH&apos;s Privacy Policy</Link>.
          However, you are free to opt out.
        </Text>
        <Spacer height={16} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.SHARING_OPT_OUT] === false}
            onChange={(newVal: boolean) =>
              setResponsesState(Field.SHARING_OPT_OUT, !newVal)
            }
          />
          <Spacer width={16} />
          <Text>I agree to share information I have provided with MLH</Text>
        </Flex>
        <Spacer height={16} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.SHARING_OPT_OUT] === true}
            onChange={(newVal: boolean) =>
              setResponsesState(Field.SHARING_OPT_OUT, newVal)
            }
          />
          <Spacer width={16} />
          <Text>I would like to opt out</Text>
        </Flex>
        {!isShareOptOutValid && CheckboxErrorTextComponent}
        <Spacer height={96} />
        <Flex justify="flex-end">
          <Button mods="secondary" onClick={navigateToRSVP}>
            Back to RSVP
          </Button>
          {!isReadOnly && (
            <>
              <Spacer width={24} />
              <Button mods="primary" disabled={isLoading} onClick={onSave}>
                {isLoading ? (
                  <Flex align="center" justify="center">
                    <Spinner />
                    <Spacer width={10} />
                    Saving
                  </Flex>
                ) : (
                  "Save and continue"
                )}
              </Button>
            </>
          )}
        </Flex>
      </ContentCard>
      <Popup isOpen={showErrorModal}>
        <Text mods="white">Please fill all the required fields</Text>
      </Popup>
    </PageWrapper>
  );
};
export default PersonalPage;
