import { Text, Spacer, Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Divider } from "src/shared/components";
import * as Mentor from "src/shared/contexts/MentorContext/types";
import { useGetFile } from "src/shared/hooks";
import { MentorshipDefaultProfileImg } from "src/static/img";

export interface MentorCardContentProps {
  profile: Mentor.Profile;
  name: string;
}

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Category = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
  && {
    margin: 3px;
  }
`;
const StyledDiv = styled.div`
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  height: 67px;
  width: 67px;
  background-color: #dddddd;
`;

const StyledImg = styled.img`
  object-position: left;
  width: 67px;
`;

const MentorCardContent: React.FC<MentorCardContentProps> = ({
  profile,
  name,
}) => {
  const fetchedFile = useGetFile(profile.mentorship_profile_photo);
  let profileImg;
  if (fetchedFile && fetchedFile[0]) {
    profileImg = (
      <StyledImg
        src={fetchedFile[0].uri}
        alt={fetchedFile[0].name ?? "profile"}
      />
    );
  } else {
    profileImg = <StyledImg src={MentorshipDefaultProfileImg} alt="profile" />;
  }
  return (
    <>
      <Divider />
      <Text mods="h4 bold">Claimed by</Text>
      <Spacer height={10} />
      <Flex row>
        <StyledDiv>{profileImg}</StyledDiv>
        <Spacer display="inline-block" width={15} />
        <Flex column>
          <Text mods="h3">{name}</Text>
          <Text mods="h4">{profile.institution}</Text>
        </Flex>
      </Flex>
      <Spacer height={10} />
      <Text nods="h4">Areas of Expertise</Text>
      <Spacer height={10} />
      <CategoryContainer>
        {(profile?.mentorship_topics || []).map((category) => (
          <Category key={category}>{category}</Category>
        ))}
      </CategoryContainer>
    </>
  );
};

export default MentorCardContent;
