import { css } from "styled-components";

import { SIDEBAR_WIDTH } from "src/shared/components";

export const hideSidebarStyles = css<{ showSidebar: boolean }>`
  left: ${({ showSidebar }) => (showSidebar ? `${SIDEBAR_WIDTH}px` : 0)};
  width: ${({ showSidebar }) =>
    showSidebar ? `calc(100% - ${SIDEBAR_WIDTH}px)` : "100%"};
  transition: width 0.3s ease-out;
  transition: left 0.3s ease-out;
`;
