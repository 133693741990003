import { JWTRole } from "@hackthenorth/north";
import { ExecutionResult } from "graphql";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router";
import { useTheme } from "styled-components";

import { UpdateClaimMutation } from "src/api/claims/updateClaim.generated";
import {
  useHackerContext,
  usePermissionsContext,
  useUserContext,
} from "src/shared/contexts";
import { Field, HackerStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { Permission } from "src/shared/contexts/PermissionsContext/types";

import {
  EventCategory,
  TComputedBannerEvents,
  TComputedEvents,
} from "../types";

import { useGetEventScheduleQuery } from "./graphql/getEventSchedule.generated";
import { computeBannerEvents, computeEvents } from "./utils";

export type TScheduleContextState = {
  isLoading: boolean;
  error: Error | undefined;
  showPrivateLink: boolean;
  viewFullSchedule: boolean;
  enableSaving: boolean;
  getIsSaved: (eventId: number) => boolean;
  toggleSaveEvent: (
    eventId: number
  ) => Promise<ExecutionResult<UpdateClaimMutation>>;
  showModalWithParam: (eventId: number) => void;
  closeModalWithParam: (eventId: number) => void;
  gearUpBannerEvents: TComputedBannerEvents;
  hackTheNorthBannerEvents: TComputedBannerEvents;
  allNonBannerEvents: TComputedEvents;
  gearUpEvents: TComputedEvents;
  hackTheNorthEvents: TComputedEvents;
  getFilteredEvents: (
    customFilter: (tags: string[]) => boolean
  ) => TComputedEvents;
  getFilteredSavedEvents: (
    customFilter: (tags: string[]) => boolean
  ) => TComputedEvents;
  savedGearUpEvents: TComputedEvents;
  savedHackTheNorthEvents: TComputedEvents;
  eventCategoryToColor: Record<EventCategory, string>;
};

const DEFAULT_STATE: TScheduleContextState = {
  isLoading: false,
  error: undefined,
  showPrivateLink: false,
  viewFullSchedule: false,
  enableSaving: false,
  getIsSaved: () => false,
  toggleSaveEvent: () =>
    Promise.reject("No parent ScheduleContextProvider found"),
  showModalWithParam: () => {},
  closeModalWithParam: () => {},
  gearUpBannerEvents: {},
  hackTheNorthBannerEvents: {},
  allNonBannerEvents: {},
  gearUpEvents: {},
  hackTheNorthEvents: {},
  getFilteredEvents: () => ({} as TComputedEvents),
  getFilteredSavedEvents: () => ({} as TComputedEvents),
  savedGearUpEvents: {},
  savedHackTheNorthEvents: {},
  eventCategoryToColor: {} as Record<EventCategory, string>,
};

const ScheduleContext: React.Context<TScheduleContextState> =
  createContext(DEFAULT_STATE);

export const useScheduleContext = () => useContext(ScheduleContext);

const ACCEPTED_FIELDS = [Field.SAVED_EVENTS];

export const ScheduleContextProvider: React.FC = ({ children }) => {
  const {
    loading,
    data,
    error: getEventsError,
    refetch,
  } = useGetEventScheduleQuery();

  const { hasPermission } = usePermissionsContext();
  const { roles, isOrganizer } = useUserContext();
  const { stage } = useHackerContext();

  // If confirmed hacker or sponsor, show Hopin link
  // If volunteer or mentor, show public link
  const showPrivateLink =
    (roles.includes(JWTRole.HACKER) && stage === HackerStage.CHECKED_IN) ||
    roles.includes(JWTRole.SPONSOR) ||
    isOrganizer;

  // Get public or private schedule?
  const viewFullSchedule = hasPermission(Permission.VIEW_FULL_SCHEDULE);
  const hasExtraFilters = useCallback(
    (tags: string[]) => {
      return (
        (viewFullSchedule
          ? true
          : !tags.includes("private") && !tags.includes("hack_the_north")) &&
        (isOrganizer ? true : !tags.includes("organizers_only"))
      );
    },
    [isOrganizer, viewFullSchedule]
  );

  // Refetch events every 10 minutes
  useEffect(() => {
    const id = setInterval(refetch, 600000);
    return () => clearInterval(id);
  }, [refetch]);

  // Refetch on tab focus
  useEffect(() => {
    const refetchTabFocus = () => {
      if (document.visibilityState === "visible") refetch();
    };
    document.addEventListener("visibilitychange", refetchTabFocus);
    return () =>
      document.removeEventListener("visibilitychange", refetchTabFocus);
  }, [refetch]);

  // Parse events
  const events = useMemo(() => data?.eventSchedule ?? [], [data]);

  // For debugging
  // const events = mock.eventSchedule;

  // Filtered for general use
  const gearUpBannerEvents = useMemo(
    () =>
      computeBannerEvents(
        events.filter(
          (event) =>
            event.tags &&
            event.tags.includes("gear_up") &&
            event.tags.includes("banner") &&
            hasExtraFilters(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );
  const hackTheNorthBannerEvents = useMemo(
    () =>
      computeBannerEvents(
        events.filter(
          (event) =>
            event.tags &&
            event.tags.includes("hack_the_north") &&
            event.tags.includes("banner") &&
            hasExtraFilters(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );
  const allNonBannerEvents = useMemo(
    () =>
      computeEvents(
        events.filter(
          (event) =>
            event.tags &&
            !event.tags.includes("banner") &&
            hasExtraFilters(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );
  const gearUpEvents = useMemo(
    () =>
      computeEvents(
        events.filter(
          (event) =>
            event.tags &&
            event.tags.includes("gear_up") &&
            !event.tags.includes("banner") &&
            hasExtraFilters(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );
  const hackTheNorthEvents = useMemo(
    () =>
      computeEvents(
        events.filter(
          (event) =>
            event.tags &&
            event.tags.includes("hack_the_north") &&
            !event.tags.includes("banner") &&
            hasExtraFilters(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );

  // Filtered for saved events
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {}) ?? null;

  // Enable saving events if the user has an account in the hacker applications pipeline
  const enableSaving = roles.includes(JWTRole.HACKER);

  const savedEvents = enableSaving ? responsesState[Field.SAVED_EVENTS] : null;
  const savedGearUpEvents = useMemo(
    () =>
      savedEvents
        ? computeEvents(
            events.filter(
              (event) =>
                event.tags &&
                event.tags.includes("gear_up") &&
                !event.tags.includes("banner") &&
                hasExtraFilters(event.tags) &&
                savedEvents.includes(event.id.toString())
            )
          )
        : ({} as TComputedEvents),
    [events, hasExtraFilters, savedEvents]
  );
  const savedHackTheNorthEvents = useMemo(
    () =>
      savedEvents
        ? computeEvents(
            events.filter(
              (event) =>
                event.tags &&
                event.tags.includes("hack_the_north") &&
                !event.tags.includes("banner") &&
                hasExtraFilters(event.tags) &&
                savedEvents.includes(event.id.toString())
            )
          )
        : ({} as TComputedEvents),
    [events, hasExtraFilters, savedEvents]
  );

  // For styling the event block
  const { globalConstants } = useTheme();
  const eventCategoryToColor = useMemo(() => {
    return {
      [EventCategory.EVENT]: globalConstants.color.bluePrimary1,
      [EventCategory.WORKSHOP]: globalConstants.color.greenSecondary,
      [EventCategory.ACTIVITY]: globalConstants.color.mustardSecondary,
      [EventCategory.SPONSORS]: globalConstants.color.redPrimary1,
      [EventCategory.TECH_TALKS]: globalConstants.color.navySecondary,
    };
  }, [globalConstants]);

  // Show event modal logic
  const navigate = useNavigate();
  const location = useLocation();

  const showModalWithParam = useCallback(
    (eventId) => navigate(`${location.pathname}?event=${eventId}`),
    [location.pathname, navigate]
  );

  const closeModalWithParam = useCallback(
    () => navigate(`${location.pathname}`),
    [location.pathname, navigate]
  );

  // Event saving logic
  const { updateResponses } = useHackerContext();
  const saveEvent = useCallback(
    (eventId: number) => {
      return updateResponses({
        ...responsesState,
        [Field.SAVED_EVENTS]: [
          ...(responsesState[Field.SAVED_EVENTS] as string[]),
          eventId.toString(),
        ],
      });
    },
    [responsesState, updateResponses]
  );

  const unsaveEvent = useCallback(
    (eventId: number) => {
      return updateResponses({
        ...responsesState,
        [Field.SAVED_EVENTS]:
          responsesState[Field.SAVED_EVENTS]?.filter(
            (savedEventId) => savedEventId !== eventId.toString()
          ) ?? [],
      });
    },
    [responsesState, updateResponses]
  );

  const getIsSaved = useCallback(
    (eventId: number) =>
      (responsesState[Field.SAVED_EVENTS] ?? []).includes(eventId.toString()),
    [responsesState]
  );

  const toggleSaveEvent = useCallback(
    (eventId: number) => {
      return getIsSaved(eventId) ? unsaveEvent(eventId) : saveEvent(eventId);
    },
    [getIsSaved, saveEvent, unsaveEvent]
  );

  // Custom tag filtering
  const getFilteredEvents = useCallback(
    (hasCustomFilter: (tags: string[]) => boolean) =>
      computeEvents(
        events.filter(
          (event) =>
            event.tags &&
            !event.tags.includes("banner") &&
            hasExtraFilters(event.tags) &&
            hasCustomFilter(event.tags)
        )
      ),
    [events, hasExtraFilters]
  );

  const getFilteredSavedEvents = useCallback(
    (hasCustomFilter: (tags: string[]) => boolean) =>
      savedEvents
        ? computeEvents(
            events.filter(
              (event) =>
                event.tags &&
                !event.tags.includes("banner") &&
                hasExtraFilters(event.tags) &&
                hasCustomFilter(event.tags) &&
                savedEvents?.includes(event.id.toString())
            )
          )
        : ({} as TComputedEvents),
    [events, hasExtraFilters, savedEvents]
  );

  // Build state
  const scheduleState: TScheduleContextState = {
    isLoading: loading,
    error: getEventsError,
    showPrivateLink,
    viewFullSchedule,
    enableSaving,
    getIsSaved,
    toggleSaveEvent,
    showModalWithParam,
    closeModalWithParam,
    gearUpBannerEvents,
    hackTheNorthBannerEvents,
    allNonBannerEvents,
    gearUpEvents,
    hackTheNorthEvents,
    getFilteredEvents,
    getFilteredSavedEvents,
    savedGearUpEvents,
    savedHackTheNorthEvents,
    eventCategoryToColor,
  };

  return (
    <ScheduleContext.Provider value={scheduleState}>
      {children}
    </ScheduleContext.Provider>
  );
};
