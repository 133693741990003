import { Flex } from "@hackthenorth/north";
import styled from "styled-components";

import { Card } from "src/shared/components";

export const ContentCard = styled(Card).attrs({ color: "white" })`
  padding: 32px 48px;
  border: ${({ theme }) => `1px solid ${theme.globalConstants.color.textDark}`};
`;

export const Popup = styled(Flex).attrs({
  justify: "center",
  align: "center",
})<{
  isOpen: boolean;
}>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: absolute;
  top: 230px;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 404px;
  height: 60px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.globalConstants.color.redSecondary};
`;
