import React, { createContext, useContext, useMemo, useEffect } from "react";

import { useUpdateClaimMutation as useUpdateSponsorPerkClaimMutation } from "src/api/claims/updateClaim.generated";
import { useSponsorContext } from "src/shared/contexts";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import { useGetSponsorPerkClaimsLazyQuery } from "./graphql/getSponsorPerkClaims.generated";

interface SponsorPerksContextState {
  perks: SponsorPerks.Perk[];
  loadError?: string;
  isLoading: boolean;
  savePerk: (
    id: SponsorPerks.Perk["id"],
    data: SponsorPerks.Perk["data"],
    stage: SponsorPerks.Perk["stage"]
  ) => Promise<unknown>;
  submitPerk: (
    id: SponsorPerks.Perk["id"],
    data: SponsorPerks.Perk["data"]
  ) => Promise<unknown>;
  isUpdating: boolean;
  updateError?: string;
}

const DEFAULT_STATE: SponsorPerksContextState = {
  perks: [],
  isLoading: false,
  savePerk: () => Promise.reject("No parent SponsorPerksContextProvider found"),
  submitPerk: () =>
    Promise.reject("No parent SponsorPerksContextProvider found"),
  isUpdating: false,
};

const SponsorPerksContext: React.Context<SponsorPerksContextState> = createContext(
  DEFAULT_STATE
);

SponsorPerksContext.displayName = "SponsorPerksContext";

export const useSponsorPerksContext = () => useContext(SponsorPerksContext);

export const SponsorPerksContextProvider: React.FC = ({ children }) => {
  const { individual } = useSponsorContext();
  const [
    getSponsorPerksClaimsQuery,
    { data: perkData, loading: perksLoading, error: perksError },
  ] = useGetSponsorPerkClaimsLazyQuery();

  useEffect(() => {
    if (individual?.company_claim_id) {
      getSponsorPerksClaimsQuery({
        variables: {
          companyClaimId: individual.company_claim_id.toString(),
        },
      });
    }
  }, [individual, getSponsorPerksClaimsQuery]);

  const perks: SponsorPerksContextState["perks"] = useMemo(
    () =>
      (perkData?.claims || []).map((p) => ({
        id: p.id,
        name: p.name,
        stage: p.stage_id,
        type: getFieldAnswer(p.fields, "perk_type") as SponsorPerks.Type,
        data: getFieldAnswer(p.fields, "data") || {},
      })),
    [perkData]
  );

  const [
    updateSponsorPerkClaimMutation,
    { loading: updateLoading, error: updateError },
  ] = useUpdateSponsorPerkClaimMutation();

  const savePerk = (
    id: SponsorPerks.Perk["id"],
    data: SponsorPerks.Perk["data"],
    stage: SponsorPerks.Perk["stage"]
  ) =>
    updateSponsorPerkClaimMutation({
      variables: {
        updatedData: {
          id: id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          stage_id:
            stage === SponsorPerks.Stage.PENDING
              ? SponsorPerks.Stage.STARTED
              : stage,
          answers: [
            {
              slug: "data",
              answer: data,
            },
          ],
        },
      },
    });

  const submitPerk = (
    id: SponsorPerks.Perk["id"],
    data: SponsorPerks.Perk["data"]
  ) =>
    updateSponsorPerkClaimMutation({
      variables: {
        updatedData: {
          id: id,
          stage_id: SponsorPerks.Stage.SUBMITTED, // eslint-disable-line @typescript-eslint/camelcase
          answers: [
            {
              slug: "data",
              answer: data,
            },
          ],
        },
      },
    });

  /**
   * Build state
   */
  const state: SponsorPerksContextState = {
    perks,
    loadError: perksError?.message,
    isLoading: perksLoading,
    savePerk,
    submitPerk,
    isUpdating: updateLoading,
    updateError: updateError?.message,
  };

  return (
    <SponsorPerksContext.Provider value={state}>
      {children}
    </SponsorPerksContext.Provider>
  );
};

SponsorPerksContextProvider.displayName = "SponsorPerksContextProvider";
