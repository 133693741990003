import { useState, useMemo } from "react";
import { usePrompt } from "react-router-dom";

import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import * as SponsorPerks from "src/views/sponsor/perks/types";

export const usePerkState = <Perk extends SponsorPerks.Perk>(
  defaultPerk: Perk,
  validatePerkData: (perkData: Perk["data"]) => boolean
) => {
  const {
    loadError,
    isLoading,
    isUpdating,
    savePerk,
    submitPerk,
    updateError,
  } = useSponsorPerksContext();

  const [perkData, setPerkData] = useState<Perk["data"]>(defaultPerk.data);

  const updatePerkField = <Field extends keyof Perk["data"]>(
    field: Field,
    value: Perk["data"][Field]
  ) => {
    setPerkData((prevData) => ({ ...prevData, [field]: value }));
  };

  const isValid = useMemo(() => validatePerkData(perkData), [
    validatePerkData,
    perkData,
  ]);

  const isDataDirty = useMemo(
    () =>
      Object.entries(perkData).some(([key, value]) => {
        return !value ? false : value !== defaultPerk.data[key];
      }),
    [perkData, defaultPerk.data]
  );

  usePrompt(
    "Are you sure you want to leave? You have unsaved changes.",
    isDataDirty
  );

  const isReadOnly = useMemo(
    () =>
      [SponsorPerks.Stage.SUBMITTED, SponsorPerks.Stage.APPROVED].includes(
        defaultPerk.stage
      ),
    [defaultPerk.stage]
  );

  return {
    perkData,
    updatePerkField,
    isLoading,
    isValid,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage: loadError || updateError,
  };
};
