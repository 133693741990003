export type CommunitySection = {
  label: string;
  sectionId: string;
  shareId: string;
};

const BIPOC_SHARE_ID = "59046449-97d0-49ef-a60f-cdcbda238fae";
const GENDER_SHARE_ID = "533f5563-9c90-4dcd-825a-4a018eb93523";
const LGBTQ_SHARE_ID = "38a6f5f1-1b78-490f-8f3d-84d28c45bf81";
const LANGUAGE_SHARE_ID = "793b3b74-5767-464d-91f1-91c771aad87d";
const SOCIAL_ISSUES_SHARE_ID = "48ef4142-1b45-4f72-890c-29d70a1d3175";
const MORE_RESOURCES_SHARE_ID = "207ddcf0-2ae9-414f-9c4e-f96913b77fc7";

export const COMMUNITY_SECTIONS: CommunitySection[] = [
  {
    label: "BIPOC",
    sectionId: "bipoc",
    shareId: BIPOC_SHARE_ID,
  },
  {
    label: "Gender",
    sectionId: "gender",
    shareId: GENDER_SHARE_ID,
  },
  {
    label: "Language",
    sectionId: "language",
    shareId: LANGUAGE_SHARE_ID,
  },
  {
    label: "LGBTQ+",
    sectionId: "lgbtq+",
    shareId: LGBTQ_SHARE_ID,
  },
  {
    label: "Social Issues",
    sectionId: "social-issues",
    shareId: SOCIAL_ISSUES_SHARE_ID,
  },
  {
    label: "More Resources",
    sectionId: "more-resources",
    shareId: MORE_RESOURCES_SHARE_ID,
  },
];
