import { Text, Button } from "@hackthenorth/north";
import React from "react";

import { useSharedAccordionContext } from "src/shared/components";

const TogglePerksButton = () => {
  const { toggleAccordions, isSharedOpen } = useSharedAccordionContext();

  return (
    <Button onClick={toggleAccordions} className="perks-collapse-all-button">
      <Text mods="medium big bluePrimary2">
        {isSharedOpen ? "Collapse all" : "Expand all"}
      </Text>
    </Button>
  );
};

export default TogglePerksButton;
