import isEqual from "lodash.isequal";
import { useEffect, useCallback } from "react";

import { useHackerContext } from "src/shared/contexts";
import { Field, TAnswers } from "src/shared/contexts/HackerContext/types";
import { TSetResponsesState } from "src/shared/contexts/HackerContext/useHackerState";

import {
  Badge,
  BADGES_DATA,
  VALID_SPONSOR_BADGE_CODES,
  VALID_WORKSHOP_BADGE_CODES,
} from "./constants";

type TUseAddBadgesArgs = {
  isResponseUpdated: boolean;
  responsesState: Partial<TAnswers>;
  setResponsesState: TSetResponsesState;
};
const getBadgeCode = (badge: Badge) => BADGES_DATA[badge].badgeCode.toString();

export const useAddBadges = ({
  isResponseUpdated,
  responsesState,
  setResponsesState,
}: TUseAddBadgesArgs) => {
  const { updateResponses, claimData } = useHackerContext();

  const hasBadge = useCallback(
    (badge: Badge) =>
      (responsesState[Field.BADGES] ?? []).includes(getBadgeCode(badge)),
    [responsesState]
  );

  // Updates badges on HAPI when state is updated
  useEffect(() => {
    const badges = responsesState[Field.BADGES] ?? [];
    if (
      isResponseUpdated &&
      badges.length > 0 &&
      !isEqual(badges, claimData[Field.BADGES])
    ) {
      console.log("updated claim", responsesState);
      updateResponses({ [Field.BADGES]: responsesState[Field.BADGES] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsesState]);

  // GENERAL BADGES
  useEffect(() => {
    if (!isResponseUpdated) return;
    const badges: string[] = [];
    const currentBadges = responsesState[Field.BADGES] ?? [];

    [Badge.GENERAL_ACCEPTED, Badge.GENERAL_LOGGED_ON].forEach((badge) => {
      if (!hasBadge(badge)) badges.push(getBadgeCode(badge).toString());
    });

    if (!hasBadge(Badge.GENERAL_ACCEPTED))
      badges.push(getBadgeCode(Badge.GENERAL_ACCEPTED).toString());
    if (!hasBadge(Badge.GENERAL_LOGGED_ON))
      badges.push(getBadgeCode(Badge.GENERAL_LOGGED_ON).toString());

    const savedEvents = responsesState[Field.SAVED_EVENTS] ?? [];
    if (!hasBadge(Badge.GENERAL_SAVED_EVENT) && savedEvents.length > 0)
      badges.push(getBadgeCode(Badge.GENERAL_SAVED_EVENT).toString());

    if (badges.length > 0)
      setResponsesState(Field.BADGES, [...currentBadges, ...badges]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResponseUpdated, responsesState]);

  // SPONSOR BADGES
  useEffect(() => {
    const currentBadges = responsesState[Field.BADGES] ?? [];

    if (!isResponseUpdated && currentBadges.length > 0) return;

    const badges: string[] = [];

    // Give hackers badges based on the number of sponsors they've talked to
    const sponsorBadgeCodes = currentBadges.filter((badge) =>
      VALID_SPONSOR_BADGE_CODES.includes(badge.toString())
    ).length;

    [
      [Badge.SPONSOR_TALKED_TO_ONE, 1],
      [Badge.SPONSOR_TALKED_TO_FIVE, 5],
      [Badge.SPONSOR_TALKED_TO_TEN, 10],
    ].forEach(([badge, badgeCount]) => {
      if (!hasBadge(badge) && sponsorBadgeCodes >= badgeCount)
        badges.push(getBadgeCode(badge).toString());
    });

    // Give hackers badges based on the number of workshops they've attended
    const workshopBadgeCodes = currentBadges.filter((badge) =>
      VALID_WORKSHOP_BADGE_CODES.includes(badge.toString())
    ).length;
    [
      [Badge.WORKSHOPS_ATTENDED_ONE, 1],
      [Badge.WORKSHOPS_ATTENDED_FIVE, 5],
      [Badge.WORKSHOPS_ATTENDED_TWENTY_FIVE, 25],
    ].forEach(([badge, badgeCount]) => {
      if (!hasBadge(badge) && workshopBadgeCodes >= badgeCount)
        badges.push(getBadgeCode(badge).toString());
    });

    if (badges.length > 0)
      setResponsesState(Field.BADGES, [...currentBadges, ...badges]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBadge, responsesState, isResponseUpdated]);
};
