import {
  Button,
  Spacer,
  Text,
  UnstyledButton,
  UnstyledLink,
  JWTRole,
} from "@hackthenorth/north";
import React, { useCallback, useEffect, useState, useRef } from "react";
import styled from "styled-components";

import {
  ChangeAvatarModal,
  Icon,
  Modal,
  TOPNAVBAR_HEIGHT,
} from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import { AVATAR_MAP } from "../ChangeAvatarModal/constants";

const DropdownItemContainer = styled(UnstyledButton)`
  padding: 16px 24px;
  display: flex;
  align-items: center;

  > :not(:first-child) {
    margin-left: 10px;
  }
`;

const DropdownContainer = styled.div<{
  isExpanded: boolean;
  isSponsor: boolean;
}>`
  background: white;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textLightGrey};
  box-sizing: border-box;
  border-radius: 5px;
  width: 200px;
  padding: 0 8px;
  top: ${TOPNAVBAR_HEIGHT}px;
  left: ${({ isSponsor }) => (isSponsor ? "-80px" : "-30px")};
  overflow-y: hidden;
  position: absolute;
  display: ${({ isExpanded }) => (isExpanded ? "initial" : "none")};
`;

const Container = styled.div<React.ComponentPropsWithRef<"div">>`
  display: flex;
  align-items: center;
  position: relative;

  > :not(:first-child) {
    margin-left: 10px;
  }
`;

const Img = styled.img`
  width: 40px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: ${({ theme }) => theme.globalConstants.color.textLightGrey};
`;

const StyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
`;

const ACCEPTED_FIELDS = [Field.AVATAR];

const AccountDropdown = () => {
  const { logOut, roles } = useUserContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showChangeAvatarModal, setShowChangeAvatarModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const isSponsor = roles?.includes(JWTRole.SPONSOR) || false;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const clickListener = useCallback(
    // todo: fix type later
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsExpanded(false);
      }
    },
    [wrapperRef]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return (
    <Container ref={wrapperRef}>
      {!isSponsor && (
        <Img
          src={AVATAR_MAP[responsesState[Field.AVATAR] ?? "red"]}
          alt=""
          aria-hidden
        />
      )}
      <StyledButton onClick={() => setIsExpanded(!isExpanded)}>
        <Text>Account</Text>
        <Spacer width="8px" />
        <Icon name="chevron" size="20px" />
      </StyledButton>
      <DropdownContainer isExpanded={isExpanded} isSponsor={isSponsor}>
        {isSponsor && (
          <>
            <DropdownItemContainer>
              <UnstyledLink href={Route.SPONSOR_PROFILE} role="link">
                <Text>Profile</Text>
              </UnstyledLink>
            </DropdownItemContainer>
            <Separator />
          </>
        )}
        {!isSponsor && (
          <>
            <DropdownItemContainer
              onClick={() => setShowChangeAvatarModal(true)}
            >
              <Text>Change avatar</Text>
            </DropdownItemContainer>

            <Separator />
          </>
        )}
        <DropdownItemContainer onClick={() => setShowConfirmationModal(true)}>
          <Icon name="log-out" size="18px" />
          <Text>Log out</Text>
        </DropdownItemContainer>
        <Modal
          isOpen={showConfirmationModal}
          title="Log out"
          actions={
            <>
              <Button
                mods="secondary"
                onClick={() => setShowConfirmationModal(false)}
              >
                Cancel
              </Button>
              <Spacer width="16px" />
              <Button mods="primary" onClick={logOut}>
                Log out
              </Button>
            </>
          }
        >
          <Text>Are you sure you want to log out?</Text>
        </Modal>
        <ChangeAvatarModal
          isOpen={showChangeAvatarModal}
          onConfirm={() => setShowChangeAvatarModal(false)}
          onCancel={() => setShowChangeAvatarModal(false)}
        />
      </DropdownContainer>
    </Container>
  );
};

export default AccountDropdown;
