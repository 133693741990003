import { Text, Spacer } from "@hackthenorth/north";
import React, { useRef } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";

const StyledButton = styled.button`
  background: transparent;
  border: none;
  position: fixed;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 70px;
  right: 70px;
  opacity: 0.5;
  transition: opacity 250ms;
  cursor: pointer;

  &:focus,
  &:hover {
    outline: none;
    opacity: 1;
  }
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.globalConstants.color.bluePrimaryD};
  /* shared styles */
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  margin: 0;

  ${({ theme }) => theme.mediaQueries.medium`
    font-size: ${theme.globalConstants.fontSize.bodyXs}px;
  `}
`;

const ScrollToTop: React.FC = () => {
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <StyledButton
      ref={ref}
      onClick={() => {
        ref?.current?.blur();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }}
    >
      <Icon name="solid-arrow" size="70px" />
      <Spacer height={10} />
      <StyledText removeSharedStyles>Back to the top</StyledText>
    </StyledButton>
  );
};

export default ScrollToTop;
