import { Text, Spacer, Spinner, Flex, Link } from "@hackthenorth/north";
import React, { Fragment } from "react";

import { Tooltip } from "src/shared/components";
import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import Perk from "../Perk";

interface PerksSectionProps {
  status: SponsorPerks.Status;
  perks: SponsorPerks.Perk[];
  numTotalPerks: number;
}

const PerksSection: React.FC<PerksSectionProps> = ({
  status,
  perks,
  numTotalPerks,
}) => {
  const { isLoading, loadError } = useSponsorPerksContext();
  const sectionHasPerks = perks.length > 0;

  const sectionHasTooltip =
    status === SponsorPerks.Status.UNDER_REVIEW ||
    status === SponsorPerks.Status.COMPLETE;

  const tooltipDelay = status === SponsorPerks.Status.COMPLETE ? 2 : 0;

  return (
    <>
      <Spacer height={48} />
      <Flex align="center">
        <Text mods="big medium lightGrey">{`${status.replace(/_/g, " ")} (${
          perks.length
        }/${numTotalPerks})`}</Text>
        <Spacer width={8} />

        {sectionHasTooltip && (
          <Tooltip
            id={status}
            indicatorColor="bluePrimary2"
            wrapperOptions={{ eventDelay: tooltipDelay }}
          >
            {status === SponsorPerks.Status.UNDER_REVIEW ? (
              <Text>
                Your perk details have been submitted and will be reviewed
                shortly by our sponsorship team! Once approved, the perk will be
                moved to the Complete section.
              </Text>
            ) : (
              <Text>
                The following perks have been finalized! If you need to make any
                changes, please contact{" "}
                <Link href="mailto:sponsor@hackthenorth.com">
                  sponsor@hackthenorth.com
                </Link>
                .
              </Text>
            )}
          </Tooltip>
        )}
      </Flex>
      <Spacer height={24} />
      {isLoading ? (
        <Spinner />
      ) : loadError ? (
        <Text mods="error">{loadError}</Text>
      ) : sectionHasPerks ? (
        perks.map((perk) => (
          <Fragment key={perk.name}>
            <Perk perk={perk} />
            <Spacer height={8} />
          </Fragment>
        ))
      ) : (
        <Text>{`No ${status
          .toLowerCase()
          .replace(/_/g, " ")} submissions.`}</Text>
      )}
    </>
  );
};

export default PerksSection;
