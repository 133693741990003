import { Text, Spacer } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { ScrollToTop } from "src/shared/components";
import { FooterImg } from "src/static/img";

type PageWrapperProps = ComponentPropsWithoutRef<"div"> & {
  pageTitle?: string;
  footer?: boolean;
  fullWidth?: boolean;
  scrollToTop?: boolean;
};

const SubContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "1200px")};
  width: 100%;
`;

const Container = styled.main`
  width: 100%;
  display: block;
  min-height: 100%;
  padding: 70px;
  background: ${({ theme }) => theme.globalConstants.color.gradientTan330};

  ${({ theme }) => theme.mediaQueries.medium`
    padding: 45px;
  `}
  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 40px;
  `}
  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 25px;
  `}
  ${({ theme }) => theme.mediaQueries.smallMobile`
    padding: 20px;
  `}
`;

const PageWrapper: React.FC<PageWrapperProps> = ({
  pageTitle,
  footer = false,
  fullWidth = false,
  scrollToTop = false,
  children,
  ...rest
}) => (
  <Container {...rest}>
    <SubContainer fullWidth={fullWidth}>
      {pageTitle && <Text mods={"heading title"}>{pageTitle}</Text>}
      {children}
      {footer && (
        <>
          <Spacer height={72} />
          <FooterImg width="100%" />
        </>
      )}
      {scrollToTop && <ScrollToTop />}
    </SubContainer>
  </Container>
);

export default PageWrapper;
