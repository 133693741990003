import {
  Text,
  Button,
  TextInput,
  Spacer,
  TextAreaInput,
  Flex,
} from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import {
  PageWrapper,
  Icon,
  Card,
  Tooltip,
  Accordion,
  AccordionArrow,
  Dropzone,
  DropzoneError,
  SpotifyPlayer,
} from "src/shared/components";

const PlaygroundHeader = styled.h1`
  font-family: "Comic Sans MS", sans-serif;
  text-decoration: underline;
  color: limegreen;
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  z-index: 10;
  cursor: pointer;
  svg {
    pointer-events: none;
  }
`;

const DropzoneComponent = () => {
  const [error, setError] = useState("");
  return (
    <>
      <Dropzone
        max={1}
        acceptedFileTypes={["png"]}
        onError={(error: DropzoneError) => {
          setError(error);
        }}
        onBlur={() => setError("")}
        onFilesUpload={(files: File[]) =>
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          new Promise((resolve, _reject) => {
            console.debug(files);
            setTimeout(() => resolve({}), 2000);
          })
        }
      />
      {error && <Card>Error</Card>}
    </>
  );
};

const Playground = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <PageWrapper pageTitle="">
      <Accordion heading={<Text>click me</Text>}>
        <>
          <div>hello</div>
          <div>it is me</div>
        </>
      </Accordion>
      <PlaygroundHeader>Text</PlaygroundHeader>
      <Text mods="heading title">Title</Text>
      <Text mods="heading h1">Heading 1</Text>
      <Text mods="heading h2">Heading 2</Text>
      <Text mods="heading hmobile">Mobile Heading</Text>
      <Text mods="big">Body Big</Text>
      <Text>Body</Text>
      <Text mods="subtle">Body Subtle</Text>
      <Text mods="bold lightGrey">Body Bold Lightgrey</Text>
      <Text mods="italic error">Body Italic Error</Text>
      <Text mods="link">Body Link</Text>

      <PlaygroundHeader>Button</PlaygroundHeader>
      <Button mods="primary">Primary Button</Button>
      <Spacer height={15} />
      <Button>Secondary Button</Button>
      <Spacer height={15} />
      <Button
        mods="primary"
        disabled={isDisabled}
        onClick={() => setIsDisabled(!isDisabled)}
      >
        Click to disable
      </Button>
      <Spacer height={15} />
      <Button disabled={isDisabled} onClick={() => setIsDisabled(!isDisabled)}>
        Click to disable
      </Button>
      <Spacer height={15} />
      <Button mods="primary square">Square Primary Button</Button>
      <Spacer height={15} />
      <Button mods="square">Square Button</Button>
      <Spacer height={15} />
      <Button mods="square" disabled>
        Disabled Button
      </Button>
      <Spacer height={15} />

      <PlaygroundHeader>TextInput</PlaygroundHeader>
      <TextInput placeholder="Empty text input" />
      <Spacer height={15} />
      <TextInput value="Filled text input" />
      <Spacer height={15} />
      <TextInput value="Error text input" mods="error" />
      <Spacer height={15} />
      <TextInput value="Disabled text input" mods="error" disabled />
      <Spacer height={15} />

      <PlaygroundHeader>TextAreaInput</PlaygroundHeader>
      <TextAreaInput placeholder="Empty text input" />
      <Spacer height={15} />
      <TextAreaInput value="Filled text input" />
      <Spacer height={15} />
      <TextAreaInput value="Error text input" mods="error" />
      <Spacer height={15} />
      <TextAreaInput value="Disabled text input" disabled />
      <Spacer height={15} />

      <PlaygroundHeader>Icon</PlaygroundHeader>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Text>copy</Text>
          <Spacer width={6} />
          <Icon name="copy" />
        </Flex>
        <Flex align="center">
          <Text>x</Text>
          <Spacer width={6} />
          <Icon name="x" />
        </Flex>
        <Flex align="center">
          <Text>upload</Text>
          <Spacer width={6} />
          <Icon name="upload" />
        </Flex>
        <Flex align="center">
          <Text>maximize</Text>
          <Spacer width={6} />
          <Icon name="maximize" />
        </Flex>
        <Flex align="center">
          <Text>eye</Text>
          <Spacer width={6} />
          <Icon name="eye" />
        </Flex>
        <Flex align="center">
          <Text>eye-off</Text>
          <Spacer width={6} />
          <Icon name="eye-off" />
        </Flex>
        <Flex align="center">
          <Text>chevron</Text>
          <Spacer width={6} />
          <Icon name="chevron" />
        </Flex>
        <Flex align="center">
          <Text>clock</Text>
          <Spacer width={6} />
          <Icon name="clock" />
        </Flex>
        <Flex align="center">
          <Text>log-out</Text>
          <Spacer width={6} />
          <Icon name="log-out" />
        </Flex>
        <Flex align="center">
          <Text>edit</Text>
          <Spacer width={6} />
          <Icon name="edit" />
        </Flex>
        <Flex align="center">
          <Text>file</Text>
          <Spacer width={6} />
          <Icon name="file" />
        </Flex>
        <Flex align="center">
          <Text>lock</Text>
          <Spacer width={6} />
          <Icon name="lock" />
        </Flex>
      </Flex>
      <Spacer height={16} />
      <Flex align="center">
        <Flex align="center">
          <Text>redSecondary color</Text>
          <Spacer width={6} />
          <Icon name="lock" color="redSecondary" />
        </Flex>
        <Spacer width={16} />
        <Flex align="center">
          <Text>h2 size</Text>
          <Spacer width={6} />
          <Icon name="lock" size="h2" />
        </Flex>
        <Spacer width={16} />
        <Flex align="center">
          <Text>3em size</Text>
          <Spacer width={6} />
          <Icon name="lock" size="3em" />
        </Flex>
        <Spacer width={16} />
        <Flex align="center">
          <Text>64px skyblue</Text>
          <Spacer width={6} />
          <Icon name="lock" size="64px" color="skyblue" />
        </Flex>
      </Flex>

      <PlaygroundHeader>Card</PlaygroundHeader>
      <Card>
        <Text mods="heading h2">This is a card.</Text>
        <Spacer height={16} />
        <Text>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </Text>
      </Card>
      <Card color="bluePrimary3">
        <Text mods="heading h2">This is a colored card.</Text>
        <Spacer height={16} />
        <Text>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </Text>
      </Card>

      <PlaygroundHeader>Tooltip</PlaygroundHeader>
      <Flex align="center">
        <Text>This text has an accompanying tooltip.</Text>
        <Spacer width={6} />
        <Tooltip>
          <Text mods="lightGrey">This is the content of the tooltip.</Text>
        </Tooltip>
      </Flex>

      <Flex align="center">
        <Text>This text has an tooltip that appears on the bottom right.</Text>
        <Spacer width={6} />
        <Tooltip>
          <Text mods="lightGrey">This is the content of the tooltip.</Text>
        </Tooltip>
      </Flex>

      <Flex align="center">
        <Text mods="heading h2">This text has a large tooltip.</Text>
        <Spacer width={6} />
        <Tooltip size="h2">
          <Text mods="lightGrey">This is the content of the tooltip.</Text>
        </Tooltip>
      </Flex>
      <Accordion
        heading={
          <Flex>
            <Text mods="bold">Playground Dropzone</Text>
            <Spacer width={20} />
            <StyledAccordionArrow />
          </Flex>
        }
      >
        <DropzoneComponent />
        <Spacer height={15} />
        <Dropzone
          acceptedFileTypes={["png"]}
          onError={() => {}}
          onFilesUpload={() => {}}
          readOnly
          onBlur={() => {}}
        />
      </Accordion>

      <SpotifyPlayer width={300} height={380} />
    </PageWrapper>
  );
};

export default Playground;
