import {
  Flex,
  Spacer,
  Text,
  Button,
  UnstyledButton,
} from "@hackthenorth/north";
import moment from "moment";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { StarIcon, StarOutlineIcon } from "src/static/icons";
import { DesignImg } from "src/static/img/eventImgs";
import { ErrorModal } from "src/views/error/ErrorModal";
import { useScheduleContext } from "src/views/schedule/ScheduleContext";

import { EventIdToImgMap } from "./constants";
import { HomeEventProps } from "./types";

const EventCardContainer = styled(Button).attrs({
  removeSharedStyles: true,
})`
  background-color: ${({ theme }) => theme.globalConstants.color.white};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};
  border-radius: 10px;
  text-align: left;
  align-items: start;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 250px;
`;

const EventCardBottom = styled(Flex).attrs({
  column: true,
  justify: "space-between",
})`
  padding-top: 9px;
  padding-left: 10px;
  padding-right: 15px;
  padding-bottom: 12px;
  height: 100%;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  border-radius: 10px 10px 0 0;
`;

const IconButton = styled(UnstyledButton)`
  margin-left: 8px;
`;

const ExtraSubtleText = styled(Text)`
  font-size: 10px !important;
  line-height: 18px !important;
`;

const EventCard: React.FC<HomeEventProps> = ({ event }) => {
  // saving event is copied from Event in schedule
  const {
    enableSaving,
    getIsSaved,
    toggleSaveEvent,
    showPrivateLink,
  } = useScheduleContext();

  const [error, setError] = useState<Error | null>(null);
  const toggleSaveEventWithErrorHandling = useCallback(async () => {
    try {
      await toggleSaveEvent(event.id);
    } catch (e) {
      setError(e);
    }
  }, [event.id, toggleSaveEvent]);

  const now = moment();
  const timeTillEvent = moment.duration(event.start.diff(now)).asMinutes();

  const canJoin = timeTillEvent <= 30;

  return (
    <EventCardContainer disabled={!canJoin}>
      <ErrorModal error={error} onCancel={() => setError(null)} />
      <Img
        src={EventIdToImgMap[event.id] ?? DesignImg}
        alt={event.title}
        aria-hidden
      />
      <EventCardBottom>
        <div>
          <Flex justify="space-between" align="flex-start">
            <Text mods="lh-regular subtle">{event.title}</Text>
            {enableSaving && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSaveEventWithErrorHandling();
                }}
              >
                {getIsSaved(event.id) ? <StarIcon /> : <StarOutlineIcon />}
              </IconButton>
            )}
          </Flex>
          <Spacer width="4px" />
          <ExtraSubtleText mods="lightGrey lh-regular">
            {event.displayedStart} - {event.displayedEnd}
          </ExtraSubtleText>
        </div>
        <Spacer height="24px" />
        <Button
          href={
            showPrivateLink && event.location
              ? event.location
              : event.links?.[0]
          }
          mods="primary compact lessOpaqueDisabled"
          disabled={!canJoin}
        >
          {canJoin ? "Join" : "Join Soon"}
        </Button>
      </EventCardBottom>
    </EventCardContainer>
  );
};

export default EventCard;
