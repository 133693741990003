import React, { useState, useCallback } from "react";
import Select, { Props, OptionTypeBase } from "react-select";
import Creatable from "react-select/creatable";
import styled, { css } from "styled-components";

import MultiValueRemove from "../MultiSelect/MultiValueRemove";

export type TMultiSelectProps = Omit<Props, "theme"> & {
  onEnter?: () => void;
  focusOnRender?: boolean;
  max?: number;
  creatable?: boolean;
};

export const selectStyles = css<TMultiSelectProps>`
  outline: none;
  line-height: normal;
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary1};
  max-width: 100%;
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.bodyBig}px;

  padding-bottom: 15px;

  .select__control {
    border: 0px;
    box-shadow: none;
    border-bottom: ${({ theme }) =>
      `2px solid ${theme.globalConstants.color.navySecondary}`};
    border-radius: 0;
    &:hover {
      cursor: pointer;
      border-color: ${({ theme }) => theme.globalConstants.color.navySecondary};
    }
  }

  .select__option {
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    padding: 12px;
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    color: ${({ theme }) => theme.globalConstants.color.navySecondary};
    border: 2px solid ${({ theme }) => theme.globalConstants.color.bluePrimary1};
    border-top: 0;
    &:hover {
      cursor: pointer;
    }
  }

  .select__value-container {
    outline: none;
    flex-wrap: wrap;
  }

  .select__menu {
    margin-top: -14px;
    padding: 0px;
    box-shadow: none;
    position: absolute;
  }

  .select__menu-list {
    padding: 0;
  }

  .select__indicator-separator {
    visibility: hidden;
  }

  .select__indicators {
    outline: none;
  }

  .select__input {
    color: ${({ theme }) => theme.globalConstants.color.bluePrimary1};
    & input {
      font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
      line-height: normal;
    }
  }

  .select__placeholder {
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    opacity: 0.6;
    color: ${({ theme }) => theme.globalConstants.color.navySecondary};
  }

  .select__multi-value {
    padding: 6px 28px;
    border-radius: 43px;
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary1};
    color: white;
    align-items: center;
    margin-right: 12px;
    flex-wrap: wrap;
  }

  .select__multi-value__label {
    color: white;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    line-height: 30px;
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  }

  .select__clear-indicator {
    visibility: hidden;
  }

  .select__multi-value__remove {
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary1};
    margin-left: 16px;
    position: relative;
    bottom: -1px;
    &:focus,
    :hover {
      background-color: ${({ theme }) =>
        theme.globalConstants.color.bluePrimary1};
      cursor: pointer;
      > svg path {
        stroke: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
      }
    }
  }
`;

const StyledSelect = styled(Select)`
  ${selectStyles}
`;

const StyledCreatable = styled(Creatable)`
  ${selectStyles}
`;

const MultiSelect: React.FC<TMultiSelectProps> = ({
  onEnter,
  value,
  onChange,
  options,
  max,
  creatable = false,
  ...rest
}) => {
  const maxOptionsSelected = value && max ? value.length === max : false;
  const [inputValue, setInputValue] = useState<string>(
    value ? value.label : ""
  );
  const [displayedOptions, setDisplayedOptions] = useState<OptionTypeBase[]>(
    options || []
  );

  const handleEnterPress = useCallback(
    (e: KeyboardEvent) => {
      const menuClosed = displayedOptions.length === 0;
      if (e.keyCode === 13 && menuClosed && onEnter) {
        onEnter();
      }
    },
    [displayedOptions, onEnter]
  );

  const handleInputChange = (text: string) => {
    // on input change, "open" the menu again
    setDisplayedOptions(options);
    setInputValue(text);
  };

  const Select = creatable ? StyledCreatable : StyledSelect;

  return (
    <Select
      controlShouldRenderValue
      className="select"
      classNamePrefix="select"
      closeMenuOnSelect={false}
      onInputChange={handleInputChange}
      isMulti
      inputValue={inputValue}
      onKeyDown={handleEnterPress}
      onChange={onChange}
      options={maxOptionsSelected ? [] : displayedOptions}
      value={value}
      isSearchable
      tabSelectsValue={false}
      noOptionsMessage={() => null}
      components={{ MultiValueRemove }}
      {...rest}
    />
  );
};

export default MultiSelect;
