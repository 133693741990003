import { Spacer, Text, DropdownInput, Link } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";

import { timeToOption } from "src/shared/utils/react-select";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";
import { formatTime } from "./utils";

interface SocialMediaTakeoverDateProps {
  perk: SponsorPerks.SocialMediaTakeoverDate;
}

type StoredPerk = SponsorPerks.SocialMediaTakeoverDate;

const description = (
  <>
    <Text mods="medium">
      The social media takeover will highlight your company on our{" "}
      <Link newTab href="https://www.instagram.com/hackthenorth/" mods="medium">
        Instagram
      </Link>{" "}
      and{" "}
      <Link newTab href="https://www.facebook.com/hackthenorth" mods="medium">
        Facebook
      </Link>
      . This includes:
      <ul>
        <li>A 5-hour takeover on our Instagram stories (11 am to 4 pm EST)</li>
        <li>
          {" "}
          A post on our Instagram after the takeover with links to your
          resources (permanent post)
        </li>
      </ul>
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Here are some suggestions for the stories:
      <ul>
        <li>Introduction (name, position, etc.)</li>
        <li>Company intro</li>
        <li>Things your company is doing at Hack the North </li>
        <li>Resources and tips for students</li>
        <li>Internship experience</li>
        <li>Q&amp;A</li>
      </ul>
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) => !!perkData.takeover_date;

const SocialMediaTakeoverDate: React.FC<SocialMediaTakeoverDateProps> = ({
  perk,
}) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveCurPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitCurPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.SOCIAL_MEDIA_TAKEOVER_DATE}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Takeover day</Text>
        <Text mods="medium">
          Please select the day that you would like your social media takeover
          to take place. After submitting this, our marketing team will reach
          out with guidelines and information about login credentials.
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTime, perkData["takeover_date"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select day"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("takeover_date", option.value)
          }
        />
        <Spacer height={28} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_TAKEOVER_DATE}--website-link`}
          title="Please provide a way for us to contact you directly on the day of your
          takeover (e.g. cell phone number, Instagram account for DMs, Messenger
          contact)."
          value={perkData["contact_info"] ?? ""}
          placeholder="Your contact information"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("contact_info", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.SOCIAL_MEDIA_TAKEOVER_DATE}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveCurPerk}
          onSubmit={submitCurPerk}
        />
      </PerkContainer>
      <PerkError
        name={SponsorPerks.Type.SOCIAL_MEDIA_TAKEOVER_DATE}
        error={error}
      />
    </>
  );
};

export default SocialMediaTakeoverDate;
