import { Text, Button } from "@hackthenorth/north";
import React, { useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import { useHackerContext } from "src/shared/contexts";
import { HackerStage } from "src/shared/contexts/HackerContext/types";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import RsvpModal from "../modals/rsvpModal";

import TaskItem from "./taskItem";

const Container = styled.div`
  border-radius: 10px;
  position: relative;
  z-index: 2;
  padding: 40px 25% 48px 48px;
  :before {
    content: " ";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #f6c9c1 0%, rgba(255, 255, 255, 0) 100%),
      #91b8cd;
    border-radius: 10px;
    opacity: 0.5;
  }

  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 32px;
    width: 100vw;
    margin: -25px;
    border-radius: 0;
    ::before {
      border-radius: 0;
    }
  `};

  ${({ theme }) => theme.mediaQueries.smallMobile`
    margin: -20px;
  `}
`;

const TasksContainer = styled.div`
  margin-top: 24px;

  > div:not(:first-child) {
    margin-top: 24px;
  }

  ${({ theme }) => theme.mediaQueries.largeMobile`
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

const bounceAnimation = css`
  animation-name: wiggle;
  animation-duration: 1400ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;

  @keyframes wiggle {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const SubmitButton = styled(Button)<{ isCompleted: boolean }>`
  margin-top: 32px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin-top: 28px;
  `};

  ${({ isCompleted }) => isCompleted && bounceAnimation}
`;

const FadedTextSpan = styled.span`
  opacity: 0.5;
`;

const ACCEPTED_FIELDS = [
  Field.PERSONAL_RSVP_STAGE,
  Field.EVENT_RSVP_STAGE,
  Field.CAREER_RSVP_STAGE,
];

export default () => {
  const [isRsvpModalOpen, setIsRsvpModalOpen] = useState(false);
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const isPersonalPageCompleted =
    responsesState[Field.PERSONAL_RSVP_STAGE] === PageStage.COMPLETED;
  const isEventPageCompleted =
    responsesState[Field.EVENT_RSVP_STAGE] === PageStage.COMPLETED;
  const isCareerPageCompleted =
    responsesState[Field.CAREER_RSVP_STAGE] === PageStage.COMPLETED;

  const { updateStage } = useHackerContext();

  const tasksCompleted = useMemo(() => {
    let count = 0;
    if (isPersonalPageCompleted) count++;
    if (isEventPageCompleted) count++;
    if (isCareerPageCompleted) count++;
    return count;
  }, [isCareerPageCompleted, isEventPageCompleted, isPersonalPageCompleted]);

  const isDisabled = useMemo(() => {
    return (
      !isPersonalPageCompleted ||
      !isEventPageCompleted ||
      !isCareerPageCompleted
    );
  }, [isCareerPageCompleted, isEventPageCompleted, isPersonalPageCompleted]);

  const submitRsvp = useCallback(() => {
    updateStage(HackerStage.CONFIRMED);
    setIsRsvpModalOpen(false);
  }, [updateStage]);

  return (
    <>
      <RsvpModal
        isOpen={isRsvpModalOpen}
        onCancel={() => setIsRsvpModalOpen(false)}
        onConfirm={submitRsvp}
      />
      <Container>
        <Text mods="heading h2">
          Tasks <FadedTextSpan>({tasksCompleted}/3 complete)</FadedTextSpan>
        </Text>
        <TasksContainer>
          <TaskItem
            title="Personal Information"
            description="Tell us more about yourself!"
            status={
              (responsesState[Field.PERSONAL_RSVP_STAGE] ??
                PageStage.NOT_STARTED) as PageStage
            }
            link="/hacker/personal"
          />
          <TaskItem
            title="Event Information"
            description="Help us curate your Hack the North experience"
            status={
              (responsesState[Field.EVENT_RSVP_STAGE] ??
                PageStage.NOT_STARTED) as PageStage
            }
            link="/hacker/event"
          />
          <TaskItem
            title="Career Information"
            description="Get to know your sponsors"
            status={
              (responsesState[Field.CAREER_RSVP_STAGE] ??
                PageStage.NOT_STARTED) as PageStage
            }
            link="/hacker/career"
          />

          <SubmitButton
            mods="primary"
            disabled={isDisabled}
            onClick={() => setIsRsvpModalOpen(true)}
            isCompleted={tasksCompleted === 3}
          >
            Confirm your spot{" "}
            {isDisabled && `(${tasksCompleted}/3 tasks complete)`}
          </SubmitButton>
        </TasksContainer>
      </Container>
    </>
  );
};
