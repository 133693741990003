import moment from "moment";

export enum EventCategory {
  EVENT = "EVENT",
  WORKSHOP = "WORKSHOP",
  ACTIVITY = "ACTIVITY",
  SPONSORS = "SPONSORS",
  TECH_TALKS = "TECH TALKS",
}

export type TBaseEvent = {
  id: number;
  title: string;
  start: moment.Moment;
  end: moment.Moment;
  category: EventCategory;
  description: string;
  links: string[];
  location: string;
};

export type TEvent = TBaseEvent & {
  localStart: moment.Moment;
  localEnd: moment.Moment;
  displayedStart: string;
  displayedEnd: string;
  numberOfBlocks: number;
  blocksFromTop: number;
  overlapLevel: number;
  overlapStackSize: number;
  location?: string;
  isDuplicate?: boolean;
};

export type TComputedEvents = Record<string, TEvent[]>;

export type TBannerEvent = {
  id: number;
  title: string;
  start: moment.Moment;
};

export type TComputedBannerEvents = Record<string, TBannerEvent[]>;
