/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */
import { gql } from "@apollo/client";

import * as Types from "../../../../api/types.generated";

export type HackerFragmentFragment = { __typename?: "Claim" } & Pick<
  Types.Claim,
  "id" | "name" | "stage_id"
> & {
    fields: Array<
      { __typename?: "FieldWithAnswer" } & Pick<
        Types.FieldWithAnswer,
        "id" | "field_type" | "slug" | "valid_values" | "updated_at"
      > & {
          answer?: Types.Maybe<
            { __typename?: "FieldAnswer" } & Pick<
              Types.FieldAnswer,
              "id" | "value" | "updated_at"
            >
          >;
        }
    >;
  };

export const HackerFragmentFragmentDoc = gql`
  fragment hackerFragment on Claim {
    id
    name
    stage_id
    fields {
      id
      field_type
      slug
      answer {
        id
        value
        updated_at
      }
      valid_values
      updated_at
    }
  }
`;
