import { Text } from "@hackthenorth/north";
import styled from "styled-components";

const InfoTag = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  background: ${({ theme }) => theme.globalConstants.color.creamSecondaryDark};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
  && {
    margin: 3px;
  }
`;

export default InfoTag;
