import { Text, DropdownInput } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";
import styled from "styled-components";

import { useSponsorContext } from "src/shared/contexts";
import { capitalize } from "src/utils/string";
import * as Sponsor from "src/views/sponsor/types";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;

  padding: 8px 16px;
`;

type TRepresentativeItem = {
  isColored: boolean;
  representative: Sponsor.TRepresentative;
};

const Container = styled.div<{ isColored: boolean }>`
  background-color: ${({ isColored, theme }) =>
    isColored ? theme.globalConstants.color.redPrimary1Light : "transparent"};

  border-radius: 10px;
`;

const StyledDropdownInput = styled(DropdownInput)`
  max-width: 135px;
  font-size: ${({ theme }) =>
    theme.globalConstants.fontSize.bodyBig}px !important;
`;

const StyledText = styled(Text)`
  display: flex !important;
  align-items: center;
  font-size: ${({ theme }) =>
    theme.globalConstants.fontSize.bodyBig}px !important;
`;

const RepresentativeItem: React.FC<TRepresentativeItem> = ({
  isColored,
  representative,
}) => {
  const { individual, updateSponsorIndividual } = useSponsorContext();
  const isReadOnly = individual?.role !== Sponsor.Role.ADMIN;

  return (
    <Container isColored={isColored}>
      <Grid>
        <StyledText>{representative.name}</StyledText>
        <StyledDropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={{
            label: capitalize(representative.role),
            value: representative.role,
          }}
          readOnly={isReadOnly}
          options={Object.values(Sponsor.Role).map((role) => ({
            label: capitalize(role),
            value: role,
          }))}
          onChange={(option: OptionTypeBase) =>
            updateSponsorIndividual(
              {
                role: option.value,
              },
              representative.id
            )
          }
        />
      </Grid>
    </Container>
  );
};

export default RepresentativeItem;
