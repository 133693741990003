import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

export const textSharedStyles = css`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.body};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
  line-height: 26px;

  color: ${({ theme }) => theme.globalConstants.color.textDark};
  margin: 0;
  display: inline;
`;

export const textModifiers = {
  // font family
  heading: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
    line-height: normal;
    display: block;
    margin-bottom: 0.4em;
  `,

  // sizes
  title: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.title}px;
  `,
  h1: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h1}px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.hmobile}px;
    `}
  `,
  h2: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h2}px;
  `,
  hmobile: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.hmobile}px;
  `,
  h3: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.h3}px;
  `,
  big: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.bodyBig}px;
    line-height: 29px;
  `,
  subtle: css`
    font-size: ${({ theme }) => theme.globalConstants.fontSize.bodySubtle}px;
    line-height: 22px;
  `,
  // colors
  error: css`
    color: ${({ theme }) => theme.globalConstants.color.redSecondary};
  `,
  greenSecondary: css`
    color: ${({ theme }) => theme.globalConstants.color.greenSecondary};
  `,
  success: css`
    color: ${({ theme }) => theme.globalConstants.color.greenSecondary};
  `,
  grey: css`
    color: ${({ theme }) => theme.globalConstants.color.textGrey};
  `,
  lightGrey: css`
    color: ${({ theme }) => theme.globalConstants.color.textLightGrey};
  `,
  tanSecondary: css`
    color: ${({ theme }) => theme.globalConstants.color.tanSecondary};
  `,
  bluePrimary2: css`
    color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  `,
  white: css`
    color: ${({ theme }) => theme.globalConstants.color.white};
  `,
  darkBlue: css`
    color: ${({ theme }) => theme.globalConstants.color.textDarkBlue};
  `,

  // other
  bold: css`
    font-weight: bold;
  `,
  medium: css`
    font-weight: 500;
  `,
  italic: css`
    font-style: italic;
  `,
  center: css`
    text-align: center;
  `,
  "lh-regular": css`
    line-height: 1.5;
  `,
  "lh-small": css`
    line-height: 1;
  `,
  "no-decoration": css`
    text-decoration: none;
  `,
  nowrap: css`
    white-space: nowrap;
  `,
  code: css`
    font-family: ${({ theme }) => theme.globalConstants.fontFamily.code};
    color: ${({ theme }) => theme.globalConstants.color.navySecondary};
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary3};
    border-radius: 4px;
    padding: 1px 4px;
  `,

  link: css`
    color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  `,
};

export const textStyles: ThemeDefinition["text"] = {
  sharedStyles: textSharedStyles,
  modifiers: textModifiers,
};
