import { JWTRole, Text } from "@hackthenorth/north";
import { darken } from "polished";
import React from "react";
import { useLocation } from "react-router";
import styled, { css } from "styled-components";

import { UnstyledLink } from "src/shared/components";
import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
  TooltipWrapper,
} from "src/shared/components";
import Icon, { IconName } from "src/shared/components/Icon";
import {
  Permission,
  useHackerContext,
  usePermissionsContext,
  useUserContext,
} from "src/shared/contexts";

import { useGetTaskCompleted, useGetRSVPStatus } from "./utils";

export interface SidebarItem {
  text: string;
  icon?: IconName;
  onClick?: () => void;
  href?: string;
  subsectionContent?: SidebarItem[];
  lockedTooltipErrors?: Partial<Record<Permission, string>>;
  nested?: boolean;
  hasAccent?: boolean;
  requiredPermissions?: Permission[];
}

export type SidebarItemProps = SidebarItem &
  React.ComponentPropsWithoutRef<"div">;

const OuterContainer = styled.div<{
  nested?: boolean;
  disabled?: boolean;
  isHacker?: boolean;
}>`
  position: relative;
  width: 100%;

  border-bottom: ${({ theme, nested }) =>
    nested ? `1px solid ${theme.globalConstants.color.borderGrey}` : null};

  background-color: ${({ theme, nested, disabled, isHacker }) =>
    darken(
      disabled ? 0.2 : 0,
      theme.globalConstants.color[
        nested ? (isHacker ? "greySecondary" : "redPrimary3") : "white"
      ]
    )};
`;

const innerContainerStyles = css<{ nested?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${({ theme, nested }) =>
    nested ? null : `1px solid ${theme.globalConstants.color.borderGrey}`};

  position: relative;
  width: 100%;
  height: 56px;

  padding-left: ${({ nested }) => (nested ? 40 : 24)}px;
  padding-right: ${({ nested }) => (nested ? 40 : 24)}px;
  &:focus {
    outline: none;
  }
  &:focus > p {
    transform: ${({ disabled }) => (disabled ? `none` : `translateX(8px)`)};
  }
`;

const HoverSlideText = styled(Text)<{ disabled?: boolean }>`
  transition: ${({ disabled }) => (disabled ? `none` : `transform 200ms`)};
  &:hover {
    transform: ${({ disabled }) => (disabled ? `none` : `translateX(8px)`)};
  }
`;

const LinkContainer = styled(UnstyledLink)`
  ${innerContainerStyles}
`;

const ButtonContainer = styled.button`
  ${innerContainerStyles}
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
`;

const StyledAccordion = styled(Accordion)`
  justify-content: space-between;
  ${innerContainerStyles}
`;

const AccordionHeading = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &:focus {
    outline: none;
    transform: ${({ disabled }) => (disabled ? `none` : `translateX(8px)`)};
  }
`;

const TooltipText = styled(Text)`
  display: flex !important;
  max-width: 240px;
`;

const SidebarTooltip: React.FC<{ lockedTooltipText?: string }> = ({
  lockedTooltipText,
}) =>
  lockedTooltipText ? (
    <TooltipWrapper
      icon={<Icon name="lock" size="bodySubtle" />}
      content={<TooltipText>{lockedTooltipText}</TooltipText>}
      direction="top"
      eventDelay={1}
    />
  ) : (
    <Icon name="lock" size="bodySubtle" />
  );

const SideAccent = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.globalConstants.color.redPrimary1};
  width: 8px;
  position: absolute;
  left: 0px;
`;

const RSVPStatusText = styled.div<{ color: string }>`
  color: ${({ theme, color }) => theme.globalConstants.color[color]};
  display: inline;
`;

const RSVP_STATUS_COLOUR = {
  "Spot Confirmed": "greenSecondary",
  Expired: "redPrimary2",
  "Not Confirmed": "redPrimary2",
  Waitlisted: "bluePrimary2",
  Withdrawn: "bluePrimary2",
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  text,
  icon,
  href,
  subsectionContent,
  onClick = () => {},
  lockedTooltipErrors,
  nested = false,
  requiredPermissions = [],
}) => {
  const { hasPermission } = usePermissionsContext();
  const locked = requiredPermissions?.some((p) => !hasPermission(p));
  const firstFailedPermission = requiredPermissions?.find(
    (p) => !hasPermission(p)
  );
  const lockedTooltipText = firstFailedPermission
    ? lockedTooltipErrors?.[firstFailedPermission]
    : undefined;
  const disabled = locked;

  const { stage } = useHackerContext();
  const completed = useGetTaskCompleted(text);
  const rsvpStatus = useGetRSVPStatus(stage);
  const { roles } = useUserContext();

  const isHacker = roles.includes(JWTRole.HACKER);
  const location = useLocation();
  const hasAccent =
    location.pathname === href ||
    (isHacker && location.pathname === "/" && text === "RSVP");

  const TextElement =
    text === "RSVP" ? (
      <Text mods="bold">
        {text}
        {text === "RSVP" && (
          <>
            :{" "}
            <RSVPStatusText color={RSVP_STATUS_COLOUR[rsvpStatus]}>
              {rsvpStatus}
            </RSVPStatusText>
          </>
        )}
      </Text>
    ) : (
      text
    );

  const InnerItem = (
    <>
      {hasAccent ? <SideAccent className="hello" /> : null}
      <HoverSlideText disabled={disabled}>{TextElement}</HoverSlideText>
      {locked ? (
        <SidebarTooltip lockedTooltipText={lockedTooltipText} />
      ) : icon ? (
        <Icon name={icon} size="bodySubtle" />
      ) : null}
      {completed &&
      !locked &&
      requiredPermissions?.includes(Permission.HACKER_RSVP_NOT_RESTRICTED) ? (
        <Icon name="check" size="bodyBig" color="greenSecondary" />
      ) : null}
    </>
  );

  return (
    <OuterContainer nested={nested} disabled={disabled} isHacker={isHacker}>
      {subsectionContent ? (
        <StyledAccordion
          heading={
            <AccordionHeading disabled={disabled}>
              <HoverSlideText disabled={disabled}>{text}</HoverSlideText>
              {locked ? (
                <SidebarTooltip lockedTooltipText={lockedTooltipText} />
              ) : (
                <AccordionArrow />
              )}
            </AccordionHeading>
          }
          disabled={disabled}
          isDefaultOpen={isHacker}
        >
          {subsectionContent.map((item) => (
            <SidebarItem key={item.text} {...item} nested />
          ))}
        </StyledAccordion>
      ) : href ? (
        <LinkContainer
          href={disabled ? undefined : href}
          nested={nested}
          disabled={disabled}
          role="link"
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : 0}
        >
          {InnerItem}
        </LinkContainer>
      ) : (
        <ButtonContainer
          onClick={() => (disabled ? null : onClick())}
          nested={nested}
          disabled={disabled}
          role="button"
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : 0}
        >
          {InnerItem}
        </ButtonContainer>
      )}
    </OuterContainer>
  );
};

export default SidebarItem;
