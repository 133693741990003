import { JWTRole, useAuth } from "@hackthenorth/north";
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router";

import { client } from "src/api/client";
import { EVENT_SLUG } from "src/api/constants";
import { BaseRoute } from "src/shared/constants/route";

import { useGetMeLazyQuery } from "./graphql/getMe.generated";

export interface Info {
  name: string;
  email?: string;
}

interface UserContextState {
  id: number | null;
  info: Info | null;
  roles: JWTRole[];
  isAuthenticated: boolean;
  isOrganizer: boolean;

  logOut: () => void;
  logIn: () => void;
  logInToPath: (path: string) => void;
  error?: string;
}

const DEFAULT_STATE: UserContextState = {
  id: null,
  roles: [],
  info: null,
  isAuthenticated: false,
  isOrganizer: false,

  logOut: () => {},
  logIn: () => {},
  logInToPath: () => {},
};

const UserContext: React.Context<UserContextState> = createContext(
  DEFAULT_STATE
);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { token, logOut: authLogOut, tokenExpired, loginUrl } = useAuth();
  const [getMe, { data, error }] = useGetMeLazyQuery();

  // wait to fetch the user since the query will not re-run even if token changes
  useEffect(() => {
    if (token) getMe();
  }, [token, getMe]);

  /**
   * Build UserState
   */
  const id: UserContextState["id"] = useMemo(() => token?.id ?? null, [token]);

  const roles: UserContextState["roles"] = useMemo(
    () => token?.roles[EVENT_SLUG] ?? [],
    [token]
  );

  const isAuthenticated: UserContextState["isAuthenticated"] = useMemo(
    () => !!token,
    [token]
  );

  const info: UserContextState["info"] = useMemo(
    () =>
      data && isAuthenticated
        ? {
            name: data.me.name,
            email: token?.email,
          }
        : null,
    [data, isAuthenticated, token]
  );

  const isOrganizer: UserContextState["isOrganizer"] = useMemo(
    () => !!token?.is_organizer,
    [token]
  );

  const logOut: UserContextState["logOut"] = useCallback(() => {
    authLogOut();
    navigate(BaseRoute.HOME);
    client.cache.reset();
  }, [navigate, authLogOut]);

  const logIn: UserContextState["logIn"] = () => {
    window.location.href = loginUrl();
  };

  // This must be a separate function to avoid breaking past use cases of logIn
  // Adding `path` as an optional parameter to logIn
  // would for example break <Button onClick={logIn} ...
  // as some weird click event gets passed in as the first parameter
  // I didn't feel like just doing type checking for string bc that feels less safe
  const logInToPath: UserContextState["logInToPath"] = (path: string) => {
    window.location.href = loginUrl(path);
  };

  useEffect(() => {
    // Log the user out if JWT is invalid
    if (tokenExpired) logOut();
  }, [token, logOut, tokenExpired]);

  /**
   * Build state
   */
  const userState: UserContextState = {
    id,
    info,
    roles,
    isAuthenticated,
    isOrganizer,
    logOut,
    logIn,
    logInToPath,
    error: error?.message,
  };

  return (
    <UserContext.Provider value={userState}>{children}</UserContext.Provider>
  );
};
