import { Field } from "src/shared/contexts/HackerContext/types";

export enum HackerFilter {
  FAVORITED = "Favorited",
  INTERESTED = "Interested",
  COFFEE_CHAT = "Coffee chat",
  SEEKING_INTERNSHIP = "Seeking internship",
  SEEKING_FULLTIME = "Seeking full-time",
  CANADIAN_CITIZEN = "Canadian citizen",
  PERMANENT_RESIDENT = "Permanent resident",
  NAME = "Name",
  SCHOOL = "School",
  PROGRAM = "Program",
  ORGANIZER = "Organizer",
}

export const HACKER_FILTERS_TO_FIELDS: Record<
  HackerFilter,
  Partial<Field> | null
> = {
  [HackerFilter.FAVORITED]: null,
  [HackerFilter.INTERESTED]: Field.COMPANY_INTEREST,
  [HackerFilter.COFFEE_CHAT]: Field.COFFEE_CHAT,
  [HackerFilter.SEEKING_INTERNSHIP]: Field.INTERNSHIP_OPPORTUNITIES,
  [HackerFilter.SEEKING_FULLTIME]: Field.FULL_TIME_OPPORTUNITIES,
  [HackerFilter.CANADIAN_CITIZEN]: Field.CITIZENSHIP,
  [HackerFilter.PERMANENT_RESIDENT]: Field.PERMANENT_RESIDENT,
  [HackerFilter.NAME]: Field.FULL_NAME,
  [HackerFilter.SCHOOL]: Field.SCHOOL,
  [HackerFilter.PROGRAM]: Field.PROGRAM,
  [HackerFilter.ORGANIZER]: null,
};

export const HACKER_FILTERS: HackerFilter[] = Object.values(HackerFilter);
