import { Button, Spacer } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal, SelectCard } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import { AVATAR_MAP } from "./constants";

interface ChangeAvatarModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 30px;
  row-gap: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -48px;
`;

const Img = styled.img`
  width: 72px;
`;

const ACCEPTED_FIELDS = [Field.AVATAR];

const ChangeAvatarModal: React.FC<ChangeAvatarModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  const { updateResponses } = useHackerContext();
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const [currentColor, setCurrentColor] = useState(
    responsesState[Field.AVATAR]
  );

  useEffect(() => {
    setCurrentColor(responsesState[Field.AVATAR]);
  }, [responsesState]);

  const onSave = () => {
    // cannot use setResponsesState, it won't update responses in the same block - updateResponses will be out of sync
    updateResponses({ [Field.AVATAR]: currentColor });
    onConfirm();
  };

  const onCancelChange = () => {
    setCurrentColor(responsesState[Field.AVATAR]);
    onCancel();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancelChange}
      width="720px"
      title="Change your avatar 🎨 :"
      actions={
        <>
          <Button mods="secondary" onClick={onCancelChange}>
            Cancel
          </Button>
          <Spacer width="20px" />
          <Button mods="primary" onClick={onSave}>
            Save Changes
          </Button>
        </>
      }
    >
      <Wrapper>
        <Container>
          {Object.keys(AVATAR_MAP).map((color) => (
            <SelectCard
              key={color}
              selected={color === currentColor}
              onSelect={() => setCurrentColor(color)}
            >
              <Img src={AVATAR_MAP[color]} alt="" aria-hidden />
            </SelectCard>
          ))}
        </Container>
      </Wrapper>
    </Modal>
  );
};

export default ChangeAvatarModal;
