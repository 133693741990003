import { Spacer, Text, DropdownInput } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";

import { useSponsorContext } from "src/shared/contexts/SponsorContext";
import { answerToOption, timeToOption } from "src/shared/utils/react-select";
import { addTwoHours, formatTimeRange } from "src/utils/formatTime";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkRadioButtons,
} from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

interface TechTalkTimeProps {
  perk: SponsorPerks.TechTalkTime;
}

type StoredPerk = SponsorPerks.TechTalkTime;

const description = (
  <>
    <Text mods="medium">Your tech talk has been chosen by hackers!</Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please rank the times that work best for you from those listed below. Once
      this perk has been submitted, our team will be in contact to finalize your
      time slot.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.representative &&
  !!perkData.time_one &&
  !!perkData.time_two &&
  !!perkData.time_three &&
  // check that all date values are unique
  perkData.time_one !== perkData.time_two &&
  perkData.time_three !== perkData.time_two &&
  perkData.time_one !== perkData.time_three;

const TechTalkTime: React.FC<TechTalkTimeProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { representatives } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveTechTalkTimePerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitTechTalkTimePerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.TECH_TALK_TIME}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Timeslot Rankings</Text>
        <Text mods="italic">
          Note that all times are in your browser&apos;s local time zone.
        </Text>
        <Spacer height={8} />
        <Text mods="medium">First Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_one"],
            addTwoHours(perkData["time_one"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_one", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Second Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_two"],
            addTwoHours(perkData["time_two"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_two", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Third Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_three"],
            addTwoHours(perkData["time_three"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_three", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">
          Who will be moderating the tech talk? (This person will have access to
          the Hopin call and be able to add/remove speakers)
        </Text>
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={answerToOption(perkData["representative"])}
          readOnly={isReadOnly}
          options={
            representatives?.map((representative) =>
              answerToOption(representative.name)
            ) ?? []
          }
          onChange={(option: OptionTypeBase) =>
            updatePerkField("representative", option.value)
          }
          placeholder="Select representative"
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["accepted_hackers_only"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.TECH_TALK_TIME}---accepted-hackers`}
          title="Would you like your session attendees to be limited to accepted hackers only?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("accepted_hackers_only", true);
            } else {
              updatePerkField("accepted_hackers_only", false);
            }
          }}
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["recording_available"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.TECH_TALK_TIME}--recording`}
          title="Would you like a recording of your session to be made publicly available after the event?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("recording_available", true);
            } else {
              updatePerkField("recording_available", false);
            }
          }}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.TECH_TALK_TIME}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveTechTalkTimePerk}
          onSubmit={submitTechTalkTimePerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.TECH_TALK_TIME} error={error} />
    </>
  );
};

export default TechTalkTime;
