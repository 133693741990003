import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { BLOCK_HEIGHT, SMALL_COLUMN_WIDTH } from "../constants";
import { HOURS_LIST } from "../constants";

const CalendarColumnSmall = styled.div`
  width: ${SMALL_COLUMN_WIDTH}px;
`;

const TimeBlock = styled.div`
  height: ${BLOCK_HEIGHT}px;
  position: relative;
`;

const TimeBlockInner = styled.div`
  position: absolute;
  display: flex;
  height: ${BLOCK_HEIGHT * 2}px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const TimeMark = styled.div`
  margin-left: 8px;
  margin-top: 1px;
  border-bottom: 1px solid #c3ccd7;
  height: 0;
  width: 15px;
`;

export const TimeColumn = () => (
  <CalendarColumnSmall>
    {HOURS_LIST.map((hour) => (
      <TimeBlock key={hour}>
        <TimeBlockInner>
          <Text mods="subtle">{hour}</Text>
          <TimeMark />
        </TimeBlockInner>
      </TimeBlock>
    ))}
  </CalendarColumnSmall>
);
