/* eslint-disable @typescript-eslint/camelcase */
import { Text, Card, Flex, Spacer } from "@hackthenorth/north";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { useUserContext } from "src/shared/contexts";
import * as Milestones from "src/shared/contexts/MilestonesContext/types";

import ActionItem from "./ActionItem";

interface ActionColumnProps {
  title: string;
  actionItems: Milestones.Milestone[];
  emptyElement: ReactElement;
}

const getCompletedBy = (milestone: Milestones.Milestone) => {
  switch (milestone.name) {
    case Milestones.Name.CUSTOM:
      return "Completed";

    default:
      return null;
  }
};

const EmptyColumnItem = styled(Flex)`
  position: relative;
  z-index: 2;
`;

const ColumnItemsContainer = styled(Card)`
  width: 100%;
  position: relative;
  background: none;
  z-index: 1;
  padding: 24px;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: ${({ theme }) => theme.globalConstants.color.redPrimary1};
    opacity: 0.5;
    -webkit-opacity: 0.5;
  }

  > :not(:first-child) {
    margin-top: 24px;
  }
`;

const Container = styled.div`
  width: 336px;
`;

const ActionColumn: React.FC<ActionColumnProps> = ({
  title,
  actionItems,
  emptyElement,
}) => {
  const { id } = useUserContext();

  return (
    <Container>
      <Text mods="h3 heading">{title}</Text>
      <Spacer height={16} />
      <ColumnItemsContainer>
        {actionItems.length === 0 ? (
          <EmptyColumnItem column align="center">
            <Spacer height={48} />
            {emptyElement}
            <Spacer height={24} />
            <Text mods="bold">No tasks</Text>
            <Spacer height={48} />
          </EmptyColumnItem>
        ) : (
          actionItems.map((item) => (
            <ActionItem
              key={item.name}
              name={item.name}
              dueDate={item.dueDate}
              isIndividual={item.owner_id === id}
              isComplete={item.complete}
              completedBy={getCompletedBy(item)}
              data={item.data}
            />
          ))
        )}
      </ColumnItemsContainer>
    </Container>
  );
};

export default ActionColumn;
