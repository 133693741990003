import React from "react";
import { components } from "react-select";

import { Icon } from "src/shared/components";

// todo: fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueRemoveComponent = (props: any) => (
  <components.MultiValueRemove {...props}>
    <Icon name="x" size="16px" />
  </components.MultiValueRemove>
);

export default MultiValueRemoveComponent;
