import { Text, Link, Grid } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import {
  OutlineDocument,
  PageWrapper,
  TOPNAVBAR_HEIGHT,
} from "src/shared/components";
import { Route } from "src/shared/constants/route";

import { JUDGING_SECTIONS } from "./constants";

const Section = styled.section`
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.borderGrey};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.regular};
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const TableOfContents = styled(Section)`
  width: 329px;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  :hover {
    text-decoration: underline !important;
  }
`;

const SCROLL_OFFSET = 50;

const SectionScrollTarget = styled.div`
  position: relative;
  top: -${TOPNAVBAR_HEIGHT + SCROLL_OFFSET}px;
`;

const JUDGING_SHARE_ID = "02c9e0a6-7fda-4886-bf52-4432b7fd2fec";

const Judging: React.FC = () => (
  <PageWrapper scrollToTop>
    <Grid columns={1} rows="auto" rowGap={48}>
      <OutlineDocument shareId={JUDGING_SHARE_ID} />
      <TableOfContents>
        <Grid columns={1} rows="auto" rowGap={12}>
          <Text mods="heading h2">Table of contents</Text>
          {JUDGING_SECTIONS.map(({ label, sectionId }) => (
            <StyledLink
              mods="medium big"
              key={sectionId}
              href={`${Route.HACKER_JUDGING}/#${sectionId}`}
            >
              {label}
            </StyledLink>
          ))}
        </Grid>
      </TableOfContents>
      {JUDGING_SECTIONS.map(({ sectionId, shareId }) => (
        <Section key={sectionId}>
          <SectionScrollTarget id={sectionId} />
          <OutlineDocument shareId={shareId} noTitle />
        </Section>
      ))}
    </Grid>
  </PageWrapper>
);

export default Judging;
