/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Roles = {
  __typename?: "Roles";
  id: Scalars["Float"];
  roles: Array<Scalars["String"]>;
};

export type EventAttendee = {
  __typename?: "EventAttendee";
  badge_codes?: Maybe<Array<Scalars["String"]>>;
  created_at: Scalars["DateTime"];
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  role: Scalars["String"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["Int"];
};

export type FileDownload = {
  __typename?: "FileDownload";
  uri: Scalars["String"];
  name: Scalars["String"];
  expiry: Scalars["String"];
};

export type File = {
  __typename?: "File";
  created_at: Scalars["DateTime"];
  event_slug?: Maybe<Scalars["String"]>;
  file_type: Scalars["String"];
  id: Scalars["Int"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["DateTime"];
  user_id?: Maybe<Scalars["Int"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  download: FileDownload;
};

export type Field = {
  __typename?: "Field";
  created_at: Scalars["DateTime"];
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  valid_values?: Maybe<Array<Scalars["String"]>>;
};

export type Stage = {
  __typename?: "Stage";
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  count: Scalars["Int"];
};

export type Pipeline = {
  __typename?: "Pipeline";
  created_at: Scalars["DateTime"];
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTime"]>;
  event_slug: Scalars["String"];
  id: Scalars["Int"];
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  relationships?: Maybe<Array<Scalars["String"]>>;
  slug: Scalars["String"];
  stage_order?: Maybe<Array<Scalars["Int"]>>;
  start_time?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  count: Scalars["Float"];
  stages: Array<Stage>;
  fields: Array<Field>;
};

export type Claim = {
  __typename?: "Claim";
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  pipeline_id: Scalars["Int"];
  stage_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["Int"];
  user: User;
  stage: Stage;
  fields: Array<FieldWithAnswer>;
  reviews: Array<Review>;
};

export type OrderedClaims = {
  __typename?: "OrderedClaims";
  order?: Maybe<Array<AugmentedClaim>>;
  start_time: Scalars["String"];
};

export type User = {
  __typename?: "User";
  address_id?: Maybe<Scalars["Int"]>;
  allergies?: Maybe<Array<Scalars["String"]>>;
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  email_hashes?: Maybe<Array<Scalars["String"]>>;
  emergency_contact_address_id?: Maybe<Scalars["Int"]>;
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  food_restrictions?: Maybe<Array<Scalars["String"]>>;
  gender?: Maybe<Gender>;
  hacker_education_id?: Maybe<Scalars["Int"]>;
  hacker_hackathons?: Maybe<Array<Scalars["String"]>>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  hacker_skills?: Maybe<Array<Scalars["String"]>>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  mentor_skills?: Maybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shipping_address_id?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  sponsor_skills?: Maybe<Array<Scalars["String"]>>;
  updated_at: Scalars["DateTime"];
  claims: Array<Claim>;
};

export enum Gender {
  Female = "female",
  Male = "male",
  Other = "other",
  PreferNotToSpecify = "prefer_not_to_specify",
}

export type AcademicInstitution = {
  __typename?: "AcademicInstitution";
  address_id?: Maybe<Scalars["Int"]>;
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at: Scalars["DateTime"];
  website?: Maybe<Scalars["String"]>;
};

export enum InstitutionTypeEnum {
  HighSchool = "high_school",
  MiddleSchool = "middle_school",
  Other = "other",
  PostSecondary = "post_secondary",
}

export type UpdatePasswordSuccess = {
  __typename?: "UpdatePasswordSuccess";
  email: Scalars["String"];
  message: Scalars["String"];
};

export type Company = {
  __typename?: "Company";
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  website: Scalars["String"];
};

export type Collaborator = {
  __typename?: "Collaborator";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  user_id: Scalars["Int"];
  claim: Claim;
  user: User;
};

export type FieldAnswer = {
  __typename?: "FieldAnswer";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  field_id: Scalars["Int"];
  id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
  value: Scalars["JSON"];
};

export type FieldWithAnswer = {
  __typename?: "FieldWithAnswer";
  created_at: Scalars["DateTime"];
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
  field_type: Scalars["String"];
  id: Scalars["Int"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  pipeline_id: Scalars["Int"];
  slug?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  valid_values?: Maybe<Array<Scalars["String"]>>;
  answer?: Maybe<FieldAnswer>;
};

export type JwtToken = {
  __typename?: "JWTToken";
  jwt: Scalars["String"];
  user: User;
};

export type Review = {
  __typename?: "Review";
  claim_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  id: Scalars["Int"];
  note?: Maybe<Scalars["String"]>;
  reviewer_id: Scalars["Int"];
  updated_at: Scalars["DateTime"];
};

export type Sponsor = {
  __typename?: "Sponsor";
  available_perks?: Maybe<Scalars["JSON"]>;
  company_id: Scalars["Int"];
  created_at: Scalars["DateTime"];
  event_id: Scalars["Int"];
  feature_description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  invoice_id?: Maybe<Scalars["Int"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type Success = {
  __typename?: "Success";
  status: Scalars["String"];
};

export type MailingList = {
  __typename?: "MailingList";
  created_at: Scalars["DateTime"];
  email: Scalars["String"];
  event_slug: Scalars["String"];
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
};

export type Email = {
  __typename?: "Email";
  campaign: Scalars["String"];
  context?: Maybe<Scalars["JSON"]>;
  created_at: Scalars["DateTime"];
  html: Scalars["String"];
  id: Scalars["Int"];
  send_time: Scalars["DateTime"];
  sent: Scalars["Boolean"];
  subject: Scalars["String"];
  to_email: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type Event = {
  __typename?: "Event";
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  themes?: Maybe<Array<Scalars["String"]>>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at: Scalars["DateTime"];
  website?: Maybe<Scalars["String"]>;
};

export type AccessToken = {
  __typename?: "AccessToken";
  access_token: Scalars["String"];
};

export type AugmentedClaim = {
  __typename?: "AugmentedClaim";
  claim_id: Scalars["Float"];
  stage_id: Scalars["Float"];
  user_id: Scalars["Float"];
  position: Scalars["String"];
  name: Scalars["String"];
  discord_id?: Maybe<Scalars["String"]>;
};

export type OutlineDocument = {
  __typename?: "OutlineDocument";
  id: Scalars["String"];
  url: Scalars["String"];
  title: Scalars["String"];
  text: Scalars["String"];
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  publishedAt: Scalars["String"];
};

export type AggregateStatistics = {
  __typename?: "AggregateStatistics";
  count: Scalars["Float"];
};

export type HealthResponse = {
  __typename?: "HealthResponse";
  healthy: Scalars["Boolean"];
};

export type ScheduleItem = {
  __typename?: "ScheduleItem";
  created_at: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  event_id: Scalars["Int"];
  id: Scalars["Int"];
  links?: Maybe<Array<Scalars["String"]>>;
  location: Scalars["String"];
  start_time: Scalars["DateTime"];
  tags?: Maybe<Array<Scalars["String"]>>;
  title: Scalars["String"];
  updated_at: Scalars["DateTime"];
};

export type FileInput = {
  fieldId: Scalars["Float"];
  type: Scalars["String"];
  file: Scalars["Upload"];
};

/** Create stage without pipeline id */
export type CustomFieldCreateWithoutPipelineInput = {
  name: Scalars["String"];
  slug: Scalars["String"];
  field_type: Scalars["String"];
  is_required: Scalars["Boolean"];
  permissions?: Maybe<Scalars["JSON"]>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
};

/** Update stages data */
export type StageUpdateInput = {
  id: Scalars["Float"];
  name?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
};

/** Create stages */
export type StageCreateInput = {
  name: Scalars["String"];
  pipeline_id: Scalars["Float"];
  permissions?: Maybe<Scalars["JSON"]>;
};

/** Create stage without pipeline id */
export type CustomStageCreateWithoutPipelineInput = {
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
};

/** Update pipeline data */
export type PipelineUpdateInput = {
  slug: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Create pipeline data */
export type PipelineCreateInput = {
  slug: Scalars["String"];
  description: Scalars["String"];
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  event_slug: Scalars["String"];
  stages: Array<CustomStageCreateWithoutPipelineInput>;
  fields: Array<CustomFieldCreateWithoutPipelineInput>;
};

/** Update claim data */
export type ClaimUpdateInput = {
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  stage_id?: Maybe<Scalars["Int"]>;
  answers?: Maybe<Array<FieldAnswerInput>>;
  user_id?: Maybe<Scalars["Int"]>;
  files?: Maybe<Array<FileInput>>;
};

/** Create claim data */
export type ClaimCreateInput = {
  name: Scalars["String"];
  stage_id: Scalars["Float"];
  pipeline_id: Scalars["Float"];
  answers?: Maybe<Array<FieldAnswerInput>>;
  files?: Maybe<Array<FileInput>>;
  owner_id?: Maybe<Scalars["Int"]>;
};

export type FieldAnswerInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  answer?: Maybe<Scalars["JSON"]>;
};

export type Query = {
  __typename?: "Query";
  claim: Claim;
  claims: Array<Claim>;
  getClaimStatistics: AggregateStatistics;
  claimsByCollaborator: Array<Claim>;
  collaborators: Array<Collaborator>;
  company: Company;
  companies: Array<Company>;
  claimByDiscordId: Claim;
  getStartTime?: Maybe<Scalars["String"]>;
  orderedClaims: OrderedClaims;
  emails: Array<Email>;
  field: Field;
  file: File;
  files: Array<File>;
  outlineDocument: OutlineDocument;
  healthy: HealthResponse;
  institution: AcademicInstitution;
  institutions: Array<AcademicInstitution>;
  mailingListEntries: Array<MailingList>;
  pipeline: Pipeline;
  review: Review;
  reviews: Array<Review>;
  eventSchedule: Array<ScheduleItem>;
  checkSponsorExists: Scalars["Boolean"];
  user: User;
  me: User;
  users: Array<User>;
};

export type QueryClaimArgs = {
  id: Scalars["Int"];
};

export type QueryClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
  orderBy?: Maybe<Array<ClaimOrderByInput>>;
  cursor?: Maybe<ClaimWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<ClaimDistinctFieldEnum>>;
};

export type QueryGetClaimStatisticsArgs = {
  where?: Maybe<ClaimWhereInput>;
  orderBy?: Maybe<Array<ClaimOrderByInput>>;
  cursor?: Maybe<ClaimWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<ClaimDistinctFieldEnum>>;
};

export type QueryClaimsByCollaboratorArgs = {
  pipeline_slug: Scalars["String"];
  user_id: Scalars["Float"];
};

export type QueryCollaboratorsArgs = {
  claim_id: Scalars["Float"];
};

export type QueryCompanyArgs = {
  id: Scalars["Float"];
};

export type QueryClaimByDiscordIdArgs = {
  discord_field_id?: Maybe<Scalars["Float"]>;
  pipeline_id: Scalars["Float"];
  discord_id: Scalars["String"];
};

export type QueryGetStartTimeArgs = {
  start_time_field_id: Scalars["Float"];
  sponsor_claim_id: Scalars["Float"];
};

export type QueryOrderedClaimsArgs = {
  pipeline_id: Scalars["Float"];
  in_progress_stage_id: Scalars["Float"];
  rep_field_id: Scalars["Float"];
  start_time_field_id: Scalars["Float"];
  position_field_id: Scalars["Float"];
  sponsor_claim_id: Scalars["Float"];
};

export type QueryEmailsArgs = {
  email: Scalars["String"];
};

export type QueryFieldArgs = {
  id: Scalars["Float"];
};

export type QueryFileArgs = {
  id: Scalars["Float"];
};

export type QueryFilesArgs = {
  where?: Maybe<FileWhereInput>;
  orderBy?: Maybe<Array<FileOrderByInput>>;
  cursor?: Maybe<FileWhereUniqueInput>;
  take?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  distinct?: Maybe<Array<FileDistinctFieldEnum>>;
};

export type QueryOutlineDocumentArgs = {
  share_id: Scalars["String"];
};

export type QueryInstitutionArgs = {
  id: Scalars["Float"];
};

export type QueryMailingListEntriesArgs = {
  email: Scalars["String"];
};

export type QueryPipelineArgs = {
  slug: Scalars["String"];
};

export type QueryReviewArgs = {
  id: Scalars["Float"];
};

export type QueryEventScheduleArgs = {
  slug: Scalars["String"];
};

export type QueryCheckSponsorExistsArgs = {
  email: Scalars["String"];
  jwt: Scalars["String"];
};

export type QueryUserArgs = {
  id: Scalars["Float"];
};

export type ClaimWhereInput = {
  AND?: Maybe<Array<ClaimWhereInput>>;
  OR?: Maybe<Array<ClaimWhereInput>>;
  NOT?: Maybe<Array<ClaimWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  pipeline_id?: Maybe<IntFilter>;
  stage_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
  stage?: Maybe<StageRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntFilter>;
};

export type StringFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export enum QueryMode {
  Default = "default",
  Insensitive = "insensitive",
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringFilter>;
};

export type PipelineRelationFilter = {
  is?: Maybe<PipelineWhereInput>;
  isNot?: Maybe<PipelineWhereInput>;
};

export type PipelineWhereInput = {
  AND?: Maybe<Array<PipelineWhereInput>>;
  OR?: Maybe<Array<PipelineWhereInput>>;
  NOT?: Maybe<Array<PipelineWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  max_num_claims?: Maybe<IntNullableFilter>;
  max_num_collaborators?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  relationships?: Maybe<StringNullableListFilter>;
  slug?: Maybe<StringFilter>;
  stage_order?: Maybe<IntNullableListFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  fields?: Maybe<FieldListRelationFilter>;
  stages?: Maybe<StageListRelationFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars["DateTime"]>;
  in?: Maybe<Array<Scalars["DateTime"]>>;
  notIn?: Maybe<Array<Scalars["DateTime"]>>;
  lt?: Maybe<Scalars["DateTime"]>;
  lte?: Maybe<Scalars["DateTime"]>;
  gt?: Maybe<Scalars["DateTime"]>;
  gte?: Maybe<Scalars["DateTime"]>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars["Int"]>;
  in?: Maybe<Array<Scalars["Int"]>>;
  notIn?: Maybe<Array<Scalars["Int"]>>;
  lt?: Maybe<Scalars["Int"]>;
  lte?: Maybe<Scalars["Int"]>;
  gt?: Maybe<Scalars["Int"]>;
  gte?: Maybe<Scalars["Int"]>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type JsonNullableFilter = {
  equals?: Maybe<Scalars["JSON"]>;
  not?: Maybe<Scalars["JSON"]>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars["String"]>>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars["Int"]>>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars["String"]>;
  in?: Maybe<Array<Scalars["String"]>>;
  notIn?: Maybe<Array<Scalars["String"]>>;
  lt?: Maybe<Scalars["String"]>;
  lte?: Maybe<Scalars["String"]>;
  gt?: Maybe<Scalars["String"]>;
  gte?: Maybe<Scalars["String"]>;
  contains?: Maybe<Scalars["String"]>;
  startsWith?: Maybe<Scalars["String"]>;
  endsWith?: Maybe<Scalars["String"]>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type EventRelationFilter = {
  is?: Maybe<EventWhereInput>;
  isNot?: Maybe<EventWhereInput>;
};

export type EventWhereInput = {
  AND?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  NOT?: Maybe<Array<EventWhereInput>>;
  contact_email?: Maybe<StringNullableFilter>;
  cost?: Maybe<IntNullableFilter>;
  cost_currency?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  has_food?: Maybe<BoolNullableFilter>;
  has_reimbursements?: Maybe<BoolNullableFilter>;
  id?: Maybe<IntFilter>;
  judging_model?: Maybe<StringNullableFilter>;
  length_hours?: Maybe<IntNullableFilter>;
  max_age_restriction?: Maybe<IntNullableFilter>;
  min_age_restriction?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  slug?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  submission_url?: Maybe<StringNullableFilter>;
  swarm_event_id?: Maybe<StringNullableFilter>;
  tags?: Maybe<StringNullableListFilter>;
  themes?: Maybe<StringNullableListFilter>;
  timezone?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  event_activities?: Maybe<EventActivityListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  event_faqs?: Maybe<EventFaqListRelationFilter>;
  event_schedule?: Maybe<EventScheduleListRelationFilter>;
  event_updates?: Maybe<EventUpdateListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  mail_templates?: Maybe<MailTemplateListRelationFilter>;
  mailing_list?: Maybe<MailingListListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  pipelines?: Maybe<PipelineListRelationFilter>;
  sponsors?: Maybe<SponsorListRelationFilter>;
  submissions?: Maybe<SubmissionListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type EventActivityListRelationFilter = {
  every?: Maybe<EventActivityWhereInput>;
  some?: Maybe<EventActivityWhereInput>;
  none?: Maybe<EventActivityWhereInput>;
};

export type EventActivityWhereInput = {
  AND?: Maybe<Array<EventActivityWhereInput>>;
  OR?: Maybe<Array<EventActivityWhereInput>>;
  NOT?: Maybe<Array<EventActivityWhereInput>>;
  category?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  end_time?: Maybe<DateTimeNullableFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  restrictions?: Maybe<JsonNullableFilter>;
  start_time?: Maybe<DateTimeNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionListRelationFilter>;
};

export type EventActivityRedemptionListRelationFilter = {
  every?: Maybe<EventActivityRedemptionWhereInput>;
  some?: Maybe<EventActivityRedemptionWhereInput>;
  none?: Maybe<EventActivityRedemptionWhereInput>;
};

export type EventActivityRedemptionWhereInput = {
  AND?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  OR?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  NOT?: Maybe<Array<EventActivityRedemptionWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_activity_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  override_reason?: Maybe<StringNullableFilter>;
  scan_time?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  volunteer_id?: Maybe<IntFilter>;
  event_activity?: Maybe<EventActivityRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  volunteer?: Maybe<UserRelationFilter>;
};

export type EventActivityRelationFilter = {
  is?: Maybe<EventActivityWhereInput>;
  isNot?: Maybe<EventActivityWhereInput>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  allergies?: Maybe<StringNullableListFilter>;
  avatar?: Maybe<StringNullableFilter>;
  bio?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_of_birth?: Maybe<DateTimeNullableFilter>;
  email?: Maybe<StringFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emergency_contact_address_id?: Maybe<IntNullableFilter>;
  emergency_contact_email?: Maybe<StringNullableFilter>;
  emergency_contact_name?: Maybe<StringNullableFilter>;
  emergency_contact_phone_number?: Maybe<StringNullableFilter>;
  emergency_contact_relationship?: Maybe<StringNullableFilter>;
  ethnicity?: Maybe<StringNullableFilter>;
  food_restrictions?: Maybe<StringNullableListFilter>;
  gender?: Maybe<EnumGenderNullableFilter>;
  hacker_education_id?: Maybe<IntNullableFilter>;
  hacker_hackathons?: Maybe<StringNullableListFilter>;
  hacker_school_email?: Maybe<StringNullableFilter>;
  hacker_skills?: Maybe<StringNullableListFilter>;
  hacker_travel_id?: Maybe<IntNullableFilter>;
  hacker_urls_id?: Maybe<IntNullableFilter>;
  id?: Maybe<IntFilter>;
  legal_name?: Maybe<StringNullableFilter>;
  mentor_location?: Maybe<StringNullableFilter>;
  mentor_skills?: Maybe<StringNullableListFilter>;
  name?: Maybe<StringFilter>;
  native_language?: Maybe<StringNullableFilter>;
  password?: Maybe<StringFilter>;
  phone_number?: Maybe<StringNullableFilter>;
  role?: Maybe<IntNullableFilter>;
  shipping_address_id?: Maybe<IntNullableFilter>;
  shirt_size?: Maybe<IntNullableFilter>;
  sponsor_company_id?: Maybe<IntNullableFilter>;
  sponsor_personal_email?: Maybe<StringNullableFilter>;
  sponsor_role?: Maybe<StringNullableFilter>;
  sponsor_skills?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  address?: Maybe<AddressRelationFilter>;
  emergency_contact_address?: Maybe<AddressRelationFilter>;
  education?: Maybe<EducationRelationFilter>;
  travel_information?: Maybe<TravelInformationRelationFilter>;
  urls?: Maybe<UrlRelationFilter>;
  shipping_address?: Maybe<AddressRelationFilter>;
  company?: Maybe<CompanyRelationFilter>;
  auth_tokens?: Maybe<AuthTokenListRelationFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
  collaborators?: Maybe<CollaboratorListRelationFilter>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionListRelationFilter>;
  event_attendees?: Maybe<EventAttendeeListRelationFilter>;
  files?: Maybe<FileListRelationFilter>;
  firebase_tokens?: Maybe<FirebaseTokenListRelationFilter>;
  forgotten_passwords?: Maybe<ForgottenPasswordListRelationFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
  mentors?: Maybe<MentorListRelationFilter>;
  person?: Maybe<PersonListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  reviews?: Maybe<ReviewListRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
  teams?: Maybe<TeamListRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
};

export type EnumGenderNullableFilter = {
  equals?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  notIn?: Maybe<Array<Gender>>;
  not?: Maybe<NestedEnumGenderNullableFilter>;
};

export type NestedEnumGenderNullableFilter = {
  equals?: Maybe<Gender>;
  in?: Maybe<Array<Gender>>;
  notIn?: Maybe<Array<Gender>>;
  not?: Maybe<NestedEnumGenderNullableFilter>;
};

export type AddressRelationFilter = {
  is?: Maybe<AddressWhereInput>;
  isNot?: Maybe<AddressWhereInput>;
};

export type AddressWhereInput = {
  AND?: Maybe<Array<AddressWhereInput>>;
  OR?: Maybe<Array<AddressWhereInput>>;
  NOT?: Maybe<Array<AddressWhereInput>>;
  city?: Maybe<StringFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  latitude?: Maybe<FloatNullableFilter>;
  line_1?: Maybe<StringFilter>;
  line_2?: Maybe<StringNullableFilter>;
  line_3?: Maybe<StringNullableFilter>;
  longitude?: Maybe<FloatNullableFilter>;
  state_province?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  zip_postal_code?: Maybe<StringFilter>;
  academic_institutions?: Maybe<AcademicInstitutionListRelationFilter>;
  users_as_address?: Maybe<UserListRelationFilter>;
  users_as_emergency_contact_address?: Maybe<UserListRelationFilter>;
  users_as_shipping_address?: Maybe<UserListRelationFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars["Float"]>;
  in?: Maybe<Array<Scalars["Float"]>>;
  notIn?: Maybe<Array<Scalars["Float"]>>;
  lt?: Maybe<Scalars["Float"]>;
  lte?: Maybe<Scalars["Float"]>;
  gt?: Maybe<Scalars["Float"]>;
  gte?: Maybe<Scalars["Float"]>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type AcademicInstitutionListRelationFilter = {
  every?: Maybe<AcademicInstitutionWhereInput>;
  some?: Maybe<AcademicInstitutionWhereInput>;
  none?: Maybe<AcademicInstitutionWhereInput>;
};

export type AcademicInstitutionWhereInput = {
  AND?: Maybe<Array<AcademicInstitutionWhereInput>>;
  OR?: Maybe<Array<AcademicInstitutionWhereInput>>;
  NOT?: Maybe<Array<AcademicInstitutionWhereInput>>;
  address_id?: Maybe<IntNullableFilter>;
  country?: Maybe<StringFilter>;
  country_code?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  institution_type?: Maybe<EnumInstitutionTypeEnumFilter>;
  is_custom?: Maybe<BoolFilter>;
  is_enabled?: Maybe<BoolFilter>;
  name?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringNullableFilter>;
  address?: Maybe<AddressRelationFilter>;
  educations?: Maybe<EducationListRelationFilter>;
};

export type EnumInstitutionTypeEnumFilter = {
  equals?: Maybe<InstitutionTypeEnum>;
  in?: Maybe<Array<InstitutionTypeEnum>>;
  notIn?: Maybe<Array<InstitutionTypeEnum>>;
  not?: Maybe<NestedEnumInstitutionTypeEnumFilter>;
};

export type NestedEnumInstitutionTypeEnumFilter = {
  equals?: Maybe<InstitutionTypeEnum>;
  in?: Maybe<Array<InstitutionTypeEnum>>;
  notIn?: Maybe<Array<InstitutionTypeEnum>>;
  not?: Maybe<NestedEnumInstitutionTypeEnumFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars["Boolean"]>;
  not?: Maybe<NestedBoolFilter>;
};

export type EducationListRelationFilter = {
  every?: Maybe<EducationWhereInput>;
  some?: Maybe<EducationWhereInput>;
  none?: Maybe<EducationWhereInput>;
};

export type EducationWhereInput = {
  AND?: Maybe<Array<EducationWhereInput>>;
  OR?: Maybe<Array<EducationWhereInput>>;
  NOT?: Maybe<Array<EducationWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  current_status?: Maybe<StringFilter>;
  current_year?: Maybe<IntNullableFilter>;
  degree?: Maybe<StringNullableFilter>;
  graduating_year?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  institution_id?: Maybe<IntFilter>;
  minor?: Maybe<StringNullableFilter>;
  program?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  academic_institution?: Maybe<AcademicInstitutionRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
};

export type AcademicInstitutionRelationFilter = {
  is?: Maybe<AcademicInstitutionWhereInput>;
  isNot?: Maybe<AcademicInstitutionWhereInput>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type EducationRelationFilter = {
  is?: Maybe<EducationWhereInput>;
  isNot?: Maybe<EducationWhereInput>;
};

export type TravelInformationRelationFilter = {
  is?: Maybe<TravelInformationWhereInput>;
  isNot?: Maybe<TravelInformationWhereInput>;
};

export type TravelInformationWhereInput = {
  AND?: Maybe<Array<TravelInformationWhereInput>>;
  OR?: Maybe<Array<TravelInformationWhereInput>>;
  NOT?: Maybe<Array<TravelInformationWhereInput>>;
  citizenship_country?: Maybe<StringNullableFilter>;
  closest_airport?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  current_city?: Maybe<StringNullableFilter>;
  expiry_date?: Maybe<DateTimeNullableFilter>;
  id?: Maybe<IntFilter>;
  issue_date?: Maybe<DateTimeNullableFilter>;
  passport_number?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  visa_country?: Maybe<StringNullableFilter>;
  visa_type?: Maybe<StringNullableFilter>;
  users?: Maybe<UserRelationFilter>;
};

export type UrlRelationFilter = {
  is?: Maybe<UrlWhereInput>;
  isNot?: Maybe<UrlWhereInput>;
};

export type UrlWhereInput = {
  AND?: Maybe<Array<UrlWhereInput>>;
  OR?: Maybe<Array<UrlWhereInput>>;
  NOT?: Maybe<Array<UrlWhereInput>>;
  behance?: Maybe<StringNullableFilter>;
  bitbucket?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  deviantart?: Maybe<StringNullableFilter>;
  devpost?: Maybe<StringNullableFilter>;
  dribbble?: Maybe<StringNullableFilter>;
  facebook?: Maybe<StringNullableFilter>;
  flickr?: Maybe<StringNullableFilter>;
  github?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  instagram?: Maybe<StringNullableFilter>;
  jsfiddle?: Maybe<StringNullableFilter>;
  linkedin?: Maybe<StringNullableFilter>;
  portfolio?: Maybe<StringNullableFilter>;
  resume?: Maybe<StringNullableFilter>;
  soundcloud?: Maybe<StringNullableFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  users?: Maybe<UserRelationFilter>;
};

export type CompanyRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  twitter?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  website?: Maybe<StringFilter>;
  sponsors?: Maybe<SponsorRelationFilter>;
  users?: Maybe<UserRelationFilter>;
};

export type SponsorRelationFilter = {
  is?: Maybe<SponsorWhereInput>;
  isNot?: Maybe<SponsorWhereInput>;
};

export type SponsorWhereInput = {
  AND?: Maybe<Array<SponsorWhereInput>>;
  OR?: Maybe<Array<SponsorWhereInput>>;
  NOT?: Maybe<Array<SponsorWhereInput>>;
  available_perks?: Maybe<JsonNullableFilter>;
  company_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  feature_description?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  invoice_id?: Maybe<IntNullableFilter>;
  poc_accepted_code_of_conduct?: Maybe<BoolNullableFilter>;
  tier?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  company?: Maybe<CompanyRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  files?: Maybe<FileRelationFilter>;
  prizes?: Maybe<PrizeListRelationFilter>;
  representatives?: Maybe<RepresentativeListRelationFilter>;
  sponsor_scans?: Maybe<SponsorScanListRelationFilter>;
};

export type FileRelationFilter = {
  is?: Maybe<FileWhereInput>;
  isNot?: Maybe<FileWhereInput>;
};

export type FileWhereInput = {
  AND?: Maybe<Array<FileWhereInput>>;
  OR?: Maybe<Array<FileWhereInput>>;
  NOT?: Maybe<Array<FileWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringNullableFilter>;
  file_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  mime_type?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  s3_path?: Maybe<StringFilter>;
  size?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  sponsors?: Maybe<SponsorRelationFilter>;
};

export type PrizeListRelationFilter = {
  every?: Maybe<PrizeWhereInput>;
  some?: Maybe<PrizeWhereInput>;
  none?: Maybe<PrizeWhereInput>;
};

export type PrizeWhereInput = {
  AND?: Maybe<Array<PrizeWhereInput>>;
  OR?: Maybe<Array<PrizeWhereInput>>;
  NOT?: Maybe<Array<PrizeWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  prize?: Maybe<StringFilter>;
  quantity?: Maybe<IntNullableFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
};

export type RepresentativeListRelationFilter = {
  every?: Maybe<RepresentativeWhereInput>;
  some?: Maybe<RepresentativeWhereInput>;
  none?: Maybe<RepresentativeWhereInput>;
};

export type RepresentativeWhereInput = {
  AND?: Maybe<Array<RepresentativeWhereInput>>;
  OR?: Maybe<Array<RepresentativeWhereInput>>;
  NOT?: Maybe<Array<RepresentativeWhereInput>>;
  activated?: Maybe<BoolNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  is_poc?: Maybe<BoolNullableFilter>;
  role?: Maybe<StringNullableFilter>;
  skills?: Maybe<StringNullableListFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type SponsorScanListRelationFilter = {
  every?: Maybe<SponsorScanWhereInput>;
  some?: Maybe<SponsorScanWhereInput>;
  none?: Maybe<SponsorScanWhereInput>;
};

export type SponsorScanWhereInput = {
  AND?: Maybe<Array<SponsorScanWhereInput>>;
  OR?: Maybe<Array<SponsorScanWhereInput>>;
  NOT?: Maybe<Array<SponsorScanWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  sponsor_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  sponsor?: Maybe<SponsorRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type AuthTokenListRelationFilter = {
  every?: Maybe<AuthTokenWhereInput>;
  some?: Maybe<AuthTokenWhereInput>;
  none?: Maybe<AuthTokenWhereInput>;
};

export type AuthTokenWhereInput = {
  AND?: Maybe<Array<AuthTokenWhereInput>>;
  OR?: Maybe<Array<AuthTokenWhereInput>>;
  NOT?: Maybe<Array<AuthTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  expire_time?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  token?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ClaimListRelationFilter = {
  every?: Maybe<ClaimWhereInput>;
  some?: Maybe<ClaimWhereInput>;
  none?: Maybe<ClaimWhereInput>;
};

export type CollaboratorListRelationFilter = {
  every?: Maybe<CollaboratorWhereInput>;
  some?: Maybe<CollaboratorWhereInput>;
  none?: Maybe<CollaboratorWhereInput>;
};

export type CollaboratorWhereInput = {
  AND?: Maybe<Array<CollaboratorWhereInput>>;
  OR?: Maybe<Array<CollaboratorWhereInput>>;
  NOT?: Maybe<Array<CollaboratorWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ClaimRelationFilter = {
  is?: Maybe<ClaimWhereInput>;
  isNot?: Maybe<ClaimWhereInput>;
};

export type EventAttendeeListRelationFilter = {
  every?: Maybe<EventAttendeeWhereInput>;
  some?: Maybe<EventAttendeeWhereInput>;
  none?: Maybe<EventAttendeeWhereInput>;
};

export type EventAttendeeWhereInput = {
  AND?: Maybe<Array<EventAttendeeWhereInput>>;
  OR?: Maybe<Array<EventAttendeeWhereInput>>;
  NOT?: Maybe<Array<EventAttendeeWhereInput>>;
  badge_codes?: Maybe<StringNullableListFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  role?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type FileListRelationFilter = {
  every?: Maybe<FileWhereInput>;
  some?: Maybe<FileWhereInput>;
  none?: Maybe<FileWhereInput>;
};

export type FirebaseTokenListRelationFilter = {
  every?: Maybe<FirebaseTokenWhereInput>;
  some?: Maybe<FirebaseTokenWhereInput>;
  none?: Maybe<FirebaseTokenWhereInput>;
};

export type FirebaseTokenWhereInput = {
  AND?: Maybe<Array<FirebaseTokenWhereInput>>;
  OR?: Maybe<Array<FirebaseTokenWhereInput>>;
  NOT?: Maybe<Array<FirebaseTokenWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  value?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type ForgottenPasswordListRelationFilter = {
  every?: Maybe<ForgottenPasswordWhereInput>;
  some?: Maybe<ForgottenPasswordWhereInput>;
  none?: Maybe<ForgottenPasswordWhereInput>;
};

export type ForgottenPasswordWhereInput = {
  AND?: Maybe<Array<ForgottenPasswordWhereInput>>;
  OR?: Maybe<Array<ForgottenPasswordWhereInput>>;
  NOT?: Maybe<Array<ForgottenPasswordWhereInput>>;
  callback_url?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_disabled?: Maybe<BoolNullableFilter>;
  token?: Maybe<StringFilter>;
  token_only?: Maybe<BoolFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type MentorRequestCommentListRelationFilter = {
  every?: Maybe<MentorRequestCommentWhereInput>;
  some?: Maybe<MentorRequestCommentWhereInput>;
  none?: Maybe<MentorRequestCommentWhereInput>;
};

export type MentorRequestCommentWhereInput = {
  AND?: Maybe<Array<MentorRequestCommentWhereInput>>;
  OR?: Maybe<Array<MentorRequestCommentWhereInput>>;
  NOT?: Maybe<Array<MentorRequestCommentWhereInput>>;
  author_id?: Maybe<IntFilter>;
  body?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  request_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor_request?: Maybe<MentorRequestRelationFilter>;
};

export type MentorRequestRelationFilter = {
  is?: Maybe<MentorRequestWhereInput>;
  isNot?: Maybe<MentorRequestWhereInput>;
};

export type MentorRequestWhereInput = {
  AND?: Maybe<Array<MentorRequestWhereInput>>;
  OR?: Maybe<Array<MentorRequestWhereInput>>;
  NOT?: Maybe<Array<MentorRequestWhereInput>>;
  anonymous?: Maybe<BoolFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  hacker_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  mentor_id?: Maybe<IntNullableFilter>;
  request?: Maybe<StringFilter>;
  resolved?: Maybe<BoolFilter>;
  tags?: Maybe<JsonNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor?: Maybe<MentorRelationFilter>;
  mentor_request_comments?: Maybe<MentorRequestCommentListRelationFilter>;
};

export type MentorRelationFilter = {
  is?: Maybe<MentorWhereInput>;
  isNot?: Maybe<MentorWhereInput>;
};

export type MentorWhereInput = {
  AND?: Maybe<Array<MentorWhereInput>>;
  OR?: Maybe<Array<MentorWhereInput>>;
  NOT?: Maybe<Array<MentorWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  skills?: Maybe<JsonNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  event?: Maybe<EventRelationFilter>;
  user?: Maybe<UserRelationFilter>;
  mentor_requests?: Maybe<MentorRequestListRelationFilter>;
};

export type MentorRequestListRelationFilter = {
  every?: Maybe<MentorRequestWhereInput>;
  some?: Maybe<MentorRequestWhereInput>;
  none?: Maybe<MentorRequestWhereInput>;
};

export type MentorListRelationFilter = {
  every?: Maybe<MentorWhereInput>;
  some?: Maybe<MentorWhereInput>;
  none?: Maybe<MentorWhereInput>;
};

export type PersonListRelationFilter = {
  every?: Maybe<PersonWhereInput>;
  some?: Maybe<PersonWhereInput>;
  none?: Maybe<PersonWhereInput>;
};

export type PersonWhereInput = {
  AND?: Maybe<Array<PersonWhereInput>>;
  OR?: Maybe<Array<PersonWhereInput>>;
  NOT?: Maybe<Array<PersonWhereInput>>;
  company?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  email_hashes?: Maybe<StringNullableListFilter>;
  emails?: Maybe<StringNullableListFilter>;
  family_name?: Maybe<StringNullableFilter>;
  full_name?: Maybe<StringNullableFilter>;
  given_name?: Maybe<StringNullableFilter>;
  id?: Maybe<IntFilter>;
  interests?: Maybe<StringNullableListFilter>;
  middle_names?: Maybe<StringNullableFilter>;
  phone_number?: Maybe<StringFilter>;
  relationship?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumPersonRoleFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntNullableFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type EnumPersonRoleFilter = {
  equals?: Maybe<PersonRole>;
  in?: Maybe<Array<PersonRole>>;
  notIn?: Maybe<Array<PersonRole>>;
  not?: Maybe<NestedEnumPersonRoleFilter>;
};

export enum PersonRole {
  EmergencyContact = "emergency_contact",
  Guest = "guest",
  Judge = "judge",
  Mentor = "mentor",
  Organizer = "organizer",
  Recruiter = "recruiter",
  Speaker = "speaker",
  Sponsor = "sponsor",
  Volunteer = "volunteer",
}

export type NestedEnumPersonRoleFilter = {
  equals?: Maybe<PersonRole>;
  in?: Maybe<Array<PersonRole>>;
  notIn?: Maybe<Array<PersonRole>>;
  not?: Maybe<NestedEnumPersonRoleFilter>;
};

export type ReviewListRelationFilter = {
  every?: Maybe<ReviewWhereInput>;
  some?: Maybe<ReviewWhereInput>;
  none?: Maybe<ReviewWhereInput>;
};

export type ReviewWhereInput = {
  AND?: Maybe<Array<ReviewWhereInput>>;
  OR?: Maybe<Array<ReviewWhereInput>>;
  NOT?: Maybe<Array<ReviewWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  note?: Maybe<StringNullableFilter>;
  reviewer_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type TeamMemberListRelationFilter = {
  every?: Maybe<TeamMemberWhereInput>;
  some?: Maybe<TeamMemberWhereInput>;
  none?: Maybe<TeamMemberWhereInput>;
};

export type TeamMemberWhereInput = {
  AND?: Maybe<Array<TeamMemberWhereInput>>;
  OR?: Maybe<Array<TeamMemberWhereInput>>;
  NOT?: Maybe<Array<TeamMemberWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  event_slug?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user_id?: Maybe<IntFilter>;
  event?: Maybe<EventRelationFilter>;
  team?: Maybe<TeamRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type TeamRelationFilter = {
  is?: Maybe<TeamWhereInput>;
  isNot?: Maybe<TeamWhereInput>;
};

export type TeamWhereInput = {
  AND?: Maybe<Array<TeamWhereInput>>;
  OR?: Maybe<Array<TeamWhereInput>>;
  NOT?: Maybe<Array<TeamWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<IntNullableFilter>;
  disabled?: Maybe<BoolFilter>;
  event_slug?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  user?: Maybe<UserRelationFilter>;
  event?: Maybe<EventRelationFilter>;
  team_members?: Maybe<TeamMemberListRelationFilter>;
};

export type TeamListRelationFilter = {
  every?: Maybe<TeamWhereInput>;
  some?: Maybe<TeamWhereInput>;
  none?: Maybe<TeamWhereInput>;
};

export type UserSubmissionListRelationFilter = {
  every?: Maybe<UserSubmissionWhereInput>;
  some?: Maybe<UserSubmissionWhereInput>;
  none?: Maybe<UserSubmissionWhereInput>;
};

export type UserSubmissionWhereInput = {
  AND?: Maybe<Array<UserSubmissionWhereInput>>;
  OR?: Maybe<Array<UserSubmissionWhereInput>>;
  NOT?: Maybe<Array<UserSubmissionWhereInput>>;
  submission_id?: Maybe<IntFilter>;
  user_id?: Maybe<IntFilter>;
  submission?: Maybe<SubmissionRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type SubmissionRelationFilter = {
  is?: Maybe<SubmissionWhereInput>;
  isNot?: Maybe<SubmissionWhereInput>;
};

export type SubmissionWhereInput = {
  AND?: Maybe<Array<SubmissionWhereInput>>;
  OR?: Maybe<Array<SubmissionWhereInput>>;
  NOT?: Maybe<Array<SubmissionWhereInput>>;
  contact_info?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  location?: Maybe<StringNullableFilter>;
  name?: Maybe<StringFilter>;
  repository_urls?: Maybe<StringNullableListFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  url?: Maybe<StringFilter>;
  event?: Maybe<EventRelationFilter>;
  user_submissions?: Maybe<UserSubmissionListRelationFilter>;
};

export type EventFaqListRelationFilter = {
  every?: Maybe<EventFaqWhereInput>;
  some?: Maybe<EventFaqWhereInput>;
  none?: Maybe<EventFaqWhereInput>;
};

export type EventFaqWhereInput = {
  AND?: Maybe<Array<EventFaqWhereInput>>;
  OR?: Maybe<Array<EventFaqWhereInput>>;
  NOT?: Maybe<Array<EventFaqWhereInput>>;
  answer?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  question?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventScheduleListRelationFilter = {
  every?: Maybe<EventScheduleWhereInput>;
  some?: Maybe<EventScheduleWhereInput>;
  none?: Maybe<EventScheduleWhereInput>;
};

export type EventScheduleWhereInput = {
  AND?: Maybe<Array<EventScheduleWhereInput>>;
  OR?: Maybe<Array<EventScheduleWhereInput>>;
  NOT?: Maybe<Array<EventScheduleWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  end_time?: Maybe<DateTimeFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringFilter>;
  start_time?: Maybe<DateTimeFilter>;
  tags?: Maybe<StringNullableListFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type EventUpdateListRelationFilter = {
  every?: Maybe<EventUpdateWhereInput>;
  some?: Maybe<EventUpdateWhereInput>;
  none?: Maybe<EventUpdateWhereInput>;
};

export type EventUpdateWhereInput = {
  AND?: Maybe<Array<EventUpdateWhereInput>>;
  OR?: Maybe<Array<EventUpdateWhereInput>>;
  NOT?: Maybe<Array<EventUpdateWhereInput>>;
  category?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringFilter>;
  event_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  links?: Maybe<StringNullableListFilter>;
  location?: Maybe<StringNullableFilter>;
  send_time?: Maybe<DateTimeNullableFilter>;
  title?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type MailTemplateListRelationFilter = {
  every?: Maybe<MailTemplateWhereInput>;
  some?: Maybe<MailTemplateWhereInput>;
  none?: Maybe<MailTemplateWhereInput>;
};

export type MailTemplateWhereInput = {
  AND?: Maybe<Array<MailTemplateWhereInput>>;
  OR?: Maybe<Array<MailTemplateWhereInput>>;
  NOT?: Maybe<Array<MailTemplateWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  event_id?: Maybe<IntFilter>;
  html?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  slug?: Maybe<StringFilter>;
  subject?: Maybe<StringFilter>;
  trigger_id?: Maybe<IntNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
  mail_trigger?: Maybe<MailTriggerRelationFilter>;
};

export type MailTriggerRelationFilter = {
  is?: Maybe<MailTriggerWhereInput>;
  isNot?: Maybe<MailTriggerWhereInput>;
};

export type MailTriggerWhereInput = {
  AND?: Maybe<Array<MailTriggerWhereInput>>;
  OR?: Maybe<Array<MailTriggerWhereInput>>;
  NOT?: Maybe<Array<MailTriggerWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  record_id?: Maybe<IntFilter>;
  record_type?: Maybe<EnumRecordTypeEmailFilter>;
  trigger_type?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  mail_templates?: Maybe<MailTemplateRelationFilter>;
};

export type EnumRecordTypeEmailFilter = {
  equals?: Maybe<RecordTypeEmail>;
  in?: Maybe<Array<RecordTypeEmail>>;
  notIn?: Maybe<Array<RecordTypeEmail>>;
  not?: Maybe<NestedEnumRecordTypeEmailFilter>;
};

export enum RecordTypeEmail {
  Claim = "claim",
  Event = "event",
  File = "file",
  Pipeline = "pipeline",
  User = "user",
}

export type NestedEnumRecordTypeEmailFilter = {
  equals?: Maybe<RecordTypeEmail>;
  in?: Maybe<Array<RecordTypeEmail>>;
  notIn?: Maybe<Array<RecordTypeEmail>>;
  not?: Maybe<NestedEnumRecordTypeEmailFilter>;
};

export type MailTemplateRelationFilter = {
  is?: Maybe<MailTemplateWhereInput>;
  isNot?: Maybe<MailTemplateWhereInput>;
};

export type MailingListListRelationFilter = {
  every?: Maybe<MailingListWhereInput>;
  some?: Maybe<MailingListWhereInput>;
  none?: Maybe<MailingListWhereInput>;
};

export type MailingListWhereInput = {
  AND?: Maybe<Array<MailingListWhereInput>>;
  OR?: Maybe<Array<MailingListWhereInput>>;
  NOT?: Maybe<Array<MailingListWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  event_slug?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  event?: Maybe<EventRelationFilter>;
};

export type PipelineListRelationFilter = {
  every?: Maybe<PipelineWhereInput>;
  some?: Maybe<PipelineWhereInput>;
  none?: Maybe<PipelineWhereInput>;
};

export type SponsorListRelationFilter = {
  every?: Maybe<SponsorWhereInput>;
  some?: Maybe<SponsorWhereInput>;
  none?: Maybe<SponsorWhereInput>;
};

export type SubmissionListRelationFilter = {
  every?: Maybe<SubmissionWhereInput>;
  some?: Maybe<SubmissionWhereInput>;
  none?: Maybe<SubmissionWhereInput>;
};

export type FieldListRelationFilter = {
  every?: Maybe<FieldWhereInput>;
  some?: Maybe<FieldWhereInput>;
  none?: Maybe<FieldWhereInput>;
};

export type FieldWhereInput = {
  AND?: Maybe<Array<FieldWhereInput>>;
  OR?: Maybe<Array<FieldWhereInput>>;
  NOT?: Maybe<Array<FieldWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  fcfs_available?: Maybe<StringNullableListFilter>;
  field_type?: Maybe<StringFilter>;
  id?: Maybe<IntFilter>;
  is_required?: Maybe<BoolNullableFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  slug?: Maybe<StringNullableFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  valid_values?: Maybe<StringNullableListFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
  field_answers?: Maybe<FieldAnswerListRelationFilter>;
};

export type FieldAnswerListRelationFilter = {
  every?: Maybe<FieldAnswerWhereInput>;
  some?: Maybe<FieldAnswerWhereInput>;
  none?: Maybe<FieldAnswerWhereInput>;
};

export type FieldAnswerWhereInput = {
  AND?: Maybe<Array<FieldAnswerWhereInput>>;
  OR?: Maybe<Array<FieldAnswerWhereInput>>;
  NOT?: Maybe<Array<FieldAnswerWhereInput>>;
  claim_id?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  field_id?: Maybe<IntFilter>;
  id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  value?: Maybe<StringNullableFilter>;
  claim?: Maybe<ClaimRelationFilter>;
  field?: Maybe<FieldRelationFilter>;
};

export type FieldRelationFilter = {
  is?: Maybe<FieldWhereInput>;
  isNot?: Maybe<FieldWhereInput>;
};

export type StageListRelationFilter = {
  every?: Maybe<StageWhereInput>;
  some?: Maybe<StageWhereInput>;
  none?: Maybe<StageWhereInput>;
};

export type StageWhereInput = {
  AND?: Maybe<Array<StageWhereInput>>;
  OR?: Maybe<Array<StageWhereInput>>;
  NOT?: Maybe<Array<StageWhereInput>>;
  created_at?: Maybe<DateTimeFilter>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  permissions?: Maybe<JsonNullableFilter>;
  pipeline_id?: Maybe<IntFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  pipeline?: Maybe<PipelineRelationFilter>;
  claims?: Maybe<ClaimListRelationFilter>;
};

export type StageRelationFilter = {
  is?: Maybe<StageWhereInput>;
  isNot?: Maybe<StageWhereInput>;
};

export type ClaimOrderByInput = {
  created_at?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  pipeline_id?: Maybe<SortOrder>;
  stage_id?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  user_id?: Maybe<SortOrder>;
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export type ClaimWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export enum ClaimDistinctFieldEnum {
  CreatedAt = "created_at",
  Id = "id",
  Name = "name",
  PipelineId = "pipeline_id",
  StageId = "stage_id",
  UpdatedAt = "updated_at",
  UserId = "user_id",
}

export type FileOrderByInput = {
  created_at?: Maybe<SortOrder>;
  event_slug?: Maybe<SortOrder>;
  file_type?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  mime_type?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  s3_path?: Maybe<SortOrder>;
  size?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  user_id?: Maybe<SortOrder>;
  permissions?: Maybe<SortOrder>;
};

export type FileWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export enum FileDistinctFieldEnum {
  CreatedAt = "created_at",
  EventSlug = "event_slug",
  FileType = "file_type",
  Id = "id",
  MimeType = "mime_type",
  Name = "name",
  S3Path = "s3_path",
  Size = "size",
  UpdatedAt = "updated_at",
  UserId = "user_id",
  Permissions = "permissions",
}

export type Mutation = {
  __typename?: "Mutation";
  loginWithPassword: JwtToken;
  loginWithJWT: JwtToken;
  impersonateUser: JwtToken;
  issueSponsorJWT: JwtToken;
  addNewSponsorCompany: JwtToken;
  registerSponsor: JwtToken;
  registerSponsorByUserId: JwtToken;
  resetPassword: UpdatePasswordSuccess;
  changePassword: UpdatePasswordSuccess;
  deleteClaim: Success;
  deleteClaims: Success;
  createClaim: Claim;
  createSponsorPerk: Claim;
  createSponsorCompanyMilestone: Claim;
  updateClaim: Claim;
  createCollaborator: Collaborator;
  deleteCollaborator: Collaborator;
  createCompany: Company;
  updateCompany: Company;
  deleteCompany: Company;
  createEmail: Email;
  confirmSent: Array<Email>;
  deleteEmails: Array<Email>;
  createEventAttendee: EventAttendee;
  generateAccessToken: AccessToken;
  verifyAccessToken: Roles;
  deleteFieldAnswer: FieldAnswer;
  createField: Field;
  updateField: Field;
  deleteField: Field;
  upload: File;
  createInstitution: AcademicInstitution;
  deleteInstitution: AcademicInstitution;
  removeFromMailingList: Array<MailingList>;
  updatePipeline: Pipeline;
  createPipeline: Pipeline;
  createReview: Review;
  updateReview: Review;
  deleteReview: Review;
  addScheduleItem: ScheduleItem;
  updateScheduleItem: ScheduleItem;
  deleteScheduleItem: ScheduleItem;
  sendSlackReviewMessage: Scalars["Boolean"];
  updateStage: Stage;
  createStage: Stage;
  updateUserEmail: User;
  createUser: User;
  deleteUser: User;
};

export type MutationLoginWithPasswordArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  password: Scalars["String"];
  email: Scalars["String"];
};

export type MutationLoginWithJwtArgs = {
  setCookie?: Maybe<Scalars["Boolean"]>;
  jwt: Scalars["String"];
};

export type MutationImpersonateUserArgs = {
  expiresIn?: Maybe<Scalars["Float"]>;
  args: UserWhereUniqueInput;
};

export type MutationIssueSponsorJwtArgs = {
  company_name: Scalars["String"];
  claim_id: Scalars["Float"];
};

export type MutationAddNewSponsorCompanyArgs = {
  primary_contact_email?: Maybe<Scalars["String"]>;
  primary_contact_name?: Maybe<Scalars["String"]>;
  tier: Scalars["String"];
  company_id: Scalars["Float"];
  milestones: Scalars["JSON"];
  perks: Scalars["JSON"];
  owner: Scalars["Float"];
  invoice: Scalars["Float"];
  contract: Scalars["Float"];
};

export type MutationRegisterSponsorArgs = {
  milestones: Scalars["JSON"];
  sponsor_jwt: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationRegisterSponsorByUserIdArgs = {
  milestones: Scalars["JSON"];
  companyClaimId: Scalars["Float"];
  userId: Scalars["Float"];
};

export type MutationResetPasswordArgs = {
  callback_url: Scalars["String"];
  email: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  token: Scalars["String"];
  password: Scalars["String"];
  email: Scalars["String"];
};

export type MutationDeleteClaimArgs = {
  id: Scalars["Int"];
};

export type MutationDeleteClaimsArgs = {
  where?: Maybe<ClaimWhereInput>;
};

export type MutationCreateClaimArgs = {
  data: ClaimCreateInput;
};

export type MutationCreateSponsorPerkArgs = {
  data?: Maybe<Scalars["JSON"]>;
  due_date?: Maybe<Scalars["String"]>;
  perk_type: Scalars["String"];
  company_claim_id: Scalars["Float"];
};

export type MutationCreateSponsorCompanyMilestoneArgs = {
  data?: Maybe<Scalars["JSON"]>;
  due_date?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
  name: Scalars["String"];
  company_claim_id: Scalars["Float"];
};

export type MutationUpdateClaimArgs = {
  data: ClaimUpdateInput;
};

export type MutationCreateCollaboratorArgs = {
  pipeline_id?: Maybe<Scalars["Float"]>;
  claim_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationDeleteCollaboratorArgs = {
  claim_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationCreateCompanyArgs = {
  twitter?: Maybe<Scalars["String"]>;
  website: Scalars["String"];
  name: Scalars["String"];
};

export type MutationUpdateCompanyArgs = {
  twitter?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars["Float"];
};

export type MutationCreateEmailArgs = {
  sent?: Maybe<Scalars["Boolean"]>;
  sendTime: Scalars["DateTime"];
  toEmail: Scalars["String"];
  subject: Scalars["String"];
  html: Scalars["String"];
  campaign: Scalars["String"];
};

export type MutationConfirmSentArgs = {
  sent: Scalars["Boolean"];
  id: Scalars["Float"];
};

export type MutationDeleteEmailsArgs = {
  email: Scalars["String"];
};

export type MutationCreateEventAttendeeArgs = {
  role: Scalars["String"];
  event_id: Scalars["Float"];
  user_id: Scalars["Float"];
};

export type MutationGenerateAccessTokenArgs = {
  event_slug?: Maybe<Scalars["String"]>;
  role: Scalars["String"];
  user_id: Scalars["Float"];
};

export type MutationVerifyAccessTokenArgs = {
  event_slug?: Maybe<Scalars["String"]>;
  discord_id: Scalars["String"];
  field_id: Scalars["Float"];
  token: Scalars["String"];
};

export type MutationDeleteFieldAnswerArgs = {
  id: Scalars["Float"];
};

export type MutationCreateFieldArgs = {
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
  permissions?: Maybe<Scalars["JSON"]>;
  is_required: Scalars["Boolean"];
  field_type: Scalars["String"];
  slug: Scalars["String"];
  name: Scalars["String"];
  pipeline_slug: Scalars["String"];
};

export type MutationUpdateFieldArgs = {
  fcfs_available?: Maybe<Array<Scalars["String"]>>;
  valid_values?: Maybe<Array<Scalars["String"]>>;
  permissions?: Maybe<Scalars["JSON"]>;
  field_type?: Maybe<Scalars["String"]>;
  slug?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  id: Scalars["Float"];
};

export type MutationDeleteFieldArgs = {
  id: Scalars["Float"];
};

export type MutationUploadArgs = {
  type: Scalars["String"];
  event_slug: Scalars["String"];
  file: Scalars["Upload"];
};

export type MutationCreateInstitutionArgs = {
  website?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  is_enabled: Scalars["Boolean"];
  is_custom: Scalars["Boolean"];
  institution_type: InstitutionTypeEnum;
  country_code: Scalars["String"];
  country: Scalars["String"];
};

export type MutationDeleteInstitutionArgs = {
  id: Scalars["Float"];
};

export type MutationRemoveFromMailingListArgs = {
  event_slug?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type MutationUpdatePipelineArgs = {
  data: PipelineUpdateInput;
};

export type MutationCreatePipelineArgs = {
  data: PipelineCreateInput;
};

export type MutationCreateReviewArgs = {
  note: Scalars["String"];
  claim_id: Scalars["Float"];
};

export type MutationUpdateReviewArgs = {
  claim_id?: Maybe<Scalars["Float"]>;
  note?: Maybe<Scalars["String"]>;
  reviewer_id?: Maybe<Scalars["Float"]>;
  id: Scalars["Float"];
};

export type MutationDeleteReviewArgs = {
  id: Scalars["Float"];
};

export type MutationAddScheduleItemArgs = {
  data: EventScheduleCreateInput;
};

export type MutationUpdateScheduleItemArgs = {
  where: EventScheduleWhereUniqueInput;
  data: CustomEventScheduleUpdateInput;
};

export type MutationDeleteScheduleItemArgs = {
  where: EventScheduleWhereUniqueInput;
};

export type MutationSendSlackReviewMessageArgs = {
  message: Scalars["String"];
};

export type MutationUpdateStageArgs = {
  data: StageUpdateInput;
};

export type MutationCreateStageArgs = {
  data: StageCreateInput;
};

export type MutationUpdateUserEmailArgs = {
  email: Scalars["String"];
  id: Scalars["Float"];
};

export type MutationCreateUserArgs = {
  password: Scalars["String"];
  email: Scalars["String"];
  name: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["Float"];
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars["String"]>;
  hacker_travel_id?: Maybe<Scalars["Int"]>;
  hacker_urls_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  sponsor_company_id?: Maybe<Scalars["Int"]>;
};

export type EventScheduleCreateInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  location: Scalars["String"];
  start_time: Scalars["DateTime"];
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  links?: Maybe<EventScheduleCreatelinksInput>;
  tags?: Maybe<EventScheduleCreatetagsInput>;
  event: EventCreateOneWithoutEvent_ScheduleInput;
};

export type EventScheduleCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventScheduleCreatetagsInput = {
  set: Array<Scalars["String"]>;
};

export type EventCreateOneWithoutEvent_ScheduleInput = {
  create?: Maybe<EventCreateWithoutEvent_ScheduleInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutevent_ScheduleInput>;
};

export type EventCreateWithoutEvent_ScheduleInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type EventCreatetagsInput = {
  set: Array<Scalars["String"]>;
};

export type EventCreatethemesInput = {
  set: Array<Scalars["String"]>;
};

export type EventActivityCreateManyWithoutEventInput = {
  create?: Maybe<Array<EventActivityCreateWithoutEventInput>>;
  connect?: Maybe<Array<EventActivityWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventActivityCreateOrConnectWithouteventInput>>;
};

export type EventActivityCreateWithoutEventInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event_activity_redemptions?: Maybe<EventActivityRedemptionCreateManyWithoutEvent_ActivityInput>;
};

export type EventActivityRedemptionCreateManyWithoutEvent_ActivityInput = {
  create?: Maybe<
    Array<EventActivityRedemptionCreateWithoutEvent_ActivityInput>
  >;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutevent_ActivityInput>
  >;
};

export type EventActivityRedemptionCreateWithoutEvent_ActivityInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time: Scalars["DateTime"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  user?: Maybe<UserCreateOneWithoutEvent_Activity_Redemptions_UserInput>;
  volunteer: UserCreateOneWithoutEvent_Activity_Redemptions_EventInput;
};

export type UserCreateOneWithoutEvent_Activity_Redemptions_UserInput = {
  create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_UserInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutevent_Activity_Redemptions_UserInput>;
};

export type UserCreateWithoutEvent_Activity_Redemptions_UserInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type UserCreateallergiesInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreateemail_HashesInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatefood_RestrictionsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatehacker_HackathonsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatehacker_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatementor_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreatesponsor_SkillsInput = {
  set: Array<Scalars["String"]>;
};

export type AddressCreateOneWithoutUsers_As_AddressInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_AddressInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutusers_As_AddressInput>;
};

export type AddressCreateWithoutUsers_As_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateManyWithoutEmergency_Contact_AddressInput>;
  users_as_shipping_address?: Maybe<UserCreateManyWithoutShipping_AddressInput>;
};

export type AcademicInstitutionCreateManyWithoutAddressInput = {
  create?: Maybe<Array<AcademicInstitutionCreateWithoutAddressInput>>;
  connect?: Maybe<Array<AcademicInstitutionWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<AcademicInstitutionCreateOrConnectWithoutaddressInput>
  >;
};

export type AcademicInstitutionCreateWithoutAddressInput = {
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  educations?: Maybe<EducationCreateManyWithoutAcademic_InstitutionInput>;
};

export type EducationCreateManyWithoutAcademic_InstitutionInput = {
  create?: Maybe<Array<EducationCreateWithoutAcademic_InstitutionInput>>;
  connect?: Maybe<Array<EducationWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<EducationCreateOrConnectWithoutacademic_InstitutionInput>
  >;
};

export type EducationCreateWithoutAcademic_InstitutionInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  current_status: Scalars["String"];
  current_year?: Maybe<Scalars["Int"]>;
  degree?: Maybe<Scalars["String"]>;
  graduating_year: Scalars["Int"];
  minor?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  users?: Maybe<UserCreateManyWithoutEducationInput>;
};

export type UserCreateManyWithoutEducationInput = {
  create?: Maybe<Array<UserCreateWithoutEducationInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithouteducationInput>>;
};

export type UserCreateWithoutEducationInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutusers_As_Emergency_Contact_AddressInput>;
};

export type AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateManyWithoutAddressInput>;
  users_as_address?: Maybe<UserCreateManyWithoutAddressInput>;
  users_as_shipping_address?: Maybe<UserCreateManyWithoutShipping_AddressInput>;
};

export type UserCreateManyWithoutAddressInput = {
  create?: Maybe<Array<UserCreateWithoutAddressInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutaddressInput>>;
};

export type UserCreateWithoutAddressInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type EducationCreateOneWithoutUsersInput = {
  create?: Maybe<EducationCreateWithoutUsersInput>;
  connect?: Maybe<EducationWhereUniqueInput>;
  connectOrCreate?: Maybe<EducationCreateOrConnectWithoutusersInput>;
};

export type EducationCreateWithoutUsersInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  current_status: Scalars["String"];
  current_year?: Maybe<Scalars["Int"]>;
  degree?: Maybe<Scalars["String"]>;
  graduating_year: Scalars["Int"];
  minor?: Maybe<Scalars["String"]>;
  program?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  academic_institution: AcademicInstitutionCreateOneWithoutEducationsInput;
};

export type AcademicInstitutionCreateOneWithoutEducationsInput = {
  create?: Maybe<AcademicInstitutionCreateWithoutEducationsInput>;
  connect?: Maybe<AcademicInstitutionWhereUniqueInput>;
  connectOrCreate?: Maybe<AcademicInstitutionCreateOrConnectWithouteducationsInput>;
};

export type AcademicInstitutionCreateWithoutEducationsInput = {
  country: Scalars["String"];
  country_code: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  institution_type: InstitutionTypeEnum;
  is_custom: Scalars["Boolean"];
  is_enabled: Scalars["Boolean"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressCreateOneWithoutAcademic_InstitutionsInput>;
};

export type AddressCreateOneWithoutAcademic_InstitutionsInput = {
  create?: Maybe<AddressCreateWithoutAcademic_InstitutionsInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutacademic_InstitutionsInput>;
};

export type AddressCreateWithoutAcademic_InstitutionsInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  zip_postal_code: Scalars["String"];
  users_as_address?: Maybe<UserCreateManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateManyWithoutEmergency_Contact_AddressInput>;
  users_as_shipping_address?: Maybe<UserCreateManyWithoutShipping_AddressInput>;
};

export type UserCreateManyWithoutEmergency_Contact_AddressInput = {
  create?: Maybe<Array<UserCreateWithoutEmergency_Contact_AddressInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutemergency_Contact_AddressInput>
  >;
};

export type UserCreateWithoutEmergency_Contact_AddressInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type TravelInformationCreateOneWithoutUsersInput = {
  create?: Maybe<TravelInformationCreateWithoutUsersInput>;
  connect?: Maybe<TravelInformationWhereUniqueInput>;
  connectOrCreate?: Maybe<TravelInformationCreateOrConnectWithoutusersInput>;
};

export type TravelInformationCreateWithoutUsersInput = {
  citizenship_country?: Maybe<Scalars["String"]>;
  closest_airport?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  current_city?: Maybe<Scalars["String"]>;
  expiry_date?: Maybe<Scalars["DateTime"]>;
  issue_date?: Maybe<Scalars["DateTime"]>;
  passport_number?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  visa_country?: Maybe<Scalars["String"]>;
  visa_type?: Maybe<Scalars["String"]>;
};

export type TravelInformationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type TravelInformationCreateOrConnectWithoutusersInput = {
  where: TravelInformationWhereUniqueInput;
  create: TravelInformationCreateWithoutUsersInput;
};

export type UrlCreateOneWithoutUsersInput = {
  create?: Maybe<UrlCreateWithoutUsersInput>;
  connect?: Maybe<UrlWhereUniqueInput>;
  connectOrCreate?: Maybe<UrlCreateOrConnectWithoutusersInput>;
};

export type UrlCreateWithoutUsersInput = {
  behance?: Maybe<Scalars["String"]>;
  bitbucket?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  deviantart?: Maybe<Scalars["String"]>;
  devpost?: Maybe<Scalars["String"]>;
  dribbble?: Maybe<Scalars["String"]>;
  facebook?: Maybe<Scalars["String"]>;
  flickr?: Maybe<Scalars["String"]>;
  github?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  jsfiddle?: Maybe<Scalars["String"]>;
  linkedin?: Maybe<Scalars["String"]>;
  portfolio?: Maybe<Scalars["String"]>;
  resume?: Maybe<Scalars["String"]>;
  soundcloud?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type UrlWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type UrlCreateOrConnectWithoutusersInput = {
  where: UrlWhereUniqueInput;
  create: UrlCreateWithoutUsersInput;
};

export type AddressCreateOneWithoutUsers_As_Shipping_AddressInput = {
  create?: Maybe<AddressCreateWithoutUsers_As_Shipping_AddressInput>;
  connect?: Maybe<AddressWhereUniqueInput>;
  connectOrCreate?: Maybe<AddressCreateOrConnectWithoutusers_As_Shipping_AddressInput>;
};

export type AddressCreateWithoutUsers_As_Shipping_AddressInput = {
  city: Scalars["String"];
  country: Scalars["String"];
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  latitude?: Maybe<Scalars["Float"]>;
  line_1: Scalars["String"];
  line_2?: Maybe<Scalars["String"]>;
  line_3?: Maybe<Scalars["String"]>;
  longitude?: Maybe<Scalars["Float"]>;
  state_province: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  zip_postal_code: Scalars["String"];
  academic_institutions?: Maybe<AcademicInstitutionCreateManyWithoutAddressInput>;
  users_as_address?: Maybe<UserCreateManyWithoutAddressInput>;
  users_as_emergency_contact_address?: Maybe<UserCreateManyWithoutEmergency_Contact_AddressInput>;
};

export type AddressWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type AddressCreateOrConnectWithoutusers_As_Shipping_AddressInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUsers_As_Shipping_AddressInput;
};

export type CompanyCreateOneWithoutUsersInput = {
  create?: Maybe<CompanyCreateWithoutUsersInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutusersInput>;
};

export type CompanyCreateWithoutUsersInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website: Scalars["String"];
  sponsors?: Maybe<SponsorCreateOneWithoutCompanyInput>;
};

export type SponsorCreateOneWithoutCompanyInput = {
  create?: Maybe<SponsorCreateWithoutCompanyInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutcompanyInput>;
};

export type SponsorCreateWithoutCompanyInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutSponsorsInput;
  files?: Maybe<FileCreateOneWithoutSponsorsInput>;
  prizes?: Maybe<PrizeCreateManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutSponsorInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutSponsorInput>;
};

export type EventCreateOneWithoutSponsorsInput = {
  create?: Maybe<EventCreateWithoutSponsorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutsponsorsInput>;
};

export type EventCreateWithoutSponsorsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type EventAttendeeCreateManyWithoutEventInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutEventInput>>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithouteventInput>>;
};

export type EventAttendeeCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  badge_codes?: Maybe<EventAttendeeCreatebadge_CodesInput>;
  user: UserCreateOneWithoutEvent_AttendeesInput;
};

export type EventAttendeeCreatebadge_CodesInput = {
  set: Array<Scalars["String"]>;
};

export type UserCreateOneWithoutEvent_AttendeesInput = {
  create?: Maybe<UserCreateWithoutEvent_AttendeesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutevent_AttendeesInput>;
};

export type UserCreateWithoutEvent_AttendeesInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type AuthTokenCreateManyWithoutUserInput = {
  create?: Maybe<Array<AuthTokenCreateWithoutUserInput>>;
  connect?: Maybe<Array<AuthTokenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<AuthTokenCreateOrConnectWithoutuserInput>>;
};

export type AuthTokenCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  expire_time: Scalars["DateTime"];
  token: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type AuthTokenWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  token?: Maybe<Scalars["String"]>;
};

export type AuthTokenCreateOrConnectWithoutuserInput = {
  where: AuthTokenWhereUniqueInput;
  create: AuthTokenCreateWithoutUserInput;
};

export type ClaimCreateManyWithoutUserInput = {
  create?: Maybe<Array<ClaimCreateWithoutUserInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutuserInput>>;
};

export type ClaimCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutClaimsInput;
  stage: StageCreateOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateManyWithoutClaimInput>;
};

export type PipelineCreateOneWithoutClaimsInput = {
  create?: Maybe<PipelineCreateWithoutClaimsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutclaimsInput>;
};

export type PipelineCreateWithoutClaimsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTime"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug: Scalars["String"];
  start_time?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  event: EventCreateOneWithoutPipelinesInput;
  fields?: Maybe<FieldCreateManyWithoutPipelineInput>;
  stages?: Maybe<StageCreateManyWithoutPipelineInput>;
};

export type PipelineCreaterelationshipsInput = {
  set: Array<Scalars["String"]>;
};

export type PipelineCreatestage_OrderInput = {
  set: Array<Scalars["Int"]>;
};

export type EventCreateOneWithoutPipelinesInput = {
  create?: Maybe<EventCreateWithoutPipelinesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutpipelinesInput>;
};

export type EventCreateWithoutPipelinesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type EventFaqCreateManyWithoutEventInput = {
  create?: Maybe<Array<EventFaqCreateWithoutEventInput>>;
  connect?: Maybe<Array<EventFaqWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventFaqCreateOrConnectWithouteventInput>>;
};

export type EventFaqCreateWithoutEventInput = {
  answer: Scalars["String"];
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  question: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  links?: Maybe<EventFaqCreatelinksInput>;
};

export type EventFaqCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventFaqWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EventFaqCreateOrConnectWithouteventInput = {
  where: EventFaqWhereUniqueInput;
  create: EventFaqCreateWithoutEventInput;
};

export type EventScheduleCreateManyWithoutEventInput = {
  create?: Maybe<Array<EventScheduleCreateWithoutEventInput>>;
  connect?: Maybe<Array<EventScheduleWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventScheduleCreateOrConnectWithouteventInput>>;
};

export type EventScheduleCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  location: Scalars["String"];
  start_time: Scalars["DateTime"];
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  links?: Maybe<EventScheduleCreatelinksInput>;
  tags?: Maybe<EventScheduleCreatetagsInput>;
};

export type EventScheduleWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EventScheduleCreateOrConnectWithouteventInput = {
  where: EventScheduleWhereUniqueInput;
  create: EventScheduleCreateWithoutEventInput;
};

export type EventUpdateCreateManyWithoutEventInput = {
  create?: Maybe<Array<EventUpdateCreateWithoutEventInput>>;
  connect?: Maybe<Array<EventUpdateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventUpdateCreateOrConnectWithouteventInput>>;
};

export type EventUpdateCreateWithoutEventInput = {
  category: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  location?: Maybe<Scalars["String"]>;
  send_time?: Maybe<Scalars["DateTime"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  links?: Maybe<EventUpdateCreatelinksInput>;
};

export type EventUpdateCreatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventUpdateWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EventUpdateCreateOrConnectWithouteventInput = {
  where: EventUpdateWhereUniqueInput;
  create: EventUpdateCreateWithoutEventInput;
};

export type FileCreateManyWithoutEventInput = {
  create?: Maybe<Array<FileCreateWithoutEventInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithouteventInput>>;
};

export type FileCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  user?: Maybe<UserCreateOneWithoutFilesInput>;
  sponsors?: Maybe<SponsorCreateOneWithoutFilesInput>;
};

export type UserCreateOneWithoutFilesInput = {
  create?: Maybe<UserCreateWithoutFilesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutfilesInput>;
};

export type UserCreateWithoutFilesInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type CollaboratorCreateManyWithoutUserInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutUserInput>>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutuserInput>>;
};

export type CollaboratorCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  claim: ClaimCreateOneWithoutCollaboratorsInput;
};

export type ClaimCreateOneWithoutCollaboratorsInput = {
  create?: Maybe<ClaimCreateWithoutCollaboratorsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutcollaboratorsInput>;
};

export type ClaimCreateWithoutCollaboratorsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutClaimsInput;
  stage: StageCreateOneWithoutClaimsInput;
  user: UserCreateOneWithoutClaimsInput;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateManyWithoutClaimInput>;
};

export type StageCreateOneWithoutClaimsInput = {
  create?: Maybe<StageCreateWithoutClaimsInput>;
  connect?: Maybe<StageWhereUniqueInput>;
  connectOrCreate?: Maybe<StageCreateOrConnectWithoutclaimsInput>;
};

export type StageCreateWithoutClaimsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutStagesInput;
};

export type PipelineCreateOneWithoutStagesInput = {
  create?: Maybe<PipelineCreateWithoutStagesInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutstagesInput>;
};

export type PipelineCreateWithoutStagesInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTime"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug: Scalars["String"];
  start_time?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  event: EventCreateOneWithoutPipelinesInput;
  claims?: Maybe<ClaimCreateManyWithoutPipelineInput>;
  fields?: Maybe<FieldCreateManyWithoutPipelineInput>;
};

export type ClaimCreateManyWithoutPipelineInput = {
  create?: Maybe<Array<ClaimCreateWithoutPipelineInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutpipelineInput>>;
};

export type ClaimCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  stage: StageCreateOneWithoutClaimsInput;
  user: UserCreateOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateManyWithoutClaimInput>;
};

export type UserCreateOneWithoutClaimsInput = {
  create?: Maybe<UserCreateWithoutClaimsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutclaimsInput>;
};

export type UserCreateWithoutClaimsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type EventActivityRedemptionCreateManyWithoutUserInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutUserInput>>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutuserInput>
  >;
};

export type EventActivityRedemptionCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time: Scalars["DateTime"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event_activity: EventActivityCreateOneWithoutEvent_Activity_RedemptionsInput;
  volunteer: UserCreateOneWithoutEvent_Activity_Redemptions_EventInput;
};

export type EventActivityCreateOneWithoutEvent_Activity_RedemptionsInput = {
  create?: Maybe<EventActivityCreateWithoutEvent_Activity_RedemptionsInput>;
  connect?: Maybe<EventActivityWhereUniqueInput>;
  connectOrCreate?: Maybe<EventActivityCreateOrConnectWithoutevent_Activity_RedemptionsInput>;
};

export type EventActivityCreateWithoutEvent_Activity_RedemptionsInput = {
  category?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  end_time?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Scalars["JSON"]>;
  start_time?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutEvent_ActivitiesInput;
};

export type EventCreateOneWithoutEvent_ActivitiesInput = {
  create?: Maybe<EventCreateWithoutEvent_ActivitiesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutevent_ActivitiesInput>;
};

export type EventCreateWithoutEvent_ActivitiesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type MailTemplateCreateManyWithoutEventInput = {
  create?: Maybe<Array<MailTemplateCreateWithoutEventInput>>;
  connect?: Maybe<Array<MailTemplateWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MailTemplateCreateOrConnectWithouteventInput>>;
};

export type MailTemplateCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  html: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
  subject: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  mail_trigger?: Maybe<MailTriggerCreateOneWithoutMail_TemplatesInput>;
};

export type MailTriggerCreateOneWithoutMail_TemplatesInput = {
  create?: Maybe<MailTriggerCreateWithoutMail_TemplatesInput>;
  connect?: Maybe<MailTriggerWhereUniqueInput>;
  connectOrCreate?: Maybe<MailTriggerCreateOrConnectWithoutmail_TemplatesInput>;
};

export type MailTriggerCreateWithoutMail_TemplatesInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  record_id: Scalars["Int"];
  record_type: RecordTypeEmail;
  trigger_type: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MailTriggerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mail_triggers_record_id_record_type_trigger_type_key?: Maybe<Mail_Triggers_Record_Id_Record_Type_Trigger_Type_KeyCompoundUniqueInput>;
};

export type Mail_Triggers_Record_Id_Record_Type_Trigger_Type_KeyCompoundUniqueInput = {
  record_id: Scalars["Int"];
  record_type: RecordTypeEmail;
  trigger_type: Scalars["String"];
};

export type MailTriggerCreateOrConnectWithoutmail_TemplatesInput = {
  where: MailTriggerWhereUniqueInput;
  create: MailTriggerCreateWithoutMail_TemplatesInput;
};

export type MailTemplateWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  trigger_id?: Maybe<Scalars["Int"]>;
};

export type MailTemplateCreateOrConnectWithouteventInput = {
  where: MailTemplateWhereUniqueInput;
  create: MailTemplateCreateWithoutEventInput;
};

export type MailingListCreateManyWithoutEventInput = {
  create?: Maybe<Array<MailingListCreateWithoutEventInput>>;
  connect?: Maybe<Array<MailingListWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MailingListCreateOrConnectWithouteventInput>>;
};

export type MailingListCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type MailingListWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mailing_list_event_slug_email_key?: Maybe<Mailing_List_Event_Slug_Email_KeyCompoundUniqueInput>;
};

export type Mailing_List_Event_Slug_Email_KeyCompoundUniqueInput = {
  event_slug: Scalars["String"];
  email: Scalars["String"];
};

export type MailingListCreateOrConnectWithouteventInput = {
  where: MailingListWhereUniqueInput;
  create: MailingListCreateWithoutEventInput;
};

export type MentorRequestCreateManyWithoutEventInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutEventInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithouteventInput>>;
};

export type MentorRequestCreateWithoutEventInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTime"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateOneWithoutMentor_RequestsInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutMentor_RequestInput>;
};

export type UserCreateOneWithoutMentor_RequestsInput = {
  create?: Maybe<UserCreateWithoutMentor_RequestsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutmentor_RequestsInput>;
};

export type UserCreateWithoutMentor_RequestsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type EventActivityRedemptionCreateManyWithoutVolunteerInput = {
  create?: Maybe<Array<EventActivityRedemptionCreateWithoutVolunteerInput>>;
  connect?: Maybe<Array<EventActivityRedemptionWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<EventActivityRedemptionCreateOrConnectWithoutvolunteerInput>
  >;
};

export type EventActivityRedemptionCreateWithoutVolunteerInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  override_reason?: Maybe<Scalars["String"]>;
  scan_time: Scalars["DateTime"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event_activity: EventActivityCreateOneWithoutEvent_Activity_RedemptionsInput;
  user?: Maybe<UserCreateOneWithoutEvent_Activity_Redemptions_UserInput>;
};

export type EventActivityRedemptionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EventActivityRedemptionCreateOrConnectWithoutvolunteerInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutVolunteerInput;
};

export type EventAttendeeCreateManyWithoutUserInput = {
  create?: Maybe<Array<EventAttendeeCreateWithoutUserInput>>;
  connect?: Maybe<Array<EventAttendeeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<EventAttendeeCreateOrConnectWithoutuserInput>>;
};

export type EventAttendeeCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  role: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  badge_codes?: Maybe<EventAttendeeCreatebadge_CodesInput>;
  event: EventCreateOneWithoutEvent_AttendeesInput;
};

export type EventCreateOneWithoutEvent_AttendeesInput = {
  create?: Maybe<EventCreateWithoutEvent_AttendeesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutevent_AttendeesInput>;
};

export type EventCreateWithoutEvent_AttendeesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type MentorCreateManyWithoutEventInput = {
  create?: Maybe<Array<MentorCreateWithoutEventInput>>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithouteventInput>>;
};

export type MentorCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutMentorsInput;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutMentorInput>;
};

export type UserCreateOneWithoutMentorsInput = {
  create?: Maybe<UserCreateWithoutMentorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutmentorsInput>;
};

export type UserCreateWithoutMentorsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type FileCreateManyWithoutUserInput = {
  create?: Maybe<Array<FileCreateWithoutUserInput>>;
  connect?: Maybe<Array<FileWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FileCreateOrConnectWithoutuserInput>>;
};

export type FileCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventCreateOneWithoutFilesInput>;
  sponsors?: Maybe<SponsorCreateOneWithoutFilesInput>;
};

export type EventCreateOneWithoutFilesInput = {
  create?: Maybe<EventCreateWithoutFilesInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutfilesInput>;
};

export type EventCreateWithoutFilesInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type PipelineCreateManyWithoutEventInput = {
  create?: Maybe<Array<PipelineCreateWithoutEventInput>>;
  connect?: Maybe<Array<PipelineWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PipelineCreateOrConnectWithouteventInput>>;
};

export type PipelineCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTime"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug: Scalars["String"];
  start_time?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  claims?: Maybe<ClaimCreateManyWithoutPipelineInput>;
  fields?: Maybe<FieldCreateManyWithoutPipelineInput>;
  stages?: Maybe<StageCreateManyWithoutPipelineInput>;
};

export type FieldCreateManyWithoutPipelineInput = {
  create?: Maybe<Array<FieldCreateWithoutPipelineInput>>;
  connect?: Maybe<Array<FieldWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FieldCreateOrConnectWithoutpipelineInput>>;
};

export type FieldCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  field_type: Scalars["String"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  fcfs_available?: Maybe<FieldCreatefcfs_AvailableInput>;
  valid_values?: Maybe<FieldCreatevalid_ValuesInput>;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutFieldInput>;
};

export type FieldCreatefcfs_AvailableInput = {
  set: Array<Scalars["String"]>;
};

export type FieldCreatevalid_ValuesInput = {
  set: Array<Scalars["String"]>;
};

export type FieldAnswerCreateManyWithoutFieldInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutFieldInput>>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutfieldInput>>;
};

export type FieldAnswerCreateWithoutFieldInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  value?: Maybe<Scalars["String"]>;
  claim: ClaimCreateOneWithoutField_AnswersInput;
};

export type ClaimCreateOneWithoutField_AnswersInput = {
  create?: Maybe<ClaimCreateWithoutField_AnswersInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutfield_AnswersInput>;
};

export type ClaimCreateWithoutField_AnswersInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutClaimsInput;
  stage: StageCreateOneWithoutClaimsInput;
  user: UserCreateOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateManyWithoutClaimInput>;
};

export type CollaboratorCreateManyWithoutClaimInput = {
  create?: Maybe<Array<CollaboratorCreateWithoutClaimInput>>;
  connect?: Maybe<Array<CollaboratorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<CollaboratorCreateOrConnectWithoutclaimInput>>;
};

export type CollaboratorCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutCollaboratorsInput;
};

export type UserCreateOneWithoutCollaboratorsInput = {
  create?: Maybe<UserCreateWithoutCollaboratorsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutcollaboratorsInput>;
};

export type UserCreateWithoutCollaboratorsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type FirebaseTokenCreateManyWithoutUserInput = {
  create?: Maybe<Array<FirebaseTokenCreateWithoutUserInput>>;
  connect?: Maybe<Array<FirebaseTokenWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FirebaseTokenCreateOrConnectWithoutuserInput>>;
};

export type FirebaseTokenCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  value: Scalars["String"];
};

export type FirebaseTokenWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type FirebaseTokenCreateOrConnectWithoutuserInput = {
  where: FirebaseTokenWhereUniqueInput;
  create: FirebaseTokenCreateWithoutUserInput;
};

export type ForgottenPasswordCreateManyWithoutUserInput = {
  create?: Maybe<Array<ForgottenPasswordCreateWithoutUserInput>>;
  connect?: Maybe<Array<ForgottenPasswordWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<ForgottenPasswordCreateOrConnectWithoutuserInput>
  >;
};

export type ForgottenPasswordCreateWithoutUserInput = {
  callback_url?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  is_disabled?: Maybe<Scalars["Boolean"]>;
  token: Scalars["String"];
  token_only: Scalars["Boolean"];
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type ForgottenPasswordWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  token?: Maybe<Scalars["String"]>;
};

export type ForgottenPasswordCreateOrConnectWithoutuserInput = {
  where: ForgottenPasswordWhereUniqueInput;
  create: ForgottenPasswordCreateWithoutUserInput;
};

export type MentorRequestCommentCreateManyWithoutUserInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutUserInput>>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutuserInput>
  >;
};

export type MentorRequestCommentCreateWithoutUserInput = {
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  mentor_request: MentorRequestCreateOneWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCreateOneWithoutMentor_Request_CommentsInput = {
  create?: Maybe<MentorRequestCreateWithoutMentor_Request_CommentsInput>;
  connect?: Maybe<MentorRequestWhereUniqueInput>;
  connectOrCreate?: Maybe<MentorRequestCreateOrConnectWithoutmentor_Request_CommentsInput>;
};

export type MentorRequestCreateWithoutMentor_Request_CommentsInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTime"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutMentor_RequestsInput;
  user: UserCreateOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateOneWithoutMentor_RequestsInput>;
};

export type EventCreateOneWithoutMentor_RequestsInput = {
  create?: Maybe<EventCreateWithoutMentor_RequestsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutmentor_RequestsInput>;
};

export type EventCreateWithoutMentor_RequestsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type SponsorCreateManyWithoutEventInput = {
  create?: Maybe<Array<SponsorCreateWithoutEventInput>>;
  connect?: Maybe<Array<SponsorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SponsorCreateOrConnectWithouteventInput>>;
};

export type SponsorCreateWithoutEventInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  company: CompanyCreateOneWithoutSponsorsInput;
  files?: Maybe<FileCreateOneWithoutSponsorsInput>;
  prizes?: Maybe<PrizeCreateManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutSponsorInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutSponsorInput>;
};

export type CompanyCreateOneWithoutSponsorsInput = {
  create?: Maybe<CompanyCreateWithoutSponsorsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutsponsorsInput>;
};

export type CompanyCreateWithoutSponsorsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  twitter?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website: Scalars["String"];
  users?: Maybe<UserCreateOneWithoutCompanyInput>;
};

export type UserCreateOneWithoutCompanyInput = {
  create?: Maybe<UserCreateWithoutCompanyInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutcompanyInput>;
};

export type UserCreateWithoutCompanyInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type MentorRequestCreateManyWithoutUserInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutUserInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MentorRequestCreateOrConnectWithoutuserInput>>;
};

export type MentorRequestCreateWithoutUserInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTime"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutMentor_RequestsInput;
  mentor?: Maybe<MentorCreateOneWithoutMentor_RequestsInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutMentor_RequestInput>;
};

export type MentorCreateOneWithoutMentor_RequestsInput = {
  create?: Maybe<MentorCreateWithoutMentor_RequestsInput>;
  connect?: Maybe<MentorWhereUniqueInput>;
  connectOrCreate?: Maybe<MentorCreateOrConnectWithoutmentor_RequestsInput>;
};

export type MentorCreateWithoutMentor_RequestsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutMentorsInput;
  user: UserCreateOneWithoutMentorsInput;
};

export type EventCreateOneWithoutMentorsInput = {
  create?: Maybe<EventCreateWithoutMentorsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutmentorsInput>;
};

export type EventCreateWithoutMentorsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type SubmissionCreateManyWithoutEventInput = {
  create?: Maybe<Array<SubmissionCreateWithoutEventInput>>;
  connect?: Maybe<Array<SubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SubmissionCreateOrConnectWithouteventInput>>;
};

export type SubmissionCreateWithoutEventInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  url: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutSubmissionInput>;
};

export type SubmissionCreaterepository_UrlsInput = {
  set: Array<Scalars["String"]>;
};

export type UserSubmissionCreateManyWithoutSubmissionInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutSubmissionInput>>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<UserSubmissionCreateOrConnectWithoutsubmissionInput>
  >;
};

export type UserSubmissionCreateWithoutSubmissionInput = {
  user: UserCreateOneWithoutUser_SubmissionsInput;
};

export type UserCreateOneWithoutUser_SubmissionsInput = {
  create?: Maybe<UserCreateWithoutUser_SubmissionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutuser_SubmissionsInput>;
};

export type UserCreateWithoutUser_SubmissionsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
};

export type MentorCreateManyWithoutUserInput = {
  create?: Maybe<Array<MentorCreateWithoutUserInput>>;
  connect?: Maybe<Array<MentorWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<MentorCreateOrConnectWithoutuserInput>>;
};

export type MentorCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  skills?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutMentorsInput;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutMentorInput>;
};

export type MentorRequestCreateManyWithoutMentorInput = {
  create?: Maybe<Array<MentorRequestCreateWithoutMentorInput>>;
  connect?: Maybe<Array<MentorRequestWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCreateOrConnectWithoutmentorInput>
  >;
};

export type MentorRequestCreateWithoutMentorInput = {
  anonymous: Scalars["Boolean"];
  created_at?: Maybe<Scalars["DateTime"]>;
  request: Scalars["String"];
  resolved: Scalars["Boolean"];
  tags?: Maybe<Scalars["JSON"]>;
  title: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutMentor_RequestsInput;
  user: UserCreateOneWithoutMentor_RequestsInput;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutMentor_RequestInput>;
};

export type MentorRequestCommentCreateManyWithoutMentor_RequestInput = {
  create?: Maybe<Array<MentorRequestCommentCreateWithoutMentor_RequestInput>>;
  connect?: Maybe<Array<MentorRequestCommentWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<MentorRequestCommentCreateOrConnectWithoutmentor_RequestInput>
  >;
};

export type MentorRequestCommentCreateWithoutMentor_RequestInput = {
  body: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutMentor_Request_CommentsInput;
};

export type UserCreateOneWithoutMentor_Request_CommentsInput = {
  create?: Maybe<UserCreateWithoutMentor_Request_CommentsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutmentor_Request_CommentsInput>;
};

export type UserCreateWithoutMentor_Request_CommentsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type PersonCreateManyWithoutUserInput = {
  create?: Maybe<Array<PersonCreateWithoutUserInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PersonCreateOrConnectWithoutuserInput>>;
};

export type PersonCreateWithoutUserInput = {
  company?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  family_name?: Maybe<Scalars["String"]>;
  full_name?: Maybe<Scalars["String"]>;
  given_name?: Maybe<Scalars["String"]>;
  middle_names?: Maybe<Scalars["String"]>;
  phone_number: Scalars["String"];
  relationship?: Maybe<Scalars["String"]>;
  role: PersonRole;
  updated_at?: Maybe<Scalars["DateTime"]>;
  email_hashes?: Maybe<PersonCreateemail_HashesInput>;
  emails?: Maybe<PersonCreateemailsInput>;
  interests?: Maybe<PersonCreateinterestsInput>;
};

export type PersonCreateemail_HashesInput = {
  set: Array<Scalars["String"]>;
};

export type PersonCreateemailsInput = {
  set: Array<Scalars["String"]>;
};

export type PersonCreateinterestsInput = {
  set: Array<Scalars["String"]>;
};

export type PersonWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type PersonCreateOrConnectWithoutuserInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutUserInput;
};

export type RepresentativeCreateManyWithoutUserInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutUserInput>>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<RepresentativeCreateOrConnectWithoutuserInput>>;
};

export type RepresentativeCreateWithoutUserInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  sponsor: SponsorCreateOneWithoutRepresentativesInput;
};

export type RepresentativeCreateskillsInput = {
  set: Array<Scalars["String"]>;
};

export type SponsorCreateOneWithoutRepresentativesInput = {
  create?: Maybe<SponsorCreateWithoutRepresentativesInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutrepresentativesInput>;
};

export type SponsorCreateWithoutRepresentativesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  company: CompanyCreateOneWithoutSponsorsInput;
  event: EventCreateOneWithoutSponsorsInput;
  files?: Maybe<FileCreateOneWithoutSponsorsInput>;
  prizes?: Maybe<PrizeCreateManyWithoutSponsorInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutSponsorInput>;
};

export type FileCreateOneWithoutSponsorsInput = {
  create?: Maybe<FileCreateWithoutSponsorsInput>;
  connect?: Maybe<FileWhereUniqueInput>;
  connectOrCreate?: Maybe<FileCreateOrConnectWithoutsponsorsInput>;
};

export type FileCreateWithoutSponsorsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  file_type: Scalars["String"];
  mime_type?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  s3_path: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  permissions?: Maybe<Scalars["JSON"]>;
  event?: Maybe<EventCreateOneWithoutFilesInput>;
  user?: Maybe<UserCreateOneWithoutFilesInput>;
};

export type FileCreateOrConnectWithoutsponsorsInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutSponsorsInput;
};

export type PrizeCreateManyWithoutSponsorInput = {
  create?: Maybe<Array<PrizeCreateWithoutSponsorInput>>;
  connect?: Maybe<Array<PrizeWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<PrizeCreateOrConnectWithoutsponsorInput>>;
};

export type PrizeCreateWithoutSponsorInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  name: Scalars["String"];
  prize: Scalars["String"];
  quantity?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
};

export type PrizeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type PrizeCreateOrConnectWithoutsponsorInput = {
  where: PrizeWhereUniqueInput;
  create: PrizeCreateWithoutSponsorInput;
};

export type SponsorScanCreateManyWithoutSponsorInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutSponsorInput>>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SponsorScanCreateOrConnectWithoutsponsorInput>>;
};

export type SponsorScanCreateWithoutSponsorInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutSponsor_ScansInput;
};

export type UserCreateOneWithoutSponsor_ScansInput = {
  create?: Maybe<UserCreateWithoutSponsor_ScansInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutsponsor_ScansInput>;
};

export type UserCreateWithoutSponsor_ScansInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type ReviewCreateManyWithoutUserInput = {
  create?: Maybe<Array<ReviewCreateWithoutUserInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutuserInput>>;
};

export type ReviewCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  claim: ClaimCreateOneWithoutReviewsInput;
};

export type ClaimCreateOneWithoutReviewsInput = {
  create?: Maybe<ClaimCreateWithoutReviewsInput>;
  connect?: Maybe<ClaimWhereUniqueInput>;
  connectOrCreate?: Maybe<ClaimCreateOrConnectWithoutreviewsInput>;
};

export type ClaimCreateWithoutReviewsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutClaimsInput;
  stage: StageCreateOneWithoutClaimsInput;
  user: UserCreateOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutClaimInput>;
};

export type FieldAnswerCreateManyWithoutClaimInput = {
  create?: Maybe<Array<FieldAnswerCreateWithoutClaimInput>>;
  connect?: Maybe<Array<FieldAnswerWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<FieldAnswerCreateOrConnectWithoutclaimInput>>;
};

export type FieldAnswerCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  value?: Maybe<Scalars["String"]>;
  field: FieldCreateOneWithoutField_AnswersInput;
};

export type FieldCreateOneWithoutField_AnswersInput = {
  create?: Maybe<FieldCreateWithoutField_AnswersInput>;
  connect?: Maybe<FieldWhereUniqueInput>;
  connectOrCreate?: Maybe<FieldCreateOrConnectWithoutfield_AnswersInput>;
};

export type FieldCreateWithoutField_AnswersInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  field_type: Scalars["String"];
  is_required?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  fcfs_available?: Maybe<FieldCreatefcfs_AvailableInput>;
  valid_values?: Maybe<FieldCreatevalid_ValuesInput>;
  pipeline: PipelineCreateOneWithoutFieldsInput;
};

export type PipelineCreateOneWithoutFieldsInput = {
  create?: Maybe<PipelineCreateWithoutFieldsInput>;
  connect?: Maybe<PipelineWhereUniqueInput>;
  connectOrCreate?: Maybe<PipelineCreateOrConnectWithoutfieldsInput>;
};

export type PipelineCreateWithoutFieldsInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  description: Scalars["String"];
  end_time?: Maybe<Scalars["DateTime"]>;
  max_num_claims?: Maybe<Scalars["Int"]>;
  max_num_collaborators?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  slug: Scalars["String"];
  start_time?: Maybe<Scalars["DateTime"]>;
  type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  relationships?: Maybe<PipelineCreaterelationshipsInput>;
  stage_order?: Maybe<PipelineCreatestage_OrderInput>;
  event: EventCreateOneWithoutPipelinesInput;
  claims?: Maybe<ClaimCreateManyWithoutPipelineInput>;
  stages?: Maybe<StageCreateManyWithoutPipelineInput>;
};

export type StageCreateManyWithoutPipelineInput = {
  create?: Maybe<Array<StageCreateWithoutPipelineInput>>;
  connect?: Maybe<Array<StageWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<StageCreateOrConnectWithoutpipelineInput>>;
};

export type StageCreateWithoutPipelineInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  permissions?: Maybe<Scalars["JSON"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  claims?: Maybe<ClaimCreateManyWithoutStageInput>;
};

export type ClaimCreateManyWithoutStageInput = {
  create?: Maybe<Array<ClaimCreateWithoutStageInput>>;
  connect?: Maybe<Array<ClaimWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ClaimCreateOrConnectWithoutstageInput>>;
};

export type ClaimCreateWithoutStageInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  pipeline: PipelineCreateOneWithoutClaimsInput;
  user: UserCreateOneWithoutClaimsInput;
  collaborators?: Maybe<CollaboratorCreateManyWithoutClaimInput>;
  field_answers?: Maybe<FieldAnswerCreateManyWithoutClaimInput>;
  reviews?: Maybe<ReviewCreateManyWithoutClaimInput>;
};

export type ReviewCreateManyWithoutClaimInput = {
  create?: Maybe<Array<ReviewCreateWithoutClaimInput>>;
  connect?: Maybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<ReviewCreateOrConnectWithoutclaimInput>>;
};

export type ReviewCreateWithoutClaimInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  note?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  user: UserCreateOneWithoutReviewsInput;
};

export type UserCreateOneWithoutReviewsInput = {
  create?: Maybe<UserCreateWithoutReviewsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutreviewsInput>;
};

export type UserCreateWithoutReviewsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type SponsorScanCreateManyWithoutUserInput = {
  create?: Maybe<Array<SponsorScanCreateWithoutUserInput>>;
  connect?: Maybe<Array<SponsorScanWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<SponsorScanCreateOrConnectWithoutuserInput>>;
};

export type SponsorScanCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  sponsor: SponsorCreateOneWithoutSponsor_ScansInput;
};

export type SponsorCreateOneWithoutSponsor_ScansInput = {
  create?: Maybe<SponsorCreateWithoutSponsor_ScansInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutsponsor_ScansInput>;
};

export type SponsorCreateWithoutSponsor_ScansInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  company: CompanyCreateOneWithoutSponsorsInput;
  event: EventCreateOneWithoutSponsorsInput;
  files?: Maybe<FileCreateOneWithoutSponsorsInput>;
  prizes?: Maybe<PrizeCreateManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutSponsorInput>;
};

export type RepresentativeCreateManyWithoutSponsorInput = {
  create?: Maybe<Array<RepresentativeCreateWithoutSponsorInput>>;
  connect?: Maybe<Array<RepresentativeWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<RepresentativeCreateOrConnectWithoutsponsorInput>
  >;
};

export type RepresentativeCreateWithoutSponsorInput = {
  activated?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  is_poc?: Maybe<Scalars["Boolean"]>;
  role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  skills?: Maybe<RepresentativeCreateskillsInput>;
  user: UserCreateOneWithoutRepresentativesInput;
};

export type UserCreateOneWithoutRepresentativesInput = {
  create?: Maybe<UserCreateWithoutRepresentativesInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutrepresentativesInput>;
};

export type UserCreateWithoutRepresentativesInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type TeamMemberCreateManyWithoutUserInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutUserInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutuserInput>>;
};

export type TeamMemberCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutTeam_MembersInput;
  team: TeamCreateOneWithoutTeam_MembersInput;
};

export type EventCreateOneWithoutTeam_MembersInput = {
  create?: Maybe<EventCreateWithoutTeam_MembersInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutteam_MembersInput>;
};

export type EventCreateWithoutTeam_MembersInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type TeamCreateManyWithoutEventInput = {
  create?: Maybe<Array<TeamCreateWithoutEventInput>>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithouteventInput>>;
};

export type TeamCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  disabled: Scalars["Boolean"];
  team_id: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  user?: Maybe<UserCreateOneWithoutTeamsInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutTeamInput>;
};

export type UserCreateOneWithoutTeamsInput = {
  create?: Maybe<UserCreateWithoutTeamsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutteamsInput>;
};

export type UserCreateWithoutTeamsInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type UserSubmissionCreateManyWithoutUserInput = {
  create?: Maybe<Array<UserSubmissionCreateWithoutUserInput>>;
  connect?: Maybe<Array<UserSubmissionWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<UserSubmissionCreateOrConnectWithoutuserInput>>;
};

export type UserSubmissionCreateWithoutUserInput = {
  submission: SubmissionCreateOneWithoutUser_SubmissionsInput;
};

export type SubmissionCreateOneWithoutUser_SubmissionsInput = {
  create?: Maybe<SubmissionCreateWithoutUser_SubmissionsInput>;
  connect?: Maybe<SubmissionWhereUniqueInput>;
  connectOrCreate?: Maybe<SubmissionCreateOrConnectWithoutuser_SubmissionsInput>;
};

export type SubmissionCreateWithoutUser_SubmissionsInput = {
  contact_info: Scalars["String"];
  created_at?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  url: Scalars["String"];
  repository_urls?: Maybe<SubmissionCreaterepository_UrlsInput>;
  event: EventCreateOneWithoutSubmissionsInput;
};

export type EventCreateOneWithoutSubmissionsInput = {
  create?: Maybe<EventCreateWithoutSubmissionsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutsubmissionsInput>;
};

export type EventCreateWithoutSubmissionsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
  teams?: Maybe<TeamCreateManyWithoutEventInput>;
};

export type TeamMemberCreateManyWithoutEventInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutEventInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithouteventInput>>;
};

export type TeamMemberCreateWithoutEventInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  team: TeamCreateOneWithoutTeam_MembersInput;
  user: UserCreateOneWithoutTeam_MembersInput;
};

export type TeamCreateOneWithoutTeam_MembersInput = {
  create?: Maybe<TeamCreateWithoutTeam_MembersInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutteam_MembersInput>;
};

export type TeamCreateWithoutTeam_MembersInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  disabled: Scalars["Boolean"];
  team_id: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  user?: Maybe<UserCreateOneWithoutTeamsInput>;
  event: EventCreateOneWithoutTeamsInput;
};

export type EventCreateOneWithoutTeamsInput = {
  create?: Maybe<EventCreateWithoutTeamsInput>;
  connect?: Maybe<EventWhereUniqueInput>;
  connectOrCreate?: Maybe<EventCreateOrConnectWithoutteamsInput>;
};

export type EventCreateWithoutTeamsInput = {
  contact_email?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Int"]>;
  cost_currency?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  end_time: Scalars["DateTime"];
  has_food?: Maybe<Scalars["Boolean"]>;
  has_reimbursements?: Maybe<Scalars["Boolean"]>;
  judging_model?: Maybe<Scalars["String"]>;
  length_hours?: Maybe<Scalars["Int"]>;
  max_age_restriction?: Maybe<Scalars["Int"]>;
  min_age_restriction?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  size?: Maybe<Scalars["Int"]>;
  slug: Scalars["String"];
  start_time: Scalars["DateTime"];
  submission_url?: Maybe<Scalars["String"]>;
  swarm_event_id?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  website?: Maybe<Scalars["String"]>;
  tags?: Maybe<EventCreatetagsInput>;
  themes?: Maybe<EventCreatethemesInput>;
  event_activities?: Maybe<EventActivityCreateManyWithoutEventInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutEventInput>;
  event_faqs?: Maybe<EventFaqCreateManyWithoutEventInput>;
  event_schedule?: Maybe<EventScheduleCreateManyWithoutEventInput>;
  event_updates?: Maybe<EventUpdateCreateManyWithoutEventInput>;
  files?: Maybe<FileCreateManyWithoutEventInput>;
  mail_templates?: Maybe<MailTemplateCreateManyWithoutEventInput>;
  mailing_list?: Maybe<MailingListCreateManyWithoutEventInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutEventInput>;
  mentors?: Maybe<MentorCreateManyWithoutEventInput>;
  pipelines?: Maybe<PipelineCreateManyWithoutEventInput>;
  sponsors?: Maybe<SponsorCreateManyWithoutEventInput>;
  submissions?: Maybe<SubmissionCreateManyWithoutEventInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutEventInput>;
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type EventCreateOrConnectWithoutteamsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutTeamsInput;
};

export type TeamWhereUniqueInput = {
  team_id?: Maybe<Scalars["String"]>;
};

export type TeamCreateOrConnectWithoutteam_MembersInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutTeam_MembersInput;
};

export type UserCreateOneWithoutTeam_MembersInput = {
  create?: Maybe<UserCreateWithoutTeam_MembersInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutteam_MembersInput>;
};

export type UserCreateWithoutTeam_MembersInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type TeamCreateManyWithoutUserInput = {
  create?: Maybe<Array<TeamCreateWithoutUserInput>>;
  connect?: Maybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TeamCreateOrConnectWithoutuserInput>>;
};

export type TeamCreateWithoutUserInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  disabled: Scalars["Boolean"];
  team_id: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutTeamsInput;
  team_members?: Maybe<TeamMemberCreateManyWithoutTeamInput>;
};

export type TeamMemberCreateManyWithoutTeamInput = {
  create?: Maybe<Array<TeamMemberCreateWithoutTeamInput>>;
  connect?: Maybe<Array<TeamMemberWhereUniqueInput>>;
  connectOrCreate?: Maybe<Array<TeamMemberCreateOrConnectWithoutteamInput>>;
};

export type TeamMemberCreateWithoutTeamInput = {
  created_at?: Maybe<Scalars["DateTime"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  event: EventCreateOneWithoutTeam_MembersInput;
  user: UserCreateOneWithoutTeam_MembersInput;
};

export type TeamMemberWhereUniqueInput = {
  user_id_event_slug?: Maybe<User_IdEvent_SlugCompoundUniqueInput>;
};

export type User_IdEvent_SlugCompoundUniqueInput = {
  user_id: Scalars["Int"];
  event_slug: Scalars["String"];
};

export type TeamMemberCreateOrConnectWithoutteamInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutTeamInput;
};

export type TeamCreateOrConnectWithoutuserInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutteam_MembersInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTeam_MembersInput;
};

export type TeamMemberCreateOrConnectWithouteventInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutsubmissionsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSubmissionsInput;
};

export type SubmissionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  _name_event_uc?: Maybe<_Name_Event_UcCompoundUniqueInput>;
};

export type _Name_Event_UcCompoundUniqueInput = {
  name: Scalars["String"];
  event_id: Scalars["Int"];
};

export type SubmissionCreateOrConnectWithoutuser_SubmissionsInput = {
  where: SubmissionWhereUniqueInput;
  create: SubmissionCreateWithoutUser_SubmissionsInput;
};

export type UserSubmissionWhereUniqueInput = {
  user_id_submission_id?: Maybe<User_IdSubmission_IdCompoundUniqueInput>;
};

export type User_IdSubmission_IdCompoundUniqueInput = {
  user_id: Scalars["Int"];
  submission_id: Scalars["Int"];
};

export type UserSubmissionCreateOrConnectWithoutuserInput = {
  where: UserSubmissionWhereUniqueInput;
  create: UserSubmissionCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutteamsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTeamsInput;
};

export type TeamCreateOrConnectWithouteventInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutteam_MembersInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutTeam_MembersInput;
};

export type TeamMemberCreateOrConnectWithoutuserInput = {
  where: TeamMemberWhereUniqueInput;
  create: TeamMemberCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutrepresentativesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutRepresentativesInput;
};

export type RepresentativeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type RepresentativeCreateOrConnectWithoutsponsorInput = {
  where: RepresentativeWhereUniqueInput;
  create: RepresentativeCreateWithoutSponsorInput;
};

export type SponsorWhereUniqueInput = {
  company_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  invoice_id?: Maybe<Scalars["Int"]>;
  sponsors_event_id_company_id_key?: Maybe<Sponsors_Event_Id_Company_Id_KeyCompoundUniqueInput>;
};

export type Sponsors_Event_Id_Company_Id_KeyCompoundUniqueInput = {
  event_id: Scalars["Int"];
  company_id: Scalars["Int"];
};

export type SponsorCreateOrConnectWithoutsponsor_ScansInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutSponsor_ScansInput;
};

export type SponsorScanWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type SponsorScanCreateOrConnectWithoutuserInput = {
  where: SponsorScanWhereUniqueInput;
  create: SponsorScanCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutreviewsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutReviewsInput;
};

export type ReviewWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type ReviewCreateOrConnectWithoutclaimInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewCreateWithoutClaimInput;
};

export type ClaimCreateOrConnectWithoutstageInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutStageInput;
};

export type StageWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type StageCreateOrConnectWithoutpipelineInput = {
  where: StageWhereUniqueInput;
  create: StageCreateWithoutPipelineInput;
};

export type PipelineWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
};

export type PipelineCreateOrConnectWithoutfieldsInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutFieldsInput;
};

export type FieldWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type FieldCreateOrConnectWithoutfield_AnswersInput = {
  where: FieldWhereUniqueInput;
  create: FieldCreateWithoutField_AnswersInput;
};

export type FieldAnswerWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type FieldAnswerCreateOrConnectWithoutclaimInput = {
  where: FieldAnswerWhereUniqueInput;
  create: FieldAnswerCreateWithoutClaimInput;
};

export type ClaimCreateOrConnectWithoutreviewsInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutReviewsInput;
};

export type ReviewCreateOrConnectWithoutuserInput = {
  where: ReviewWhereUniqueInput;
  create: ReviewCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutsponsor_ScansInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSponsor_ScansInput;
};

export type SponsorScanCreateOrConnectWithoutsponsorInput = {
  where: SponsorScanWhereUniqueInput;
  create: SponsorScanCreateWithoutSponsorInput;
};

export type SponsorCreateOrConnectWithoutrepresentativesInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutRepresentativesInput;
};

export type RepresentativeCreateOrConnectWithoutuserInput = {
  where: RepresentativeWhereUniqueInput;
  create: RepresentativeCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutmentor_Request_CommentsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCommentWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type MentorRequestCommentCreateOrConnectWithoutmentor_RequestInput = {
  where: MentorRequestCommentWhereUniqueInput;
  create: MentorRequestCommentCreateWithoutMentor_RequestInput;
};

export type MentorRequestWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type MentorRequestCreateOrConnectWithoutmentorInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutMentorInput;
};

export type MentorWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  mentors_event_id_user_id_key?: Maybe<Mentors_Event_Id_User_Id_KeyCompoundUniqueInput>;
};

export type Mentors_Event_Id_User_Id_KeyCompoundUniqueInput = {
  event_id: Scalars["Int"];
  user_id: Scalars["Int"];
};

export type MentorCreateOrConnectWithoutuserInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutuser_SubmissionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutUser_SubmissionsInput;
};

export type UserSubmissionCreateOrConnectWithoutsubmissionInput = {
  where: UserSubmissionWhereUniqueInput;
  create: UserSubmissionCreateWithoutSubmissionInput;
};

export type SubmissionCreateOrConnectWithouteventInput = {
  where: SubmissionWhereUniqueInput;
  create: SubmissionCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutmentorsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutMentorsInput;
};

export type MentorCreateOrConnectWithoutmentor_RequestsInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutMentor_RequestsInput;
};

export type MentorRequestCreateOrConnectWithoutuserInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutcompanyInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCompanyInput;
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type CompanyCreateOrConnectWithoutsponsorsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutSponsorsInput;
};

export type SponsorCreateOrConnectWithouteventInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutmentor_RequestsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutMentor_RequestsInput;
};

export type MentorRequestCreateOrConnectWithoutmentor_Request_CommentsInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutMentor_Request_CommentsInput;
};

export type MentorRequestCommentCreateOrConnectWithoutuserInput = {
  where: MentorRequestCommentWhereUniqueInput;
  create: MentorRequestCommentCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutcollaboratorsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutCollaboratorsInput;
};

export type CollaboratorWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type CollaboratorCreateOrConnectWithoutclaimInput = {
  where: CollaboratorWhereUniqueInput;
  create: CollaboratorCreateWithoutClaimInput;
};

export type ClaimCreateOrConnectWithoutfield_AnswersInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutField_AnswersInput;
};

export type FieldAnswerCreateOrConnectWithoutfieldInput = {
  where: FieldAnswerWhereUniqueInput;
  create: FieldAnswerCreateWithoutFieldInput;
};

export type FieldCreateOrConnectWithoutpipelineInput = {
  where: FieldWhereUniqueInput;
  create: FieldCreateWithoutPipelineInput;
};

export type PipelineCreateOrConnectWithouteventInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutfilesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutFilesInput;
};

export type SponsorCreateOneWithoutFilesInput = {
  create?: Maybe<SponsorCreateWithoutFilesInput>;
  connect?: Maybe<SponsorWhereUniqueInput>;
  connectOrCreate?: Maybe<SponsorCreateOrConnectWithoutfilesInput>;
};

export type SponsorCreateWithoutFilesInput = {
  available_perks?: Maybe<Scalars["JSON"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  feature_description?: Maybe<Scalars["String"]>;
  poc_accepted_code_of_conduct?: Maybe<Scalars["Boolean"]>;
  tier: Scalars["String"];
  updated_at?: Maybe<Scalars["DateTime"]>;
  company: CompanyCreateOneWithoutSponsorsInput;
  event: EventCreateOneWithoutSponsorsInput;
  prizes?: Maybe<PrizeCreateManyWithoutSponsorInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutSponsorInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutSponsorInput>;
};

export type SponsorCreateOrConnectWithoutfilesInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutFilesInput;
};

export type FileCreateOrConnectWithoutuserInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutmentorsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentorsInput;
};

export type MentorCreateOrConnectWithouteventInput = {
  where: MentorWhereUniqueInput;
  create: MentorCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutevent_AttendeesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_AttendeesInput;
};

export type EventAttendeeWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
  event_attendees_role_user_id_event_id_key?: Maybe<Event_Attendees_Role_User_Id_Event_Id_KeyCompoundUniqueInput>;
};

export type Event_Attendees_Role_User_Id_Event_Id_KeyCompoundUniqueInput = {
  role: Scalars["String"];
  user_id: Scalars["Int"];
  event_id: Scalars["Int"];
};

export type EventAttendeeCreateOrConnectWithoutuserInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutmentor_RequestsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMentor_RequestsInput;
};

export type MentorRequestCreateOrConnectWithouteventInput = {
  where: MentorRequestWhereUniqueInput;
  create: MentorRequestCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutevent_ActivitiesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_ActivitiesInput;
};

export type EventActivityWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EventActivityCreateOrConnectWithoutevent_Activity_RedemptionsInput = {
  where: EventActivityWhereUniqueInput;
  create: EventActivityCreateWithoutEvent_Activity_RedemptionsInput;
};

export type UserCreateOneWithoutEvent_Activity_Redemptions_EventInput = {
  create?: Maybe<UserCreateWithoutEvent_Activity_Redemptions_EventInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutevent_Activity_Redemptions_EventInput>;
};

export type UserCreateWithoutEvent_Activity_Redemptions_EventInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  shipping_address?: Maybe<AddressCreateOneWithoutUsers_As_Shipping_AddressInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutevent_Activity_Redemptions_EventInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_EventInput;
};

export type EventActivityRedemptionCreateOrConnectWithoutuserInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutclaimsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutClaimsInput;
};

export type ClaimCreateOrConnectWithoutpipelineInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutPipelineInput;
};

export type PipelineCreateOrConnectWithoutstagesInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutStagesInput;
};

export type StageCreateOrConnectWithoutclaimsInput = {
  where: StageWhereUniqueInput;
  create: StageCreateWithoutClaimsInput;
};

export type ClaimCreateOrConnectWithoutcollaboratorsInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutCollaboratorsInput;
};

export type CollaboratorCreateOrConnectWithoutuserInput = {
  where: CollaboratorWhereUniqueInput;
  create: CollaboratorCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutfilesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutFilesInput;
};

export type FileCreateOrConnectWithouteventInput = {
  where: FileWhereUniqueInput;
  create: FileCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutpipelinesInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutPipelinesInput;
};

export type PipelineCreateOrConnectWithoutclaimsInput = {
  where: PipelineWhereUniqueInput;
  create: PipelineCreateWithoutClaimsInput;
};

export type ClaimCreateOrConnectWithoutuserInput = {
  where: ClaimWhereUniqueInput;
  create: ClaimCreateWithoutUserInput;
};

export type UserCreateOrConnectWithoutevent_AttendeesInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_AttendeesInput;
};

export type EventAttendeeCreateOrConnectWithouteventInput = {
  where: EventAttendeeWhereUniqueInput;
  create: EventAttendeeCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutsponsorsInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutSponsorsInput;
};

export type SponsorCreateOrConnectWithoutcompanyInput = {
  where: SponsorWhereUniqueInput;
  create: SponsorCreateWithoutCompanyInput;
};

export type CompanyCreateOrConnectWithoutusersInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutUsersInput;
};

export type UserCreateOrConnectWithoutemergency_Contact_AddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEmergency_Contact_AddressInput;
};

export type UserCreateManyWithoutShipping_AddressInput = {
  create?: Maybe<Array<UserCreateWithoutShipping_AddressInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: Maybe<
    Array<UserCreateOrConnectWithoutshipping_AddressInput>
  >;
};

export type UserCreateWithoutShipping_AddressInput = {
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["DateTime"]>;
  date_of_birth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  emergency_contact_email?: Maybe<Scalars["String"]>;
  emergency_contact_name?: Maybe<Scalars["String"]>;
  emergency_contact_phone_number?: Maybe<Scalars["String"]>;
  emergency_contact_relationship?: Maybe<Scalars["String"]>;
  ethnicity?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  hacker_school_email?: Maybe<Scalars["String"]>;
  legal_name?: Maybe<Scalars["String"]>;
  mentor_location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  native_language?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  phone_number?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["Int"]>;
  shirt_size?: Maybe<Scalars["Int"]>;
  sponsor_personal_email?: Maybe<Scalars["String"]>;
  sponsor_role?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["DateTime"]>;
  allergies?: Maybe<UserCreateallergiesInput>;
  email_hashes?: Maybe<UserCreateemail_HashesInput>;
  food_restrictions?: Maybe<UserCreatefood_RestrictionsInput>;
  hacker_hackathons?: Maybe<UserCreatehacker_HackathonsInput>;
  hacker_skills?: Maybe<UserCreatehacker_SkillsInput>;
  mentor_skills?: Maybe<UserCreatementor_SkillsInput>;
  sponsor_skills?: Maybe<UserCreatesponsor_SkillsInput>;
  address?: Maybe<AddressCreateOneWithoutUsers_As_AddressInput>;
  emergency_contact_address?: Maybe<AddressCreateOneWithoutUsers_As_Emergency_Contact_AddressInput>;
  education?: Maybe<EducationCreateOneWithoutUsersInput>;
  travel_information?: Maybe<TravelInformationCreateOneWithoutUsersInput>;
  urls?: Maybe<UrlCreateOneWithoutUsersInput>;
  company?: Maybe<CompanyCreateOneWithoutUsersInput>;
  auth_tokens?: Maybe<AuthTokenCreateManyWithoutUserInput>;
  claims?: Maybe<ClaimCreateManyWithoutUserInput>;
  collaborators?: Maybe<CollaboratorCreateManyWithoutUserInput>;
  event_activity_redemptions_user?: Maybe<EventActivityRedemptionCreateManyWithoutUserInput>;
  event_activity_redemptions_event?: Maybe<EventActivityRedemptionCreateManyWithoutVolunteerInput>;
  event_attendees?: Maybe<EventAttendeeCreateManyWithoutUserInput>;
  files?: Maybe<FileCreateManyWithoutUserInput>;
  firebase_tokens?: Maybe<FirebaseTokenCreateManyWithoutUserInput>;
  forgotten_passwords?: Maybe<ForgottenPasswordCreateManyWithoutUserInput>;
  mentor_request_comments?: Maybe<MentorRequestCommentCreateManyWithoutUserInput>;
  mentor_requests?: Maybe<MentorRequestCreateManyWithoutUserInput>;
  mentors?: Maybe<MentorCreateManyWithoutUserInput>;
  person?: Maybe<PersonCreateManyWithoutUserInput>;
  representatives?: Maybe<RepresentativeCreateManyWithoutUserInput>;
  reviews?: Maybe<ReviewCreateManyWithoutUserInput>;
  sponsor_scans?: Maybe<SponsorScanCreateManyWithoutUserInput>;
  team_members?: Maybe<TeamMemberCreateManyWithoutUserInput>;
  teams?: Maybe<TeamCreateManyWithoutUserInput>;
  user_submissions?: Maybe<UserSubmissionCreateManyWithoutUserInput>;
};

export type UserCreateOrConnectWithoutshipping_AddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutShipping_AddressInput;
};

export type AddressCreateOrConnectWithoutacademic_InstitutionsInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutAcademic_InstitutionsInput;
};

export type AcademicInstitutionWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type AcademicInstitutionCreateOrConnectWithouteducationsInput = {
  where: AcademicInstitutionWhereUniqueInput;
  create: AcademicInstitutionCreateWithoutEducationsInput;
};

export type EducationWhereUniqueInput = {
  id?: Maybe<Scalars["Int"]>;
};

export type EducationCreateOrConnectWithoutusersInput = {
  where: EducationWhereUniqueInput;
  create: EducationCreateWithoutUsersInput;
};

export type UserCreateOrConnectWithoutaddressInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutAddressInput;
};

export type AddressCreateOrConnectWithoutusers_As_Emergency_Contact_AddressInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUsers_As_Emergency_Contact_AddressInput;
};

export type UserCreateOrConnectWithouteducationInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEducationInput;
};

export type EducationCreateOrConnectWithoutacademic_InstitutionInput = {
  where: EducationWhereUniqueInput;
  create: EducationCreateWithoutAcademic_InstitutionInput;
};

export type AcademicInstitutionCreateOrConnectWithoutaddressInput = {
  where: AcademicInstitutionWhereUniqueInput;
  create: AcademicInstitutionCreateWithoutAddressInput;
};

export type AddressCreateOrConnectWithoutusers_As_AddressInput = {
  where: AddressWhereUniqueInput;
  create: AddressCreateWithoutUsers_As_AddressInput;
};

export type UserCreateOrConnectWithoutevent_Activity_Redemptions_UserInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutEvent_Activity_Redemptions_UserInput;
};

export type EventActivityRedemptionCreateOrConnectWithoutevent_ActivityInput = {
  where: EventActivityRedemptionWhereUniqueInput;
  create: EventActivityRedemptionCreateWithoutEvent_ActivityInput;
};

export type EventActivityCreateOrConnectWithouteventInput = {
  where: EventActivityWhereUniqueInput;
  create: EventActivityCreateWithoutEventInput;
};

export type EventCreateOrConnectWithoutevent_ScheduleInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutEvent_ScheduleInput;
};

export type CustomEventScheduleUpdateInput = {
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  end_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  location?: Maybe<StringFieldUpdateOperationsInput>;
  start_time?: Maybe<DateTimeFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  links?: Maybe<EventScheduleUpdatelinksInput>;
  tags?: Maybe<EventScheduleUpdatetagsInput>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["DateTime"]>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars["String"]>;
};

export type EventScheduleUpdatelinksInput = {
  set: Array<Scalars["String"]>;
};

export type EventScheduleUpdatetagsInput = {
  set: Array<Scalars["String"]>;
};
