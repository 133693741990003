import { Text, Link, Spacer, Button } from "@hackthenorth/north";
import React, { useCallback, useMemo, useRef } from "react";
import styled from "styled-components";

// import { Link } from "src/shared/components";
import { AVATAR_MAP } from "src/shared/components/ChangeAvatarModal/constants";
import { SOCIALS, SocialPlatforms } from "src/shared/constants/social";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { RSVPSocialImg } from "src/static/img";

import Footnote from "./shared/footnote";
import getSchoolImg from "./shared/getSchoolImg";

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background: rgba(14, 52, 96, 0.2);
`;

const Container = styled.div`
  max-width: 750px;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Img = styled.img`
  width: 650px;
  position: absolute;
  right: 0;
  bottom: -190px;
  margin-right: -70px;
  z-index: -1;
`;

const SocialImgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SocialImg = styled.img`
  width: 100%;
`;

const AvatarImg = styled.img`
  width: 7%;
  position: absolute;
  bottom: 45%;
  right: 6.5%;
`;

const ACCEPTED_FIELDS = [Field.SCHOOL, Field.AVATAR];

export default () => {
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const socialImgRef = useRef<HTMLDivElement>(null);

  const schoolImg = useMemo(() => getSchoolImg(responsesState[Field.SCHOOL]), [
    responsesState,
  ]);

  const saveSocialImg = useCallback(() => {
    const canvas = document.createElement("canvas");
    canvas.width = 1603;
    canvas.height = 645;

    const ctx = canvas.getContext("2d");

    const base = new Image();
    base.src = RSVPSocialImg;
    ctx?.drawImage(base, 0, 0);

    const avatar = new Image();
    avatar.src = AVATAR_MAP[responsesState[Field.AVATAR] ?? "red"];
    ctx?.drawImage(avatar, 1390, 250, 100, 100);

    const image = canvas.toDataURL();
    const link = document.createElement("a");
    link.download = "my-hacker-rsvp.png";
    link.href = image;
    link.click();
  }, [responsesState]);

  return (
    <OuterContainer>
      <Img src={schoolImg} alt="" aria-hidden />
      <Container>
        <Text mods="hmobile heading">
          Hooray! You’ve just confirmed your spot for Hack the North 2020++{" "}
          <span role="img" aria-label="clapping hands emoji">
            👏
          </span>
        </Text>
        <Text>
          We look forward to seeing you at the event! In the meantime, be sure
          to stay updated with all things Hack the North by following our{" "}
          <Link href={SOCIALS[SocialPlatforms.FACEBOOK]} newTab>
            Facebook
          </Link>
          ,{" "}
          <Link href={SOCIALS[SocialPlatforms.INSTA]} newTab>
            Instagram
          </Link>
          ,{" "}
          <Link href={SOCIALS[SocialPlatforms.LINKEDIN]} newTab>
            LinkedIn
          </Link>
          , and{" "}
          <Link href={SOCIALS[SocialPlatforms.TWITTER]} newTab>
            Twitter
          </Link>
          !
        </Text>
        <Spacer height={40} />
        <SocialImgContainer ref={socialImgRef}>
          <SocialImg
            src={RSVPSocialImg}
            alt="Artwork of your Hack the North acceptance for sharing on social media"
          />
          <AvatarImg src={AVATAR_MAP[responsesState[Field.AVATAR] ?? "red"]} />
        </SocialImgContainer>
        <Spacer height={24} />
        <Button mods="primary" onClick={saveSocialImg}>
          Share on social media
        </Button>
        <Separator />
      </Container>
      <Footnote />
    </OuterContainer>
  );
};
