import { TAnswer } from "src/shared/contexts/HackerContext/types";
import {
  uWaterlooImg,
  CarletonImg,
  McMasterImg,
  McGillImg,
  SimonFraserImg,
  UBCImg,
  UofTImg,
  uOttawaImg,
  WesternImg,
  YorkImg,
  UnlistedSchoolImg,
} from "src/static/img";

const SCHOOL_TO_BUILDING_MAP = {
  "University of Waterloo": uWaterlooImg,
  "University of Western Ontario": WesternImg,
  "Carleton University": CarletonImg,
  "McGill University": McGillImg,
  "McMaster University": McMasterImg,
  "Simon Fraser University": SimonFraserImg,
  "University of British Columbia": UBCImg,
  "University of Toronto": UofTImg,
  "University of Ottawa": uOttawaImg,
  "York University": YorkImg,
};

export default (schoolName: TAnswer) => {
  return typeof schoolName === "string" && schoolName in SCHOOL_TO_BUILDING_MAP
    ? SCHOOL_TO_BUILDING_MAP[schoolName]
    : UnlistedSchoolImg;
};
