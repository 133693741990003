import { Tier } from "src/views/sponsor/types";

export const videoLength = (companyTier?: Tier) => {
  switch (companyTier) {
    case Tier.GOLD:
      return "3 minutes";
    default:
      return "1 minute";
  }
};
