/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type UploadFileMutationVariables = Types.Exact<{
  type: Types.Scalars["String"];
  eventSlug: Types.Scalars["String"];
  file: Types.Scalars["Upload"];
}>;

export type UploadFileMutation = { __typename?: "Mutation" } & {
  upload: { __typename?: "File" } & Pick<
    Types.File,
    "id" | "name" | "mime_type"
  > & {
      download: { __typename?: "FileDownload" } & Pick<
        Types.FileDownload,
        "uri" | "expiry"
      >;
    };
};

export const UploadFileDocument = gql`
  mutation UploadFile($type: String!, $eventSlug: String!, $file: Upload!) {
    upload(type: $type, event_slug: $eventSlug, file: $file) {
      id
      name
      mime_type
      download {
        uri
        expiry
      }
    }
  }
`;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      type: // value for 'type'
 *      eventSlug: // value for 'eventSlug'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UploadFileMutation,
    UploadFileMutationVariables
  >(UploadFileDocument, baseOptions);
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult = ApolloReactCommon.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
