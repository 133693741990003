import { Flex, Spacer, Text, Link, Button } from "@hackthenorth/north";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { Icon, PageWrapper } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";
import { ErrorModal } from "src/views/error/ErrorModal";

import { Calendar } from "./components/Calendar";
import { EventModal } from "./components/EventModal";
import { LoggedOutModal } from "./components/LoggedOutModal";
import { Slider } from "./components/Slider";
import { TimePeriodSelector } from "./components/TimePeriodSelector";
import {
  GEAR_UP_END,
  GEAR_UP_START,
  HACK_THE_NORTH_END,
  HACK_THE_NORTH_START,
  PRIVATE_CALENDAR_LINK,
  PUBLIC_CALENDAR_LINK,
} from "./constants";
import { useScheduleContext } from "./ScheduleContext";

const StyledPageWrapper = styled(PageWrapper)`
  overflow: hidden;
  height: 100%;

  padding: 45px;

  > div {
    display: flex;
    height: 100%;
  }
`;

const InfoContainer = styled.div`
  margin-right: 30px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const BallButton = styled(Button)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  margin: 12px 0;
  padding: 4px 20px !important;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.greySecondary} !important;
  background: ${({ selected, theme }) =>
    selected
      ? theme.globalConstants.color.bluePrimary3
      : theme.globalConstants.color.white};

  ${({ theme }) => theme.globalConstants.color.white};
  white-space: nowrap;
`;

const SavedIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Ball = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const BlueBall = styled(Ball)`
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary1};
`;

const GreenBall = styled(Ball)`
  background: ${({ theme }) => theme.globalConstants.color.greenSecondary};
`;

const YellowBall = styled(Ball)`
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
`;

const PinkBall = styled(Ball)`
  background: ${({ theme }) => theme.globalConstants.color.redPrimary1};
`;

const DarkBlueBall = styled(Ball)`
  background: ${({ theme }) => theme.globalConstants.color.navySecondary};
`;

const CopyChangeBanner = styled.div`
  width: 100%;
  top: -40px;
  position: absolute;
`;

const getDatesInRange = (startTime: moment.Moment, endTime: moment.Moment) => {
  const currDate = moment(startTime).startOf("day");
  const lastDate = moment(endTime);

  const dates = [currDate.format()];

  while (currDate.add(1, "days").diff(lastDate) <= 0) {
    dates.push(currDate.format());
  }

  return dates;
};

export default () => {
  const { isAuthenticated, isOrganizer } = useUserContext();

  const {
    eventCategoryToColor,
    viewFullSchedule,
    enableSaving,
    getIsSaved,
    toggleSaveEvent,
    closeModalWithParam,
    gearUpBannerEvents,
    hackTheNorthBannerEvents,
    allNonBannerEvents,
    gearUpEvents,
    hackTheNorthEvents,
    getFilteredEvents,
    getFilteredSavedEvents,
    savedGearUpEvents,
    savedHackTheNorthEvents,
  } = useScheduleContext();

  // Default to Gear Up / Hack the North schedule depending on what the time is
  // If the user is a guest, show them Gear Up
  const [showGearUp, setShowGearUp] = useState(
    moment().isBefore(HACK_THE_NORTH_START) || !viewFullSchedule
  );

  // Show saved events?
  const [showSaved, setShowSaved] = useState(false);

  // Show in day view? And if so, which day?
  const [showDaysView, setShowDaysView] = useState(false);
  const [daysViewDay, setDaysViewDay] = useState(-1);

  // Which event tag (aka category) filter is being applied?
  const [tagFilter, setTagFilter] = useState<string | null>(null);
  const toggleTagFilter = (tag: string) => {
    tag === tagFilter ? setTagFilter(null) : setTagFilter(tag);
  };

  // Do we show the modal?
  const [openModal, setOpenModal] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    const openedModalParam = new URLSearchParams(location.search).get("event");
    setOpenModal(parseInt(openedModalParam ?? "-1"));
  }, [location.search]);

  // Event saving with error checking
  const [error, setError] = useState<Error | null>(null);
  const toggleSaveEventWithErrorHandling = useCallback(
    async (eventId: number) => {
      try {
        await toggleSaveEvent(eventId);
      } catch (e) {
        closeModalWithParam(eventId);
        setError(e);
      }
    },
    [closeModalWithParam, toggleSaveEvent]
  );

  const gearUpDays = useMemo(
    () => getDatesInRange(GEAR_UP_START, GEAR_UP_END),
    []
  );
  const hackTheNorthDays = useMemo(
    () => getDatesInRange(HACK_THE_NORTH_START, HACK_THE_NORTH_END),
    []
  );

  // We do our filtering here
  const daysToShow = useMemo(() => {
    const sortedDays = showGearUp ? gearUpDays : hackTheNorthDays;

    // If it's in day view, which day to show??
    if (showDaysView) {
      // const today = moment().startOf("day").format();
      const today = moment(moment.now())
        .subtract(50, "days")
        .subtract(12, "hours")
        .format();

      if (daysViewDay !== -1) {
        return [sortedDays[daysViewDay]];
      } else if (sortedDays.includes(today)) {
        setDaysViewDay(sortedDays.indexOf(today));
        return [today];
      } else {
        setDaysViewDay(0);
        return [sortedDays[0]];
      }
    } else {
      // It's not in day view, so reset the day and show the week
      setDaysViewDay(-1);
      return sortedDays;
    }
  }, [daysViewDay, gearUpDays, hackTheNorthDays, showDaysView, showGearUp]);

  const bannerEventsToShow = useMemo(
    () => (showGearUp ? gearUpBannerEvents : hackTheNorthBannerEvents),
    [gearUpBannerEvents, hackTheNorthBannerEvents, showGearUp]
  );

  const eventsToShow = useMemo(() => {
    if (showSaved) {
      if (tagFilter) {
        if (tagFilter === "event") {
          return getFilteredSavedEvents((tags) => {
            return (
              (showGearUp
                ? tags.includes("gear_up")
                : tags.includes("hack_the_north")) &&
              !tags.includes("workshop") &&
              !tags.includes("activity") &&
              !tags.includes("sponsors") &&
              !tags.includes("tech_talks")
            );
          });
        } else {
          return getFilteredSavedEvents((tags) => {
            return (
              (showGearUp
                ? tags.includes("gear_up")
                : tags.includes("hack_the_north")) && tags.includes(tagFilter)
            );
          });
        }
      } else if (showGearUp) {
        return savedGearUpEvents;
      } else {
        return savedHackTheNorthEvents;
      }
    } else {
      if (tagFilter) {
        if (tagFilter === "event") {
          return getFilteredEvents((tags) => {
            return (
              (showGearUp
                ? tags.includes("gear_up")
                : tags.includes("hack_the_north")) &&
              !tags.includes("workshop") &&
              !tags.includes("activity") &&
              !tags.includes("sponsors") &&
              !tags.includes("tech_talks")
            );
          });
        } else {
          return getFilteredEvents((tags) => {
            return (
              (showGearUp
                ? tags.includes("gear_up")
                : tags.includes("hack_the_north")) && tags.includes(tagFilter)
            );
          });
        }
      } else if (showGearUp) {
        return gearUpEvents;
      } else {
        return hackTheNorthEvents;
      }
    }
  }, [
    gearUpEvents,
    getFilteredEvents,
    getFilteredSavedEvents,
    hackTheNorthEvents,
    savedGearUpEvents,
    savedHackTheNorthEvents,
    showGearUp,
    showSaved,
    tagFilter,
  ]);

  return (
    <StyledPageWrapper fullWidth={true}>
      {isOrganizer && (
        <CopyChangeBanner>
          <Link
            href="https://docs.team.hackthenorth.com/share/b35bd0ca-2946-4d7d-be61-532886759997"
            mods="heading big"
            newTab
          >
            Hi organizer! Need a copy change? Click me!!
          </Link>
        </CopyChangeBanner>
      )}
      <InfoContainer>
        <Text mods="heading h2">Schedule</Text>
        <Spacer height={12} />
        <Text>Apply filters:</Text>
        <BallButton
          onClick={() => toggleTagFilter("event")}
          selected={tagFilter === "event"}
        >
          <BlueBall />
          <Text>Event</Text>
        </BallButton>
        <BallButton
          onClick={() => toggleTagFilter("workshop")}
          selected={tagFilter === "workshop"}
        >
          <GreenBall />
          <Text>Workshop</Text>
        </BallButton>
        <BallButton
          onClick={() => toggleTagFilter("activity")}
          selected={tagFilter === "activity"}
        >
          <YellowBall />
          <Text>Activity</Text>
        </BallButton>
        <BallButton
          onClick={() => toggleTagFilter("sponsors")}
          selected={tagFilter === "sponsors"}
        >
          <PinkBall />
          <Text>Sponsors</Text>
        </BallButton>
        <BallButton
          onClick={() => toggleTagFilter("tech_talks")}
          selected={tagFilter === "tech_talks"}
        >
          <DarkBlueBall />
          <Text>Tech Talks</Text>
        </BallButton>
        {enableSaving && (
          <BallButton
            onClick={() => setShowSaved(!showSaved)}
            selected={showSaved}
          >
            <SavedIcon name="star" />
            <Text>Saved</Text>
          </BallButton>
        )}
      </InfoContainer>

      <RightContainer>
        <TopContainer>
          {viewFullSchedule ? (
            <Slider
              isLeftSelected={showGearUp}
              onLeftSelected={() => {
                setDaysViewDay(-1);
                setShowGearUp(true);
              }}
              onRightSelected={() => {
                setDaysViewDay(-1);
                setShowGearUp(false);
              }}
            />
          ) : (
            // Makes the justify-content: space-between style cleaner
            <div />
          )}
          <Flex align="center">
            <Link
              newTab
              href={
                viewFullSchedule ? PRIVATE_CALENDAR_LINK : PUBLIC_CALENDAR_LINK
              }
            >
              <Flex align="center">
                <Icon name="calendar" />
                <Spacer width="8px" />
                <Text mods="link">Add to Calendar</Text>
              </Flex>
            </Link>
            <Spacer width={32} />
            <TimePeriodSelector
              isWeekView={!showDaysView}
              toggleTimePeriod={() => {
                setDaysViewDay(-1);
                setShowDaysView(!showDaysView);
              }}
              currentDay={daysViewDay}
              totalDays={
                showGearUp ? gearUpDays.length : hackTheNorthDays.length
              }
              goToNextDay={() => setDaysViewDay(daysViewDay + 1)}
              goToPrevDay={() => setDaysViewDay(daysViewDay - 1)}
            />
          </Flex>
        </TopContainer>
        <Calendar
          inDayView={showDaysView}
          daysViewDay={daysViewDay}
          daysToShow={daysToShow}
          calendarEvents={eventsToShow}
          bannerEvents={bannerEventsToShow}
        />
      </RightContainer>

      <ErrorModal error={error} onCancel={() => setError(null)} />
      {/* We need to render the event modals here because otherwise, when the user
          - turns "View saved" on
          - opens an event modal
          - unsaves the event through the modal
          the event modal will disappear
      */}
      {Object.keys(allNonBannerEvents).map((day) =>
        allNonBannerEvents[day].map(
          (event) =>
            !event.isDuplicate &&
            openModal === event.id && (
              <EventModal
                event={event}
                eventColor={eventCategoryToColor[event.category]}
                toggleSaveEvent={() =>
                  toggleSaveEventWithErrorHandling(event.id)
                }
                enableSaving={enableSaving}
                isSaved={getIsSaved(event.id)}
                isOpen={true}
                onCancel={() => closeModalWithParam(event.id)}
              />
            )
        )
      )}
      {!isAuthenticated && <LoggedOutModal />}
    </StyledPageWrapper>
  );
};
