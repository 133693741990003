const padWithZeros = (val: number) => val.toString().padStart(2, "0");

// 2 possible states for timer:
// 1. Time until hacking starts (before Jan 15 11pm)
// 2. Time to submit (between Jan 15 11:01pm - Jan 17 11am)

const hackingStartTime = new Date(2021, 0, 15, 23, 0, 0, 0); // Jan 15th 11pm
const submissionTime = new Date(2021, 0, 17, 11, 0, 0, 0); // Jan 17th 11am

export const getTimeLeft = () => {
  const now = Date.UTC(2021, 0, 16, 11, 0);
  // default is submission time timer
  let difference = +submissionTime - +now;
  // put + to convert dates to number
  if (+now < +hackingStartTime) {
    difference = +hackingStartTime - +now;
  }

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24) + days * 24;
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return `${padWithZeros(hours)} : ${padWithZeros(minutes)} : 
    ${padWithZeros(seconds)}`;
  }
  return "00 : 00 : 00";
};
