import { Text, UnstyledButton } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

interface SliderProps {
  isLeftSelected: boolean;
  onLeftSelected: () => void;
  onRightSelected: () => void;
  leftText?: string;
  rightText?: string;
}

const Container = styled.div`
  display: inline-flex;
  background: ${({ theme }) => theme.globalConstants.color.greySecondary};
  border-radius: 4px;
`;

const SliderButton = styled(UnstyledButton)<{ selected?: boolean }>`
  background: ${({ theme, selected }) =>
    selected && theme.globalConstants.color.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.globalConstants.color.bluePrimary2 : "transparent"};
  border-radius: 5px;
  padding: 3px 32px;

  > p {
    color: ${({ theme, selected }) =>
      selected && theme.globalConstants.color.bluePrimary2};
  }
`;

export const Slider: React.FC<SliderProps> = ({
  isLeftSelected,
  onLeftSelected,
  onRightSelected,
  leftText = "Gear Up",
  rightText = "Hack the North",
}) => {
  return (
    <Container>
      <SliderButton selected={isLeftSelected} onClick={onLeftSelected}>
        <Text>{leftText}</Text>
      </SliderButton>
      <SliderButton selected={!isLeftSelected} onClick={onRightSelected}>
        <Text>{rightText}</Text>
      </SliderButton>
    </Container>
  );
};
