import { Text, Spacer, Flex, Button, Spinner } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import { OptionsType, OptionTypeBase } from "react-select";
import styled from "styled-components";

import { Modal, MultiSelect } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import {
  answersToOptions,
  optionsToAnswers,
} from "src/shared/utils/react-select";

import { INTERESTS } from "./constants";

export const ACCEPTED_FIELDS = [Field.INTERESTS];

const Wrapper = styled(Flex).attrs({ column: "true" })``;

interface InterestsModalProps {
  shouldShow: boolean;
  setShouldShow: (val: boolean) => void;
}

const InterestsModal: React.FC<InterestsModalProps> = ({
  shouldShow,
  setShouldShow,
}) => {
  const { updateResponses, isLoading } = useHackerContext();
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const [currentInterests, setCurrentInterests] = useState(
    responsesState[Field.INTERESTS]
  );

  useEffect(() => {
    setCurrentInterests(responsesState[Field.INTERESTS]);
  }, [responsesState]);

  const onSave = () => {
    updateResponses({
      [Field.INTERESTS]: currentInterests,
    });
  };

  const onCancel = () => {
    setCurrentInterests(responsesState[Field.INTERESTS]);
    setShouldShow(false);
  };

  return (
    <Modal
      isOpen={shouldShow}
      onClose={onCancel}
      width="720px"
      title="Areas of interest"
      actions={
        <>
          <Button mods="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Spacer width="20px" />
          <Button mods="primary" onClick={onSave}>
            Save Changes
          </Button>
        </>
      }
    >
      <Wrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Text>This will affect your reccomendations on the home page</Text>
            <Spacer width="10px" />
            <MultiSelect
              value={answersToOptions(currentInterests || [])}
              options={answersToOptions(INTERESTS)}
              onChange={(selectedOptions: OptionsType<OptionTypeBase>) =>
                setCurrentInterests(optionsToAnswers(selectedOptions))
              }
              placeholder="Select from dropdown"
            />
            <Spacer height="15px" />
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default InterestsModal;
