import { Text } from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import { Icon, Checkbox } from "src/shared/components";

import { TTechTalk } from "./useTechTalks";

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
`;

const TitleContainer = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  display: flex;
  svg {
    transition: transform 100ms;
    transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
    margin-left: 12px;
  }
  cursor: pointer;
  align-items: center;
`;

const TechTalkContainer = styled.div`
  margin-bottom: 18px;

  .description-text {
    display: inline-block;
    margin-top: 8px;
    margin-left: 32px;
  }
`;

type TTechTalkProps = {
  disabled?: boolean;
  checked: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (change: any) => void;
  talk: TTechTalk;
};

const TechTalk: React.FC<TTechTalkProps> = ({ disabled, talk, ...rest }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { title, description } = talk;

  return (
    <TechTalkContainer>
      <InnerContainer>
        <Checkbox disabled={disabled} {...rest} />
        <TitleContainer
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Text mods="medium">{title}</Text>
          <Icon name="chevron" {...rest} />
        </TitleContainer>
      </InnerContainer>
      {isExpanded && <Text className="description-text">{description}</Text>}
    </TechTalkContainer>
  );
};

export default TechTalk;
