/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetMentorshipRequestQueryVariables = Types.Exact<{
  claimId: Types.Scalars["Int"];
}>;

export type GetMentorshipRequestQuery = { __typename?: "Query" } & {
  claims: Array<{ __typename?: "Claim" } & Pick<Types.Claim, "stage_id">>;
};

export const GetMentorshipRequestDocument = gql`
  query GetMentorshipRequest($claimId: Int!) {
    claims(
      where: {
        pipeline: { is: { slug: { equals: "hackthenorth2020-mentorship" } } }
        id: { equals: $claimId }
      }
    ) {
      stage_id
    }
  }
`;

/**
 * __useGetMentorshipRequestQuery__
 *
 * To run a query within a React component, call `useGetMentorshipRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMentorshipRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMentorshipRequestQuery({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGetMentorshipRequestQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMentorshipRequestQuery,
    GetMentorshipRequestQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMentorshipRequestQuery,
    GetMentorshipRequestQueryVariables
  >(GetMentorshipRequestDocument, baseOptions);
}
export function useGetMentorshipRequestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMentorshipRequestQuery,
    GetMentorshipRequestQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMentorshipRequestQuery,
    GetMentorshipRequestQueryVariables
  >(GetMentorshipRequestDocument, baseOptions);
}
export type GetMentorshipRequestQueryHookResult = ReturnType<
  typeof useGetMentorshipRequestQuery
>;
export type GetMentorshipRequestLazyQueryHookResult = ReturnType<
  typeof useGetMentorshipRequestLazyQuery
>;
export type GetMentorshipRequestQueryResult = ApolloReactCommon.QueryResult<
  GetMentorshipRequestQuery,
  GetMentorshipRequestQueryVariables
>;
