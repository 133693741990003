/* eslint-disable @typescript-eslint/camelcase */
import { Text, Spacer, Button, Flex } from "@hackthenorth/north";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { PRIORITY_TO_LABEL } from "src/shared/constants/mentorship";
import { useGetMentorshipIndividualLazyQuery } from "src/shared/contexts/MentorContext/graphql/getMentorIndividual.generated";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { formatTime } from "src/utils/formatTime";

import MentorCardContent from "./MentorCardContent";
import RequestModal from "./RequestModal";

export interface RequestCardProps {
  request: MentorshipRequests.Request;
  updateRequest: (data: MentorshipRequests.Request) => Promise<unknown>;
  onResolve: (request: MentorshipRequests.Request) => void;
  onUnclaim: (request: MentorshipRequests.Request) => void;
}

const StyledFlex = styled(Flex)`
  justify-content: space-between;
`;

const Card = styled.div`
  width: 100%;
  max-width: 750px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.navySecondary};
  margin-bottom: 24px;
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const Tag = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
`;

const StageTag = styled(Tag)`
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
  height: 24px;
  text-transform: capitalize;
`;

const PriorityTag = styled(Tag)`
  background: ${({ theme }) => theme.globalConstants.color.creamSecondaryDark};
  height: 24px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Category = styled(Tag)`
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  && {
    margin: 3px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const EditButton = styled(Button)`
  margin-left: 12px;
  display: flex;
  align-items: center;
  height: 45px;
`;

// This component UI is largely based on the card in claimed-requests for mentors
const RequestCard: React.FC<RequestCardProps> = ({
  request,
  updateRequest,
  onResolve,
  onUnclaim,
}) => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [
    getMentorshipIndividualQuery,
    { data: mentorData },
  ] = useGetMentorshipIndividualLazyQuery();

  useEffect(() => {
    if (request?.mentor_id) {
      getMentorshipIndividualQuery({
        variables: {
          userId: request.mentor_id.toString(),
        },
      });
    }
  }, [request, getMentorshipIndividualQuery]);
  const mentorName = useMemo(() => {
    return mentorData?.claims[0]?.name;
  }, [mentorData]);

  // Repeat code from MentorContext
  const mentorProfile = useMemo(() => {
    const individualClaim = mentorData?.claims[0];
    const individualFields = individualClaim?.fields;
    return individualClaim && individualFields
      ? {
          user_id: request.mentor_id as number,
          institution: getFieldAnswer(
            individualFields,
            "institution"
          ) as string,
          mentorship_profile_photo: getFieldAnswer(
            individualFields,
            "mentorship_profile_photo"
          ) as HackerAPIFile,
          mentorship_topics: getFieldAnswer(
            individualFields,
            "mentorship_topics"
          ) as string[],
        }
      : null;
  }, [mentorData, request.mentor_id]);

  const submit = (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    const updated = { ...request, title, description, category, priority };
    updateRequest(updated);
    setIsRequestModalOpen(false);
  };

  // Titlecase the stage tag
  const stageTag =
    request.stage === "OPEN" ? "Unclaimed" : request.stage.toLowerCase();
  const isResolved = request.stage === "RESOLVED";
  const isUnclaimed = request.stage === "OPEN";
  return (
    <>
      <RequestModal
        isOpen={isRequestModalOpen}
        onCancel={() => setIsRequestModalOpen(false)}
        onSubmit={submit}
        requestToEdit={request}
        newRequest={false}
      />
      <Card>
        <StyledFlex row>
          <Flex align="center">
            <Text mods="h3">{request.title}</Text>
            <Spacer width={10} />
            <StageTag>{stageTag}</StageTag>
          </Flex>
          {!isResolved ? (
            <EditButton
              mods="nowrap"
              onClick={() => setIsRequestModalOpen(true)}
            >
              <Icon name="edit" size="16px" color="bluePrimary2" />
              <Spacer display="inline-block" width={7} />
              Edit
            </EditButton>
          ) : (
            <Spacer height={47} />
          )}
        </StyledFlex>
        <Flex row>
          <Text>{"Priority:"}</Text>
          <Spacer display="inline-block" width={5} />
          <PriorityTag>
            {request &&
              request.priority !== null &&
              request.priority !== undefined &&
              PRIORITY_TO_LABEL[request.priority]}
          </PriorityTag>
        </Flex>
        <Spacer height={10} />
        <Text>{request.description}</Text>
        <Spacer height={10} />
        <CategoryContainer>
          {(request?.category || []).map((category) => (
            <Category key={category}>{category}</Category>
          ))}
        </CategoryContainer>
        <Spacer height={10} />
        <Text mods="bluePrimary2">
          Submitted {formatTime(request.created_at)}
        </Text>
        {!isUnclaimed && mentorProfile && mentorName && (
          <MentorCardContent profile={mentorProfile} name={mentorName} />
        )}
        <Spacer height={10} />
        <ButtonContainer>
          {isResolved ? (
            <Button mods="secondary" onClick={() => onUnclaim(request)}>
              Reopen Request
            </Button>
          ) : (
            <Button mods="primary" onClick={() => onResolve(request)}>
              Mark Request as Resolved
            </Button>
          )}
        </ButtonContainer>
      </Card>
    </>
  );
};

export default RequestCard;
