import { useState } from "react";

import { Nullable } from "src/shared/utils/typescript";

import * as Milestones from "./types";

import { useMilestonesContext } from ".";

export const useMilestoneState = <Milestone extends Milestones.Milestone>(
  defaultMilestone: Milestone,
  validateMilestoneState: (
    milestoneState: Milestone["data"]
  ) => Nullable<string>, // returns error string if invalid, else valid
  submitSuccessString = "Successfully submitted!"
) => {
  const { isUpdating, updateError, completeMilestone } = useMilestonesContext();

  const [milestoneData, setMilestoneData] = useState<Milestone["data"]>(
    defaultMilestone.data
  );
  const [localError, setLocalError] = useState<string | null>(null);
  const [localSuccess, setLocalSuccess] = useState<string | null>(null);

  const updateMilestoneField = <Field extends keyof Milestone["data"]>(
    field: Field,
    value: Milestone["data"][Field]
  ) => {
    setMilestoneData((prevState) => ({ ...prevState, [field]: value }));
  };

  const onSubmit = async <T extends HTMLButtonElement>(
    _: React.MouseEvent<T>,
    customData?: Partial<Milestone["data"]>
  ) => {
    setLocalSuccess(null);

    const validationError = validateMilestoneState(milestoneData);
    if (typeof validationError === "string") {
      setLocalError(validationError);
      return;
    } else {
      setLocalError(null);
    }

    await completeMilestone(defaultMilestone.id, {
      ...milestoneData,
      ...(customData ?? {}),
    });

    setLocalSuccess(submitSuccessString);
  };

  return {
    data: milestoneData,
    onChange: updateMilestoneField,
    onSubmit,
    loading: isUpdating,
    error: localError || updateError,
    success: localSuccess,
  };
};
