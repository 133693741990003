import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";

export const ACCEPTED_FIELDS = [
  Field.RESUME,
  Field.RECRUITMENT_CONSENT,
  Field.CITIZENSHIP,
  Field.PERMANENT_RESIDENT,
  Field.INTERNSHIP_OPPORTUNITIES,
  Field.FULL_TIME_OPPORTUNITIES,
  Field.COFFEE_CHAT,
  Field.COMPANY_INTEREST,
];

export const VALIDATORS: Partial<
  Record<Field, (value: TAnswer) => boolean>
> = {}; // no validation needed - they can leave all fields on this page empty if they want
