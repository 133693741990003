import { useEffect } from "react";

import { useGetFileLazyQuery } from "src/api/files/getFile.generated";
import { useGetFilesLazyQuery } from "src/api/files/getFiles.generated";
import { Nullable } from "src/shared/utils/typescript";

import { HackerAPIFile } from "../utils/hackerapi";

const FILE_DELETED = "-1";

/**
 * A hook to re-fetch a file if it's expired, otherwise it returns the same file.
 *
 * NOTE: A fetch error will occur with an invalid file id (Ex. when calling convertFileToHAPIFile(file))
 * NOTE: The correct permissions are needed, otherwise the new file can't be fetched and we would return null
 */
export const useGetFile = (
  file: Nullable<HackerAPIFile>
): Nullable<HackerAPIFile> => {
  const fileExpired =
    file?.expiry !== undefined &&
    file?.expiry !== null &&
    Date.now() >= parseInt(file?.expiry);
  const fileId = parseInt(file?.id ?? FILE_DELETED);
  const shouldFetch = file && fileExpired && !isNaN(fileId);

  const [getFile, { data, loading, error }] = useGetFileLazyQuery({
    variables: {
      fileId: fileId,
    },
  });

  useEffect(() => {
    if (shouldFetch) getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  if (!shouldFetch || loading || error || !data) return file;

  return {
    id: data?.file.id.toString(),
    name: data?.file.name,
    type: data?.file.mime_type,
    ...data?.file.download,
  } as HackerAPIFile;
};

/**
 * A hook to re-fetch a list of files if there's at least one expired file.
 *  Only expired files will have their fields re-populated.
 *
 * NOTE: A fetch error will occur with an invalid file id (Ex. when calling convertFileToHAPIFile(file))
 * NOTE: The correct permissions are needed, otherwise the new file can't be fetched and we would return null
 */

export const useGetFiles = (files: HackerAPIFile[]): HackerAPIFile[] => {
  const expiredFiles = files.filter(
    (file) =>
      file?.expiry !== undefined &&
      file?.expiry !== null &&
      Date.now() >= parseInt(file?.expiry)
  );
  const shouldFetch = expiredFiles.length > 0;

  const [getFiles, { data, loading, error }] = useGetFilesLazyQuery({
    variables: {
      fileIds: expiredFiles.map((file) => parseInt(file.id ?? FILE_DELETED)),
    },
  });

  useEffect(() => {
    if (shouldFetch) getFiles();
  }, [shouldFetch, getFiles]);

  if (!shouldFetch || loading || error || !data) return files;

  const expiredFileIds = expiredFiles.map((file) => file.id);

  // Only re-populate fields for expired files
  return files.map((file) => {
    const isExpired = expiredFileIds.includes(file.id);
    if (!isExpired) return file;

    const fetchedFile = data?.files.find(
      ({ id }) => id === parseInt(file?.id ?? "")
    );

    const hapiFile = {
      id: fetchedFile?.id.toString(),
      name: fetchedFile?.name,
      type: fetchedFile?.mime_type,
      ...fetchedFile?.download,
    } as HackerAPIFile;

    return hapiFile;
  });
};
