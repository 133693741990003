/**
 * TODO: MAKE THIS INTO AN IndividualsContext or something so it can fetch information about any role
 */
/* eslint-disable @typescript-eslint/camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { useUpdateClaimMutation } from "src/api/claims/updateClaim.generated";
import {
  BaseAttendeeContextState,
  MaybeAvailable,
} from "src/shared/contexts/types";
import { useUserContext } from "src/shared/contexts/UserContext";
import { getFieldAnswer, parseState } from "src/shared/utils/hackerapi";
import * as Sponsor from "src/views/sponsor/types";

import { useGetCompanyNameLazyQuery } from "./graphql/getCompany.generated";
import { useGetCompanyRepresentativesLazyQuery } from "./graphql/getCompanyRepresentatives.generated";
import { useGetSponsorCompanyClaimLazyQuery } from "./graphql/getSponsorCompanyClaim.generated";
import { useGetSponsorIndividualClaimQuery } from "./graphql/getSponsorIndividualClaim.generated";
import { useUpdateCompanyClaimLogoMutation } from "./graphql/updateCompanyClaimLogo.generated";

interface SponsorContextState extends BaseAttendeeContextState {
  individual: MaybeAvailable<Sponsor.TIndividual | null>;
  company: MaybeAvailable<(Sponsor.TCompany & { id: number }) | null>;
  representatives: MaybeAvailable<Sponsor.TRepresentative[] | null>;

  updateCompanyLogo: (logoFileIds: number[]) => Promise<unknown>;
  updateSponsorIndividual: (
    data: Partial<SponsorContextState["individual"]>,
    id?: number
  ) => Promise<unknown>;
  updateSponsorCompany: (
    data: Partial<SponsorContextState["company"]>,
    id?: number
  ) => Promise<unknown>;
  updating: boolean;
}

const DEFAULT_STATE: SponsorContextState = {
  individual: null,
  company: null,
  representatives: null,

  updateCompanyLogo: () => Promise.reject(),
  updateSponsorIndividual: () => Promise.reject(),
  updateSponsorCompany: () => Promise.reject(),
  updating: false,

  loading: false,
  error: null,
};

const SponsorContext: React.Context<SponsorContextState> = createContext(
  DEFAULT_STATE
);

export const useSponsorContext = () => useContext(SponsorContext);

export const SponsorContextProvider: React.FC = ({ children }) => {
  const { id } = useUserContext();
  const {
    data: individualData,
    loading: individualLoading,
    error: individualError,
  } = useGetSponsorIndividualClaimQuery({
    variables: { userId: id?.toString() },
  });

  const individual: SponsorContextState["individual"] = useMemo(() => {
    const individualFields = individualData?.claims[0]?.fields;
    return individualFields
      ? {
          user_id: getFieldAnswer<number>(
            individualFields,
            Sponsor.IndividualField.USER_ID
          )!,
          company_claim_id: getFieldAnswer<number>(
            individualFields,
            Sponsor.IndividualField.COMPANY_CLAIM_ID
          )! as number,
          role: getFieldAnswer(
            individualFields,
            Sponsor.IndividualField.ROLE
          )! as Sponsor.Role,
          first_name: getFieldAnswer(
            individualFields,
            Sponsor.IndividualField.FIRST_NAME
          )! as string,
          last_name: getFieldAnswer(
            individualFields,
            Sponsor.IndividualField.LAST_NAME
          )! as string,
          preferred_name: getFieldAnswer(
            individualFields,
            Sponsor.IndividualField.PREFERRED_NAME
          )! as string,
          role_in_company: getFieldAnswer(
            individualFields,
            Sponsor.IndividualField.ROLE_IN_COMPANY
          )! as string,
        }
      : null;
  }, [individualData]);

  const [
    getSponsorCompanyClaimQuery,
    {
      data: companyClaimData,
      loading: companyClaimLoading,
      error: companyClaimError,
    },
  ] = useGetSponsorCompanyClaimLazyQuery();

  const [
    getCompanyNameQuery,
    { data: companyData, loading: companyLoading, error: companyError },
  ] = useGetCompanyNameLazyQuery();

  const company: SponsorContextState["company"] = useMemo(() => {
    const companyFields = companyClaimData?.claim.fields;
    return companyFields && companyData?.company.name
      ? {
          id: companyClaimData?.claim?.id!,
          name: companyData?.company.name,
          tier: getFieldAnswer<Sponsor.Tier>(
            companyFields,
            Sponsor.CompanyField.TIER
          )!,
          logo: getFieldAnswer(companyFields, Sponsor.CompanyField.LOGO),
          invite_code: getFieldAnswer(
            companyFields,
            Sponsor.CompanyField.INVITE_CODE
          ),
          favorited_hackers:
            getFieldAnswer(
              companyFields,
              Sponsor.CompanyField.FAVORITED_HACKERS
            ) ?? [],
        }
      : null;
  }, [companyClaimData, companyData]);

  const [
    getSponsorRepresentativesQuery,
    {
      data: companyRepresentativesData,
      loading: companyRepresentativesLoading,
      error: companyRepresentativesError,
    },
  ] = useGetCompanyRepresentativesLazyQuery();

  const representatives: SponsorContextState["representatives"] = useMemo(() => {
    const companyRepresentatives = companyRepresentativesData?.claims.map(
      (claim) => {
        const representativeFields = claim.fields;
        return {
          id: claim.id,
          user_id: getFieldAnswer<number>(representativeFields, "user_id")!,
          company_claim_id: getFieldAnswer<number>(
            representativeFields,
            "company_claim_id"
          )! as number,
          role: getFieldAnswer(representativeFields, "role")! as Sponsor.Role,
          name: claim.name,
        } as Sponsor.TRepresentative;
      }
    );
    return companyRepresentatives;
  }, [companyRepresentativesData]);

  /**
   * Fetch data only when their dependant variables are defined
   */
  useEffect(() => {
    if (individual?.company_claim_id) {
      getSponsorCompanyClaimQuery({
        variables: { companyClaimId: individual.company_claim_id },
      });
      getSponsorRepresentativesQuery({
        variables: { companyClaimId: individual.company_claim_id.toString() },
      });
    }
  }, [individual, getSponsorCompanyClaimQuery, getSponsorRepresentativesQuery]);

  useEffect(() => {
    const companyId = getFieldAnswer(
      companyClaimData?.claim.fields,
      "company_id"
    );
    if (companyId) {
      getCompanyNameQuery({ variables: { companyId } });
    }
  }, [companyClaimData, getCompanyNameQuery]);

  const [
    updateCompanyLogoMutation,
    { loading: logoUpdating, error: logoUpdateError },
  ] = useUpdateCompanyClaimLogoMutation();

  const updateCompanyLogo: SponsorContextState["updateCompanyLogo"] = useCallback(
    (logoFileIds: number[]) => {
      if (individual?.company_claim_id) {
        return updateCompanyLogoMutation({
          variables: {
            companyClaimId: individual?.company_claim_id,
            logoFileIds,
          },
        });
      }

      return Promise.resolve({});
    },
    [individual, updateCompanyLogoMutation]
  );

  const [
    updateSponsorIndividualMutation,
    { loading: sponsorIndividualLoading, error: sponsorIndividualError },
  ] = useUpdateClaimMutation();

  const updateSponsorIndividual: SponsorContextState["updateSponsorIndividual"] = useCallback(
    (data: Partial<SponsorContextState["individual"]>, id?: number) => {
      if (individualData) {
        const parsedData = parseState(data);
        return updateSponsorIndividualMutation({
          variables: {
            updatedData: {
              id: id ?? individualData?.claims[0].id,
              answers: parsedData,
            },
          },
        });
      }

      return Promise.resolve({});
    },
    [individualData, updateSponsorIndividualMutation]
  );

  const [
    updateSponsorCompanyMutation,
    { loading: sponsorCompanyLoading, error: sponsorCompanyError },
  ] = useUpdateClaimMutation();

  const updateSponsorCompany: SponsorContextState["updateSponsorCompany"] = useCallback(
    (data: Partial<SponsorContextState["company"]>, id?: number) => {
      if (companyClaimData) {
        const parsedData = parseState(data);
        return updateSponsorCompanyMutation({
          variables: {
            updatedData: {
              id: id ?? companyClaimData?.claim.id,
              answers: parsedData,
            },
          },
        });
      }

      return Promise.resolve({});
    },
    [companyClaimData, updateSponsorCompanyMutation]
  );

  /**
   * Build state
   */
  const state: SponsorContextState = {
    individual,
    company,
    representatives,
    loading:
      individualLoading ||
      companyClaimLoading ||
      companyLoading ||
      companyRepresentativesLoading ||
      sponsorIndividualLoading ||
      sponsorCompanyLoading,

    updateCompanyLogo,
    updateSponsorIndividual,
    updateSponsorCompany,
    updating: logoUpdating,

    error:
      individualError?.message ??
      companyClaimError?.message ??
      companyError?.message ??
      logoUpdateError?.message ??
      companyRepresentativesError?.message ??
      sponsorIndividualError?.message ??
      sponsorCompanyError?.message ??
      null,
  };

  return (
    <SponsorContext.Provider value={state}>{children}</SponsorContext.Provider>
  );
};
