import React, { createContext, useContext, useState } from "react";

interface SharedAccordionContextProps {
  isDefaultOpen?: boolean;
}

const SharedAccordionContext = createContext({
  isSharedOpen: false,
  isUsingSharedContext: false,
  toggleAccordions: () =>
    console.error("No parent SharedAccordionContext found!"),
});

export const useSharedAccordionContext = () =>
  useContext(SharedAccordionContext);

export const SharedAccordionContextProvider: React.FC<SharedAccordionContextProps> = ({
  children,
  isDefaultOpen = false,
}) => {
  const [isSharedOpen, setIsSharedOpen] = useState(isDefaultOpen);

  const toggleAccordions = () =>
    setIsSharedOpen((prevIsSharedOpen) => !prevIsSharedOpen);

  return (
    <SharedAccordionContext.Provider
      value={{ isSharedOpen, toggleAccordions, isUsingSharedContext: true }}
    >
      {children}
    </SharedAccordionContext.Provider>
  );
};

SharedAccordionContextProvider.displayName = "SharedAccordionContextProvider";
