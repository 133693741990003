import React, { useMemo } from "react";
import { useTheme } from "styled-components";

import {
  CopyIcon,
  XIcon,
  UploadIcon,
  MaximizeIcon,
  EyeIcon,
  EyeOffIcon,
  ChevronDownIcon,
  ChevronSideIcon,
  StarIcon,
  StarOutlineIcon,
  ClockIcon,
  LogOutIcon,
  EditIcon,
  FileIcon,
  LockIcon,
  CheckIcon,
  BlueCheckmarkIcon,
  UsersIcon,
  SolidArrowIcon,
  CalendarIcon,
  RefreshIcon,
} from "src/static/icons";

export type IconName =
  | "copy"
  | "x"
  | "upload"
  | "maximize"
  | "eye"
  | "eye-off"
  | "chevron"
  | "chevron-side"
  | "clock"
  | "log-out"
  | "edit"
  | "file"
  | "lock"
  | "check"
  | "blue-checkmark"
  | "calendar"
  | "star"
  | "star-outline"
  | "users"
  | "solid-arrow"
  | "refresh";

export interface IconProps extends React.ComponentPropsWithoutRef<"svg"> {
  name: IconName;
  color?: string;
  /**
   * Pass in the name of a globalConstant fontSize value or a css value. Defaults to `bodyBig` size.
   */
  size?: string;
}

const NAME_TO_ICON_MAP: Record<
  IconName,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  copy: CopyIcon,
  x: XIcon,
  upload: UploadIcon,
  maximize: MaximizeIcon,
  eye: EyeIcon,
  "eye-off": EyeOffIcon,
  chevron: ChevronDownIcon,
  "chevron-side": ChevronSideIcon,
  clock: ClockIcon,
  "log-out": LogOutIcon,
  edit: EditIcon,
  file: FileIcon,
  lock: LockIcon,
  check: CheckIcon,
  calendar: CalendarIcon,
  "blue-checkmark": BlueCheckmarkIcon,
  star: StarIcon,
  "star-outline": StarOutlineIcon,
  users: UsersIcon,
  "solid-arrow": SolidArrowIcon,
  refresh: RefreshIcon,
};

const Icon: React.FC<IconProps> = ({
  name,
  size = "bodyBig",
  color = "textPrimary",
  ...rest
}) => {
  const { globalConstants } = useTheme();

  const internalSize = globalConstants.fontSize[size]
    ? `${globalConstants.fontSize[size]}px`
    : size;
  const internalColor = globalConstants.color[color] || color;
  const IconSVG = useMemo(() => NAME_TO_ICON_MAP[name], [name]);

  if (!IconSVG) console.warn(`Invalid icon name: ${name}`);

  return IconSVG ? (
    <IconSVG
      width={internalSize}
      height={internalSize}
      color={internalColor}
      {...rest}
    />
  ) : null;
};

export default Icon;
