import { HackerAPIFile } from "src/shared/utils/hackerapi";

// company
export enum Tier {
  GOLD = "gold",
  SILVER = "silver",
  BRONZE = "bronze",
  STARTUP = "startup",
}

export enum CompanyField {
  NAME = "name",
  TIER = "tier",
  LOGO = "logo",
  INVITE_CODE = "invite_code",
  FAVORITED_HACKERS = "favorited_hackers",
}

export type TCompany = {
  [CompanyField.NAME]: string;
  [CompanyField.TIER]: Tier;
  [CompanyField.LOGO]: HackerAPIFile;
  [CompanyField.INVITE_CODE]: string;
  [CompanyField.FAVORITED_HACKERS]: string[];
};

// individual
export enum Role {
  ADMIN = "admin",
  MEMBER = "member",
}

export enum IndividualField {
  USER_ID = "user_id",
  COMPANY_CLAIM_ID = "company_claim_id",
  ROLE = "role",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  PREFERRED_NAME = "preferred_name",
  ROLE_IN_COMPANY = "role_in_company",
}

export type TIndividual = {
  [IndividualField.USER_ID]: number;
  [IndividualField.COMPANY_CLAIM_ID]: number;
  [IndividualField.ROLE]: Role;
  [IndividualField.FIRST_NAME]: string;
  [IndividualField.LAST_NAME]: string;
  [IndividualField.PREFERRED_NAME]: string;
  [IndividualField.ROLE_IN_COMPANY]: string;
};

// representative
export type TRepresentative = TIndividual & {
  id: number;
  name: string;
};
