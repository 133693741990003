import { JWTRole } from "@hackthenorth/north";
import React, { createContext, useContext } from "react";

import {
  useMilestonesContext,
  useUserContext,
  useSponsorContext,
  useHackerContext,
} from "src/shared/contexts";
import { IS_PRODUCTION } from "src/utils/env";
import {
  Role as SponsorRole,
  Tier as SponsorTier,
} from "src/views/sponsor/types";

import { HackerStage } from "../HackerContext/types";

import { Permission } from "./types";

export interface PermissionsContextState {
  isLoading: boolean;
  permissions: Permission[];
  hasPermission: (permission: Permission) => boolean;
  hasPermissions: (permissions?: Permission[]) => boolean;
}

const DEFAULT_STATE: PermissionsContextState = {
  isLoading: true,
  permissions: [],
  hasPermission: () => {
    throw new Error("No provider found");
  },
  hasPermissions: () => {
    throw new Error("No provider found");
  },
};

const PermissionsContext: React.Context<PermissionsContextState> =
  createContext(DEFAULT_STATE);

export const usePermissionsContext = () => useContext(PermissionsContext);

export const PermissionsContextProvider: React.FC = ({ children }) => {
  const { onboarding, isLoading: isMilestonesLoading } = useMilestonesContext();
  const { roles, isOrganizer } = useUserContext();
  const {
    individual,
    company,
    loading: isSponsorLoading,
  } = useSponsorContext();
  const { stage, isLoading: isHackerLoading } = useHackerContext();

  const hasPermission: PermissionsContextState["hasPermission"] = (
    permission: Permission
  ) => {
    if (isOrganizer) return true;

    // values that are shared across multiple permissions
    const isSponsor = roles.includes(JWTRole.SPONSOR);
    const isHacker = roles.includes(JWTRole.HACKER);
    const isVolunteer = roles.includes(JWTRole.VOLUNTEER);
    const isMentor = roles.includes(JWTRole.MENTOR);

    switch (permission) {
      case Permission.SPONSOR:
        return isSponsor;

      case Permission.HACKER:
        return isHacker;

      case Permission.VOLUNTEER:
        return isVolunteer;

      case Permission.MENTOR:
        return isMentor;

      case Permission.SPONSOR_FEATURES_ACCESS:
        return isSponsor && !!onboarding?.complete;

      case Permission.SPONSOR_ADMIN:
        return isSponsor && individual?.role === SponsorRole.ADMIN;

      case Permission.SPONSOR_RECRUITMENT_ACCESS:
        return (
          isSponsor &&
          company?.id !== 63526 && // Restrict Shopify from recruitment page
          (company?.tier === SponsorTier.GOLD ||
            company?.tier === SponsorTier.SILVER ||
            company?.tier === SponsorTier.BRONZE)
        );

      case Permission.HACKER_RSVP:
        return (
          isHacker &&
          (stage === HackerStage.ACCEPTED ||
            stage === HackerStage.WAITLISTED ||
            stage === HackerStage.WITHDRAWN ||
            stage === HackerStage.CONFIRMED ||
            stage === HackerStage.EXPIRED)
        );

      case Permission.HACKER_RSVP_NOT_RESTRICTED:
        return (
          isHacker &&
          (stage === HackerStage.ACCEPTED || stage === HackerStage.CONFIRMED)
        );

      case Permission.HACKER_DAY_OF_TOOLS_ACCESS:
        return isHacker && stage === HackerStage.CHECKED_IN;

      case Permission.VIEW_FULL_SCHEDULE:
        return (
          (isHacker && stage === HackerStage.CHECKED_IN) ||
          isSponsor ||
          isVolunteer ||
          isMentor
        );

      case Permission.STAGING:
        return !IS_PRODUCTION;

      default:
        throw new Error(`Invalid permission found: ${permission}`);
    }
  };

  const hasPermissions: PermissionsContextState["hasPermissions"] = (
    permissions: Permission[] = [],
    requireAll = true
  ) =>
    requireAll
      ? permissions.every((p) => hasPermission(p))
      : permissions.some((p) => hasPermission(p));

  const permissions: PermissionsContextState["permissions"] = Object.values(
    Permission
  ).filter((p) => hasPermission(p));

  const isLoading = isMilestonesLoading || isSponsorLoading || isHackerLoading;

  return (
    <PermissionsContext.Provider
      value={{ isLoading, permissions, hasPermission, hasPermissions }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
