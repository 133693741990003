import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";

const SHARE_ID = "6cd7bbda-0905-407a-ac52-3e35364e6053";

const PrivacyPolicy: React.FC = () => {
  return (
    <PageWrapper>
      <OutlineDocument shareId={SHARE_ID} />
    </PageWrapper>
  );
};

export default PrivacyPolicy;
