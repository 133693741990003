import { Flex, Spacer, Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { UnstyledLink } from "src/shared/components";
import { useDeviceSize } from "src/shared/hooks";
import {
  GlobeEmojiSVG,
  HackerMapImg,
  HouseEmojiSVG,
  SpotifyCodeSVG,
  WaveEmojiSVG,
} from "src/static/img";
import { ScheduleContextProvider } from "src/views/schedule/ScheduleContext";

import Annoucements from "./Annoucements";
import BeaverMessages from "./BeaverMessages";
import HappeningNow from "./CurrentEvents";
// import Judging from "./Judging";
import ReccomendedForYou from "./RecommendedEvents";
import UsefulLinks from "./UsefulLinks";
import VirtualPosters from "./VirtualPosters";

const PageWrapper = styled.main`
  width: 100%;
  height: 100%;
  display: block;
  overflow: auto;
  padding: 25px 50px;
  background: ${({ theme }) => theme.globalConstants.color.gradientTan330};
  position: relative;
`;

const PageGrid = styled.div<{ isExtraLargeOrSmaller: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ isExtraLargeOrSmaller }) =>
    isExtraLargeOrSmaller ? "3fr 1fr" : "4fr 1fr 1fr"};
  column-gap: 65px;
`;

const MapGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 32px;
  align-items: center;
`;

const MapTextGroup = styled(Flex).attrs({ column: true, align: "flex-end" })`
  text-align: right;
`;

const Img = styled.img`
  width: 100%;
  max-width: 1000px;
`;

const SpotifyButton = styled(UnstyledLink)`
  width: 100%;
`;

const HackerHome: React.FC = () => {
  const isExtraLargeOrSmaller = useDeviceSize("extraLarge");

  return (
    <ScheduleContextProvider>
      <PageWrapper>
        <Spacer height="15px" />
        <BeaverMessages />
        <PageGrid isExtraLargeOrSmaller={isExtraLargeOrSmaller}>
          <div>
            <Annoucements />
            <Spacer height="35px" />
            <MapGrid>
              <MapTextGroup>
                <Text mods="heading h3">You&apos;re hacking with...</Text>
                <Text mods="medium bluePrimary2">
                  3290 hackers &nbsp;
                  <WaveEmojiSVG />
                </Text>
                <Spacer height="16px" />
                <Text mods="medium bluePrimary2">
                  from 48 countries &nbsp;
                  <GlobeEmojiSVG />
                </Text>
                <Spacer height="16px" />
                <Text mods="medium bluePrimary2">
                  from 170 schools &nbsp;
                  <HouseEmojiSVG />
                </Text>
              </MapTextGroup>
              <Img src={HackerMapImg} alt="Hacker Map" />
            </MapGrid>
            <Spacer height="29px" />
            <HappeningNow />
            <Spacer height="34px" />
            <ReccomendedForYou />
            <Spacer height="24px" />
            {/* remove next line before releasing */}
            {/* <Judging /> */}
          </div>
          {!isExtraLargeOrSmaller && <div />}
          <div>
            <UsefulLinks />
            <Spacer height="36px" />
            <VirtualPosters />
            <Spacer height="36px" />
            <Text mods="bold">Hack the North 2020++ Playlist</Text>
            <Spacer height="24px" />
            <SpotifyButton
              href="https://open.spotify.com/playlist/0F5NQi9NC9dNcCtwlO4UlS?si=RnSr4F1gS0q-CJYaGywh5w"
              target="_blank"
            >
              <SpotifyCodeSVG />
            </SpotifyButton>
          </div>
        </PageGrid>
        <Spacer height="25px" />
      </PageWrapper>
    </ScheduleContextProvider>
  );
};

export default HackerHome;
