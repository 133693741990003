import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";
import { useMilestonesContext } from "src/shared/contexts";

import { TCForm } from "./Form";

const SHARE_ID = "abdd7ea4-cb4a-4817-aedf-cf1b0bcbe114";

const TermsAndConditions: React.FC = () => {
  const { termsAndConditions } = useMilestonesContext();

  return (
    <PageWrapper>
      <OutlineDocument shareId={SHARE_ID} />

      {termsAndConditions && <TCForm termsAndConditions={termsAndConditions} />}
    </PageWrapper>
  );
};
export default TermsAndConditions;
