import { Spacer, Text } from "@hackthenorth/north";
import React from "react";

import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

interface PreHackContestProps {
  perk: SponsorPerks.PreHackContest;
}

type StoredPerk = SponsorPerks.PreHackContest;

const description = (
  <>
    <Text mods="medium">
      This contest will run before the weekend of Hack the North, and be an
      opportunity for you to challenge hackers with a fun and interesting prompt
      and receive submissions from hackers outside of their hackathon projects.
      Please provide a title and description of your planned contest.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      After you submit a description, our team will be in contact to determine
      logistical details and timelines!
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.title && !!perkData.description;

const PreHackContest: React.FC<PreHackContestProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const savePreHackContestPerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitPreHackContestPerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.PRE_HACK_CONTEST}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.PRE_HACK_CONTEST}--title`}
          title="Title*"
          value={perkData["title"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.PRE_HACK_CONTEST}--description`}
          title="Description* (max 150 words)"
          value={perkData["description"] ?? ""}
          wordLimit={150}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("description", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.PRE_HACK_CONTEST}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={savePreHackContestPerk}
          onSubmit={submitPreHackContestPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.PRE_HACK_CONTEST} error={error} />
    </>
  );
};

export default PreHackContest;
