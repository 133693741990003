import React from "react";
import styled from "styled-components";

import Collapsible from "./Collapsible";
import { AccordionContextProvider } from "./context";

export interface AccordionProps extends React.ComponentPropsWithoutRef<"div"> {
  heading: React.ReactNode;
  disabled?: boolean;
  isDefaultOpen?: boolean;
  showOverflow?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const Accordion: React.FC<AccordionProps> = ({
  heading,
  disabled = false,
  isDefaultOpen = false,
  showOverflow = false,
  children,
  ...rest
}) => (
  <AccordionContextProvider isDefaultOpen={isDefaultOpen}>
    <Container {...rest}>{heading}</Container>
    <Collapsible disabled={disabled} showOverflow={showOverflow}>
      {children}
    </Collapsible>
  </AccordionContextProvider>
);

export default Accordion;
