import { JWTRole } from "@hackthenorth/north";
import React from "react";

import { HackerContextProvider } from "src/shared/contexts/HackerContext";
import { SponsorContextProvider } from "src/shared/contexts/SponsorContext";
import { useUserContext } from "src/shared/contexts/UserContext";

// TODO: change the key type to match JWTRole
const ROLE_TO_CONTEXT: Partial<Record<JWTRole, React.FC>> = {
  sponsor: SponsorContextProvider,
  hacker: HackerContextProvider,
};

// roles that have their own provider components
const PROVIDER_ROLES = [JWTRole.SPONSOR, JWTRole.HACKER];

/**
 * The Attendee dashboard needs to support access by users of multiple
 * roles (e.g. hacker, sponsor). We do not want to have to perform unnecessary
 * work for roles that a user does not possess, so we conditionally render
 * the context provider for the context matching the user role.
 *
 * This is possible due to the default state that is provided by a context when
 * it is used outside of a parent provider. The MaybeAvailable type should be wrapped
 * around any piece of state that is conditional (i.e. all of the data in a context provider)
 * and `undefined` used as the default value so that children accessing that piece
 * must handle `undefined` as a possible value and thus do not break when that context provider
 * is not currently rendered.
 */
export const DataProvider: React.FC = ({ children }) => {
  const { roles } = useUserContext();

  const providerRoles = roles.filter((role) => PROVIDER_ROLES.includes(role));

  console.log(`Providing context for ${providerRoles.join(", ")}`);

  const ProviderComponents = providerRoles.map(
    (providerRole) => ROLE_TO_CONTEXT[providerRole]
  );

  return ProviderComponents.reduce(
    (child, ProviderComponent) =>
      ProviderComponent ? (
        <ProviderComponent>{child}</ProviderComponent>
      ) : (
        child
      ),
    children
  ) as React.ReactElement;
};
