import React, { createContext, useContext, useState } from "react";

interface AccordionContextProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  toggleOpen: () => void;
  setLocked: () => void;
}
interface AccordionContextProviderProps {
  isDefaultOpen?: boolean;
  children?: React.ReactNode;
}

const AccordionContext = createContext<AccordionContextProps>({
  isOpen: false,
  setOpen: () => console.error("No parent AccordionContext found!"),
  toggleOpen: () => console.error("No parent AccordionContext found!"),
  setLocked: () => console.error("No parent AccordionContext found!"),
});

export const useAccordionContext = () => useContext(AccordionContext);

export const AccordionContextProvider: React.FC<AccordionContextProviderProps> = ({
  isDefaultOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const [isLocked, setIsLocked] = useState(false);
  const toggleOpen = () => {
    if (!isLocked) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  };
  const setLocked = () => setIsLocked(true);

  return (
    <AccordionContext.Provider
      value={{ isOpen, setOpen: setIsOpen, toggleOpen, setLocked }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

AccordionContextProvider.displayName = "AccordionContextProvider";
