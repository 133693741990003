import { Text, Button, Spacer } from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import { Link, Modal } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";
import { AttendeeImg, GuestImg } from "src/static/img";

const Wrapper = styled.div`
  display: flex;
  padding: 50px 20px;
`;

const FootnoteContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
`;

const BlueText = styled.span`
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
`;

const StyledButton = styled(Button)`
  font-size: ${({ theme }) =>
    theme.globalConstants.fontSize.bodyBig}px !important;
`;

const Img = styled.img`
  height: 110px;
`;

const Separator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.globalConstants.color.textDark};
  opacity: 0.5;
  margin: 0 60px;
`;

export const LoggedOutModal = () => {
  const { logIn } = useUserContext();
  const [isOpen, setOpen] = useState(true);

  return (
    <Modal isOpen={isOpen} width="760px">
      <Wrapper>
        <Container>
          <Img src={AttendeeImg} alt="" aria-hidden />
          <Spacer height={20} />
          <Text mods="heading h3">
            Log in as an <BlueText>Attendee*</BlueText> to view the full
            schedule
          </Text>
          <Spacer height={16} />
          <StyledButton mods="primary" onClick={logIn}>
            Log in
          </StyledButton>
        </Container>
        <Separator />
        <Container>
          <Img src={GuestImg} alt="" aria-hidden />
          <Spacer height={20} />
          <Text mods="heading h3">
            Continue as a <BlueText>Guest</BlueText> to view the Gear Up
            schedule
          </Text>
          <Spacer height={16} />
          <Link href="/schedule" onClick={() => setOpen(false)}>
            <StyledButton mods="primary">Continue</StyledButton>
          </Link>
        </Container>
      </Wrapper>
      <FootnoteContainer>
        <Text mods="subtle">
          *Attendees include accepted hackers, volunteers, mentors, sponsors
        </Text>
      </FootnoteContainer>
    </Modal>
  );
};
