import { Flex } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { PageWrapper } from "src/shared/components";
import { RightCloudImg, LeftCloudImg } from "src/static/img";

const FlexWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.medium`
    align-items: baseline;
  `}
`;

const WestCloud = styled.img`
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  width: 1400px;
  left: -400px;
  bottom: 80px;

  ${({ theme }) => theme.mediaQueries.medium`
    width: 1000px;
    left: -300px;
    bottom: 0px;
  `};
`;

const EastCloud = styled.img`
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  width: 1140px;
  right: -400px;
  bottom: 110px;

  ${({ theme }) => theme.mediaQueries.medium`
    width: 700px;
    right: -300px;
    bottom: -55px;
  `};
`;

const NECloud = styled.img`
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  width: 500px;
  top: -100px;
  right: 100px;

  ${({ theme }) => theme.mediaQueries.medium`
    width: 800px;
    top: 20px;
    right: -250px
  `};
`;

const NWCloud = styled.img`
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  width: 800px;
  top: -150px;
  left: 150px;

  ${({ theme }) => theme.mediaQueries.large`
    width: 700px;
  `};

  ${({ theme }) => theme.mediaQueries.medium`
    width: 800px;
    left: -240px;
    top: -90px;
  `};
`;

const SouthCloud = styled.img`
  z-index: -1;
  position: absolute;
  opacity: 0.4;
  bottom: -100px;
  width: 1300px;
  left: 380px;

  ${({ theme }) => theme.mediaQueries.large`
    width: 1140px;
    left: 100px;
  `};

  ${({ theme }) => theme.mediaQueries.medium`
    display: none;
  `};
`;

const ErrorPageWrapper: React.FC<ComponentPropsWithoutRef<"div">> = ({
  children,
  className,
}) => (
  <PageWrapper className={className}>
    <WestCloud src={RightCloudImg} alt="" aria-hidden />
    <EastCloud src={RightCloudImg} alt="" aria-hidden />
    <SouthCloud src={LeftCloudImg} alt="" aria-hidden />
    <NECloud src={RightCloudImg} alt="" aria-hidden />
    <NWCloud src={RightCloudImg} alt="" aria-hidden />
    <FlexWrapper column align="center" justify="center">
      {children}
    </FlexWrapper>
  </PageWrapper>
);

export default ErrorPageWrapper;
