import {
  MENTORSHIP_REQUEST_STAGE_ID_CLAIMED,
  MENTORSHIP_REQUEST_STAGE_ID_CLOSED,
  MENTORSHIP_REQUEST_STAGE_ID_OPEN,
  MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED,
  MENTORSHIP_REQUEST_STAGE_ID_RESOLVED,
} from "src/shared/constants/mentorship";
import { Nullable } from "src/shared/utils/typescript";

export interface Request extends RequestInput {
  id: number;
  created_at: Date;
  reopened: boolean;
  stage: string;

  // Mentee
  mentee_name: string;
  mentee_id: number;
  mentee_discord_id: Nullable<string>;

  // Mentor
  mentor_id: Nullable<number>;
}

export interface RequestInput {
  title: Nullable<string>;
  description: Nullable<string>;
  category: Nullable<string[]>;
  priority: Nullable<number>;
}

export enum Stage {
  OPEN = MENTORSHIP_REQUEST_STAGE_ID_OPEN,
  CLAIMED = MENTORSHIP_REQUEST_STAGE_ID_CLAIMED, // claimed by a mentor
  NOTIFIED = MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED, // notified on discord
  CLOSED = MENTORSHIP_REQUEST_STAGE_ID_CLOSED, // resolved by mentors
  RESOLVED = MENTORSHIP_REQUEST_STAGE_ID_RESOLVED, //resolved by mentees
}
