export const MENTORSHIP_TOPICS = [
  "Software",
  "Hardware",
  "Design",
  "Web",
  "Frontend",
  "Backend",
  "Data science",
  "Computer Vision",
  "Machine Learning",
  "Blockchain",
  "Android",
  "iOS",
  "Game Development",
  "Virtual Reality",
  "Augmented Reality",
  "Infrastructure",
  "Cloud Computing",
  "Security",
  "Embedded",
  "Robotics",
  "Other",
];

export const PRIORITY_TO_LABEL = {
  0: "Low",
  1: "Medium",
  2: "High",
};

export const PRIORITY_OPTIONS = [
  { value: 0, label: PRIORITY_TO_LABEL[0] },
  { value: 1, label: PRIORITY_TO_LABEL[1] },
  { value: 2, label: PRIORITY_TO_LABEL[2] },
];

export const TOPICS = [
  "3D",
  "Android",
  "Angular",
  "Arduino",
  "C",
  "C++",
  "C#",
  "HTML",
  "CSS",
  "Design",
  "Ember",
  "Go",
  "Golang",
  "Java",
  "Javascript",
  "Typescript",
  "Kotlin",
  "MongoDB",
  "Nodejs",
  "Oculus",
  "PHP",
  "Prototyping",
  "Python",
  "React",
  "Ruby on Rails",
  "Rust",
  "Scala",
  "Swift",
  "TensorFlow",
  "Unity",
  "VR",
  "iOS",
  "Blockchain",
];

export const MENTORSHIP_REQUEST_PIPELINE_ID = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_PIPELINE_ID || ""
);
export const MENTORSHIP_PROFILE_PIPELINE_ID = parseInt(
  process.env.REACT_APP_MENTORSHIP_PROFILE_PIPELINE_ID || ""
);
export const MENTORSHIP_REQUEST_STAGE_ID_OPEN = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_STAGE_ID_OPEN || ""
);
export const MENTORSHIP_REQUEST_STAGE_ID_CLAIMED = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_STAGE_ID_CLAIMED || ""
);
export const MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED || ""
);
export const MENTORSHIP_REQUEST_STAGE_ID_CLOSED = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_STAGE_ID_CLOSED || ""
);
export const MENTORSHIP_REQUEST_STAGE_ID_RESOLVED = parseInt(
  process.env.REACT_APP_MENTORSHIP_REQUEST_STAGE_ID_RESOLVED || ""
);
export const MENTORSHIP_PROFILE_STAGE_ID = parseInt(
  process.env.REACT_APP_MENTORSHIP_PROFILE_STAGE_ID || ""
);
