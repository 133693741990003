export type WorkshopSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

const ANDROID_SHARE_ID = "4a025a37-6d23-4f6f-8df0-9b7278c3c44c";
const BACKEND_SHARE_ID = "94cef602-b048-4ab6-98a1-3d74e7ee234d";
const BLOCKCHAIN_SHARE_ID = "dd2eff17-2a66-42df-b98f-7d2d56292f4c";
const CAREER_SHARE_ID = "bee2d2da-adf4-4287-8339-8426b02f2736";
const DATA_SCIENCE_SHARE_ID = "a2d21df1-6be3-4b0e-8fc4-50a6f3523e5d";
const DESIGN_SHARE_ID = "b6dcb107-c108-4b5a-87bb-adad1d9bceb4";
const EMBEDDED_SHARE_ID = "0861e938-5fbd-41e3-9e10-9b9778665ab4";
const FRONTEND_SHARE_ID = "b574e2e3-6ebe-4eaf-b952-ae62d2523bfc";
const HACKATHON_SUCCESS_SHARE_ID = "99c168fe-7b78-413e-8b38-f488a6f8aed3";
const MACHINE_LEARNING_SHARE_ID = "4614282a-4233-42ae-894a-0bdd2bd1ad4e";
const OPEN_SOURCE_SHARE_ID = "609a5e3f-a3a8-42a6-93d5-b3a08820d639";
const SECURITY_SHARE_ID = "a29e9fdd-2e21-4c7c-9aa2-21fdfc55f03b";
const VIRTUAL_REALITY_SHARE_ID = "7266748c-dcd5-4c64-930d-1a5ade31f171";

export const WORKSHOP_SECTIONS: WorkshopSection[] = [
  {
    label: "Android",
    sectionId: "android",
    shareId: ANDROID_SHARE_ID,
  },
  {
    label: "Backend",
    sectionId: "backend",
    shareId: BACKEND_SHARE_ID,
  },
  {
    label: "Blockchain",
    sectionId: "blockchain",
    shareId: BLOCKCHAIN_SHARE_ID,
  },
  {
    label: "Career",
    sectionId: "career",
    shareId: CAREER_SHARE_ID,
  },
  {
    label: "Data Science",
    sectionId: "data-science",
    shareId: DATA_SCIENCE_SHARE_ID,
  },
  {
    label: "Design",
    sectionId: "design",
    shareId: DESIGN_SHARE_ID,
  },
  {
    label: "Embedded",
    sectionId: "embedded",
    shareId: EMBEDDED_SHARE_ID,
  },
  {
    label: "Frontend",
    sectionId: "frontend",
    shareId: FRONTEND_SHARE_ID,
  },
  {
    label: "Hackathon Success",
    sectionId: "hackathon-success",
    shareId: HACKATHON_SUCCESS_SHARE_ID,
  },
  {
    label: "Machine Learning",
    sectionId: "machine-learning",
    shareId: MACHINE_LEARNING_SHARE_ID,
  },
  {
    label: "Open Source",
    sectionId: "open-source",
    shareId: OPEN_SOURCE_SHARE_ID,
  },
  {
    label: "Security",
    sectionId: "security",
    shareId: SECURITY_SHARE_ID,
  },
  {
    label: "Virtual Reality (VR)",
    sectionId: "virtual-reality",
    shareId: VIRTUAL_REALITY_SHARE_ID,
  },
];
