import {
  Text,
  Spacer,
  UnstyledButton,
  Button,
  Flex,
} from "@hackthenorth/north";
import moment from "moment";
import { rgba } from "polished";
import React, { useMemo } from "react";
import styled from "styled-components";

import { Icon, Link } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { buttonStyles } from "src/theme/componentStyles/button";
import { linkStyles } from "src/theme/componentStyles/link";

import { useScheduleContext } from "../ScheduleContext";
import { TEvent } from "../types";

interface EventModalProps {
  event: TEvent;
  eventColor: string;
  toggleSaveEvent: () => void;
  enableSaving: boolean;
  isSaved: boolean;
  onCancel: () => void;
  isOpen: boolean;
}

const StyledModal = styled(Modal)<{ eventColor: string }>`
  border: 4px solid ${({ eventColor }) => eventColor};
  box-sizing: border-box;
  border-radius: 20px;

  > div {
    padding-right: 24px;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledText = styled(Text)`
  margin: 0 !important;
`;

const TimePeriodText = styled(Text)`
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2} !important;
`;

const EventTypeIndicator = styled.div<{ eventColor: string }>`
  background: ${({ eventColor }) => rgba(eventColor, 0.5)};
  border-radius: 10px;
  padding: 1px 12px 0 12px;
`;

const IconButton = styled(UnstyledButton)`
  display: flex;
`;

const LinkContainer = styled(Flex)`
  display: flex;
  justify-content: center;
  margin: 26px -24px 8px 0;
`;

const LinkButton = styled(Button)`
  padding: 7px 127px !important;
  font-size: ${({ theme }) =>
    theme.globalConstants.fontSize.bodyBig}px !important;
  &:disabled {
    opacity: 0.5;
    filter: none !important;
  }
`;

const DescriptionContainer = styled.div`
  /* font-size: 16px; */

  a {
    ${linkStyles?.sharedStyles}
  }

  .bigger-text {
    font-weight: 500;
  }

  .smaller-text {
    font-size: ${({ theme }) => theme.globalConstants.fontSize.bodySubtle}px;
  }

  .spacer {
    height: 12px;
  }

  .link-container {
    display: flex;
    justify-content: center;
    margin: 10px -24px 24px 0;
  }

  a.link-button {
    ${buttonStyles?.sharedStyles}
    ${buttonStyles?.modifiers?.primary}
    text-decoration: none;
    padding: 7px 127px;
    font-size: ${({ theme }) => theme.globalConstants.fontSize.bodyBig}px;
  }

  .profiles-container {
    margin-top: 6px;
  }

  .profile-container {
    display: inline-block;
    padding: 5px 0;

    &:not(:first-child) {
      margin-left: 40px;
    }
  }

  .profile-content {
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;
    padding: 2px 0;
  }

  .profile-picture {
    border-radius: 100%;
    width: 60px;
    height: 60px;
  }
`;

export const EventModal: React.FC<EventModalProps> = ({
  event,
  eventColor,
  toggleSaveEvent,
  enableSaving,
  isSaved,
  onCancel,
  isOpen,
}) => {
  const { showPrivateLink } = useScheduleContext();

  const eventPassed = useMemo(() => moment().isAfter(event.end), [event.end]);
  const eventUpcoming = useMemo(
    () => moment().add(1, "hours").isBefore(event.start),
    [event.start]
  );

  const getLinkMessage = (link: string) => {
    const a = document.createElement("a");
    a.href = link;

    const hostname = a.hostname;
    if (hostname === "discord.gg") {
      return "Join on Discord";
    } else if (
      hostname === "youtu.be" ||
      hostname === "youtube.com" ||
      hostname === "www.youtube.com"
    ) {
      return eventPassed
        ? "Watch the Recording on YouTube"
        : "Watch on YouTube Live";
    } else if (hostname === "app.hopin.com") {
      return "Join on Hopin";
    } else if (
      hostname === "devpost.com" ||
      hostname === "hackthenorth2020.devpost.com"
    ) {
      return "Submit on Devpost";
    } else {
      return "Join";
    }
  };

  const displayedTimePeriod = useMemo(() => {
    const startDay = event.localStart.clone().startOf("day");
    const endDay = event.localEnd.clone().startOf("day");

    if (startDay.isSame(endDay)) {
      return `${event.displayedStart} - ${event.displayedEnd} on ${moment(
        startDay
      ).format("dddd, MMMM D")}`;
    }

    return `${event.displayedStart} on ${moment(startDay).format(
      "dddd, MMMM D"
    )} - ${event.displayedEnd} on ${moment(endDay).format("dddd, MMMM D")}`;
  }, [event]);

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onCancel}
      width="780px"
      eventColor={eventColor}
    >
      <NameContainer>
        <StyledText mods="heading h3">{event.title}</StyledText>
        {enableSaving && (
          <>
            <Spacer width={8} />
            <IconButton onClick={toggleSaveEvent}>
              <Icon name={isSaved ? "star" : "star-outline"} size="20" />
            </IconButton>
          </>
        )}
        <Spacer width={28} />
        <EventTypeIndicator eventColor={eventColor}>
          <Text mods="subtle">{event.category}</Text>
        </EventTypeIndicator>
      </NameContainer>
      <Spacer height={12} />
      <TimePeriodText mods="bodyBig">{displayedTimePeriod}</TimePeriodText>
      <Spacer height={12} />
      <Text as="div">
        <DescriptionContainer
          dangerouslySetInnerHTML={{ __html: event.description }}
        />
      </Text>
      {/* If the appropriate link doesn't exist, show a disabled linkless button */}
      {showPrivateLink &&
      event.location &&
      (!eventPassed || (eventPassed && !event.links[0])) ? (
        <LinkContainer>
          <Link href={eventUpcoming || eventPassed ? "" : event.location}>
            <LinkButton mods="primary" disabled={eventUpcoming || eventPassed}>
              {getLinkMessage(event.location)}
            </LinkButton>
          </Link>
        </LinkContainer>
      ) : (
        event.links[0] && (
          <LinkContainer>
            <Link href={eventUpcoming ? "" : event.links[0]}>
              <LinkButton mods="primary" disabled={eventUpcoming}>
                {getLinkMessage(event.links[0])}
              </LinkButton>
            </Link>
          </LinkContainer>
        )
      )}
    </StyledModal>
  );
};
