import { HackerAPIFile, HackerAPIDate } from "src/shared/utils/hackerapi";
import { Nullable, DeepReplaceType } from "src/shared/utils/typescript";

export enum Type {
  WEBSITE_LOGO = "website_logo",
  WEBSITE_SHOWCASE = "website_feature",
  NEWSLETTER_SPOTLIGHT = "newsletter_spotlight",
  NEWSLETTER_FEATURE = "newsletter_feature",
  NEWSLETTER_OPPORTUNITY = "newsletter_opportunity",
  TECH_TALK_PROPOSAL = "tech_talk_proposal",
  PRE_HACK_CONTEST = "pre_hack_contest",
  API_PRIZE = "api_prize",
  API_WORKSHOP = "api_workshop",
  SOCIAL_MEDIA_ASSETS = "social_media_assets",
  TECH_TALK_TIME = "tech_talk_time",
  CAREER_DEVELOPMENT = "career_development",
  SOCIAL_MEDIA_TAKEOVER_DATE = "social_media_date",
  LIGHTNING_CHALLENGE = "lightning_challenge",
  STAGE_TIME = "stage_time",
  COFFEE_CHATS = "coffee_chats",
  HOPIN_REPRESENTATIVES = "hopin_representatives",
}

export enum JudgingType {
  LIVE_DEMOS = "Live Demos",
  DEVPOST = "Devpost Submissions",
}

export enum Stage { // IDs of `Stage`s in hapi
  PENDING = 1476,
  STARTED = 1477, // data saved, but not submitted yet
  SUBMITTED = 1478,
  APPROVED = 1479,
  INACTIVE = 1480,
}

export interface Base {
  id: number;
  name: string;
  // company_id: number; // ID of claim in companies pipeline (NOT NEEDED RN)
  type: Type; // more added as the year progresses
  stage: Stage;
  data: unknown;
}

export interface WebsiteLogo extends Base {
  type: Type.WEBSITE_LOGO;
  data: {
    file: Nullable<HackerAPIFile>;
    website_link: Nullable<string>; // url
  };
}

export interface WebsiteShowcase extends Base {
  type: Type.WEBSITE_SHOWCASE;
  data: {
    message: Nullable<string>; // max 250 words
    website_link: Nullable<string>; // url
    link_desc: Nullable<string>;
  };
}

export interface NewsletterSpotlight extends Base {
  type: Type.NEWSLETTER_SPOTLIGHT;
  data: {
    file: Nullable<HackerAPIFile>;
    title: Nullable<string>;
    website_link: Nullable<string>; // url
    description: Nullable<string>; // max 150 words
  };
}

export interface NewsletterFeature extends Base {
  type: Type.NEWSLETTER_FEATURE;
  data: {
    file: Nullable<HackerAPIFile>;
    title: Nullable<string>;
    website_link: Nullable<string>; // url
    description: Nullable<string>; // max 150 words
  };
}

export interface NewsletterOpportunity extends Base {
  type: Type.NEWSLETTER_OPPORTUNITY;
  data: {
    due_date: Nullable<HackerAPIDate>;
    link: Nullable<string>; // url
    name: Nullable<string>;
  };
}

export interface TechTalkProposal extends Base {
  type: Type.TECH_TALK_PROPOSAL;
  data: {
    title: Nullable<string>;
    description: Nullable<string>; // max 150 words
    title_optional: Nullable<string>;
    description_optional: Nullable<string>; // max 150 words
  };
}

export interface ApiPrize extends Base {
  type: Type.API_PRIZE;
  data: {
    title: Nullable<string>;
    description: Nullable<string>; // max 250 characters
    judging_type: Nullable<JudgingType>;
  };
}

export interface ApiWorkshop extends Base {
  type: Type.API_WORKSHOP;
  data: {
    time_one: Nullable<Date>;
    time_two: Nullable<Date>;
    time_three: Nullable<Date>;
    representative: Nullable<string>;
    accepted_hackers_only: Nullable<boolean>;
    recording_available: Nullable<boolean>;
    video_link: Nullable<string>;
  };
}

export interface PreHackContest extends Base {
  type: Type.PRE_HACK_CONTEST;
  data: {
    title: Nullable<string>;
    description: Nullable<string>; // max 150 words
  };
}

export interface SocialMediaAssets extends Base {
  type: Type.SOCIAL_MEDIA_ASSETS;
  data: {
    files: Nullable<HackerAPIFile[]>;
    title_one: Nullable<string>;
    website_link_one: Nullable<string>; // url
    title_two: Nullable<string>;
    website_link_two: Nullable<string>; // url
    title_three: Nullable<string>;
    website_link_three: Nullable<string>; // url
  };
}

export interface TechTalkTime extends Base {
  type: Type.TECH_TALK_TIME;
  data: {
    time_one: Nullable<Date>;
    time_two: Nullable<Date>;
    time_three: Nullable<Date>;
    representative: Nullable<string>;
    accepted_hackers_only: Nullable<boolean>;
    recording_available: Nullable<boolean>;
  };
}

export interface CareerDevelopment extends Base {
  type: Type.CAREER_DEVELOPMENT;
  data: {
    time_one: Nullable<Date>;
    time_two: Nullable<Date>;
    time_three: Nullable<Date>;
    title: Nullable<string>;
    description: Nullable<string>;
    representative: Nullable<string>;
    accepted_hackers_only: Nullable<boolean>;
    recording_available: Nullable<boolean>;
  };
}

export interface SocialMediaTakeoverDate extends Base {
  type: Type.SOCIAL_MEDIA_TAKEOVER_DATE;
  data: {
    takeover_date: Nullable<Date>;
    contact_info: Nullable<string>;
  };
}

export interface LightningChallenge extends Base {
  type: Type.LIGHTNING_CHALLENGE;
  data: {
    description: Nullable<string>;
    start_time: Nullable<Date>;
    representative: Nullable<string>;
  };
}

export interface StageTime extends Base {
  type: Type.STAGE_TIME;
  data: {
    uploaded: Nullable<boolean>;
    video_link: Nullable<string>;
  };
}

export interface CoffeeChats extends Base {
  type: Type.COFFEE_CHATS;
  data: {
    start_time: Nullable<Date>;
    duration: Nullable<string>;
    representatives: Nullable<number[]>;
    hackers: Nullable<number[]>;
  };
}

export interface HopinRepresentatives extends Base {
  type: Type.HOPIN_REPRESENTATIVES;
  data: {
    representatives: Nullable<number[]>;
    emails: Nullable<string>;
  };
}

export type Perk =
  | WebsiteLogo
  | WebsiteShowcase
  | NewsletterSpotlight
  | NewsletterFeature
  | NewsletterOpportunity
  | TechTalkProposal
  | ApiPrize
  | ApiWorkshop
  | PreHackContest
  | SocialMediaAssets
  | TechTalkTime
  | CareerDevelopment
  | SocialMediaTakeoverDate
  | LightningChallenge
  | StageTime
  | CoffeeChats
  | HopinRepresentatives;

// wherever we store a file in a perk, it's stored with the shape of Nullable<HackerAPIFile>.
// however, when we need to store or use the actual `File`, use these utility types that replace `Nullable<HackerAPIFile>` with `File`
export type ReplacePerkFile<T> = DeepReplaceType<
  T,
  [Nullable<HackerAPIFile>, Nullable<File>]
>;

export type WebsiteLogoWithFile = ReplacePerkFile<WebsiteLogo>;
export type WebsiteShowcaseWithFile = ReplacePerkFile<WebsiteShowcase>;
export type NewsletterSpotlightWithFile = ReplacePerkFile<NewsletterSpotlight>;
export type NewsletterFeatureWithFile = ReplacePerkFile<NewsletterFeature>;
export type NewsletterOpportunityWithFile = ReplacePerkFile<NewsletterOpportunity>;
export type TechTalkProposalWithFile = ReplacePerkFile<TechTalkProposal>;

export type PerkWithFile = ReplacePerkFile<Perk>;

export enum Status {
  NOT_STARTED = "NOT_STARTED",
  INCOMPLETE = "INCOMPLETE",
  UNDER_REVIEW = "UNDER REVIEW",
  COMPLETE = "COMPLETE",
}
