import {
  Text,
  Flex,
  Spacer,
  TextInput,
  Button,
  DropdownInput,
} from "@hackthenorth/north";
import React, { useState } from "react";
import { OptionTypeBase } from "react-select";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { TOPICS } from "src/shared/constants/mentorship";
import { MentorContextState } from "src/shared/contexts/MentorContext";
import * as Mentor from "src/shared/contexts/MentorContext/types";
import { Info } from "src/shared/contexts/UserContext";
import { useGetFile } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import { MentorshipDefaultProfileImg } from "src/static/img";
import { capitalize } from "src/utils/string";

const StyledDropdownInput = styled(DropdownInput)`
  max-width: 598px;
  font-size: ${({ theme }) =>
    theme.globalConstants.fontSize.bodyBig}px !important;
`;

const StyledTextInput = styled(TextInput)`
  min-width: 389px;
`;

const StyledDiv = styled.div`
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  height: 153px;
  width: 153px;
  margin: auto;
  background-color: #dddddd;
`;

const StyledImg = styled.img`
  object-position: center;
  width: 153px;
`;

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
  text-align: center;
`;

interface ProfileFormProps {
  info: Info;
  profile: Mentor.Profile;
  updateProfile: MentorContextState["updateProfile"];
  updateProfilePhoto: MentorContextState["updateProfilePhoto"];
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  info,
  profile,
  updateProfile,
  updateProfilePhoto,
}) => {
  const { uploadFile } = useUploadFile();
  const fetchedFile = useGetFile(profile.mentorship_profile_photo);

  const [topics, setTopics] = useState(profile["mentorship_topics"]);
  const [institution, setInstitution] = useState(profile["institution"]);
  const [isPhotoSelected, setIsPhotoSelected] = useState(false);
  const [photo, setPhoto] = useState(new File([""], "pog"));

  const selectPicture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      setPhoto(e.target.files[0]);
      setIsPhotoSelected(true);
    }
  };

  const addFile = async (file: File) => {
    const hapiFile = await uploadFile(file);
    if (hapiFile && hapiFile.id) {
      await updateProfilePhoto([parseInt(hapiFile.id)]); //eslint-disable-line
    }
  };

  let profileImg;
  if (isPhotoSelected) {
    profileImg = <StyledImg src={URL.createObjectURL(photo)} alt="profile" />;
  } else if (fetchedFile && fetchedFile[0]) {
    profileImg = (
      <StyledImg
        src={fetchedFile[0].uri}
        alt={fetchedFile[0].name ?? "profile"}
      />
    );
  } else {
    profileImg = <StyledImg src={MentorshipDefaultProfileImg} alt="profile" />;
  }
  return (
    <>
      <Flex column>
        <Flex row>
          <Flex column>
            <StyledDiv>{profileImg}</StyledDiv>
            <Spacer height={12} />
            <StyledLabel htmlFor="file-upload">
              <Icon name="upload" size="20px" color="bluePrimary2" />
              <Spacer display="inline-block" width={7} />
              <Text mods={"bluePrimary2"}>Select Picture</Text>
            </StyledLabel>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={selectPicture}
            />
          </Flex>
          <Spacer width={56} />
          <Flex column>
            <Text mods="bold medium">Name</Text>
            <StyledTextInput disabled placeholder={info && info["name"]} />
            <Spacer height={24} />
            <Text mods="bold medium">Company/Institution</Text>
            <StyledTextInput
              placeholder={"Company/Institution"}
              value={institution}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInstitution(event.target.value);
              }}
            />
          </Flex>
        </Flex>
        <Spacer height={24} />
        <Text mods="bold medium">Areas of expertise</Text>
        <StyledDropdownInput
          multi
          value={
            topics &&
            topics[0] !== "" &&
            topics.map((t: string) => ({
              label: capitalize(t),
              value: t,
            }))
          }
          options={TOPICS.map((t) => ({
            label: capitalize(t),
            value: t,
          }))}
          onChange={(option: OptionTypeBase) => {
            if (option) {
              const updated = option.map((op: OptionTypeBase) => op["label"]);
              setTopics(updated);
            } else {
              setTopics([]);
            }
          }}
        />
        <Spacer height={24} />
        <Flex justify="self-end">
          <Button
            mods="primary"
            onClick={async () => {
              isPhotoSelected && photo && (await addFile(photo));
              await updateProfile({
                mentorship_topics: topics, // eslint-disable-line
                institution,
              });
              window.location.reload();
            }}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ProfileForm;
