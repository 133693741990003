import { Text, Spacer, Flex, Spinner, Link } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Route } from "src/shared/constants/route";
import { useMilestonesContext, useSponsorContext } from "src/shared/contexts";
import { UnstackedImg, StackedImg, TasksCompleteSVG } from "src/static/img";

import ActionColumn from "./ActionColumn";

const Container = styled(Flex)`
  & > :not(:first-child) {
    margin-left: 64px;
  }
`;

const LoggedInContent = () => {
  const {
    isLoading,
    isUpdating,
    onboarding,
    incompletedItems,
    completedItems,
  } = useMilestonesContext();

  const { company } = useSponsorContext();

  return isLoading || isUpdating ? (
    <Spinner />
  ) : incompletedItems.length === 0 ? (
    <Flex align="center" justify="center" column>
      <TasksCompleteSVG width="237px" />
      <Text mods="h1 heading">You&apos;re all set!</Text>
      <Text>All tasks are complete.</Text>
      <Text>
        Head to the <Link href={Route.SPONSOR_PERKS}>Perks page</Link> to view
        your available perks and submit information.
      </Text>
      <Text>
        Head to the{" "}
        <Link href={Route.SPONSOR_REPRESENTATIVES}>Representatives page</Link>{" "}
        to invite others who are representing {company?.name ?? "your company"}{" "}
        at Hack the North 2020++.
      </Text>
    </Flex>
  ) : (
    <>
      <Text mods="h1 heading">
        {onboarding?.complete ? "Upcoming" : "Onboarding"}
      </Text>
      <Text mods="big">
        {onboarding?.complete
          ? "Please complete the tasks below to help the event run as smoothly as possible!"
          : "As a part of onboarding, please complete all of the following tasks to gain access to your perks. We are excited to have you on board!"}
      </Text>
      <Spacer height={64} />
      <Container>
        <ActionColumn
          title="Incomplete"
          actionItems={incompletedItems || []}
          emptyElement={<UnstackedImg width="121px" height="auto" />}
        />
        <ActionColumn
          title="Complete"
          actionItems={completedItems || []}
          emptyElement={<StackedImg width="121px" height="auto" />}
        />
      </Container>
    </>
  );
};

export default LoggedInContent;
