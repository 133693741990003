import React from "react";
import styled from "styled-components";

import { BLOCK_HEIGHT } from "../constants";
import { HOURS_LIST } from "../constants";
import { TEvent } from "../types";

import { Event } from "./Event";

interface EventsColumnProps {
  events: TEvent[];
  inDayView: boolean;
}

const CalendarColumn = styled.div`
  flex: 1;
  border-left: 1px solid #c3ccd7;
  border-bottom: 1px solid #c3ccd7;
  position: relative;
  overflow-y: hidden;
  width: 100%;
`;

const EventsBlock = styled.div`
  height: ${BLOCK_HEIGHT}px;
  width: 100%;
  :not(:first-child) {
    border-top: 1px solid #c3ccd7;
  }
`;

export const EventsColumn: React.FC<EventsColumnProps> = ({
  events,
  inDayView,
}) => {
  return (
    <CalendarColumn>
      {HOURS_LIST.map((hour) => (
        <EventsBlock key={hour} />
      ))}
      {events.map((event) => (
        <Event key={event.id} event={event} inDayView={inDayView} />
      ))}
    </CalendarColumn>
  );
};
