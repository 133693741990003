import {
  Flex,
  Text,
  Link,
  UnstyledLink,
  Spacer,
  Button,
} from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { isPreviewAvailable } from "src/shared/utils/file";

import ResumeViewer from "./components/ResumeViewer";
import { THackerProfile } from "./types";
import { getLinkName, getLinkUrl } from "./utils";

type THackerProfileProps = THackerProfile & {
  favorited?: boolean;
  onFavorite: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
};

const Container = styled(Flex)`
  padding: ${({ theme }) => theme.globalConstants.padding.medium};
  background-color: ${({ theme }) => theme.globalConstants.color.white};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.halo};

  transition: 250ms;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.dark};
    transform: scale(1.02);
  }
`;

const HackerTag = styled.div`
  padding: 7px 24px;
  display: inline-block;
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3Light};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.large}px;
  margin: 6px;
`;

const HackerTagsContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

// Prevents link and button clicks from triggering onFavorite
const onPreventPropagationClick = (e: React.MouseEvent<HTMLButtonElement>) =>
  e.stopPropagation();

const HackerProfile: React.FC<THackerProfileProps> = ({
  name,
  graduatingYear,
  school,
  program,
  email,
  links,
  resume,
  tags,

  favorited,
  onFavorite,
}) => {
  const [isResumeViewerOpen, setResumeViewerOpen] = useState(false);

  const onViewResume = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setResumeViewerOpen(true);
  };

  return (
    <>
      <Container justify="space-between" onClick={onFavorite}>
        <div>
          <Text mods="big bold">{name}</Text>
          <Text> - </Text>
          <Text>{email}</Text>
          <Spacer height={4} />
          <Text mods="medium">
            {school ? `${program} @ ${school}` : "High School"} | Class of{" "}
            {graduatingYear}
          </Text>
          {tags.length > 0 && (
            <>
              <Spacer height={12} />
              <HackerTagsContainer>
                {tags.map((tag, index) => (
                  <HackerTag key={index}>
                    <Text mods="medium">{tag}</Text>
                  </HackerTag>
                ))}
              </HackerTagsContainer>
            </>
          )}

          {links.length > 0 && (
            <>
              <Spacer height={12} />
              <Flex>
                <Text mods="bold">Links: </Text>
                <Spacer width={12} />
                {links.map((link, index) => (
                  <>
                    {index !== 0 && (
                      <>
                        <Spacer width={4} />
                        <Text>•</Text>
                        <Spacer width={4} />
                      </>
                    )}
                    <Link
                      key={link}
                      href={getLinkUrl(link)}
                      onClick={onPreventPropagationClick}
                      newTab
                    >
                      {getLinkName(link)}
                    </Link>
                  </>
                ))}
              </Flex>
            </>
          )}
          {resume && (
            <>
              <Spacer height={12} />
              <Flex>
                {isPreviewAvailable(resume) && (
                  <>
                    <Button mods="primary" onClick={onViewResume}>
                      View Resume
                    </Button>
                    <Spacer width={12} />
                  </>
                )}
                <Button mods="secondary" onClick={onPreventPropagationClick}>
                  <UnstyledLink download href={resume?.uri ?? "#"}>
                    Download Resume
                  </UnstyledLink>
                </Button>
              </Flex>
            </>
          )}
        </div>
        <Icon name={favorited ? "star" : "star-outline"} size="20" />
      </Container>
      {resume && isPreviewAvailable(resume) && (
        <ResumeViewer
          file={resume}
          isOpen={isResumeViewerOpen}
          onClose={() => setResumeViewerOpen(false)}
        />
      )}
    </>
  );
};

export default HackerProfile;
