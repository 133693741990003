import { timesToOptions } from "src/shared/utils/react-select";
import { formatTimeRange } from "src/utils/formatTime";

// January 16th hours within 9am - 11pm
const LIGHTNING_CHALLENGE_TIMES = [
  new Date(Date.UTC(2021, 0, 16, 14, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 15, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 16, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 17, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 18, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 19, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 20, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 22, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 23, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 0, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 1, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 2, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 3, 0, 0)),
];

export const TIME_OPTIONS = timesToOptions(
  formatTimeRange,
  LIGHTNING_CHALLENGE_TIMES
);
