import React from "react";

import { Route } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";

import { ScheduleContextProvider } from "./ScheduleContext";

import Schedule from "./index";

export const ScheduleRoutes = () => (
  <ScheduleContextProvider>
    <Route path={BaseRoute.SCHEDULE} element={<Schedule />} />
  </ScheduleContextProvider>
);
