import {
  Text,
  Spacer,
  Spinner,
  TextInput,
  Flex,
  Button,
} from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import { Link, PageWrapper, Divider } from "src/shared/components";
import {
  useSponsorContext,
  useUserContext,
  useMilestonesContext,
  useMilestoneState,
} from "src/shared/contexts";
import * as Milestones from "src/shared/contexts/MilestonesContext/types";
import { MaybeAvailable } from "src/shared/contexts/types";
import { notBlank } from "src/utils/validation";
import { ContentCard } from "src/views/hacker/rsvp/LayoutComponents";
import * as Sponsor from "src/views/sponsor/types";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 48px;
`;

const ErrorText = styled(Text).attrs({ mods: "error" })`
  margin: 0 0 0 24px;
`;

type Milestone = Milestones.ProfileUpdated;

const validate = (milestoneData: Milestone["data"]) => {
  if (
    !milestoneData.first_name ||
    !milestoneData.last_name ||
    !milestoneData.company_role
  )
    return "This error message is not being used!";

  return null;
};

const SponsorProfile = () => {
  const {
    individual,
    company,
    loading,
    updateSponsorIndividual,
  } = useSponsorContext();
  const { info: userInfo } = useUserContext();

  const [localIndividual, setLocalIndividualState] = useState<
    Partial<MaybeAvailable<Sponsor.TIndividual | null>>
  >(individual);

  const setLocalIndividual = (field: string, value: string) => {
    setLocalIndividualState((prevData) => ({ ...prevData, [field]: value }));
  };

  const isFirstNameValid = notBlank(localIndividual?.first_name ?? "");
  const isLastNameValid = notBlank(localIndividual?.last_name ?? "");
  const isRoleInCompanyValid = notBlank(localIndividual?.role_in_company ?? "");
  const isValid = isFirstNameValid && isLastNameValid && isRoleInCompanyValid;

  const [showErrors, setShowErrors] = useState(false);

  const { profileUpdated } = useMilestonesContext();
  const { onSubmit, onChange } = useMilestoneState<Milestone>(
    profileUpdated as Milestone,
    validate
  );

  const getMods = (isFieldValid: boolean): string =>
    showErrors && !isFieldValid ? "error" : "";

  const ErrorTextComponent = showErrors && (
    <ErrorText>This field is required</ErrorText>
  );

  const onSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowErrors(true);
    if (isValid) {
      await updateSponsorIndividual({
        ...localIndividual,
      });
      onSubmit(e);
    }
  };

  return (
    <PageWrapper pageTitle="Profile">
      <Text mods="medium">
        Please edit and verify your personal information below.
      </Text>
      <Spacer height={24} />
      <ContentCard>
        <Text mods="h2 heading">Personal</Text>
        <Spacer height={40} />
        <Grid>
          <div>
            <Text>First Name</Text>
            <Spacer height={10} />
            <TextInput
              placeholder="Enter your first name"
              value={localIndividual?.first_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLocalIndividual("first_name", e.target.value);
                onChange("first_name", e.target.value);
              }}
              mods={getMods(isFirstNameValid)}
            />
            {!isFirstNameValid && ErrorTextComponent}
          </div>
          <div>
            <Text>Preferred Name (Optional)</Text>
            <Spacer height={10} />
            <TextInput
              placeholder="Enter your preferred name"
              value={localIndividual?.preferred_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLocalIndividual("preferred_name", e.target.value)
              }
            />
          </div>
        </Grid>
        <Spacer height={32} />
        <Text>Last Name</Text>
        <Spacer height={10} />
        <TextInput
          placeholder="Enter your last name"
          value={localIndividual?.last_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLocalIndividual("last_name", e.target.value);
            onChange("last_name", e.target.value);
          }}
          mods={getMods(isLastNameValid)}
        />
        {!isLastNameValid && ErrorTextComponent}
        <Spacer height={32} />
        <Text>Email (Contact us to change)</Text>
        <Spacer height={10} />
        <TextInput value={userInfo?.email ?? ""} disabled />
        <Spacer height={32} />
        <Text>Interested in being a mentor? Fill out&nbsp;</Text>
        <Link href="http://bit.ly/htn-mentor-rsvp">this form.</Link>
        <Spacer height={48} />
        <Divider />
        <Spacer height={40} />
        <Text mods="h2 heading">Company</Text>
        <Spacer height={40} />
        <Grid>
          <div>
            <Text>Company (Contact us to change)</Text>
            <Spacer height={10} />
            <TextInput value={company?.name ?? ""} disabled />
          </div>
          <div>
            <Text>Role</Text>
            <Spacer height={10} />
            <TextInput
              placeholder="Enter your role"
              value={localIndividual?.role_in_company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLocalIndividual("role_in_company", e.target.value);
                onChange("company_role", e.target.value);
              }}
              mods={getMods(isRoleInCompanyValid)}
            />
            {!isRoleInCompanyValid && ErrorTextComponent}
          </div>
        </Grid>
        <Spacer height={40} />
        <Button mods="primary" disabled={loading || !isValid} onClick={onSave}>
          {loading ? (
            <Flex align="center" justify="center">
              <Spinner />
              <Spacer width={10} />
              Saving
            </Flex>
          ) : (
            "Save"
          )}
        </Button>
      </ContentCard>
    </PageWrapper>
  );
};

export default SponsorProfile;
