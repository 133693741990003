import { HackerStage } from "src/shared/contexts/HackerContext/types";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

const ACCEPTED_FIELDS = [
  Field.PERSONAL_RSVP_STAGE,
  Field.EVENT_RSVP_STAGE,
  Field.CAREER_RSVP_STAGE,
];

export const useGetTaskCompleted = (text: string) => {
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  switch (text) {
    case "Personal":
      return responsesState[Field.PERSONAL_RSVP_STAGE] === PageStage.COMPLETED;
    case "Event":
      return responsesState[Field.EVENT_RSVP_STAGE] === PageStage.COMPLETED;
    case "Career":
      return responsesState[Field.CAREER_RSVP_STAGE] === PageStage.COMPLETED;
    default:
      return false;
  }
};

export const useGetRSVPStatus = (stage: HackerStage) => {
  switch (stage) {
    case HackerStage.CHECKED_IN:
    case HackerStage.CONFIRMED:
      return "Spot Confirmed";
    case HackerStage.EXPIRED:
      return "Expired";
    case HackerStage.WAITLISTED:
      return "Waitlisted";
    case HackerStage.WITHDRAWN:
      return "Withdrawn";
    default:
      return "Not Confirmed";
  }
};
