/* eslint-disable @typescript-eslint/camelcase */
import { ExecutionResult } from "graphql";
import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react";

import {
  useUpdateClaimMutation as useUpdateMilestoneMutation,
  UpdateClaimMutation as UpdateMilestoneMutation,
} from "src/api/claims/updateClaim.generated";
import { useUserContext } from "src/shared/contexts/UserContext";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import { Nullable } from "src/shared/utils/typescript";

import {
  GetMilestonesQuery,
  useGetMilestonesLazyQuery,
} from "./graphql/getMilestones.generated";
import * as Milestones from "./types";

export interface MilestonesContextState extends Milestones.State {
  incompletedItems: Milestones.Milestone[];
  completedItems: Milestones.Milestone[];

  isLoading: boolean;
  loadError?: string;
  isUpdating: boolean;
  updateError?: string;

  completeMilestone: (
    milestoneId: number,
    data?: Milestones.Milestone["data"]
  ) => Promise<ExecutionResult<UpdateMilestoneMutation>>;
  updateMilestone: (
    milestoneId: number,
    data: Milestones.Milestone["data"]
  ) => Promise<ExecutionResult<UpdateMilestoneMutation>>;
}

const DEFAULT_STATE: MilestonesContextState = {
  onboarding: null,
  onboardingModal: null,
  codeOfConduct: null,
  termsAndConditions: null,
  profileUpdated: null,

  incompletedItems: [],
  completedItems: [],

  isLoading: false,
  isUpdating: false,

  completeMilestone: () => Promise.resolve({}),
  updateMilestone: () => Promise.resolve({}),
};

const MilestonesContext: React.Context<MilestonesContextState> = createContext(
  DEFAULT_STATE
);

export const useMilestonesContext = () => useContext(MilestonesContext);

const getFlagFromMilestone = <T extends Milestones.Milestone>(
  milestones: GetMilestonesQuery["claims"] | undefined,
  name: Milestones.Name
): Nullable<T> => {
  if (milestones) {
    const milestone = milestones.find(
      (milestone) => getFieldAnswer(milestone.fields, "name") === name
    );
    const milestoneName = getFieldAnswer(
      milestone?.fields,
      "name"
    ) as Milestones.Name;

    const milestoneDataFieldValue = getFieldAnswer(milestone?.fields, "data");
    return milestone
      ? ({
          id: milestone.id,
          complete: milestone.stage_id === Milestones.Stage.COMPLETE,
          owner_id: getFieldAnswer(milestone.fields, "owner_id"), // eslint-disable-line
          type: getFieldAnswer(milestone.fields, "type"),
          name: milestoneName,
          data: milestoneDataFieldValue,
        } as T)
      : null;
  }
  return null;
};

const filterMilestones = (
  milestones: GetMilestonesQuery["claims"],
  filteredType: Milestones.Type,
  stageId: Milestones.Stage
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return milestones.reduce<Milestones.Milestone[]>((filtered, milestone) => {
    if (
      milestone.stage_id === stageId &&
      getFieldAnswer(milestone.fields, "type") === filteredType
    ) {
      const milestoneNameFieldValue = getFieldAnswer(
        milestone?.fields,
        "name"
      ) as Milestones.Name;

      const milestoneDataFieldValue = getFieldAnswer(milestone?.fields, "data");
      const milestoneDueDate = getFieldAnswer(milestone.fields, "due_date");
      filtered.push({
        id: milestone.id,
        type: filteredType,
        complete: milestone.stage_id === Milestones.Stage.COMPLETE,
        name: milestoneNameFieldValue,
        owner_id: getFieldAnswer(milestone.fields, "owner_id"),
        data: milestoneDataFieldValue,
        dueDate: milestoneDueDate ? new Date(milestoneDueDate) : null,
      } as Milestones.Milestone);
    }
    return filtered;
  }, []);
};

const getItems = (
  milestones: GetMilestonesQuery["claims"] | undefined,
  onboardingComplete: boolean,
  stageId: Milestones.Stage
) => {
  const itemsMilestoneType = onboardingComplete
    ? Milestones.Type.ACTION_ITEM
    : Milestones.Type.ONBOARDING;
  if (milestones) {
    const incompleteItemData = filterMilestones(
      milestones,
      itemsMilestoneType,
      stageId
    );
    return incompleteItemData;
  }
  return [];
};

export const MilestonesContextProvider: React.FC = ({ children }) => {
  const { id } = useUserContext();
  const [
    getMilestonesQuery,
    { data, loading, error: loadError },
  ] = useGetMilestonesLazyQuery();
  const [
    updateMilestoneMutation,
    { loading: updateLoading, error: updateError },
  ] = useUpdateMilestoneMutation();

  // only fetch milestones when id is defined
  useEffect(() => {
    if (id) {
      getMilestonesQuery({ variables: { myId: id } });
    }
  }, [id, getMilestonesQuery]);

  const milestones = data?.claims;

  /**
   * Build MilestonesState
   */
  const onboarding: MilestonesContextState["onboarding"] = useMemo(
    () => getFlagFromMilestone(milestones, Milestones.Name.ONBOARDING_STEPS),
    [milestones]
  );

  const onboardingModal: MilestonesContextState["onboardingModal"] = useMemo(() => {
    const modalMilestoneName = onboarding?.complete
      ? Milestones.Name.ONBOARDING_COMPLETE_MODAL_DISMISSED
      : Milestones.Name.ONBOARDING_MODAL_DISMISSED;
    return getFlagFromMilestone(milestones, modalMilestoneName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestones, onboarding?.complete]);

  const codeOfConduct: MilestonesContextState["codeOfConduct"] = useMemo(
    () => getFlagFromMilestone(milestones, Milestones.Name.COC_ACCEPTED),
    [milestones]
  );

  const termsAndConditions: MilestonesContextState["termsAndConditions"] = useMemo(
    () => getFlagFromMilestone(milestones, Milestones.Name.TC_ACCEPTED),
    [milestones]
  );

  const profileUpdated: MilestonesContextState["profileUpdated"] = useMemo(
    () => getFlagFromMilestone(milestones, Milestones.Name.PROFILE_UPDATED),
    [milestones]
  );

  const incompletedItems: MilestonesContextState["incompletedItems"] = useMemo(
    () =>
      getItems(
        milestones,
        onboarding?.complete ?? false,
        Milestones.Stage.INCOMPLETE
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [milestones, onboarding?.complete]
  );

  const completedItems: MilestonesContextState["completedItems"] = useMemo(
    () =>
      getItems(
        milestones,
        onboarding?.complete ?? false,
        Milestones.Stage.COMPLETE
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [milestones, onboarding?.complete]
  );

  const completeMilestone = useCallback(
    (milestoneId: number, data: Milestones.Milestone["data"] = null) =>
      updateMilestoneMutation({
        variables: {
          updatedData: {
            id: milestoneId,
            stage_id: Milestones.Stage.COMPLETE, // eslint-disable-line @typescript-eslint/camelcase
            answers: [
              {
                slug: "data",
                answer: data,
              },
            ],
          },
        },
      }),
    [updateMilestoneMutation]
  );

  const updateMilestone = useCallback(
    (milestoneId: number, data: Milestones.Milestone["data"] = null) =>
      updateMilestoneMutation({
        variables: {
          updatedData: {
            id: milestoneId,
            answers: [
              {
                slug: "data",
                answer: data,
              },
            ],
          },
        },
      }),
    [updateMilestoneMutation]
  );

  /**
   * Check if onboarding is complete
   */
  useEffect(() => {
    if (
      !loading &&
      !updateLoading &&
      !loadError &&
      !updateError &&
      onboarding &&
      !onboarding.complete &&
      !incompletedItems.length
    ) {
      onboarding.complete = true;
      completeMilestone(onboarding.id);
    }
  }, [
    completeMilestone,
    incompletedItems,
    loadError,
    loading,
    onboarding,
    updateError,
    updateLoading,
  ]);

  /**
   * Build state
   */
  const milestonesState: MilestonesContextState = {
    onboarding,
    onboardingModal,
    codeOfConduct,
    termsAndConditions,
    profileUpdated,

    incompletedItems,
    completedItems,

    isLoading: loading,
    isUpdating: updateLoading,
    loadError: loadError?.message,
    updateError: updateError?.message,

    completeMilestone,
    updateMilestone,
  };

  return (
    <MilestonesContext.Provider value={milestonesState}>
      {children}
    </MilestonesContext.Provider>
  );
};
