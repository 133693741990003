import { Flex, Spacer, JWTRole } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { useUserContext, useSidebarContext } from "src/shared/contexts";
import Timer from "src/views/hacker/home/Timer";

import SidebarSection from "./SidebarSection";
import { useSidebarContent } from "./useSidebarContent";

export interface SidebarProps extends React.ComponentPropsWithoutRef<"div"> {}

export const SIDEBAR_SECTIONS_CONTAINER_ID = "sidebar-sections-container"; // todo: react portals to render sidebar sections on the fly

export const SIDEBAR_WIDTH = 240;

const Subcontainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Container = styled(Flex)<{ showSidebar: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  z-index: 10;
  background: ${({ theme }) => theme.globalConstants.color.white};
  border-right: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGrey};

  transition: width 0.3s ease-out, opacity 0.3s;
  width: ${({ showSidebar }) => (showSidebar ? SIDEBAR_WIDTH : "0")}px;
  opacity: ${({ showSidebar }) => (showSidebar ? "1" : "0")};
`;

const TitleContainer = styled(Flex).attrs({ column: true, align: "center" })`
  margin: 32px 24px 0;
`;

const Sidebar: React.FC<SidebarProps> = ({ ...rest }) => {
  const { logo, title, subtitle, sections } = useSidebarContent();
  const { showSidebar } = useSidebarContext();
  const { roles } = useUserContext();
  const isHacker = roles.includes(JWTRole.HACKER);

  return (
    <Container showSidebar={showSidebar} {...rest} column>
      <TitleContainer>
        {logo}
        <Spacer height={16} />
        {title}
        <Spacer height={16} />
        {subtitle}
        <Spacer height={16} />
      </TitleContainer>
      <Subcontainer id={SIDEBAR_SECTIONS_CONTAINER_ID}>
        {sections.map(({ sectionTitle, sectionContent }) => (
          <SidebarSection
            key={sectionTitle}
            sectionTitle={sectionTitle}
            sectionContent={sectionContent}
          />
        ))}
      </Subcontainer>
      {isHacker ? <Timer /> : null}
    </Container>
  );
};

export default Sidebar;
