import styled from "styled-components";

export interface CardProps {
  color?: string;
}
const Card = styled.div<CardProps>`
  background-color: ${({ theme, color = "white" }) =>
    theme.globalConstants.color[color]};
  border-radius: 10px;
  padding: 24px;
`;

export default Card;
