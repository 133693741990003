import {
  Flex,
  Checkbox,
  Text,
  Spacer,
  Button,
  Spinner,
} from "@hackthenorth/north";
import React from "react";

import { useMilestoneFlag } from "src/shared/contexts";
import * as Milestones from "src/shared/contexts/MilestonesContext/types";

export interface COCFormProps {
  codeOfConduct: Milestones.COCAccepted;
}

export const COCForm: React.FC<COCFormProps> = ({ codeOfConduct }) => {
  const { isComplete, onChange, onSubmit, loading, error } = useMilestoneFlag(
    codeOfConduct,
    "You must agree to the Code of Conduct."
  );

  return codeOfConduct.complete ? (
    <Text mods="success">
      You&apos;ve agreed to the Code of Conduct. Thank you!
    </Text>
  ) : (
    <>
      <Flex>
        <Checkbox checked={isComplete} onChange={onChange} />
        <Spacer width={16} />
        <Text>I agree to follow the Code of Conduct.</Text>
      </Flex>
      <Spacer height={64} />
      <Flex align="center">
        <Button mods="primary" disabled={loading} onClick={onSubmit}>
          Submit
        </Button>
        <Spacer width={16} />
        {loading && <Spinner />}
      </Flex>
      {error && (
        <>
          <Spacer height={16} />
          <Flex>
            <Text mods="error" className="code-of-conduct--error">
              {error}
            </Text>
          </Flex>
        </>
      )}
    </>
  );
};
