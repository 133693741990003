import { Spacer, Text } from "@hackthenorth/north";
import React, { useMemo } from "react";
import styled from "styled-components";

import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
  Link,
  Shimmer,
} from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts";
import { Nullable } from "src/shared/utils/typescript";

export interface OpportunityPreviewProps {
  link: Nullable<string>;
  name: Nullable<string>;
}

const LinkPreview = <Shimmer />;

const OpportunityLink = styled(Link).attrs({ removeSharedStyles: true })`
  text-decoration-color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const StyledHeadingContainer = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
`;

const Preview: React.FC<OpportunityPreviewProps> = ({ link, name }) => {
  const { company } = useSponsorContext();
  const companyName = useMemo(() => company?.name, [company]);

  return (
    <Accordion
      heading={
        <StyledHeadingContainer>
          <Text mods="bold">Preview</Text>
          <Spacer width={8} />
          <AccordionArrow />
        </StyledHeadingContainer>
      }
    >
      <Spacer height={8} />
      {name ? (
        <OpportunityLink href={link ?? ""}>
          <Text mods="h2">{companyName}</Text>
          <Text mods="h2"> - </Text>
          <Text mods="h2">{name}</Text>
        </OpportunityLink>
      ) : (
        LinkPreview
      )}
    </Accordion>
  );
};

export default Preview;
