import { css } from "styled-components";

export const hoverStyles = css`
  transition: opacity 200ms;
  &:hover:not(:disabled) {
    opacity: 0.85;
  }
`;

export const focusStyles = css`
  transition: opacity 200ms;
  &:focus:not(:disabled) {
    opacity: 0.85;
  }
`;

export const disabledStyles = css`
  transition: filter 200ms;
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
`;
