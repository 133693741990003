import { Checkbox } from "@hackthenorth/north";
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)<{ checked: boolean }>`
  border-radius: 4px;
  border: ${({ theme, checked }) =>
    `2px solid ${
      checked
        ? theme.globalConstants.color.greenSecondary
        : theme.globalConstants.color.textDark
    }`};
  background-color: ${({ theme, checked }) =>
    checked
      ? theme.globalConstants.color.greenSecondary
      : theme.globalConstants.color.white};
  color: ${({ theme, checked }) =>
    checked
      ? theme.globalConstants.color.white
      : theme.globalConstants.color.textDark};
  margin-right: 8px;
  flex-shrink: 0;
`;

export default StyledCheckbox;
