import { Spacer, Text } from "@hackthenorth/north";
import React, { useMemo } from "react";
import styled from "styled-components";

import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
} from "src/shared/components";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import ApiPrize from "./ApiPrize";
import ApiWorkshop from "./ApiWorkshop";
import CareerDevelopment from "./CareerDevelopment";
import CoffeeChats from "./CoffeeChats";
import HopinRepresentatives from "./HopinRepresentatives";
import LightningChallenge from "./LightningChallenge";
import NewsletterFeature from "./NewsletterFeature";
import NewsletterOpportunity from "./NewsletterOpportunity";
import NewsletterSpotlight from "./NewsletterSpotlight";
import PreHackContest from "./PreHackContest";
import SocialMediaAssets from "./SocialMediaAssets";
import SocialMediaTakeoverDate from "./SocialMediaTakeoverDate";
import StageTime from "./StageTime";
import TechTalkProposal from "./TechTalkProposal";
import TechTalkTime from "./TechTalkTime";
import WebsiteLogo from "./WebsiteLogo";
import WebsiteShowcase from "./WebsiteShowcase";

interface PerkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  perk: any; // TODO: fix this
}

const AccordionHeading = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

const getPerkData = (
  perkType: SponsorPerks.Type
): {
  title: string;
  component:
    | typeof WebsiteLogo
    | typeof WebsiteShowcase
    | typeof NewsletterSpotlight
    | typeof NewsletterOpportunity
    | typeof NewsletterFeature
    | typeof TechTalkProposal
    | typeof PreHackContest
    | typeof ApiPrize
    | typeof ApiWorkshop
    | typeof SocialMediaAssets
    | typeof TechTalkTime
    | typeof CareerDevelopment
    | typeof SocialMediaTakeoverDate
    | typeof LightningChallenge
    | typeof StageTime
    | typeof CoffeeChats
    | typeof HopinRepresentatives;
} => {
  switch (perkType) {
    case SponsorPerks.Type.WEBSITE_LOGO:
      return {
        title: "Website Logo",
        component: WebsiteLogo,
      };

    case SponsorPerks.Type.WEBSITE_SHOWCASE:
      return {
        title: "Website Showcase",
        component: WebsiteShowcase,
      };

    case SponsorPerks.Type.NEWSLETTER_OPPORTUNITY:
      return {
        title: "Newsletter Opportunity",
        component: NewsletterOpportunity,
      };

    case SponsorPerks.Type.NEWSLETTER_SPOTLIGHT:
      return {
        title: "Newsletter Spotlight",
        component: NewsletterSpotlight,
      };

    case SponsorPerks.Type.NEWSLETTER_FEATURE:
      return {
        title: "Newsletter Feature",
        component: NewsletterFeature,
      };

    case SponsorPerks.Type.TECH_TALK_PROPOSAL:
      return {
        title: "Tech Talk Proposal",
        component: TechTalkProposal,
      };

    case SponsorPerks.Type.PRE_HACK_CONTEST:
      return {
        title: "Pre-Hack Contest",
        component: PreHackContest,
      };

    case SponsorPerks.Type.API_PRIZE:
      return {
        title: "API Prize",
        component: ApiPrize,
      };

    case SponsorPerks.Type.API_WORKSHOP:
      return {
        title: "API Workshop",
        component: ApiWorkshop,
      };

    case SponsorPerks.Type.SOCIAL_MEDIA_ASSETS:
      return {
        title: "Social Media Assets",
        component: SocialMediaAssets,
      };

    case SponsorPerks.Type.TECH_TALK_TIME:
      return {
        title: "Tech Talk Time",
        component: TechTalkTime,
      };

    case SponsorPerks.Type.CAREER_DEVELOPMENT:
      return {
        title: "Career Development",
        component: CareerDevelopment,
      };

    case SponsorPerks.Type.SOCIAL_MEDIA_TAKEOVER_DATE:
      return {
        title: "Social Media Takeover Date",
        component: SocialMediaTakeoverDate,
      };

    case SponsorPerks.Type.LIGHTNING_CHALLENGE:
      return {
        title: "Lightning Challenge",
        component: LightningChallenge,
      };

    case SponsorPerks.Type.STAGE_TIME:
      return {
        title: "Stage Time",
        component: StageTime,
      };

    case SponsorPerks.Type.COFFEE_CHATS:
      return {
        title: "Coffee Chats",
        component: CoffeeChats,
      };

    case SponsorPerks.Type.HOPIN_REPRESENTATIVES:
      return {
        title: "Hopin Representatives",
        component: HopinRepresentatives,
      };

    default:
      throw new Error(`Unknown perk ${perkType}`);
  }
};

const StyledAccordionArrow = styled(AccordionArrow)`
  cursor: pointer;
  svg {
    pointer-events: none;
  }
`;

const Perk: React.FC<PerkProps> = ({ perk }) => {
  const { component: Component, title } = useMemo(
    () => getPerkData(perk.type),
    [perk.type]
  );

  const isSubmitted = [
    SponsorPerks.Stage.SUBMITTED,
    SponsorPerks.Stage.APPROVED,
  ].includes(perk.stage);

  return (
    <Accordion
      heading={
        <AccordionHeading>
          <Text mods={{ bold: true, success: isSubmitted }}>{title}</Text>
          <Spacer width={20} />
          <StyledAccordionArrow />
        </AccordionHeading>
      }
      showOverflow={true}
    >
      <Component perk={perk} />
    </Accordion>
  );
};

export default Perk;
