import React from "react";

import { Route } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";

import Playground from "./root/Playground";

export const PlaygroundRoutes = () => (
  <Route path={BaseRoute.PLAYGROUND} element={<Playground />} />
);
