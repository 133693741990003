import { Button, Text, Spinner, Flex } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import {
  Route as RouterRoute,
  RouteProps as RouterRouteProps,
  useNavigate,
} from "react-router";

import Modal from "src/shared/components/Modal";
import PageWrapper from "src/shared/components/PageWrapper";
import { BaseRoute } from "src/shared/constants/route";
import { useUserContext, usePermissionsContext } from "src/shared/contexts";
import { Permission } from "src/shared/contexts/PermissionsContext/types";

export interface RouteProps extends RouterRouteProps {
  /**
   * Require users to be authenticated to access this route.
   */
  requireAuth?: boolean;
  /**
   * Require users to be authenticated and hold valid permissions.
   */
  requirePermissions?: Permission[];
}

const Route: React.FC<RouteProps> = ({
  requireAuth,
  requirePermissions,
  element,
  ...rest
}) => {
  const navigate = useNavigate();
  const { isAuthenticated, logIn } = useUserContext();
  const { hasPermissions, isLoading } = usePermissionsContext();
  const [showLoading, setShowLoading] = useState(true);

  // Only allow loading modal to show up once on initial render
  useEffect(() => {
    if (!isLoading && showLoading) {
      setShowLoading(false);
    }
  }, [isLoading, showLoading]);

  let internalElement = element;
  if (showLoading) {
    internalElement = (
      <>
        <Modal isOpen>
          <Flex align="center" justify="center">
            <Spinner />
          </Flex>
        </Modal>
        <PageWrapper pageTitle="" />
      </>
    );
  }
  // Restrict "Access Denied" modals to only display after all dependencies have been loaded
  else if (
    (requireAuth || requirePermissions) &&
    !isAuthenticated &&
    !isLoading
  ) {
    internalElement = (
      <>
        <Modal
          isOpen
          title="Access denied"
          actions={
            <Button mods="primary" onClick={logIn}>
              Log in
            </Button>
          }
        >
          <Text>You are not logged in!</Text>
        </Modal>
        <PageWrapper pageTitle="" />
      </>
    );
  } else if (!hasPermissions(requirePermissions) && !isLoading) {
    internalElement = (
      <>
        <Modal
          isOpen
          title="Access denied"
          actions={
            <Button mods="primary" onClick={() => navigate(BaseRoute.HOME)}>
              Go home
            </Button>
          }
        >
          <Text>You are not permitted to view this page.</Text>
        </Modal>
        <PageWrapper pageTitle="" />
      </>
    );
  }

  return <RouterRoute {...rest} element={internalElement} />;
};

export default Route;
