import { Flex, Text, Link, Grid, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import {
  OutlineDocument,
  PageWrapper,
  TOPNAVBAR_HEIGHT,
} from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { CommunityImg } from "src/static/img";
import { buttonStyles } from "src/theme/componentStyles/button";

import { COMMUNITY_SECTIONS } from "./constants";

const Section = styled.section`
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.borderGrey};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.regular};
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const TableOfContents = styled(Section)`
  width: 329px;
`;

const StyledLink = styled(Link)`
  text-decoration: none !important;
  :hover {
    text-decoration: underline !important;
  }
`;

const LinkButton = styled(Link)`
  ${buttonStyles?.sharedStyles}
  color: ${({ theme }) => theme.globalConstants.color.tanSecondary} !important;
  background-color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  text-decoration: none !important;
  cursor: pointer;
`;

const StyledImg = styled.img`
  width: 200px;
  height: 200px;
`;

const SCROLL_OFFSET = 50;

const SectionScrollTarget = styled.div`
  position: relative;
  top: -${TOPNAVBAR_HEIGHT + SCROLL_OFFSET}px;
`;

const COMMUNITY_SHARE_ID = "6fce4cde-7548-43a3-b072-62f3592d9aa2";

const Community: React.FC = () => (
  <PageWrapper scrollToTop>
    <Grid columns={1} rows="auto" rowGap={48}>
      <Flex align="center">
        <OutlineDocument shareId={COMMUNITY_SHARE_ID} />
        <Spacer width={64} />
        <StyledImg src={CommunityImg} alt="You belong in tech" />
      </Flex>

      <Flex justify="space-between">
        <TableOfContents>
          <Grid columns={1} rows="auto" rowGap={12}>
            <Text mods="heading h2">Table of contents</Text>
            {COMMUNITY_SECTIONS.map(({ label, sectionId }) => (
              <StyledLink
                mods="medium big"
                key={sectionId}
                href={`${Route.HACKER_COMMUNITY}/#${sectionId}`}
              >
                {label}
              </StyledLink>
            ))}
          </Grid>
        </TableOfContents>
        <Flex align="flex-end">
          <LinkButton
            download
            mods="primary"
            href="https://my.hackthenorth.com/HacktheNorth_CommunityResources.pdf"
          >
            Download PDF
          </LinkButton>
        </Flex>
      </Flex>
      {COMMUNITY_SECTIONS.map(({ sectionId, shareId }) => (
        <Section key={sectionId}>
          <SectionScrollTarget id={sectionId} />
          <OutlineDocument shareId={shareId} noTitle />
        </Section>
      ))}
    </Grid>
  </PageWrapper>
);

export default Community;
