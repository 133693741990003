import { Text, Spacer, Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import {
  Accordion,
  AccordionHeadingContainer,
  AccordionArrow,
} from "src/shared/components";
import {
  HourglassIconImg,
  DiscordIconImg,
  ChatBoxImg,
  MentorshipTool,
} from "src/static/img";

const Card = styled(Flex).attrs({
  column: true,
  align: "center",
  justify: "space-between",
})`
  padding: 24px;
  background-color: ${({ theme }) => theme.globalConstants.color.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.greySecondary};
  max-width: 250px;
`;

const Img = styled.img`
  display: block;
  max-height: 45px;
  max-width: 100%;
`;

const MentorshipToolImg = styled(Img)`
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textGrey};
  border-left: none;
`;

const Tag = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
`;

const StageTag = styled(Tag)`
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
  height: 24px;
  margin-right: 8px !important;
`;

const AccordionHeading = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
  height: 100%;

  &:focus {
    outline: none;
    transform: ${({ disabled }) => (disabled ? `none` : `translateX(8px)`)};
  }
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  margin-bottom: 0.2em;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 12px;
  row-gap: 12px;
`;

const StageContainer = styled(Flex)`
  align-items: center;
  margin-top: 12px;
`;

const Instructions: React.FC = () => {
  return (
    <Accordion
      heading={
        <AccordionHeading>
          <Text mods="h3 heading">How to get started</Text>
          <StyledAccordionArrow />
        </AccordionHeading>
      }
      isDefaultOpen={true}
    >
      <Container>
        <Card>
          <Text>1. Submit your request/question! </Text>
          <Spacer height="12px" />
          <Img src={ChatBoxImg} alt="chatbox" />
        </Card>
        <Card>
          <Text>2. Wait for a mentor to claim it</Text>
          <Spacer height="12px" />
          <Img src={HourglassIconImg} alt="chatbox" />
        </Card>
        <Card>
          <Text>
            3. Check the <i>mentorship-pairings</i> channel on Discord to
            connect with your mentor.
          </Text>
          <Spacer height="12px" />
          <Img src={DiscordIconImg} alt="chatbox" />
        </Card>
        <Card>
          <Text>
            4. Resolve or reopen the request back here, on the Mentorship Tool.
          </Text>
          <Spacer height="12px" />
          <MentorshipToolImg src={MentorshipTool} alt="chatbox" />
        </Card>
      </Container>
      <Spacer height={20} />
      <Text>
        Your requests are in the section below. Here is what each status means:
      </Text>
      <StageContainer>
        <StageTag>Unclaimed</StageTag>{" "}
        <Text>A mentor will claim your ticket and help you out soon!</Text>
      </StageContainer>
      <StageContainer>
        <StageTag>Claimed</StageTag>{" "}
        <Text>
          A mentor has claimed your ticket. Our discordbot will quickly connect
          you.
        </Text>
      </StageContainer>
      <StageContainer>
        <StageTag>Notified</StageTag>{" "}
        <Text>
          You and your mentor have been paired on the <i>mentorship-pairings</i>{" "}
          channel on Discord.
        </Text>
      </StageContainer>
      <StageContainer>
        <StageTag>Resolved</StageTag>{" "}
        <Text>
          Your request no longer needs a mentor&apos;s attention. Need more help
          on the same request? Reopen it to be connected to a mentor.
        </Text>
      </StageContainer>
    </Accordion>
  );
};

export default Instructions;
