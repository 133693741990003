import { ExecutionResult } from "graphql";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useNavigate, useLocation } from "react-router";

import {
  useUpdateClaimMutation,
  UpdateClaimMutation,
} from "src/api/claims/updateClaim.generated";
import { Route } from "src/shared/constants/route";
import { useUserContext } from "src/shared/contexts";
import { getFieldAnswer, parseState } from "src/shared/utils/hackerapi";

import { DEFAULT_DATA } from "./defaults";
import {
  GetHackerQuery,
  useGetHackerLazyQuery,
} from "./graphql/getHacker.generated";
import {
  UpdateHackerStageMutation,
  useUpdateHackerStageMutation,
} from "./graphql/updateHackerStage.generated";
import { HackerStage, TAnswers, Field } from "./types";

const FIELDS = Object.values(Field);

// TODO: Improve this
const parseClaim = (data: GetHackerQuery["claims"][0] | undefined) =>
  FIELDS.reduce((obj, field) => {
    obj[field] = getFieldAnswer(data?.fields, field) ?? DEFAULT_DATA[field];
    return obj;
  }, {}) as TAnswers;

export type THackerContextState = {
  isFetchingData: boolean;
  isUpdatingData: boolean;
  isUpdatingStage: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
  error: string | undefined;
  stage: HackerStage;
  claimData: TAnswers;
  updateStage: (
    newStage: HackerStage
  ) => Promise<ExecutionResult<UpdateHackerStageMutation>>;
  updateResponses: (
    updatedData?: Partial<TAnswers>
  ) => Promise<ExecutionResult<UpdateClaimMutation>>;
  navigateToRSVP: () => void;
  navigateNext: () => void;
};

const DEFAULT_STATE: THackerContextState = {
  isFetchingData: false,
  isUpdatingData: false,
  isUpdatingStage: false,
  isLoading: false,
  isReadOnly: true,
  error: undefined,
  stage: HackerStage.SUBMITTED,
  claimData: DEFAULT_DATA,
  updateStage: () => Promise.reject("No parent HackerContextProvider found"),
  updateResponses: () =>
    Promise.reject("No parent HackerContextProvider found"),
  navigateToRSVP: () => {},
  navigateNext: () => {},
};

const HackerContext: React.Context<THackerContextState> = createContext(
  DEFAULT_STATE
);

export const useHackerContext = () => useContext(HackerContext);

export const HackerContextProvider: React.FC = ({ children }) => {
  const { id } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [
    getHackerQuery,
    { loading: getLoading, data: getData, error: getError },
  ] = useGetHackerLazyQuery();

  const [
    updateHacker,
    {
      loading: updateHackerLoading,
      data: updateHackerData,
      error: updateHackerError,
    },
  ] = useUpdateClaimMutation();

  const [
    updateHackerStage,
    {
      loading: updateStageLoading,
      data: updateStageData,
      error: updateStageError,
    },
  ] = useUpdateHackerStageMutation();

  // fetch responses from hapi (if user id is defined)
  useEffect(() => {
    if (id) getHackerQuery({ variables: { myId: id } });
  }, [id, getHackerQuery]);

  const claim = getData?.claims?.[0];

  const stage =
    claim?.stage_id ??
    updateStageData?.updateClaim?.stage_id ??
    HackerStage.DEFAULT_STAGE;

  const claimData = parseClaim(claim ?? updateHackerData?.updateClaim);

  const updateStage = useCallback(
    async (newStage: HackerStage) => {
      const id = claim?.id;

      if (!id) return Promise.reject("Undefined claim id");

      return await updateHackerStage({
        variables: {
          id,
          newStage,
        },
      });
    },
    [claim, updateHackerStage]
  );

  const updateResponses = useCallback(
    async (updatedData: Partial<TAnswers> = {}) => {
      const id = claim?.id;

      if (!id) return Promise.reject("Undefined claim id");

      return updateHacker({
        variables: {
          updatedData: {
            id,
            answers: parseState({ ...claimData, ...updatedData }),
          },
        },
      });
    },
    [claim, updateHacker, claimData]
  );

  const navigateToRSVP = () => navigate(Route.HACKER_RSVP);

  const navigateNext = () => {
    switch (location.pathname) {
      case Route.HACKER_PERSONAL:
        navigate(Route.HACKER_EVENT);
        break;

      case Route.HACKER_EVENT:
        navigate(Route.HACKER_CAREER);
        break;

      case Route.HACKER_CAREER:
      default:
        navigateToRSVP();
    }
  };

  const isFetchingData = getLoading || !claim; // There can be a state where getLoading = false and claim = undefined, so we use OR instead of AND
  const isUpdatingData = updateHackerLoading && !updateHackerData;
  const isUpdatingStage = updateStageLoading && !updateStageData;

  /**
   * Build state
   */
  const hackerState: THackerContextState = {
    isFetchingData: isFetchingData,
    isUpdatingData: isUpdatingData,
    isUpdatingStage: isUpdatingStage,
    isLoading: isFetchingData || isUpdatingData || isUpdatingStage,
    isReadOnly: [
      HackerStage.CHECKED_IN,
      HackerStage.CONFIRMED,
      HackerStage.WITHDRAWN,
    ].includes(stage),
    error:
      getError?.message ??
      updateHackerError?.message ??
      updateStageError?.message ??
      undefined,
    stage,
    claimData,
    updateStage,
    updateResponses,
    navigateToRSVP,
    navigateNext,
  };

  return (
    <HackerContext.Provider value={hackerState}>
      {children}
    </HackerContext.Provider>
  );
};
