import { Spacer, Text } from "@hackthenorth/north";
import React, { useState, useCallback, useRef } from "react";

import { Dropzone, DropzoneError, FileLink } from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts";
import { useGetFile } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

interface WebsiteLogoProps {
  perk: SponsorPerks.WebsiteLogo;
}

type StoredPerk = SponsorPerks.WebsiteLogo;

const description = (
  <Text mods="medium" className="website-logo-description-1">
    Submit your company logo in SVG or PNG form (SVG is preferred). If you are
    submitting a PNG, please ensure the background is transparent and the image
    has a minimum height of 400px, so we can showcase your logo clearly!
  </Text>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.file && !!perkData.website_link;

const WebsiteLogo: React.FC<WebsiteLogoProps> = ({ perk }) => {
  const {
    company,
    updateCompanyLogo,
    loading: isCompanyLoading,
  } = useSponsorContext();
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const {
    uploadFile,
    mutationResult: { loading: isFileUploading, error: fileUploadError },
  } = useUploadFile();
  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState("");
  const dropzoneInputRef = useRef<HTMLInputElement>();
  const fetchedFile = useGetFile(perkData.file);

  const error =
    errorMessage || dropzoneErrorMessage || fileUploadError?.message;
  const loading = isLoading || isUpdating || isFileUploading;

  const addFile = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const hapiFile = await uploadFile(file);

      if (hapiFile) updatePerkField("file", hapiFile);
    }
  };

  const deleteFile = () => {
    updatePerkField("file", null);
    if (dropzoneInputRef.current) dropzoneInputRef.current.value = "";
  };

  const onFileError = useCallback((error: DropzoneError) => {
    switch (error) {
      case DropzoneError.MAX_AMOUNT:
        setDropzoneErrorMessage("Please add a single file.");
        return;
      case DropzoneError.INVALID_FORMAT:
        setDropzoneErrorMessage("Please add a valid file format (SVG or PNG).");
        return;
      default:
        setDropzoneErrorMessage("An error has occurred.");
    }
  }, []);

  const saveWebsiteLogoPerk = async () => {
    if (isValid) {
      // TODO: upload this logo to company claim in onboarding, not here
      if (
        !isCompanyLoading &&
        company &&
        !company.logo &&
        perkData.file &&
        perkData.file.id
      ) {
        await updateCompanyLogo([parseInt(perkData.file.id)]);
      }

      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitWebsiteLogoPerk = async () => {
    if (isValid) {
      // TODO: upload this logo to company claim in onboarding, not here
      if (
        !isCompanyLoading &&
        company &&
        !company.logo &&
        perkData.file &&
        perkData.file.id
      ) {
        await updateCompanyLogo([parseInt(perkData.file.id)]);
      }

      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.WEBSITE_LOGO}
        error={!!error}
        readOnly={isReadOnly}
      >
        {!isReadOnly && (
          <Dropzone
            max={1}
            acceptedFileTypes={["png", "svg"]}
            onFilesUpload={addFile}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <FileLink file={fetchedFile} deleteFile={deleteFile} />
          </>
        )}
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.WEBSITE_LOGO}--website-link`}
          title="Website Link*"
          value={perkData["website_link"] ?? ""}
          placeholder="Ex. Home Page"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("website_link", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.WEBSITE_LOGO}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveWebsiteLogoPerk}
          onSubmit={submitWebsiteLogoPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.WEBSITE_LOGO} error={error} />
    </>
  );
};

export default WebsiteLogo;
