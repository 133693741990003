import React, { createContext, useContext, useState } from "react";

export type TSidebarState = {
  showSidebar: boolean;
  toggleSidebar: () => void;
};

const DEFAULT_STATE: TSidebarState = {
  showSidebar: true,
  toggleSidebar: () => {},
};

export const SidebarContext: React.Context<TSidebarState> = createContext(
  DEFAULT_STATE
);

export const SidebarContextProvider: React.FC = ({ children, ...rest }) => {
  const [showSidebar, setShowSidebar] = useState(true);

  const toggleSidebar = () =>
    setShowSidebar((prevShowSidebar) => !prevShowSidebar);

  const stateValue = {
    showSidebar,
    toggleSidebar,
  };

  return (
    <SidebarContext.Provider value={stateValue} {...rest}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
