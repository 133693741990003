import {
  Text,
  Spacer,
  Flex,
  Button,
  Spinner,
  TextInput,
  UnstyledLink,
} from "@hackthenorth/north";
import debounce from "lodash.debounce";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { useGetHackersQuery } from "src/api/hackers/getHackers.generated";
import { PageWrapper, MultiSelect } from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts";
import { useGetFiles } from "src/shared/hooks";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import {
  answersToOptions,
  optionsToAnswers,
  Options,
} from "src/shared/utils/react-select";

import { HackerFilter, HACKER_FILTERS } from "./constants";
import HackerProfile from "./HackerProfile";
import { THackerProfile } from "./HackerProfile/types";
import { parseHackerProfiles, parseFilters } from "./utils";

const Container = styled.div`
  display: grid;
  grid-row-gap: 24px;
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 500px;
`;

// Keep in mind that file limit on HAPI is 10
const DEFAULT_NUM_PROFILES = 10;

const SponsorRecruitment = () => {
  const [skipped, setSkipped] = useState(0);
  const [filters, setFilters] = useState<HackerFilter[]>([]);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [stringFilters, setStringFilters] = useState<{ [key: string]: string }>(
    { [HackerFilter.NAME]: nameFilter }
  );
  const debouncedSetStringFilters = useRef(
    debounce(
      (newFilters: { [key: string]: string }) => setStringFilters(newFilters),
      500
    )
  ).current;

  const {
    company,
    loading: loadingSponsorCompany,
    updateSponsorCompany,
  } = useSponsorContext();

  const { data, loading: fetchingHackers } = useGetHackersQuery({
    variables: {
      skip: skipped,
      take: DEFAULT_NUM_PROFILES,
      filters: parseFilters(filters, company?.id, stringFilters),
    },
  });

  const [favoritedHackers, setFavoritedHackers] = useState<number[]>([]);

  // Stores all hacker profiles
  const [hackerProfiles, setHackerProfiles] = useState<THackerProfile[]>([]);

  // Stores the newest set of hacker profiles for resume fetching
  const [newProfiles, setNewProfiles] = useState<THackerProfile[]>([]);

  // Update favorited hackers when claim is re-fetched

  useEffect(() => {
    if (data && !fetchingHackers && company) {
      const hackerProfilesData = parseHackerProfiles(data, company.id);
      setNewProfiles(hackerProfilesData);
    }
  }, [data, fetchingHackers, skipped, company]);

  const resumes = newProfiles
    .map((hackerProfile) => hackerProfile.resume)
    .filter((resume) => resume !== undefined) as HackerAPIFile[];
  const fetchedResumes = useGetFiles(resumes);

  useEffect(() => {
    // If the two HAPI file arrays are different, then update hackerProfiles state
    if (JSON.stringify(resumes) !== JSON.stringify(fetchedResumes)) {
      const refetchedProfiles = newProfiles.map((hackerProfile) => {
        // If the hackerProfile doesn't have a resume, skip over it
        if (!hackerProfile.resume) return hackerProfile;

        // Otherwise update the profile with the fetched resume
        const { resume, ...rest } = hackerProfile;

        return {
          resume: fetchedResumes.find(
            (fetchedResume) => fetchedResume.id === resume.id
          ),
          ...rest,
        } as THackerProfile;
      });
      if (skipped === 0) {
        // Update hacker profiles when claim is re-fetched
        setHackerProfiles(refetchedProfiles);
      } else {
        // Update state with new hacker profiles [Pagination]
        setHackerProfiles((prevHackerProfiles) => [
          ...prevHackerProfiles,
          ...refetchedProfiles,
        ]);
      }
      setNewProfiles(refetchedProfiles);
    }
  }, [resumes, fetchedResumes, skipped, newProfiles]);

  const saveFavoritedHackers = () =>
    /* eslint-disable @typescript-eslint/camelcase */
    updateSponsorCompany({
      favorited_hackers: favoritedHackers.map((id) => id.toString()),
    });

  const onFavorite = (hackerId: number) => {
    const favorited = favoritedHackers.includes(hackerId);
    setFavoritedHackers((prevFavoritedHackers) =>
      favorited
        ? prevFavoritedHackers.filter((id) => id !== hackerId)
        : [hackerId, ...prevFavoritedHackers]
    );
  };

  // Client-side filter for favorited hackers
  const filteredHackerProfiles = filters.includes(HackerFilter.FAVORITED)
    ? hackerProfiles.filter(({ id }) => favoritedHackers.includes(id))
    : hackerProfiles;

  return (
    <PageWrapper footer pageTitle="Recruitment">
      <Text mods="big medium">
        Here you can view and sort through hacker resumes!
      </Text>
      <Spacer height={48} />
      <Flex justify="space-between" align="center">
        <div>
          <Text mods="heading h2">Filter hackers</Text>
          <Spacer height={12} />
          <StyledMultiSelect
            placeholder="Select tags to filter by..."
            value={answersToOptions(filters)}
            options={answersToOptions(HACKER_FILTERS)}
            onChange={(selectedOptions: Options) => {
              setFilters(optionsToAnswers(selectedOptions));
            }}
          />
        </div>
        <Button
          mods="primary"
          disabled={loadingSponsorCompany}
          onClick={saveFavoritedHackers}
        >
          {loadingSponsorCompany ? (
            <Flex align="center" justify="center">
              <Spinner />
              <Spacer width={10} />
              Saving
            </Flex>
          ) : (
            "Save favorited hackers"
          )}
        </Button>
      </Flex>
      <Spacer height={12} />
      <Text mods="heading h3">Filter hackers by name or email</Text>
      <TextInput
        placeholder="Filter by name or email..."
        value={nameFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNameFilter(e.target.value);
          debouncedSetStringFilters({
            [HackerFilter.NAME]: e.target.value,
          });
        }}
      />
      <Spacer height={24} />
      <Flex justify="space-between" align="center">
        <div>
          <Text mods="heading h3">Bulk download hacker information</Text>
          <Text mods="medium">
            Note: Download links for resumes will expire on February 24, 2021.
          </Text>
        </div>
        <Button mods="primary">
          <UnstyledLink
            href="https://hackerapi.storage.googleapis.com/files/hackthenorth2020/application/vnd.ms-excel/d2f48668c982fd2b6f1507de9764815a/c66a1472_1611648161_hacker_profiles_bulk_download.csv?AWSAccessKeyId=GOOG1E7VQ63ECFS25JDMAPYUWPWPIWLAW533KMAOEV5W5PDUSPXD2752SNY4A&Expires=1614307803&Signature=%2Fy5o1lIk7FKBj6LP03D3dburXRY%3D"
            target="_blank"
          >
            CSV download link (2726 hackers)
          </UnstyledLink>
        </Button>
      </Flex>
      <Spacer height={48} />
      <Container>
        {filteredHackerProfiles.map((profile) => {
          return (
            <HackerProfile
              key={profile.name}
              favorited={favoritedHackers.includes(profile.id)}
              onFavorite={() => onFavorite(profile.id)}
              {...profile}
            />
          );
        })}
      </Container>
      {fetchingHackers && (
        <>
          <Spacer height={48} />
          <Flex justify="center">
            <Spinner />
          </Flex>
        </>
      )}
      <Spacer height={48} />
      <Flex
        justify="center"
        onClick={() =>
          setSkipped((prevSkipped) => prevSkipped + DEFAULT_NUM_PROFILES)
        }
      >
        <Button mods="secondary">Load more</Button>
      </Flex>
    </PageWrapper>
  );
};

export default SponsorRecruitment;
