import styled from "styled-components";

const Divider = styled.div`
  border: ${({ theme }) =>
    `1px solid ${theme.globalConstants.color.textLightGrey}`};
  width: 100%;
  opacity: 25%;
`;

export default Divider;
