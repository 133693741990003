import { ApolloProvider } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { AuthProvider, North } from "@hackthenorth/north";
import { JWTRole, Button } from "@hackthenorth/north";
import { Worker as PDFWorker } from "@phuocng/react-pdf-viewer";
import React, { memo } from "react";
import { Routes, BrowserRouter } from "react-router-dom";
import styled from "styled-components";

import { client } from "src/api/client";
import {
  Route,
  TopNavBar,
  TOPNAVBAR_HEIGHT,
  Sidebar,
  QA,
} from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";
import {
  UserContextProvider,
  MilestonesContextProvider,
  PermissionsContextProvider,
  DataProvider,
  MentorContextProvider,
} from "src/shared/contexts";
import {
  Permission,
  useUserContext,
  usePermissionsContext,
  SidebarContextProvider,
  useSidebarContext,
} from "src/shared/contexts";
import { hideSidebarStyles } from "src/shared/contexts/SidebarContext/styles";
import themeDef from "src/theme";
import { APP_ENV, IS_PRODUCTION } from "src/utils/env";
import NotFound from "src/views/404";
import { DocumentRoutes } from "src/views/documents/routes";
import AwSnap from "src/views/error/AwSnap";
import { HackerHomeRoutes } from "src/views/hacker/home/routes";
import { HackerRoutes } from "src/views/hacker/routes";
import HackerRsvpHome from "src/views/hacker/rsvp/home";
import { HelpRoutes } from "src/views/help/routes";
import { HomeRoutes as SponsorHomeRoutes } from "src/views/home/routes";
import { MentorRoutes } from "src/views/mentor/routes";
import { PlaygroundRoutes } from "src/views/playground/routes";
import { ScheduleRoutes } from "src/views/schedule/routes";
import { SponsorRoutes } from "src/views/sponsor/routes";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import MentorHome from "./views/mentor/home";

Bugsnag.start({
  apiKey: "fcc22e94006ad0b4c21cf5bd345065c5",
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React); // eslint-disable-line

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const MainContent = styled.div<{ showSidebar: boolean }>`
  position: relative;
  top: ${TOPNAVBAR_HEIGHT}px;
  height: calc(100vh - ${TOPNAVBAR_HEIGHT}px);

  ${hideSidebarStyles}
`;

const INDICATOR_COLOR: string = {
  production: "green",
  staging: "yellow",
  latest: "red",
  development: "grey",
}[APP_ENV];

const Indicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 4px;
  z-index: 1000;
  background-color: ${INDICATOR_COLOR};
`;

const EnvIndicator = memo(function EnvIndicator() {
  return IS_PRODUCTION ? null : <Indicator />;
});

const AppRoutes: React.FC = () => {
  const { roles } = useUserContext();
  const { hasPermissions } = usePermissionsContext();

  const isMentor = roles.includes(JWTRole.MENTOR);

  // TODO: Cleanup and separate SponsorHome page
  const Home = () => {
    if (hasPermissions([Permission.HACKER_DAY_OF_TOOLS_ACCESS])) {
      return <HackerHomeRoutes />;
    } else if (hasPermissions([Permission.HACKER_RSVP])) {
      return <HackerRsvpHome />;
    } else if (isMentor) {
      return <MentorHome />;
    } else {
      return <SponsorHomeRoutes />;
    }
  };

  return (
    <Routes>
      <Route path={BaseRoute.HOME} element={<Home />} />
      <Route path={BaseRoute.SCHEDULE} element={<ScheduleRoutes />} />
      <Route
        path={`${BaseRoute.PLAYGROUND}/*`}
        element={<PlaygroundRoutes />}
      />
      <Route path={`${BaseRoute.DOCUMENTS}/*`} element={<DocumentRoutes />} />
      <Route path={`${BaseRoute.HELP}/*`} element={<HelpRoutes />} />
      <Route
        path={`${BaseRoute.SPONSOR}/*`}
        element={<SponsorRoutes />}
        requirePermissions={[Permission.SPONSOR]}
      />
      <Route
        path={`${BaseRoute.HACKER}/*`}
        element={<HackerRoutes />}
        requirePermissions={[Permission.HACKER]}
      />
      <Route
        path={`${BaseRoute.MENTOR}/*`}
        element={
          <MentorContextProvider>
            <MentorRoutes />
          </MentorContextProvider>
        }
        requirePermissions={[Permission.MENTOR]}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const AppContent = () => {
  const { isAuthenticated, roles, logOut, isOrganizer } = useUserContext();
  const { showSidebar } = useSidebarContext();

  const isUnauthorized =
    isAuthenticated &&
    !roles.includes(JWTRole.SPONSOR) &&
    !roles.includes(JWTRole.HACKER) &&
    !roles.includes(JWTRole.MENTOR) &&
    !isOrganizer;

  return isUnauthorized ? (
    <AwSnap
      error={
        new Error(
          "The attendee dashboard is currently only available to sponsors, hackers and mentors."
        )
      }
    >
      <Button onClick={logOut}>Log out</Button>
    </AwSnap>
  ) : (
    <Container className="app">
      <TopNavBar className="top-nav" />
      <Sidebar className="side-nav" />
      <MainContent showSidebar={showSidebar}>
        <AppRoutes />
      </MainContent>
    </Container>
  );
};

const App: React.FC = () => (
  <North themeDefinition={themeDef}>
    <PDFWorker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ErrorBoundary FallbackComponent={AwSnap}>
            <AuthProvider production={IS_PRODUCTION}>
              <UserContextProvider>
                <DataProvider>
                  <MilestonesContextProvider>
                    <PermissionsContextProvider>
                      <EnvIndicator />
                      <SidebarContextProvider>
                        <AppContent />
                      </SidebarContextProvider>
                      <QA />
                    </PermissionsContextProvider>
                  </MilestonesContextProvider>
                </DataProvider>
              </UserContextProvider>
            </AuthProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </ApolloProvider>
    </PDFWorker>
  </North>
);

export default App;
