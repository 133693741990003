import {
  Text,
  Flex,
  Grid,
  TextInput,
  UnstyledButton,
  Spacer,
  Spinner,
} from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import { Icon, PageWrapper, TooltipWrapper } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import { BADGES_DATA, VALID_BADGE_CODES } from "./constants";
import { useAddBadges } from "./useAddBadges";

const StyledTextInput = styled(TextInput)`
  max-width: 400px;
  height: 55px !important;
  padding: 12px 24px !important;
`;

const StyledImg = styled.img<{ hasBadge: boolean }>`
  width: 82px;
  height: 82px;
  opacity: ${({ hasBadge }) => (hasBadge ? 1 : 0.2)};
`;

const Container = styled(Grid)`
  padding: 32px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.borderGrey};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.regular};
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const StyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.globalConstants.color.bluePrimaryD};

  transition: 250ms;

  &:hover {
    transform: scale(1.05);
  }
`;

const ArrowIcon = styled(Icon)`
  transform: rotate(90deg);
`;

export const ACCEPTED_FIELDS = [Field.BADGES, Field.SAVED_EVENTS];

const Badges: React.FC = () => {
  const [badge, setBadge] = useState("");
  const [error, setError] = useState("");
  const { updateResponses, isLoading } = useHackerContext();
  const {
    responsesState,
    setResponsesState,
    isResponseUpdated,
  } = useHackerState(ACCEPTED_FIELDS, {});

  useAddBadges({ isResponseUpdated, responsesState, setResponsesState });

  const submitCode = () => {
    if (!isResponseUpdated) return;
    // If the code is invalid, display error
    if (!VALID_BADGE_CODES.includes(badge)) {
      setError("Sorry, you've entered an invalid code 😢");
      return;
    }

    // If you're already claimed the badge, display another error message
    if (responsesState[Field.BADGES]?.includes(badge)) {
      setError("It looks like you've already claimed this badge 😮");
      return;
    }

    const currentBadges = responsesState[Field.BADGES] ?? [];

    updateResponses({
      [Field.BADGES]: [badge, ...currentBadges],
    });
  };

  return (
    <PageWrapper pageTitle="Badges" onBlur={() => setError("")}>
      <Text>
        Keep an eye out on Discord for the 6 digit numeric codes that will be
        distributed to redeem your badge!{" "}
        <span role="img" aria-label="eyes emoji">
          👀
        </span>
      </Text>
      <Spacer height={24} />
      <Text mods="medium big">Enter your badge code!</Text>
      <Spacer height={8} />
      <Flex align="center">
        <StyledTextInput
          placeholder="Input badge number"
          value={badge}
          maxLength={6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setBadge(e.target.value)
          }
        />
        <Spacer width={24} />
        {isLoading ? (
          <Spinner />
        ) : (
          <StyledButton onClick={submitCode}>
            <ArrowIcon name="solid-arrow" size="70px" />
          </StyledButton>
        )}
      </Flex>
      <Spacer height={24} />
      {error && <Text mods="error big">{error}</Text>}
      <Spacer height={48} />
      <Container columns={6} rows="auto" rowGap={12} columnGap={12}>
        {Object.values(BADGES_DATA).map(
          ({ imgSrc, badgeCode, tooltipText }, i) => (
            <Flex justify="center" align="center" key={i}>
              <TooltipWrapper
                id={badgeCode.toString()}
                eventDelay={0.5}
                icon={
                  <StyledImg
                    src={imgSrc}
                    alt=""
                    hasBadge={(responsesState[Field.BADGES] ?? []).includes(
                      badgeCode.toString()
                    )}
                  />
                }
                content={<Text mods="subtle">{tooltipText}</Text>}
              />
            </Flex>
          )
        )}
      </Container>
    </PageWrapper>
  );
};

export default Badges;
