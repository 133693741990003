import { Spacer, Text, Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
  Link,
  Shimmer,
} from "src/shared/components";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { Nullable } from "src/shared/utils/typescript";

export interface FeaturePreviewProps {
  title: Nullable<string>;
  link: Nullable<string>;
  description: Nullable<string>;
  image: Nullable<HackerAPIFile>;
}

const ImagePreview = <Shimmer style={{ height: "200px", width: "200px" }} />;

const TitlePreview = <Shimmer />;

const DescPreview = <Shimmer />;

const TitleText = styled(Text).attrs({ removeSharedStyles: true })`
  color: ${({ theme }) => theme.globalConstants.color.navySecondary};
  margin-top: 0;
`;

const DescriptionText = styled(Text)`
  font-size: 18px !important;
`;

const FeatureLink = styled(Link).attrs({ removeSharedStyles: true })`
  text-decoration-color: ${({ theme }) => theme.globalConstants.color.textDark};
`;

const ImageContainer = styled.div<{ src: string }>`
  width: 200px;
  min-width: 200px;
  height: 200px;
  background: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`;

const StyledHeadingContainer = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
`;

const Preview: React.FC<FeaturePreviewProps> = ({
  image,
  title,
  link,
  description,
}) => {
  return (
    <Accordion
      heading={
        <StyledHeadingContainer>
          <Text mods="bold">Preview</Text>
          <Spacer width={8} />
          <AccordionArrow />
        </StyledHeadingContainer>
      }
    >
      <Spacer height={8} />
      <Flex>
        {image?.uri ? (
          <ImageContainer src={image.uri} title={image.name ?? ""} />
        ) : (
          ImagePreview
        )}
        <Spacer display="inline" width={46} />
        <div>
          {title ? (
            <TitleText mods="heading bold h2">{title}</TitleText>
          ) : (
            TitlePreview
          )}
          {description ? (
            <DescriptionText>{description}</DescriptionText>
          ) : (
            DescPreview
          )}
          <Spacer height={9} />
          <FeatureLink href={link ?? ""}>
            <DescriptionText>Learn More</DescriptionText>
          </FeatureLink>
        </div>
      </Flex>
    </Accordion>
  );
};

export default Preview;
