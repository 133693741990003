import { Text, Spacer, Button, Flex } from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { formatTime } from "src/utils/formatTime";

export interface ClaimedRequestCardProps {
  request: MentorshipRequests.Request;
  onResolve: (request: MentorshipRequests.Request) => void;
  onUnclaim: (request: MentorshipRequests.Request) => void;
  showActions?: boolean;
}

const Card = styled.div`
  width: 616px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.navySecondary};
  margin-bottom: 24px;
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Category = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
  && {
    margin: 3px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const ResolveButton = styled(Button).attrs({ mods: "primary" })`
  margin-right: 12px;
`;

const StageTag = styled(Text).attrs({ mods: "subtle medium nowrap darkBlue" })`
  padding: 0 10px;
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.globalConstants.color.mustardSecondary};
  height: 24px;
  text-transform: capitalize;
`;

const ClaimedRequestCard: React.FC<ClaimedRequestCardProps> = ({
  request,
  onResolve,
  onUnclaim,
  showActions,
}) => {
  const [showCard, setShowCard] = useState(true);
  // Titlecase the stage tag
  const stageTag =
    request.stage === "OPEN" ? "Unclaimed" : request.stage.toLowerCase();
  const isResolved = request.stage === "RESOLVED";
  return (
    <>
      {showCard && (
        <Card>
          <Flex align="center">
            <Text mods="h3">{request.title}</Text>
            <Spacer width={10} />
            <StageTag>{stageTag}</StageTag>
          </Flex>
          <Spacer height={20} />
          <Text>{request.description}</Text>
          <Spacer height={20} />
          <CategoryContainer>
            {(request?.category || []).map((category) => (
              <Category key={category}>{category}</Category>
            ))}
          </CategoryContainer>
          <Spacer height={12} />
          <Text mods="bluePrimary2">
            Submitted {formatTime(request.created_at)}
          </Text>
          <Spacer height={12} />
          <Text>{request.mentee_name}</Text>
          <br />
          <Text>
            Discord ID: {request.mentee_discord_id || "No Discord ID found :("}
          </Text>
          <Spacer height={20} />
          {showActions && (
            <ButtonContainer>
              <ResolveButton
                onClick={() => {
                  onResolve(request);
                  setShowCard(false);
                }}
                disabled={isResolved}
              >
                Mark as Resolved
              </ResolveButton>
              <Button
                onClick={() => {
                  onUnclaim(request);
                  setShowCard(false);
                }}
                disabled={isResolved}
              >
                Unclaim Request
              </Button>
            </ButtonContainer>
          )}
        </Card>
      )}
    </>
  );
};

export default ClaimedRequestCard;
