export enum SocialPlatforms {
  FACEBOOK = "Facebook",
  INSTA = "Instagram",
  TWITTER = "Twitter",
  LINKEDIN = "LinkedIn",
}

export const SOCIALS = {
  [SocialPlatforms.FACEBOOK]: "https://www.facebook.com/hackthenorth/",
  [SocialPlatforms.INSTA]: "https://www.instagram.com/hackthenorth",
  [SocialPlatforms.TWITTER]: "https://twitter.com/hackthenorth",
  [SocialPlatforms.LINKEDIN]: "https://www.linkedin.com/company/hack-the-north",
};
