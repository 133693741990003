import {
  Text,
  Flex,
  Button,
  Spacer,
  JWTRole,
  UnstyledButton,
} from "@hackthenorth/north";
import React, { useState, useMemo } from "react";
import styled from "styled-components";

import { AccountDropdown, Modal, Icon } from "src/shared/components";
import { useUserContext, useSidebarContext } from "src/shared/contexts";
import { hideSidebarStyles } from "src/shared/contexts/SidebarContext/styles";
import { getFirstName } from "src/utils/string";

interface TopNavBarProps extends React.ComponentPropsWithoutRef<"div"> {}

export const TOPNAVBAR_HEIGHT = 80;

const Container = styled(Flex).attrs({
  align: "center",
  justify: "space-between",
})<{ showSidebar: boolean }>`
  position: fixed;
  top: 0;
  height: ${TOPNAVBAR_HEIGHT}px;
  padding-right: 64px;

  z-index: 10;
  background: ${({ theme }) => theme.globalConstants.color.white};
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGrey};

  ${hideSidebarStyles}
`;

const ArrowContainer = styled(UnstyledButton)<{ isOpen: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  svg {
    cursor: pointer;
    transition: transform 100ms;
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : 270)}deg);
  }

  transition: 250ms;
  &:hover {
    transform: scale(1.4);
  }
`;

const TopNavBar: React.FC<TopNavBarProps> = ({ ...rest }) => {
  const { info, isAuthenticated, logOut, logIn, roles } = useUserContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { showSidebar, toggleSidebar } = useSidebarContext();

  const firstName = useMemo(() => getFirstName(info?.name), [info]);
  const leftText = useMemo(
    () => (firstName ? `Hi there, ${firstName}! 👋` : ""),
    [firstName]
  );

  return (
    <Container showSidebar={showSidebar} {...rest}>
      <Flex align="center">
        <Spacer width="24px" />
        <ArrowContainer isOpen={showSidebar} onClick={toggleSidebar}>
          <Icon size="h2" name="chevron" />
        </ArrowContainer>
        <Spacer width="48px" />
        <Text mods="big">{leftText}</Text>
      </Flex>
      {isAuthenticated ? (
        roles?.includes(JWTRole.HACKER) || roles?.includes(JWTRole.SPONSOR) ? (
          <AccountDropdown />
        ) : (
          <>
            <Button onClick={() => setShowConfirmationModal(true)}>
              Log out
            </Button>
            <Modal
              isOpen={showConfirmationModal}
              title="Log out"
              actions={
                <>
                  <Button
                    mods="secondary"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Cancel
                  </Button>
                  <Spacer width="16px" />
                  <Button mods="primary" onClick={logOut}>
                    Log out
                  </Button>
                </>
              }
            >
              <Text>Are you sure you want to log out?</Text>
            </Modal>
          </>
        )
      ) : (
        <Button onClick={logIn} mods="primary">
          Log in
        </Button>
      )}
    </Container>
  );
}; // todo: add actual account dropdown

export default TopNavBar;
