import { Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Nullable } from "src/shared/utils/typescript";

import InfoTag from "./InfoTag";

interface CategoriesProps {
  categories: Nullable<string[]>;
}

const CategoryTag = styled(InfoTag)`
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3Light};
`;

const Categories: React.FC<CategoriesProps> = ({ categories }) => (
  <Flex wrap="wrap">
    {categories?.map((category, index) => (
      <CategoryTag key={index}>{category}</CategoryTag>
    ))}
  </Flex>
);

export default Categories;
