import {
  ActivitiesAerobicsBadgeImg,
  ActivitiesDrawphoneBadgeImg,
  ActivitiesEmpowermentLoungeBadgeImg,
  ActivitiesHackerNetworkingBadgeImg,
  ActivitiesWerewolfBadgeImg,
  GeneralAcceptedBadgeImg,
  GeneralLoggedOnBadgeImg,
  GeneralSavedAnEventBadgeImg,
  MentorshipSubmittedOneBadgeImg,
  MentorshipSubmittedFiveBadgeImg,
  MentorshipSubmittedTenBadgeImg,
  MentorshipSubmittedTwentyFiveBadgeImg,
  SponsorACVAuctionsBadgeImg,
  SponsorArctypeBadgeImg,
  SponsorCANSOFCOMBadgeImg,
  SponsorCashAppBadgeImg,
  SponsorCitadelBadgeImg,
  SponsorCockroachLabsBadgeImg,
  SponsorContraryBadgeImg,
  SponsorCSEBadgeImg,
  SponsorDeloitteBadgeImg,
  SponsorDropbaseBadgeImg,
  SponsorFacebookBadgeImg,
  SponsorFirebaseBadgeImg,
  SponsorGeneralDynamicsBadgeImg,
  SponsorHootsuiteBadgeImg,
  SponsorIBMBadgeImg,
  SponsorIMCTradingBadgeImg,
  SponsorKPMGBadgeImg,
  SponsorMicrosoftBadgeImg,
  SponsorNEXTCanadaBadgeImg,
  SponsorOTPPBadgeImg,
  SponsorProcterAndGambleBadgeImg,
  SponsorRBCBadgeImg,
  SponsorShopify,
  SponsorSunLifeFinancialBadgeImg,
  SponsorUbisoftBadgeImg,
  SponsorVoiceflowBadgeImg,
  SponsorVonageBadgeImg,
  SponsorsTalkedToOneBadgeImg,
  SponsorsTalkedToFiveBadgeImg,
  SponsorsTalkedToTenBadgeImg,
  WorkshopsAttendedOneBadgeImg,
  WorkshopsAttendedFiveBadgeImg,
  WorkshopsAttendedTwentyFiveBadgeImg,
  WorkshopsBlockchainBeyondTheHypeBadgeImg,
  WorkshopsBuildingChromeExtensionsBadgeImg,
  WorkshopsCodingEthereumSmartContractsBadgeImg,
  WorkshopsCrackingTheCodingInterviewBadgeImg,
  WorkshopsCreatingEffectiveDesignSystemsBadgeImg,
  WorkshopsCreativeIdeationBadgeImg,
  WorkshopsDataScienceEthicsBadgeImg,
  WorkshopsDesigningYourPersonalBrandBadgeImg,
  WorkshopsEmbeddedCryptographyBadgeImg,
  WorkshopsEstablishingMLWorkflowBadgeImg,
  WorkshopsPitchYourProductBadgeImg,
  WorkshopsInteractiveDataVisualizationBadgeImg,
  WorkshopsIntermediateAndroidBadgeImg,
  WorkshopsIntroToAndroidBadgeImg,
  WorkshopsIntroToAPIsBadgeImg,
  WorkshopsIntroToComputerVisionBadgeImg,
  WorkshopsIntroToDesignThinkingBadgeImg,
  WorkshopsIntroToDockerBadgeImg,
  WorkshopsIntroToFigmaBadgeImg,
  WorkshopsIntroToGraphQLBadgeImg,
  WorkshopsIntroToOpenSourceBadgeImg,
  WorkshopsIntroToReactBadgeImg,
  WorkshopsIntroToSecurityEngineeringBadgeImg,
  WorkshopsIntroToTensorflowBadgeImg,
  WorkshopsIntroToVRBadgeImg,
  WorkshopsIntroToWebDevelopmentBadgeImg,
  WorkshopsAdvancedAndroidBadgeImg,
  WorkshopsNegotiatingJobOfferBadgeImg,
  WorkshopsNetworkingBadgeImg,
  WorkshopsScalingSideProjectsBadgeImg,
  WorkshopsTechNeuroscienceIntersectionsBadgeImg,
} from "src/static/img";

export enum Badge {
  ACTIVITY_AEROBICS,
  ACTIVITY_DRAWPHONE,
  ACTIVITY_EMPOWERMENT_LOUNGE,
  ACTIVITY_HACKER_NETWORKING,
  ACTIVITY_WEREWOLF,

  GENERAL_ACCEPTED,
  GENERAL_LOGGED_ON,
  GENERAL_SAVED_EVENT,

  MENTORSHIP_SUBMITTED_ONE,
  MENTORSHIP_SUBMITTED_FIVE,
  MENTORSHIP_SUBMITTED_TEN,
  MENTORSHIP_SUBMITTED_TWENTY_FIVE,

  SPONSOR_ACV_AUCTIONS,
  SPONSOR_ARCTYPE,
  SPONSOR_CANSOFCOM,
  SPONSOR_CASH_APP,
  SPONSOR_CITADEL,
  SPONSOR_COCKROACH_LABS,
  SPONSOR_CONTRARY,
  SPONSOR_CSE,
  SPONSOR_DELOITTE,
  SPONSOR_DROPBASE,
  SPONSOR_FACEBOOK,
  SPONSOR_FIREBASE,
  SPONSOR_GENERAL_DYNAMICS,
  SPONSOR_HOOTSUITE,
  SPONSOR_IBM,
  SPONSOR_IMC_TRADING,
  SPONSOR_KPMG,
  SPONSOR_MICROSOFT,
  SPONSOR_NEXT_CANADA,
  SPONSOR_OTPP,
  SPONSOR_PROCTER_AND_GAMBLE,
  SPONSOR_RBC,
  SPONSOR_SHOPIFY,
  SPONSOR_SUN_LIFE_FINANCIAL,
  SPONSOR_UBISOFT,
  SPONSOR_VOICEFLOW,
  SPONSOR_VONAGE,
  SPONSOR_TALKED_TO_ONE,
  SPONSOR_TALKED_TO_FIVE,
  SPONSOR_TALKED_TO_TEN,

  WORKSHOPS_ATTENDED_ONE,
  WORKSHOPS_ATTENDED_FIVE,
  WORKSHOPS_ATTENDED_TWENTY_FIVE,
  WORKSHOPS_BLOCKCHAIN,
  WORKSHOPS_CHROME_EXTENSIONS,
  WORKSHOPS_ETHEREUM,
  WORKSHOPS_CTCI,
  WORKSHOPS_DESIGN_SYSTEMS,
  WORKSHOPS_CREATIVE_IDEATION,
  WORKSHOPS_DATA_SCI_ETHICS,
  WORKSHOPS_PERSONAL_BRAND,
  WORKSHOPS_EMBEDDED_CRYPTOGRAPHY,
  WORKSHOPS_ML_WORKFLOW,
  WORKSHOPS_PITCH_YOUR_PRODUCT,
  WORKSHOPS_DATA_VISUALIZATION,
  WORKSHOPS_INTERMEDIATE_ANDROID,
  WORKSHOPS_INTRO_TO_ANDROID,
  WORKSHOPS_INTRO_TO_API,
  WORKSHOPS_INTO_TO_CV,
  WORKSHOPS_INTRO_TO_DESIGN,
  WORKSHOPS_INTRO_TO_DOCKER,
  WORKSHOPS_INTRO_TO_FIGMA,
  WORKSHOPS_INTRO_TO_GRAPHQL,
  WORKSHOPS_INTRO_TO_OPEN_SOURCE,
  WORKSHOPS_INTRO_TO_REACT,
  WORKSHOPS_INTRO_TO_SECURITY,
  WORKSHOPS_INTRO_TO_TENSORFLOW,
  WORKSHOPS_INTRO_TO_VR,
  WORKSHOPS_INTRO_TO_WEB_DEV,
  WORKSHOPS_ADVANCED_ANDROID,
  WORKSHOPS_NEGOTIATING_OFFER,
  WORKSHOPS_NETWORKING,
  WORKSHOPS_SCALING_SIDE_PROJECTS,
  WORKSHOPS_TECH_NEUROSCIENCE,
}

type TBadge = {
  tooltipText: string;
  imgSrc: string;
  isGearUp?: boolean;
  badgeCode: number;
};

export const BADGES_DATA: Record<Badge, TBadge> = {
  [Badge.GENERAL_ACCEPTED]: {
    tooltipText: "Got accepted to HTN 2020++",
    imgSrc: GeneralAcceptedBadgeImg,
    badgeCode: 888489,
  },
  [Badge.GENERAL_LOGGED_ON]: {
    tooltipText: "Logged on to Hacker Dashboard",
    imgSrc: GeneralLoggedOnBadgeImg,
    badgeCode: 825689,
  },
  [Badge.GENERAL_SAVED_EVENT]: {
    tooltipText: "Saved an event on the schedule",
    imgSrc: GeneralSavedAnEventBadgeImg,
    badgeCode: 778232,
  },
  [Badge.ACTIVITY_AEROBICS]: {
    tooltipText: "Attended Aerobics",
    imgSrc: ActivitiesAerobicsBadgeImg,
    badgeCode: 622285,
  },
  [Badge.ACTIVITY_DRAWPHONE]: {
    tooltipText: "Attended Drawphone",
    imgSrc: ActivitiesDrawphoneBadgeImg,
    badgeCode: 774678,
  },
  [Badge.ACTIVITY_EMPOWERMENT_LOUNGE]: {
    tooltipText: "Caught chillin' in the Empowerment Lounge",
    imgSrc: ActivitiesEmpowermentLoungeBadgeImg,
    badgeCode: 921479,
  },
  [Badge.ACTIVITY_HACKER_NETWORKING]: {
    tooltipText: "Attended Hacker <> Hacker Networking",
    imgSrc: ActivitiesHackerNetworkingBadgeImg,
    badgeCode: 725082,
  },
  [Badge.ACTIVITY_WEREWOLF]: {
    tooltipText: "Attended Werewolf",
    imgSrc: ActivitiesWerewolfBadgeImg,
    badgeCode: 323494,
  },
  [Badge.MENTORSHIP_SUBMITTED_ONE]: {
    tooltipText: "Submitted a request to our mentorship team",
    imgSrc: MentorshipSubmittedOneBadgeImg,
    badgeCode: 174055,
  },
  [Badge.MENTORSHIP_SUBMITTED_FIVE]: {
    tooltipText: "Submitted 5 requests to our mentorship team",
    imgSrc: MentorshipSubmittedFiveBadgeImg,
    badgeCode: 952509,
  },
  [Badge.MENTORSHIP_SUBMITTED_TEN]: {
    tooltipText: "Submitted 10 requests to our mentorship team",
    imgSrc: MentorshipSubmittedTenBadgeImg,
    badgeCode: 872945,
  },
  [Badge.MENTORSHIP_SUBMITTED_TWENTY_FIVE]: {
    tooltipText: "Submitted 25 requests to our mentorship team",
    imgSrc: MentorshipSubmittedTwentyFiveBadgeImg,
    badgeCode: 667216,
  },

  [Badge.SPONSOR_ACV_AUCTIONS]: {
    tooltipText: "Connected with an ACV Auctions representative",
    imgSrc: SponsorACVAuctionsBadgeImg,
    badgeCode: 170904,
  },
  [Badge.SPONSOR_ARCTYPE]: {
    tooltipText: "Connected with an Arctype representative",
    imgSrc: SponsorArctypeBadgeImg,
    badgeCode: 678148,
  },
  [Badge.SPONSOR_CANSOFCOM]: {
    tooltipText: "Connected with a CANSOFCOM representative",
    imgSrc: SponsorCANSOFCOMBadgeImg,
    badgeCode: 565854,
  },
  [Badge.SPONSOR_CASH_APP]: {
    tooltipText: "Connected with a Cash App representative",
    imgSrc: SponsorCashAppBadgeImg,
    badgeCode: 181271,
  },
  [Badge.SPONSOR_CITADEL]: {
    tooltipText: "Connected with a Citadel representative",
    imgSrc: SponsorCitadelBadgeImg,
    badgeCode: 272022,
  },
  [Badge.SPONSOR_COCKROACH_LABS]: {
    tooltipText: "Connected with a Cockroach Labs representative",
    imgSrc: SponsorCockroachLabsBadgeImg,
    badgeCode: 525129,
  },
  [Badge.SPONSOR_CONTRARY]: {
    tooltipText: "Connected with a Contrary representative",
    imgSrc: SponsorContraryBadgeImg,
    badgeCode: 702830,
  },
  [Badge.SPONSOR_CSE]: {
    tooltipText: "Connected with a CSE representative",
    imgSrc: SponsorCSEBadgeImg,
    badgeCode: 964214,
  },
  [Badge.SPONSOR_DELOITTE]: {
    tooltipText: "Connected with a Deloitte representative",
    imgSrc: SponsorDeloitteBadgeImg,
    badgeCode: 700162,
  },
  [Badge.SPONSOR_DROPBASE]: {
    tooltipText: "Connected with a Dropbase representative",
    imgSrc: SponsorDropbaseBadgeImg,
    badgeCode: 749195,
  },
  [Badge.SPONSOR_FACEBOOK]: {
    tooltipText: "Connected with a Facebook representative",
    imgSrc: SponsorFacebookBadgeImg,
    badgeCode: 181461,
  },
  [Badge.SPONSOR_FIREBASE]: {
    tooltipText: "Connected with a Firebase representative",
    imgSrc: SponsorFirebaseBadgeImg,
    badgeCode: 429531,
  },
  [Badge.SPONSOR_GENERAL_DYNAMICS]: {
    tooltipText: "Connected with a General Dynamics representative",
    imgSrc: SponsorGeneralDynamicsBadgeImg,
    badgeCode: 116647,
  },
  [Badge.SPONSOR_HOOTSUITE]: {
    tooltipText: "Connected with a Hootsuite representative",
    imgSrc: SponsorHootsuiteBadgeImg,
    badgeCode: 790276,
  },
  [Badge.SPONSOR_IBM]: {
    tooltipText: "Connected with an IBM representative",
    imgSrc: SponsorIBMBadgeImg,
    badgeCode: 220381,
  },
  [Badge.SPONSOR_IMC_TRADING]: {
    tooltipText: "Connected with an IMC Trading representative",
    imgSrc: SponsorIMCTradingBadgeImg,
    badgeCode: 364117,
  },
  [Badge.SPONSOR_KPMG]: {
    tooltipText: "Connected with a KPMG representative",
    imgSrc: SponsorKPMGBadgeImg,
    badgeCode: 803777,
  },
  [Badge.SPONSOR_MICROSOFT]: {
    tooltipText: "Connected with a Microsoft representative",
    imgSrc: SponsorMicrosoftBadgeImg,
    badgeCode: 715379,
  },
  [Badge.SPONSOR_NEXT_CANADA]: {
    tooltipText: "Connected with a NEXT Canada representative",
    imgSrc: SponsorNEXTCanadaBadgeImg,
    badgeCode: 130923,
  },
  [Badge.SPONSOR_OTPP]: {
    tooltipText: "Connected with an OTPP representative",
    imgSrc: SponsorOTPPBadgeImg,
    badgeCode: 289430,
  },
  [Badge.SPONSOR_PROCTER_AND_GAMBLE]: {
    tooltipText: "Connected with a P&G representative",
    imgSrc: SponsorProcterAndGambleBadgeImg,
    badgeCode: 254807,
  },
  [Badge.SPONSOR_RBC]: {
    tooltipText: "Connected with an RBC representative",
    imgSrc: SponsorRBCBadgeImg,
    badgeCode: 661321,
  },
  [Badge.SPONSOR_SHOPIFY]: {
    tooltipText: "Connected with a Shopify representative",
    imgSrc: SponsorShopify,
    badgeCode: 202780,
  },
  [Badge.SPONSOR_SUN_LIFE_FINANCIAL]: {
    tooltipText: "Connected with a Sun Life Financial representative",
    imgSrc: SponsorSunLifeFinancialBadgeImg,
    badgeCode: 801900,
  },
  [Badge.SPONSOR_UBISOFT]: {
    tooltipText: "Connected with a Ubisoft representative",
    imgSrc: SponsorUbisoftBadgeImg,
    badgeCode: 721235,
  },
  [Badge.SPONSOR_VOICEFLOW]: {
    tooltipText: "Connected with a Voiceflow representative",
    imgSrc: SponsorVoiceflowBadgeImg,
    badgeCode: 917626,
  },
  [Badge.SPONSOR_VONAGE]: {
    tooltipText: "Connected with a Vonage representative",
    imgSrc: SponsorVonageBadgeImg,
    badgeCode: 269378,
  },
  [Badge.SPONSOR_TALKED_TO_ONE]: {
    tooltipText: "Connected with 1 sponsor",
    imgSrc: SponsorsTalkedToOneBadgeImg,
    badgeCode: 266625,
  },
  [Badge.SPONSOR_TALKED_TO_FIVE]: {
    tooltipText: "Connected with 5 sponsors",
    imgSrc: SponsorsTalkedToFiveBadgeImg,
    badgeCode: 653366,
  },
  [Badge.SPONSOR_TALKED_TO_TEN]: {
    tooltipText: "Connected with 10 sponsors",
    imgSrc: SponsorsTalkedToTenBadgeImg,
    badgeCode: 175977,
  },

  [Badge.WORKSHOPS_ATTENDED_ONE]: {
    tooltipText: "Attended 1 workshop",
    imgSrc: WorkshopsAttendedOneBadgeImg,
    badgeCode: 141886,
  },
  [Badge.WORKSHOPS_ATTENDED_FIVE]: {
    tooltipText: "Attended 5 workshops",
    imgSrc: WorkshopsAttendedFiveBadgeImg,
    badgeCode: 104430,
  },
  [Badge.WORKSHOPS_ATTENDED_TWENTY_FIVE]: {
    tooltipText: "Attended 25 workshops",
    imgSrc: WorkshopsAttendedTwentyFiveBadgeImg,
    badgeCode: 268854,
  },
  [Badge.WORKSHOPS_BLOCKCHAIN]: {
    tooltipText: "Attended the Blockchain workshop",
    imgSrc: WorkshopsBlockchainBeyondTheHypeBadgeImg,
    badgeCode: 729628,
  },
  [Badge.WORKSHOPS_CHROME_EXTENSIONS]: {
    tooltipText: "Attended the Chrome Extension workshop",
    imgSrc: WorkshopsBuildingChromeExtensionsBadgeImg,
    isGearUp: true,
    badgeCode: 549826,
  },
  [Badge.WORKSHOPS_ETHEREUM]: {
    tooltipText: "Attended the Ethereum workshop",
    imgSrc: WorkshopsCodingEthereumSmartContractsBadgeImg,
    badgeCode: 190277,
  },
  [Badge.WORKSHOPS_CTCI]: {
    tooltipText: "Attended the Cracking the Coding Interview workshop",
    imgSrc: WorkshopsCrackingTheCodingInterviewBadgeImg,
    badgeCode: 652874,
  },
  [Badge.WORKSHOPS_DESIGN_SYSTEMS]: {
    tooltipText: "Attended the Design Systems workshop",
    imgSrc: WorkshopsCreatingEffectiveDesignSystemsBadgeImg,
    badgeCode: 204614,
  },
  [Badge.WORKSHOPS_CREATIVE_IDEATION]: {
    tooltipText: "Attended the Creative Ideation workshop",
    imgSrc: WorkshopsCreativeIdeationBadgeImg,
    badgeCode: 272112,
  },
  [Badge.WORKSHOPS_DATA_SCI_ETHICS]: {
    tooltipText: "Attended the Data Science Ethics workshop",
    imgSrc: WorkshopsDataScienceEthicsBadgeImg,
    isGearUp: true,
    badgeCode: 401226,
  },
  [Badge.WORKSHOPS_PERSONAL_BRAND]: {
    tooltipText: "Attended the Personal Brand Design workshop",
    imgSrc: WorkshopsDesigningYourPersonalBrandBadgeImg,
    badgeCode: 280326,
  },
  [Badge.WORKSHOPS_EMBEDDED_CRYPTOGRAPHY]: {
    tooltipText: "Attended the Embedded Cryptography workshop",
    imgSrc: WorkshopsEmbeddedCryptographyBadgeImg,
    badgeCode: 428908,
  },
  [Badge.WORKSHOPS_ML_WORKFLOW]: {
    tooltipText: "Attended the Productive ML Workflow workshop",
    imgSrc: WorkshopsEstablishingMLWorkflowBadgeImg,
    isGearUp: true,
    badgeCode: 137843,
  },
  [Badge.WORKSHOPS_PITCH_YOUR_PRODUCT]: {
    tooltipText: "Attended the Product Pitching workshop",
    imgSrc: WorkshopsPitchYourProductBadgeImg,
    badgeCode: 133102,
  },
  [Badge.WORKSHOPS_DATA_VISUALIZATION]: {
    tooltipText: "Attended the Data Visualization workshop",
    imgSrc: WorkshopsInteractiveDataVisualizationBadgeImg,
    badgeCode: 616778,
  },
  [Badge.WORKSHOPS_INTERMEDIATE_ANDROID]: {
    tooltipText: "Attended the Intermediate Android workshop",
    imgSrc: WorkshopsIntermediateAndroidBadgeImg,
    isGearUp: true,
    badgeCode: 701305,
  },
  [Badge.WORKSHOPS_INTRO_TO_ANDROID]: {
    tooltipText: "Attended the Beginner Android workshop",
    imgSrc: WorkshopsIntroToAndroidBadgeImg,
    isGearUp: true,
    badgeCode: 243302,
  },
  [Badge.WORKSHOPS_INTRO_TO_API]: {
    tooltipText: "Attended the Intro to APIs workshop",
    imgSrc: WorkshopsIntroToAPIsBadgeImg,
    isGearUp: true,
    badgeCode: 272724,
  },
  [Badge.WORKSHOPS_INTO_TO_CV]: {
    tooltipText: "Attended the Intro to Computer Vision workshop",
    imgSrc: WorkshopsIntroToComputerVisionBadgeImg,
    isGearUp: true,
    badgeCode: 172514,
  },
  [Badge.WORKSHOPS_INTRO_TO_DESIGN]: {
    tooltipText: "Attended the Intro to Design Thinking workshop",
    imgSrc: WorkshopsIntroToDesignThinkingBadgeImg,
    isGearUp: true,
    badgeCode: 831131,
  },
  [Badge.WORKSHOPS_INTRO_TO_DOCKER]: {
    tooltipText: "Attended the Intro to Docker workshop",
    imgSrc: WorkshopsIntroToDockerBadgeImg,
    isGearUp: true,
    badgeCode: 816720,
  },
  [Badge.WORKSHOPS_INTRO_TO_FIGMA]: {
    tooltipText: "Attended the Intro to Figma workshop",
    imgSrc: WorkshopsIntroToFigmaBadgeImg,
    isGearUp: true,
    badgeCode: 523878,
  },
  [Badge.WORKSHOPS_INTRO_TO_GRAPHQL]: {
    tooltipText: "Attended the Intro to GraphQL workshop",
    imgSrc: WorkshopsIntroToGraphQLBadgeImg,
    isGearUp: true,
    badgeCode: 255226,
  },
  [Badge.WORKSHOPS_INTRO_TO_OPEN_SOURCE]: {
    tooltipText: "Attended the Intro to Open Source workshop",
    imgSrc: WorkshopsIntroToOpenSourceBadgeImg,
    isGearUp: true,
    badgeCode: 297026,
  },
  [Badge.WORKSHOPS_INTRO_TO_REACT]: {
    tooltipText: "Attended the Intro to React workshop",
    imgSrc: WorkshopsIntroToReactBadgeImg,
    isGearUp: true,
    badgeCode: 167721,
  },
  [Badge.WORKSHOPS_INTRO_TO_SECURITY]: {
    tooltipText: "Attended the Intro to Security Engineering workshop",
    imgSrc: WorkshopsIntroToSecurityEngineeringBadgeImg,
    badgeCode: 616714,
  },
  [Badge.WORKSHOPS_INTRO_TO_TENSORFLOW]: {
    tooltipText: "Attended the Intro to Tensorflow.js workshop",
    imgSrc: WorkshopsIntroToTensorflowBadgeImg,
    isGearUp: true,
    badgeCode: 371710,
  },
  [Badge.WORKSHOPS_INTRO_TO_VR]: {
    tooltipText: "Attended the Intro to VR workshop",
    imgSrc: WorkshopsIntroToVRBadgeImg,
    badgeCode: 852356,
  },
  [Badge.WORKSHOPS_INTRO_TO_WEB_DEV]: {
    tooltipText: "Attended the Intro to Web Development workshop",
    imgSrc: WorkshopsIntroToWebDevelopmentBadgeImg,
    isGearUp: true,
    badgeCode: 130329,
  },
  [Badge.WORKSHOPS_ADVANCED_ANDROID]: {
    tooltipText: "Attended the Advanced Android workshop",
    imgSrc: WorkshopsAdvancedAndroidBadgeImg,
    isGearUp: true,
    badgeCode: 525041,
  },
  [Badge.WORKSHOPS_NEGOTIATING_OFFER]: {
    tooltipText: "Attended the Job Offer Negotiation workshop",
    imgSrc: WorkshopsNegotiatingJobOfferBadgeImg,
    isGearUp: true,
    badgeCode: 534289,
  },
  [Badge.WORKSHOPS_NETWORKING]: {
    tooltipText: "Attended the Internship Networking workshop",
    imgSrc: WorkshopsNetworkingBadgeImg,
    badgeCode: 140743,
  },
  [Badge.WORKSHOPS_SCALING_SIDE_PROJECTS]: {
    tooltipText: "Attended the Scaling Side Projects workshop",
    imgSrc: WorkshopsScalingSideProjectsBadgeImg,
    isGearUp: true,
    badgeCode: 178872,
  },
  [Badge.WORKSHOPS_TECH_NEUROSCIENCE]: {
    tooltipText: "Attended the Tech <> Neuroscience workshop",
    imgSrc: WorkshopsTechNeuroscienceIntersectionsBadgeImg,
    badgeCode: 222282,
  },
};

// Valid badge codes are those which are allowed to be entered to redeem a badge!
const VALID_ACTIVIY_BADGES: Badge[] = [
  Badge.ACTIVITY_AEROBICS,
  Badge.ACTIVITY_DRAWPHONE,
  Badge.ACTIVITY_EMPOWERMENT_LOUNGE,
  Badge.ACTIVITY_HACKER_NETWORKING,
  Badge.ACTIVITY_WEREWOLF,
];

export const VALID_ACTIVITY_BADGE_CODES = VALID_ACTIVIY_BADGES.map((badge) =>
  BADGES_DATA[badge].badgeCode.toString()
);

const VALID_SPONSOR_BADGES: Badge[] = [
  Badge.SPONSOR_ACV_AUCTIONS,
  Badge.SPONSOR_ARCTYPE,
  Badge.SPONSOR_CANSOFCOM,
  Badge.SPONSOR_CASH_APP,
  Badge.SPONSOR_CITADEL,
  Badge.SPONSOR_COCKROACH_LABS,
  Badge.SPONSOR_CONTRARY,
  Badge.SPONSOR_CSE,
  Badge.SPONSOR_DELOITTE,
  Badge.SPONSOR_DROPBASE,
  Badge.SPONSOR_FACEBOOK,
  Badge.SPONSOR_FIREBASE,
  Badge.SPONSOR_GENERAL_DYNAMICS,
  Badge.SPONSOR_HOOTSUITE,
  Badge.SPONSOR_IBM,
  Badge.SPONSOR_IMC_TRADING,
  Badge.SPONSOR_KPMG,
  Badge.SPONSOR_MICROSOFT,
  Badge.SPONSOR_NEXT_CANADA,
  Badge.SPONSOR_OTPP,
  Badge.SPONSOR_PROCTER_AND_GAMBLE,
  Badge.SPONSOR_RBC,
  Badge.SPONSOR_SHOPIFY,
  Badge.SPONSOR_SUN_LIFE_FINANCIAL,
  Badge.SPONSOR_UBISOFT,
  Badge.SPONSOR_VOICEFLOW,
  Badge.SPONSOR_VONAGE,
];

export const VALID_SPONSOR_BADGE_CODES = VALID_SPONSOR_BADGES.map((badge) =>
  BADGES_DATA[badge].badgeCode.toString()
);

export const VALID_WORKSHOP_BADGES: Badge[] = [
  Badge.WORKSHOPS_BLOCKCHAIN,
  Badge.WORKSHOPS_CHROME_EXTENSIONS,
  Badge.WORKSHOPS_ETHEREUM,
  Badge.WORKSHOPS_CTCI,
  Badge.WORKSHOPS_DESIGN_SYSTEMS,
  Badge.WORKSHOPS_CREATIVE_IDEATION,
  Badge.WORKSHOPS_DATA_SCI_ETHICS,
  Badge.WORKSHOPS_PERSONAL_BRAND,
  Badge.WORKSHOPS_EMBEDDED_CRYPTOGRAPHY,
  Badge.WORKSHOPS_ML_WORKFLOW,
  Badge.WORKSHOPS_PITCH_YOUR_PRODUCT,
  Badge.WORKSHOPS_DATA_VISUALIZATION,
  Badge.WORKSHOPS_INTERMEDIATE_ANDROID,
  Badge.WORKSHOPS_INTRO_TO_ANDROID,
  Badge.WORKSHOPS_INTRO_TO_API,
  Badge.WORKSHOPS_INTO_TO_CV,
  Badge.WORKSHOPS_INTRO_TO_DESIGN,
  Badge.WORKSHOPS_INTRO_TO_DOCKER,
  Badge.WORKSHOPS_INTRO_TO_FIGMA,
  Badge.WORKSHOPS_INTRO_TO_GRAPHQL,
  Badge.WORKSHOPS_INTRO_TO_OPEN_SOURCE,
  Badge.WORKSHOPS_INTRO_TO_REACT,
  Badge.WORKSHOPS_INTRO_TO_SECURITY,
  Badge.WORKSHOPS_INTRO_TO_TENSORFLOW,
  Badge.WORKSHOPS_INTRO_TO_VR,
  Badge.WORKSHOPS_INTRO_TO_WEB_DEV,
  Badge.WORKSHOPS_ADVANCED_ANDROID,
  Badge.WORKSHOPS_NEGOTIATING_OFFER,
  Badge.WORKSHOPS_NETWORKING,
  Badge.WORKSHOPS_SCALING_SIDE_PROJECTS,
  Badge.WORKSHOPS_TECH_NEUROSCIENCE,
];

export const VALID_WORKSHOP_BADGE_CODES = VALID_WORKSHOP_BADGES.map((badge) =>
  BADGES_DATA[badge].badgeCode.toString()
);

export const VALID_BADGE_CODES = [
  ...VALID_ACTIVITY_BADGE_CODES,
  ...VALID_SPONSOR_BADGE_CODES,
  ...VALID_WORKSHOP_BADGE_CODES,
];
