import {
  timesToOptions,
  answersToOptions,
} from "src/shared/utils/react-select";
import { formatTime } from "src/utils/formatTime";

// January 16th hours within 9am - 11pm
const COFFEE_CHAT_TIMES = [
  new Date(Date.UTC(2021, 0, 16, 14, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 15, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 16, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 17, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 18, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 19, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 20, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 22, 0, 0)),
  new Date(Date.UTC(2021, 0, 16, 23, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 0, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 1, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 2, 0, 0)),
  new Date(Date.UTC(2021, 0, 17, 3, 0, 0)),
];

export const TIME_OPTIONS = timesToOptions(formatTime, COFFEE_CHAT_TIMES);

export const DURATION_OPTIONS = answersToOptions([
  "5 minutes",
  "10 minutes",
  "15 minutes",
  "20 minutes",
  "25 minutes",
  "30 minutes",
]);
