import { Text, Spinner, Flex, Spacer } from "@hackthenorth/north";
import React from "react";

import { useGetOutlineFileQuery } from "src/api/files/getOutlineFile.generated";
import { MarkdownDocument } from "src/shared/components";

export interface OutlineDocumentProps {
  shareId: string;
  noTitle?: boolean;
}

const OutlineDocument: React.FC<OutlineDocumentProps> = ({
  shareId,
  noTitle = false,
}) => {
  const { data, loading: loadingFile } = useGetOutlineFileQuery({
    variables: { shareId },
  });

  return (
    <Flex column align="start" justify="center">
      {!noTitle && (
        <Text mods="heading title">{data?.outlineDocument.title}</Text>
      )}
      {loadingFile ? (
        <>
          <Spinner />
          <Spacer height={16} />
        </>
      ) : data?.outlineDocument.text ? (
        <MarkdownDocument content={data?.outlineDocument.text} />
      ) : (
        <Text mods="error">Something went wrong. Please try again.</Text>
      )}
    </Flex>
  );
};

export default OutlineDocument;
