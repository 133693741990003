import React from "react";
import { Routes } from "react-router-dom";

import { MentorRoute } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts";
import NotFound from "src/views/404";

import { Route } from "../../shared/components";

import MentorClaimedRequests from "./claimed-requests";
import MentorOpenRequests from "./open-requests";
import MentorProfile from "./profile";

export const MentorRoutes = () => (
  <Routes>
    <Route
      path={MentorRoute.CLAIMED_REQUESTS}
      element={<MentorClaimedRequests />}
      requirePermissions={[Permission.MENTOR]}
    />
    <Route
      path={MentorRoute.REQUESTS}
      element={<MentorOpenRequests />}
      requirePermissions={[Permission.MENTOR]}
    />
    <Route
      path={MentorRoute.PROFILE}
      element={<MentorProfile />}
      requirePermissions={[Permission.MENTOR]}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
