export type JudgingSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

const HACK_THE_NORTH_JUDGING_SHARE_ID = "6b478c82-14f2-461b-b4f2-97d6be8634c6";
const SPONSOR_JUDGING_SHARE_ID = "5a922a73-b28f-4080-8879-25f6b8c0fcf6";
const APPENDIX_SHARE_ID = "e55142ce-0094-4d64-a496-68779e2eeaef";

export const JUDGING_SECTIONS: JudgingSection[] = [
  {
    label: "Hack the North Judging",
    sectionId: "hack-the-North-judging",
    shareId: HACK_THE_NORTH_JUDGING_SHARE_ID,
  },
  {
    label: "Sponsor Judging",
    sectionId: "sponsor-judging",
    shareId: SPONSOR_JUDGING_SHARE_ID,
  },
  {
    label: "Appendix",
    sectionId: "appendix",
    shareId: APPENDIX_SHARE_ID,
  },
];
