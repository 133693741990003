import { Flex, Text, UnstyledButton } from "@hackthenorth/north";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon, SIDEBAR_WIDTH } from "src/shared/components";
import { useSidebarContext } from "src/shared/contexts";
import { BlueHatBeaverImg } from "src/static/img";

import { MESSAGES, TMessage } from "./constants";

const Container = styled.div<{ showMessage: boolean; showSidebar: boolean }>`
  background: ${({ showMessage }) =>
    showMessage
      ? "linear-gradient(180deg, rgba(180, 217, 233, 0) 0%, #b4d9e9 100%)"
      : "transparent"};
  width: 100%;
  height: ${({ showMessage }) => (showMessage ? "175px" : "auto")};
  z-index: ${({ showMessage }) => (showMessage ? 2 : 0)};
  position: fixed;
  bottom: 0;
  left: ${({ showSidebar }) => `${showSidebar ? SIDEBAR_WIDTH : 0}px`};
  transition: all 200ms;
`;

const StyledBlueHatBeaver = styled.img`
  width: 132px;
`;

const BeaverButton = styled(UnstyledButton)<{
  showMessage: boolean;
  showSidebar: boolean;
}>`
  position: fixed;
  bottom: ${({ showMessage }) => (showMessage ? "30px" : "0px")};
  left: ${({ showMessage, showSidebar }) =>
    showMessage
      ? `calc(${showSidebar ? SIDEBAR_WIDTH : 0}px + 10px)`
      : `calc(${showSidebar ? SIDEBAR_WIDTH : 0}px - 75px)`};
  transform: ${({ showMessage }) => (showMessage ? "" : "rotate(16deg)")};
  transition: all 100ms;
`;

const MessageContainer = styled(Flex).attrs({
  align: "center",
  justify: "center",
})<{
  showMessage: boolean;
  showSidebar: boolean;
}>`
  position: fixed;
  bottom: 50px;
  left: ${({ showSidebar }) =>
    showSidebar ? `calc(${SIDEBAR_WIDTH}px + 180px)` : "180px"};

  opacity: ${({ showMessage }) => (showMessage ? "1" : "0")};
  display: ${({ showMessage }) => (showMessage ? "auto" : "none")};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.greySecondary};
  background-color: ${({ theme }) => theme.globalConstants.color.white};
  min-width: 500px;
  height: 100px;
  padding: 10px 20px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: all 100ms;
`;

const CloseIcon = styled(Icon)``;

const CloseButton = styled(UnstyledButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const BeaverMessages: React.FC = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [msg, setMsg] = useState<TMessage>(
    MESSAGES[Math.floor(Math.random() * MESSAGES.length)]
  );

  const getNewMessage = () => {
    const currentHour = moment().hour();
    const index =
      currentHour === 1 ? 15 : Math.floor(Math.random() * MESSAGES.length); // show goodnight message at 1ams
    return MESSAGES[index];
  };

  const showNewMessage = useCallback(() => {
    setMsg(getNewMessage());
    setShowMessage(true);
  }, []);

  useEffect(() => {
    setInterval(() => {
      showNewMessage();
    }, 1000 * 60 * 60); // show message every hour (1000 * 60 * 60)
  }, [showNewMessage]);

  const onBeaverClick = () => {
    showMessage ? setShowMessage(false) : showNewMessage();
  };

  const { showSidebar } = useSidebarContext();

  return (
    <Container
      onBlur={() => setShowMessage(false)}
      showMessage={showMessage}
      showSidebar={showSidebar}
    >
      <BeaverButton
        onClick={onBeaverClick}
        showMessage={showMessage}
        showSidebar={showSidebar}
      >
        <StyledBlueHatBeaver src={BlueHatBeaverImg} aria-hidden alt="Beaver" />
      </BeaverButton>
      <MessageContainer showMessage={showMessage} showSidebar={showSidebar}>
        <Text>
          {msg.text}{" "}
          {msg.emojis &&
            msg.emojis[Math.floor(Math.random() * msg.emojis.length)]}{" "}
        </Text>
        <CloseButton onClick={() => setShowMessage(false)}>
          <CloseIcon name="x" />
        </CloseButton>
      </MessageContainer>
    </Container>
  );
};

export default BeaverMessages;
