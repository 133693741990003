import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { HackerRoute } from "src/shared/constants/route";
import {
  HackerContextProvider,
  Permission,
  MentorshipRequestContextProvider,
} from "src/shared/contexts";
import NotFound from "src/views/404";

import HackerBadges from "./badges";
import HackerCommunity from "./community";
import HackerFAQ from "./faq";
import HackerGettingStarted from "./getting-started";
import HackerJudging from "./judging";
import HackerMentorshipHome from "./mentorship/home";
import HackerRsvpCareer from "./rsvp/career";
import HackerRsvpEvent from "./rsvp/event";
import HackerRsvpHome from "./rsvp/home";
import HackerRsvpPersonal from "./rsvp/personal";
import HackerWorkshops from "./workshops";

export const HackerRoutes = () => (
  <HackerContextProvider>
    <Routes>
      <Route
        path={HackerRoute.RSVP}
        element={<HackerRsvpHome />}
        requirePermissions={[Permission.HACKER_RSVP]}
      />
      <Route
        path={HackerRoute.PERSONAL}
        element={<HackerRsvpPersonal />}
        requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
      />
      <Route
        path={HackerRoute.EVENT}
        element={<HackerRsvpEvent />}
        requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
      />
      <Route
        path={HackerRoute.CAREER}
        element={<HackerRsvpCareer />}
        requirePermissions={[Permission.HACKER_RSVP_NOT_RESTRICTED]}
      />
      <Route
        path={HackerRoute.MENTORSHIP}
        element={
          <MentorshipRequestContextProvider>
            <HackerMentorshipHome />
          </MentorshipRequestContextProvider>
        }
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />

      <Route
        path={HackerRoute.BADGES}
        element={<HackerBadges />}
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />
      <Route
        path={HackerRoute.GETTING_STARTED}
        element={<HackerGettingStarted />}
      />
      <Route
        path={HackerRoute.FAQ}
        element={<HackerFAQ />}
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />
      <Route
        path={HackerRoute.WORKSHOPS}
        element={<HackerWorkshops />}
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />
      <Route
        path={HackerRoute.JUDGING}
        element={<HackerJudging />}
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />
      <Route
        path={HackerRoute.COMMUNITY}
        element={<HackerCommunity />}
        requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </HackerContextProvider>
);
