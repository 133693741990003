import { Text, Spacer } from "@hackthenorth/north";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getTimeLeft } from "./utils";

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.globalConstants.color.greySecondary};
  padding: 18px;
  margin: 28px 14px;
`;

const Timer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <Container>
      <Text mods="bold">{timeLeft[0]}</Text>
      <Spacer height="10" />
      <Text mods="heading h2 center bluePrimary2">{timeLeft[1]}</Text>
    </Container>
  );
};

export default Timer;
