export enum Permission {
  HACKER = "hacker",
  VOLUNTEER = "volunteer",
  VIEW_FULL_SCHEDULE = "view-full-schedule",

  SPONSOR = "sponsor",
  SPONSOR_FEATURES_ACCESS = "sponsor-features-access",
  SPONSOR_ADMIN = "admin",
  SPONSOR_RECRUITMENT_ACCESS = "sponsor-recruitment-access",

  HACKER_RSVP = "hacker-rsvp",
  HACKER_RSVP_NOT_RESTRICTED = "hacker-rsvp-not-restricted",

  HACKER_DAY_OF_TOOLS_ACCESS = "hacker-day-of-tools-access",

  MENTOR = "mentor",

  STAGING = "staging",
}
