import {
  BlueSmileyImg,
  NavySmileyImg,
  OrangeSmileyImg,
  PinkSmileyImg,
  RedSmileyImg,
  TealSmileyImg,
} from "src/static/img";

export const AVATAR_MAP = {
  teal: TealSmileyImg,
  pink: PinkSmileyImg,
  orange: OrangeSmileyImg,
  blue: BlueSmileyImg,
  navy: NavySmileyImg,
  red: RedSmileyImg,
};
