import { Spacer } from "@hackthenorth/north";
import PDFViewer from "@phuocng/react-pdf-viewer";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { Modal, GradientText } from "src/shared/components";
import { isPDF } from "src/shared/utils/file";
import { HackerAPIFile } from "src/shared/utils/hackerapi";

type ResumeViewerProps = ComponentPropsWithoutRef<"div"> & {
  file: HackerAPIFile;
  isOpen: boolean;
  onClose: () => void;
};

const StyledModal = styled(Modal)`
  overflow: hidden;
  > div {
    padding-right: 0;
  }
`;

const Container = styled.div`
  width: 700px;
  height: 70vh;
`;

const StyledImg = styled.img`
  height: 70vh;
`;

const ResumeViewer: React.FC<ResumeViewerProps> = ({
  file,
  isOpen,
  onClose,
}) => (
  <StyledModal isOpen={isOpen} onClose={onClose} width="auto">
    <GradientText gradient="gradientRedBlue172" mods="heading h2">
      Resume Preview
    </GradientText>
    <Spacer height={12} />
    {isPDF(file) ? (
      <Container>
        <PDFViewer fileUrl={file?.uri} />
      </Container>
    ) : (
      <StyledImg src={file?.uri ?? ""} alt={file.name ?? ""} />
    )}
  </StyledModal>
);

export default ResumeViewer;
