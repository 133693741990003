import {
  Button,
  Text,
  Spacer,
  TextInput,
  TextAreaInput,
  DropdownInput,
} from "@hackthenorth/north";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Modal, MultiSelect } from "src/shared/components";
import {
  MENTORSHIP_TOPICS,
  PRIORITY_OPTIONS,
} from "src/shared/constants/mentorship";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import {
  answersToOptions,
  optionsToAnswers,
  Options,
  Option,
} from "src/shared/utils/react-select";

const StyledMultiSelect = styled(MultiSelect)`
  .select__menu-list {
    max-height: 150px !important;
    z-index: 1;
  }
`;

const StyledDropdownInput = styled(DropdownInput)`
  &:focus {
    border-color: ${({ theme }) => theme.globalConstants.color.bluePrimary1};
    outline: none;
  }
`;

const CancelButton = styled(Button)`
  &:focus {
    color: ${({ theme }) => theme.globalConstants.color.redPrimary2};
    border-color: ${({ theme }) => theme.globalConstants.color.redPrimary2};
  }
`;

interface RequestModalProps {
  isOpen: boolean;
  onSubmit: (
    title: string,
    description: string,
    categories: string[],
    priority: number
  ) => void;
  onCancel: () => void;
  requestToEdit?: MentorshipRequests.Request;
  newRequest: boolean;
}

const RequestModal: React.FC<RequestModalProps> = ({
  isOpen,
  onSubmit,
  onCancel,
  requestToEdit,
  newRequest,
}) => {
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [priority, setPriority] = useState(1); // Default to Medium priority

  useEffect(() => {
    setConfirmCancel(false);
    setTitle(requestToEdit?.title ?? "");
    setDescription(requestToEdit?.description ?? "");
    setCategories(requestToEdit?.category ?? []);
    setPriority(requestToEdit?.priority ?? 1); // Default to Medium priority
  }, [isOpen, requestToEdit]);

  const isValid = !!title && !!description;

  const cancelWithConfirmation = () => {
    if (title === "" && description === "" && categories.length === 0) {
      // If all responses are empty, cancel without confirmation
      onCancel();
    } else if (!confirmCancel) {
      // Otherwise, ask for confirmation before cancelling
      setConfirmCancel(true);
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      width="800px"
      title="Submit a mentorship request"
      actions={
        <>
          <CancelButton
            mods={confirmCancel ? "error secondary" : "secondary"}
            onClick={cancelWithConfirmation}
          >
            {confirmCancel
              ? newRequest
                ? "Are you sure you want to discard this request?"
                : "Are you sure you want to discard your changes?"
              : "Cancel"}
          </CancelButton>
          <Spacer width="20px" />
          <Button
            mods="primary"
            disabled={!isValid}
            onClick={() => {
              setConfirmCancel(false); // for the next time the modal is opened
              onSubmit(title, description, categories, priority);
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      <Text mods="medium">Title</Text>
      <Spacer height={10} />
      <TextInput
        maxLength={200}
        placeholder="I need help with..."
        value={title}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setTitle(e.target.value);
          setConfirmCancel(false);
        }}
      />
      <Spacer height={20} />
      <Text mods="medium">Description</Text>
      <Spacer height={10} />
      <TextAreaInput
        placeholder="Explain your problem or question in detail."
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDescription(e.target.value);
          setConfirmCancel(false);
        }}
      />
      <Spacer height={20} />
      <Text mods="medium">Categories</Text>
      <Spacer height={10} />
      <StyledMultiSelect
        value={answersToOptions(categories)}
        options={answersToOptions(MENTORSHIP_TOPICS)}
        onChange={(selectedOptions: Options) => {
          setCategories(optionsToAnswers(selectedOptions));
          setConfirmCancel(false);
        }}
        placeholder="iOS, Frontend, Hardware, etc."
      />
      <Spacer height={20} />
      <Text mods="medium">Priority</Text>
      <Spacer height={10} />
      <StyledDropdownInput
        value={PRIORITY_OPTIONS[priority]}
        options={PRIORITY_OPTIONS}
        onChange={(option: Option) => {
          setPriority(option.value);
          setConfirmCancel(false);
        }}
      />
    </Modal>
  );
};

export default RequestModal;
