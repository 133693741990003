import React from "react";
import { Routes } from "react-router-dom";

import { Route } from "src/shared/components";
import { SponsorRoute } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts";
import NotFound from "src/views/404";

import SponsorPerksPage from "./perks";
import { SponsorPerksContextProvider } from "./perks/SponsorPerksContext";
import SponsorPlatformOnboarding from "./platform-onboarding";
import SponsorProfile from "./profile";
import SponsorRecruitment from "./recruitment";
import SponsorRepresentatives from "./representatives";

export const SponsorRoutes = () => (
  <Routes>
    <Route
      path={SponsorRoute.PERKS}
      element={
        <SponsorPerksContextProvider>
          <SponsorPerksPage />
        </SponsorPerksContextProvider>
      }
      requirePermissions={[
        Permission.SPONSOR_FEATURES_ACCESS,
        Permission.SPONSOR_ADMIN,
      ]}
    />
    <Route path={SponsorRoute.PROFILE} element={<SponsorProfile />} />
    <Route
      path={SponsorRoute.REPRESENTATIVES}
      element={<SponsorRepresentatives />}
      requirePermissions={[Permission.SPONSOR_FEATURES_ACCESS]}
    />
    <Route
      path={SponsorRoute.RECRUITMENT}
      element={<SponsorRecruitment />}
      requirePermissions={[
        Permission.SPONSOR_FEATURES_ACCESS,
        Permission.SPONSOR_ADMIN,
        Permission.SPONSOR_RECRUITMENT_ACCESS,
      ]}
    />
    <Route
      path={SponsorRoute.PLATFORM_ONBOARDING}
      element={<SponsorPlatformOnboarding />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
