import { Spacer, Flex, Link, Button, Text } from "@hackthenorth/north";
import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import {
  hoverStyles,
  focusStyles,
  disabledStyles,
} from "src/theme/sharedStyles";

interface FileLinkProps extends ComponentPropsWithoutRef<"a"> {
  file: HackerAPIFile;
  /**
   * Text to show as fallback if `file` does not have a name
   */
  label?: string;
  deleteFile?: () => void;
  onFileClick?: () => void;
}

const StyledLinkContainer = styled(Link)`
  display: flex !important;
  align-items: center;
  padding: ${({ theme }) => theme.globalConstants.padding.link};
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textDark};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const NoStyleButton = styled(Button).attrs({ removeSharedStyles: true })`
  height: 24px;
  ${hoverStyles}
  ${disabledStyles}
  ${focusStyles}
`;

const FileLink: React.FC<FileLinkProps> = ({
  file,
  label = "File",
  deleteFile,
  onFileClick,
  ...rest
}) => (
  <>
    <Flex align="center">
      <StyledLinkContainer
        download
        href={file.uri || "#"}
        onClick={onFileClick}
        {...rest}
      >
        <Icon
          className="file-link-icon"
          name="file"
          color="textDark"
          width={24}
          height={30}
        />
        <Spacer width={12} />
        <Text className="file-link">{file.name ?? label}</Text>
      </StyledLinkContainer>
      <Spacer width={26} />
      {deleteFile && (
        <NoStyleButton className="file-link-close-button">
          <Icon
            name="x"
            color="textDark"
            onClick={deleteFile}
            width={24}
            height={24}
          />
        </NoStyleButton>
      )}
    </Flex>
  </>
);

export default FileLink;
