import {
  Text,
  Spacer,
  Spinner,
  Flex,
  UnstyledButton,
} from "@hackthenorth/north";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { textSharedStyles } from "src/theme/componentStyles/text";
import { useGetEventScheduleQuery } from "src/views/schedule/ScheduleContext/graphql/getEventSchedule.generated";
import {
  computeBaseCopyWithTimezone,
  formatEventsFromQuery,
} from "src/views/schedule/ScheduleContext/utils";

import { InterestsToTagsMap } from "./constants";
import EventCard from "./EventCard";
import InterestsModal from "./InterestsModal";
import Slideshow from "./Slideshow";
import { THomeEvent } from "./types";

const EditInterestsButton = styled(UnstyledButton)`
  ${textSharedStyles}
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  display: inline-block;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const ACCEPTED_FIELDS = [Field.INTERESTS, Field.SAVED_EVENTS];

/**
 * recommended events are one of
 * 1. an event with a same tag as one of your saved event
 * 2. an event with a same tag as one of your interests
 * 3. an activity
 */
const RecommendedEvents: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const { loading: fetchingEvents, data, refetch } = useGetEventScheduleQuery();
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const events = useMemo(() => data?.eventSchedule ?? [], [data]);

  const tagsNotUsedForReccomendations = [
    "gear_up",
    "hack_the_north",
    "banner",
    "private",
    "organizer_only",
    "workshop",
  ];

  // todo: fix later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTagsForReccomendations = (arr: any[] | undefined) =>
    arr
      ?.flat()
      .filter((val, index, self) => self.indexOf(val) === index)
      .filter((tags) => !tagsNotUsedForReccomendations.includes(tags));

  const savedEventsTags = getTagsForReccomendations(
    events
      .filter((event) =>
        responsesState[Field.SAVED_EVENTS]?.includes(event.id.toString())
      )
      .map((event) => event.tags)
  );

  const interestsTags = getTagsForReccomendations(
    responsesState[Field.INTERESTS]?.map(
      (interest) => InterestsToTagsMap[interest]
    )
  );

  const reccomendedTags = savedEventsTags?.concat(interestsTags);
  const now = moment(moment.now()).subtract(50, "days").subtract(12, "hours");

  const filteredEvents = computeBaseCopyWithTimezone(
    formatEventsFromQuery(
      events
        .filter(
          (event) =>
            event.tags &&
            event.tags.includes("hack_the_north") &&
            (reccomendedTags?.some((tag: string) =>
              event.tags?.includes(tag)
            ) ||
              event.tags.includes("activity")) &&
            now < moment(event.end_time)
        )
        .sort((e1, e2) =>
          moment(e1.start_time) < moment(e2.start_time)
            ? moment(e1.start_time) === moment(e2.start_time)
              ? 0
              : -1
            : 1
        )
    )
  );

  // Refetch events every 10 minutes
  useEffect(() => {
    const id = setInterval(refetch, 600000);
    return () => clearInterval(id);
  }, [refetch]);

  return (
    <div>
      <InterestsModal shouldShow={showModal} setShouldShow={setShowModal} />
      <Flex align="center">
        <Text mods="heading h3">Recommended for you</Text>
        <Spacer width="24px" />
        <EditInterestsButton onClick={() => setShowModal(true)}>
          Edit your interests
        </EditInterestsButton>
      </Flex>
      <Spacer height="24px" />
      {fetchingEvents ? (
        <Spinner />
      ) : filteredEvents.length === 0 ? (
        <Text>
          No recommended events. Try saving events or editing your interests!
        </Text>
      ) : (
        <Slideshow
          items={filteredEvents.map((event: THomeEvent) => {
            console.log({ event });
            return <EventCard event={event} key={event.title} />;
          })}
          paddingBetween={20}
        />
      )}
    </div>
  );
};

export default RecommendedEvents;
