import {
  Text,
  Flex,
  Button,
  Spacer,
  RadioButton,
  RadioButtonGroup,
} from "@hackthenorth/north";
import React from "react";

type TPerkRadioButtonsProps = {
  name: string;
  title?: string;
  value?: string;
  isReadOnly: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
  options: string[];
};

const PerkRadioButtons: React.FC<TPerkRadioButtonsProps> = ({
  name,
  title,
  value,
  isReadOnly,
  onChange,
  options,
}) => (
  <>
    {title && (
      <Text mods="medium" className={name}>
        {title}
      </Text>
    )}
    <Spacer height={8} />
    <RadioButtonGroup
      name={name}
      className={`${name}--radio-button-group`}
      onSelect={(option: string) => onChange(option)}
      defaultValue={value}
    >
      <Flex>
        {options.map((option, i) => (
          <React.Fragment key={i}>
            <RadioButton key={i} value={option} disabled={isReadOnly}>
              {(selected: boolean) => (
                <Button mods={`square ${selected ? "primary" : ""}`}>
                  {option}
                </Button>
              )}
            </RadioButton>
            {i !== options.length - 1 && <Spacer width={24} />}
          </React.Fragment>
        ))}
      </Flex>
    </RadioButtonGroup>
  </>
);

export default PerkRadioButtons;
