import { ThemeDefinition } from "@hackthenorth/north";
import { css } from "styled-components";

import { hoverStyles, focusStyles, disabledStyles } from "../sharedStyles";

export const buttonStyles: ThemeDefinition["button"] = {
  sharedStyles: css`
    color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
    font-family: ${(props) => props.theme.globalConstants.fontFamily.body};
    font-size: ${({ theme }) => theme.globalConstants.fontSize.body}px;
    line-height: 29px;

    border: 1px solid ${({ theme }) => theme.globalConstants.color.bluePrimary2};
    border-radius: 50px;
    padding: 7px 32px;

    ${hoverStyles}
    ${disabledStyles}
    ${focusStyles}
  `,
  modifiers: {
    primary: css`
      color: ${({ theme }) => theme.globalConstants.color.tanSecondary};
      background-color: ${({ theme }) =>
        theme.globalConstants.color.bluePrimary2};
    `,
    error: css`
      color: ${({ theme }) => theme.globalConstants.color.redPrimary2};
      border-color: ${({ theme }) => theme.globalConstants.color.redPrimary2};
    `,
    confirm: css`
      background-color: ${({ theme }) =>
        theme.globalConstants.color.greenSecondary};
      color: ${({ theme }) => theme.globalConstants.color.white};
      border-color: ${({ theme }) =>
        theme.globalConstants.color.greenSecondary};
    `,
    square: css`
      border-radius: 5px;
    `,
    compact: css`
      padding: 1px 19px;
      line-height: 1.5;
      font-size: 10px !important;
    `,
    nowrap: css`
      white-space: nowrap;
    `,
    lessOpaqueDisabled: css`
      &:disabled {
        filter: none;
        opacity: 0.6;
      }
    `,
  },
};
