import { useRef, useCallback } from "react";

const SEPARATOR = "-";

export const useDateAutoComplete = () => {
  const canAutoComplete = useRef<boolean>(true);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Implementation https://www.youtube.com/watch?v=6bKvevjR7GM

      const char = event.key;
      // do not register non-numerical and non-backspace characters
      if (isNaN(Number(char)) && char !== "Backspace") {
        event.preventDefault();
      }

      // avoid separator being added when backspacing
      if (char === "Backspace") canAutoComplete.current = false;
    },
    []
  );

  // adds a separator character at locations to format a date properly without interfering with input
  const onAutoComplete = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target.value;
      const charLength = input.split("").length;

      if (charLength === 4 || charLength === 7) {
        if (canAutoComplete.current) e.target.value += SEPARATOR;
        else canAutoComplete.current = true;
      }

      if (charLength === 5 || charLength === 8) {
        const lastChar = input.charAt(input.length - 1);
        if (lastChar === SEPARATOR) {
          e.target.value = input.substring(0, input.length - 1);
        } else {
          e.target.value =
            input.substring(0, input.length - 1) + SEPARATOR + lastChar;
        }
      }
    },
    []
  );

  return { onKeyDown, onAutoComplete };
};
