import { timesToOptions } from "src/shared/utils/react-select";
import { formatTimeRange } from "src/utils/formatTime";

const CAREER_DEVELOPMENT_TIMES = [
  new Date(Date.UTC(2021, 0, 12, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 12, 22, 0, 0)),
  new Date(Date.UTC(2021, 0, 13, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 13, 22, 0, 0)),
  new Date(Date.UTC(2021, 0, 14, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 14, 22, 0, 0)),
  new Date(Date.UTC(2021, 0, 15, 21, 0, 0)),
  new Date(Date.UTC(2021, 0, 15, 22, 0, 0)),
];

export const TIME_OPTIONS = timesToOptions(
  formatTimeRange,
  CAREER_DEVELOPMENT_TIMES
);
