/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../types.generated";
export type GetFilesQueryVariables = Types.Exact<{
  fileIds?: Types.Maybe<Array<Types.Scalars["Int"]>>;
}>;

export type GetFilesQuery = { __typename?: "Query" } & {
  files: Array<
    { __typename?: "File" } & Pick<Types.File, "id" | "name" | "mime_type"> & {
        download: { __typename?: "FileDownload" } & Pick<
          Types.FileDownload,
          "uri" | "expiry"
        >;
      }
  >;
};

export const GetFilesDocument = gql`
  query getFiles($fileIds: [Int!]) {
    files(where: { id: { in: $fileIds } }) {
      id
      name
      mime_type
      download {
        uri
        expiry
      }
    }
  }
`;

/**
 * __useGetFilesQuery__
 *
 * To run a query within a React component, call `useGetFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilesQuery({
 *   variables: {
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useGetFilesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFilesQuery,
    GetFilesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetFilesQuery, GetFilesQueryVariables>(
    GetFilesDocument,
    baseOptions
  );
}
export function useGetFilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFilesQuery,
    GetFilesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<GetFilesQuery, GetFilesQueryVariables>(
    GetFilesDocument,
    baseOptions
  );
}
export type GetFilesQueryHookResult = ReturnType<typeof useGetFilesQuery>;
export type GetFilesLazyQueryHookResult = ReturnType<
  typeof useGetFilesLazyQuery
>;
export type GetFilesQueryResult = ApolloReactCommon.QueryResult<
  GetFilesQuery,
  GetFilesQueryVariables
>;
