import { Button, Text, UnstyledButton } from "@hackthenorth/north";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Icon, Link } from "src/shared/components";
import { buttonStyles } from "src/theme/componentStyles/button";
import { ErrorModal } from "src/views/error/ErrorModal";

import { BLOCK_HEIGHT, HOURS_LIST } from "../constants";
import { useScheduleContext } from "../ScheduleContext";
import { TEvent } from "../types";

interface EventProps {
  inDayView: boolean;
  event: TEvent;
}

const FadeBottom = styled.div<{
  blocksFromTop: number;
  numberOfBlocks: number;
}>`
  position: absolute;
  left: 5px;
  bottom: ${({ blocksFromTop, numberOfBlocks }) =>
    numberOfBlocks > HOURS_LIST.length - blocksFromTop ? "0" : "2px"};
  width: calc(100% - 20px);
  height: 10px;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`;

const FadeRight = styled.div`
  position: absolute;
  top: 5px;
  right: 12px;
  height: calc(100% - 10px);
  width: 10px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`;

const Container = styled.div<{
  eventColor: string;
  blocksFromTop: number;
  numberOfBlocks: number;
  overlapLevel: number;
}>`
  cursor: pointer;

  z-index: ${({ overlapLevel }) => overlapLevel + 1};

  border: 2px solid ${({ eventColor }) => eventColor};
  border-bottom: ${({ blocksFromTop, numberOfBlocks, eventColor }) =>
    numberOfBlocks > HOURS_LIST.length - blocksFromTop
      ? "none"
      : `2px solid ${eventColor}`};

  border-radius: ${({ blocksFromTop, numberOfBlocks }) =>
    numberOfBlocks > HOURS_LIST.length - blocksFromTop ? "5px 5px 0 0" : "5px"};

  overflow: hidden;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 10px 12px;
  padding-top: ${({ blocksFromTop }) =>
    blocksFromTop >= 0 ? 10 : 8 + -1 * blocksFromTop * BLOCK_HEIGHT}px;

  background: white;

  height: 100%;
  width: 100%;

  * p {
    text-align: start;
  }

  &:focus {
    outline: none;
  }
`;

// old width
// calc(
//   5% + (100% / ${({ overlapStackSize }) => overlapStackSize}) *
//     ${({ overlapStackSize, overlapLevel }) =>
//       overlapStackSize - overlapLevel + 1}
// );

const Positioner = styled.div<{
  blocksFromTop: number;
  numberOfBlocks: number;
  overlapLevel: number;
  overlapStackSize: number;
  inDayView: boolean;
}>`
  position: absolute;
  right: ${({ inDayView }) => (inDayView ? "initial" : "0")};
  left: ${({ inDayView, overlapLevel }) =>
    inDayView ? `${315 * (overlapLevel - 1)}px` : "initial"};
  top: ${({ blocksFromTop }) => blocksFromTop * BLOCK_HEIGHT}px;
  height: ${({ numberOfBlocks, blocksFromTop }) =>
    BLOCK_HEIGHT *
    Math.min(numberOfBlocks, HOURS_LIST.length - blocksFromTop)}px;
  box-sizing: border-box;
  overflow: hidden;
  padding-right: 10px;
  width: ${({ inDayView, overlapStackSize, overlapLevel }) =>
    inDayView
      ? "300px"
      : `calc(100% - ${
          overlapStackSize === 1
            ? "0%"
            : overlapStackSize === 2
            ? "45%"
            : 40 / (overlapStackSize - 1) + "%"
        } * ${overlapLevel - 1})`};
`;

const SubContainer = styled.div`
  width: 100%;
  display: block;
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const StyledButton = styled(Button).attrs({ removeSharedStyles: true })`
  ${buttonStyles?.sharedStyles}

  font-size: ${({ theme }) => theme.globalConstants.fontSize.bodySubtle}px;
  line-height: 22px;
  padding: 1px 18px;
  margin: 5px 0;
  width: 100%;
  display: block;

  &:disabled {
    opacity: 0.5;
    filter: none;
  }
`;

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const IconButton = styled(UnstyledButton)`
  margin-left: 8px;
`;

const StyledIcon = styled(Icon)`
  flex: 0 0 12px;
`;

const SmallerLineHeightText = styled(Text)`
  line-height: 18px !important;
`;

const ExtraSubtleText = styled(Text)`
  font-size: 10px !important;
  line-height: 18px !important;
`;

export const Event: React.FC<EventProps> = ({ inDayView, event }) => {
  const {
    enableSaving,
    getIsSaved,
    showPrivateLink,
    eventCategoryToColor,
    showModalWithParam,
    toggleSaveEvent,
  } = useScheduleContext();

  const [error, setError] = useState<Error | null>(null);
  const toggleSaveEventWithErrorHandling = useCallback(async () => {
    try {
      await toggleSaveEvent(event.id);
    } catch (e) {
      setError(e);
    }
  }, [event.id, toggleSaveEvent]);

  // Is it currently before, during, or after the event?
  const eventPassed = useMemo(() => moment().isAfter(event.end), [event.end]);
  const eventUpcoming = useMemo(
    () => moment().add(1, "hours").isBefore(event.start),
    [event.start]
  );

  const getLinkMessage = (link: string) => {
    const a = document.createElement("a");
    a.href = link;

    const hostname = a.hostname;
    if (
      hostname === "youtu.be" ||
      hostname === "youtube.com" ||
      hostname === "www.youtube.com"
    ) {
      return "Watch";
    } else if (
      hostname === "devpost.com" ||
      hostname === "hackthenorth2020.devpost.com"
    ) {
      return "Submit";
    } else {
      return "Join";
    }
  };

  return (
    <>
      <ErrorModal error={error} onCancel={() => setError(null)} />
      <Positioner
        blocksFromTop={event.blocksFromTop}
        numberOfBlocks={event.numberOfBlocks}
        overlapLevel={event.overlapLevel}
        overlapStackSize={event.overlapStackSize}
        inDayView={inDayView}
      >
        <Container
          eventColor={eventCategoryToColor[event.category]}
          blocksFromTop={event.blocksFromTop}
          numberOfBlocks={event.numberOfBlocks}
          overlapLevel={event.overlapLevel}
          onClick={() => showModalWithParam(event.id)}
          role="button"
          tabIndex={0}
        >
          <SubContainer>
            <NameContainer>
              <SmallerLineHeightText mods="subtle">
                {event.title}
              </SmallerLineHeightText>
              {enableSaving && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSaveEventWithErrorHandling();
                  }}
                >
                  <StyledIcon
                    name={getIsSaved(event.id) ? "star" : "star-outline"}
                  />
                </IconButton>
              )}
            </NameContainer>
            <ExtraSubtleText mods="lightGrey">
              {event.displayedStart} - {event.displayedEnd}
            </ExtraSubtleText>
            {!eventUpcoming &&
              (!eventPassed && showPrivateLink && event.location ? (
                <StyledLink href={event.location}>
                  <StyledButton mods="primary">
                    {getLinkMessage(event.location)}
                  </StyledButton>
                </StyledLink>
              ) : (
                event.links[0] && (
                  <StyledLink href={event.links[0]}>
                    <StyledButton mods="primary">
                      {getLinkMessage(event.links[0])}
                    </StyledButton>
                  </StyledLink>
                )
              ))}
          </SubContainer>
          <SubContainer>
            <Text mods="subtle lightGrey">{event.category}</Text>
          </SubContainer>
          <FadeBottom
            blocksFromTop={event.blocksFromTop}
            numberOfBlocks={event.numberOfBlocks}
          />
          <FadeRight />
        </Container>
      </Positioner>
    </>
  );
};
