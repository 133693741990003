/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpdateProfilePhotoMutationVariables = Types.Exact<{
  mentorClaimId: Types.Scalars["Int"];
  fileIds: Types.Scalars["JSON"];
}>;

export type UpdateProfilePhotoMutation = { __typename?: "Mutation" } & {
  updateClaim: { __typename?: "Claim" } & Pick<Types.Claim, "id">;
};

export const UpdateProfilePhotoDocument = gql`
  mutation UpdateProfilePhoto($mentorClaimId: Int!, $fileIds: JSON!) {
    updateClaim(
      data: {
        id: $mentorClaimId
        answers: [{ slug: "mentorship_profile_photo", answer: $fileIds }]
      }
    ) {
      id
    }
  }
`;

/**
 * __useUpdateProfilePhotoMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePhotoMutation, { data, loading, error }] = useUpdateProfilePhotoMutation({
 *   variables: {
 *      mentorClaimId: // value for 'mentorClaimId'
 *      fileIds: // value for 'fileIds'
 *   },
 * });
 */
export function useUpdateProfilePhotoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProfilePhotoMutation,
    UpdateProfilePhotoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProfilePhotoMutation,
    UpdateProfilePhotoMutationVariables
  >(UpdateProfilePhotoDocument, baseOptions);
}
export type UpdateProfilePhotoMutationHookResult = ReturnType<
  typeof useUpdateProfilePhotoMutation
>;
export type UpdateProfilePhotoMutationResult = ApolloReactCommon.MutationResult<UpdateProfilePhotoMutation>;
export type UpdateProfilePhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProfilePhotoMutation,
  UpdateProfilePhotoMutationVariables
>;
