import { Text, Spacer, Link } from "@hackthenorth/north";
import React from "react";

import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

interface WebsiteShowcaseProps {
  perk: SponsorPerks.WebsiteShowcase;
}

type StoredPerk = SponsorPerks.WebsiteShowcase;

const description = (
  <div className="website-showcase-description">
    <Text mods="medium">
      Along with displaying your logo on our website, we will also feature your
      company in a special showcase area within
    </Text>{" "}
    <Link newTab href="https://hackthenorth.com/#sponsors" mods="medium">
      hackthenorth.com/#sponsors
    </Link>
    <Text mods="medium">. Past examples can be found at</Text>{" "}
    <Link newTab href="https://2019.hackthenorth.com/#sponsors" mods="medium">
      https://2019.hackthenorth.com/#sponsors
    </Link>
    <Text mods="medium">.</Text>
  </div>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.message && !!perkData.link_desc && !!perkData.website_link;

const WebsiteShowcase: React.FC<WebsiteShowcaseProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveWebsiteShowcasePerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  // todo: add error msg here
  const submitWebsiteShowcasePerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.WEBSITE_SHOWCASE}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.WEBSITE_SHOWCASE}--website-link`}
          title="Website Link*"
          value={perkData["website_link"] ?? ""}
          placeholder="Your company's home or recruiting page"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("website_link", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.WEBSITE_SHOWCASE}--link-description`}
          title="Description of Link*"
          value={perkData["link_desc"] ?? ""}
          placeholder="Ex. Careers page"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("link_desc", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.WEBSITE_SHOWCASE}--message`}
          title="Sponsor Message* (max 150 words)"
          value={perkData["message"] ?? ""}
          placeholder="Share a message with hackers"
          wordLimit={150}
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("message", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.WEBSITE_SHOWCASE}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveWebsiteShowcasePerk}
          onSubmit={submitWebsiteShowcasePerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.WEBSITE_SHOWCASE} error={error} />
    </>
  );
};

export default WebsiteShowcase;
