/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../../../api/types.generated";
export type GetTechTalkProposalsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetTechTalkProposalsQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<Types.Claim, "id"> & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetTechTalkProposalsDocument = gql`
  query GetTechTalkProposals {
    claims(
      where: {
        pipeline_id: { equals: 1431 }
        stage_id: { equals: 1480 }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "perk_type" } } } }
              { value: { equals: "tech_talk_proposal" } }
            ]
          }
        }
      }
    ) {
      id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetTechTalkProposalsQuery__
 *
 * To run a query within a React component, call `useGetTechTalkProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTechTalkProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTechTalkProposalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTechTalkProposalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetTechTalkProposalsQuery,
    GetTechTalkProposalsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetTechTalkProposalsQuery,
    GetTechTalkProposalsQueryVariables
  >(GetTechTalkProposalsDocument, baseOptions);
}
export function useGetTechTalkProposalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetTechTalkProposalsQuery,
    GetTechTalkProposalsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetTechTalkProposalsQuery,
    GetTechTalkProposalsQueryVariables
  >(GetTechTalkProposalsDocument, baseOptions);
}
export type GetTechTalkProposalsQueryHookResult = ReturnType<
  typeof useGetTechTalkProposalsQuery
>;
export type GetTechTalkProposalsLazyQueryHookResult = ReturnType<
  typeof useGetTechTalkProposalsLazyQuery
>;
export type GetTechTalkProposalsQueryResult = ApolloReactCommon.QueryResult<
  GetTechTalkProposalsQuery,
  GetTechTalkProposalsQueryVariables
>;
