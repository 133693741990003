import { Text, Flex, Spacer, JWTRole } from "@hackthenorth/north";
import { darken } from "polished";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useUserContext, useSponsorContext } from "src/shared/contexts";
import { LogoDarkSVG } from "src/static/img";
import { possessify } from "src/utils/string";
import * as Sponsor from "src/views/sponsor/types";

const VerticalLine = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.globalConstants.color.navySecondary};
  width: 2px;
  height: 40px;
`;

const CompanyLogo = styled.img`
  max-height: 32px;
  max-width: 100px;
`;

const TIER_COLORS = {
  [Sponsor.Tier.GOLD]: "mustardSecondary",
  [Sponsor.Tier.SILVER]: "#CDE8E8",
  [Sponsor.Tier.BRONZE]: "#C88015",
  [Sponsor.Tier.STARTUP]: "bluePrimary2",
};

const TierCircle = styled.div<{
  tier?: Sponsor.Tier;
}>`
  border-radius: 50%;
  background-color: ${({ theme, tier = "" }) =>
    theme.globalConstants.color[TIER_COLORS[tier]] ?? TIER_COLORS[tier]};
  width: 12px;
  height: 12px;
  margin-bottom: 1px;
`;

const TierText = styled(Text).attrs({ mods: "subtle" })<{
  tier?: Sponsor.Tier;
}>`
  color: ${({ theme, tier = "" }) =>
    darken(
      0.2,
      (theme.globalConstants.color[TIER_COLORS[tier]] ??
        TIER_COLORS[tier]) as string
    )} !important;
`;

export const useTopContent = () => {
  const { roles } = useUserContext();
  const { loading, company } = useSponsorContext();

  const websiteLogoSrc = useMemo(() => company?.logo?.uri, [company]);
  const companyTier = useMemo(() => company?.tier, [company]);
  const companyName = useMemo(() => company?.name, [company]);

  return useMemo(() => {
    if (roles?.includes(JWTRole.SPONSOR)) {
      return {
        // todo: smooth transition to loaded logo
        logo: (
          <Flex align="center" justify="center">
            <LogoDarkSVG width={32} />
            {websiteLogoSrc && (
              <>
                <Spacer width={16} />
                <VerticalLine />
                <Spacer width={16} />
                <CompanyLogo src={websiteLogoSrc} alt="Company name" />
              </>
            )}
          </Flex>
        ),
        title: (
          <Text mods="big center medium lh-regular">
            {companyName ? possessify(companyName) : ""}
            Sponsor Dashboard
          </Text>
        ),
        subtitle: loading ? null : (
          <Flex align="center" justify="center">
            {companyTier && (
              <>
                <TierCircle tier={companyTier} />
                <Spacer width={8} />
                <TierText tier={companyTier}>
                  {companyTier?.toUpperCase()}
                </TierText>
                <Spacer width={4} />
              </>
            )}
            <Text mods="subtle lightGrey">SPONSOR</Text>
          </Flex>
        ),
      };
    } else if (roles?.includes(JWTRole.HACKER)) {
      return {
        logo: <LogoDarkSVG width={32} />,
        title: <Text mods="big center medium">Hacker Dashboard</Text>,
        subtitle: null,
      };
    } else if (roles?.includes(JWTRole.MENTOR)) {
      return {
        logo: <LogoDarkSVG width={32} />,
        title: <Text mods="big center medium">Mentor Dashboard</Text>,
        subtitle: null,
      };
    } else {
      return {
        logo: <LogoDarkSVG width={32} />,
        title: <Text mods="big center medium">Hack the North Dashboard</Text>,
        subtitle: null,
      };
    }
  }, [roles, loading, companyTier, companyName, websiteLogoSrc]);
};
