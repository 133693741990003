import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { FittedImg, UnisexImg } from "src/static/img";

const LIGHTEST_GREY = "#EFF8FA";

const UNISEX_SIZES = [
  ["XS", '31" - 34"'],
  ["S", '35" - 37"'],
  ["M", '38" - 41"'],
  ["L", '42" - 45"'],
  ["XL", '46" - 49"'],
  ["2XL", '50" - 53"'],
];

const FITTED_SIZES = [
  ["S", "2 - 4"],
  ["M", "6 - 8"],
  ["L", "8 - 10"],
  ["XL", "10 - 14"],
];

const SIZE_TO_NAME = {
  XS: "Extra Small",
  S: "Small",
  M: "Medium",
  L: "Large",
  XL: "Extra Large",
  "2XL": "Double Extra Large",
};

const Chart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 413px;
  width: 391px;
  padding: 20px 14px;
  border: 1px solid #bdc0ca;
  margin-bottom: 16px;
`;

const ChartLabels = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    font-size: 16px;
  }
`;

const Row = styled.div<{ oddIndex: boolean }>`
  height: 36px;
  padding: 0px 12px;
  background-color: ${({ oddIndex }) => (oddIndex ? "white" : LIGHTEST_GREY)};
  width: 100%;
  display: flex;
  align-items: center;

  .size-symbol {
    font-weight: 700;
    margin-right: 8px;
    width: 36px;
  }

  .size-label {
    flex-grow: 2;
    font-weight: 400;
  }

  .size-number {
    font-weight: 500;
  }
`;

const FitImage = styled.img`
  width: auto;
  height: 62px;
`;

type TSizeChartProps = {
  unisex?: boolean;
};

const SizeChart: React.FC<TSizeChartProps> = ({ unisex }) => {
  const [imageSrc, sizeLabel, title, availableSizes] = unisex
    ? [UnisexImg, "Chest to Fit", "Unisex", UNISEX_SIZES]
    : [FittedImg, "Size (US women's)", "Fitted", FITTED_SIZES];

  return (
    <Chart>
      <Text mods="heading h2">{title}</Text>
      <FitImage src={imageSrc} alt={title} />
      <ChartLabels>
        <Text mods="heading h3">Available Sizes</Text>
        <Text mods="heading h3">{sizeLabel}</Text>
      </ChartLabels>
      {availableSizes.map((size, index) => (
        <Row oddIndex={index % 2 === 1} key={size[0]}>
          <Text className="size-symbol">{size[0]}</Text>
          <Text className="size-label">{SIZE_TO_NAME[size[0]]}</Text>
          <Text className="size-number">{size[1]}</Text>
        </Row>
      ))}
    </Chart>
  );
};

export default SizeChart;
