export type GettingStartedSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

const FIRST_TIME_HACKER_SHARE_ID = "3641f85c-0aed-47d6-a590-5e7c53a1f173";
const PLATFORMS_SHARE_ID = "ab770ee1-72c0-4a5d-8e91-b10cdede5e11";
const WORKSHOPS_SHARE_ID = "f17a422a-307c-4a42-841c-67837e97bcc7";
const ACTIVITIES_AND_MEETUPS_SHARE_ID = "e945240e-f6dc-4a77-b1bd-f5cc9d28775d";
const SPONSORS_SHARE_ID = "fd36d603-e402-4c96-bf92-aae2bdd6a50b";

export const GETTING_STARTED_SECTIONS: GettingStartedSection[] = [
  {
    label: "First Time Hacker",
    sectionId: "first-time-hacker",
    shareId: FIRST_TIME_HACKER_SHARE_ID,
  },
  {
    label: "Platforms",
    sectionId: "platforms",
    shareId: PLATFORMS_SHARE_ID,
  },
  {
    label: "Workshops",
    sectionId: "workshops",
    shareId: WORKSHOPS_SHARE_ID,
  },
  {
    label: "Activities and Meet-Ups",
    sectionId: "activities-and-meetups",
    shareId: ACTIVITIES_AND_MEETUPS_SHARE_ID,
  },
  {
    label: "Sponsors",
    sectionId: "sponsors",
    shareId: SPONSORS_SHARE_ID,
  },
];
