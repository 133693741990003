import { Card, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import * as SponsorPerks from "src/views/sponsor/perks/types";

export interface PerkContainerProps
  extends React.ComponentPropsWithoutRef<"div"> {
  id: SponsorPerks.Type;
  description: React.ReactNode;
  error?: boolean;
  readOnly?: boolean;
}

const PerkCard = styled(Card)<Pick<PerkContainerProps, "error" | "readOnly">>`
  padding: 48px;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  border-color: ${({ theme, error, readOnly }) =>
    theme.globalConstants.color[
      error && !readOnly ? "redSecondary" : "bluePrimary2"
    ]};
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.medium}px;
  background: ${({ theme }) => theme.globalConstants.color.white};
  filter: ${({ readOnly }) => (readOnly ? "brightness(95%)" : "none")};
`;

const PerkContainer: React.FC<PerkContainerProps> = ({
  id,
  description,
  children,
  error,
  readOnly,
  ...rest
}) => (
  <div id={id} {...rest}>
    <div id={`${id}--description`}>
      <Spacer height={16} />
      {description}
      <Spacer height={24} />
    </div>
    <PerkCard error={error} readOnly={readOnly}>
      {children}
    </PerkCard>
    <Spacer height={32} />
  </div>
);

export default PerkContainer;
