/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetClaimedRequestsQueryVariables = Types.Exact<{
  mentorId: Types.Scalars["String"];
  notifiedStageId: Types.Scalars["Int"];
  claimedStageId: Types.Scalars["Int"];
  resolvedStageId: Types.Scalars["Int"];
}>;

export type GetClaimedRequestsQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<
      Types.Claim,
      "id" | "name" | "stage_id" | "created_at"
    > & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetClaimedRequestsDocument = gql`
  query GetClaimedRequests(
    $mentorId: String!
    $notifiedStageId: Int!
    $claimedStageId: Int!
    $resolvedStageId: Int!
  ) {
    claims(
      orderBy: { updated_at: desc }
      where: {
        stage_id: { in: [$notifiedStageId, $claimedStageId, $resolvedStageId] }
        pipeline: { is: { slug: { equals: "hackthenorth2020-mentorship" } } }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "mentor_id" } } } }
              { value: { equals: $mentorId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
      created_at
    }
  }
`;

/**
 * __useGetClaimedRequestsQuery__
 *
 * To run a query within a React component, call `useGetClaimedRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClaimedRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClaimedRequestsQuery({
 *   variables: {
 *      mentorId: // value for 'mentorId'
 *      notifiedStageId: // value for 'notifiedStageId'
 *      claimedStageId: // value for 'claimedStageId'
 *      resolvedStageId: // value for 'resolvedStageId'
 *   },
 * });
 */
export function useGetClaimedRequestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >(GetClaimedRequestsDocument, baseOptions);
}
export function useGetClaimedRequestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetClaimedRequestsQuery,
    GetClaimedRequestsQueryVariables
  >(GetClaimedRequestsDocument, baseOptions);
}
export type GetClaimedRequestsQueryHookResult = ReturnType<
  typeof useGetClaimedRequestsQuery
>;
export type GetClaimedRequestsLazyQueryHookResult = ReturnType<
  typeof useGetClaimedRequestsLazyQuery
>;
export type GetClaimedRequestsQueryResult = ApolloReactCommon.QueryResult<
  GetClaimedRequestsQuery,
  GetClaimedRequestsQueryVariables
>;
