import { Text, Button } from "@hackthenorth/north";
import React, { useMemo } from "react";
import styled from "styled-components";

import { PageWrapper, Modal } from "src/shared/components";
import { useMilestonesContext, useUserContext } from "src/shared/contexts";
import { getFirstName } from "src/utils/string";

import LoggedInContent from "./LoggedInContent";
import LoggedOutContent from "./LoggedOutContent";

const HomeModal = styled(Modal)`
  width: 632px;
`;

const Home: React.FC = () => {
  const {
    onboarding,
    onboardingModal,
    completeMilestone,
  } = useMilestonesContext();
  const { info, isAuthenticated } = useUserContext();

  const isOpen: boolean = useMemo(
    () => !!onboarding && onboardingModal?.complete === false,
    [onboardingModal, onboarding]
  );

  const onModalDismiss = async () => {
    if (onboardingModal) await completeMilestone(onboardingModal.id);
  };

  const firstName = useMemo(() => getFirstName(info?.name), [info]);

  return (
    <>
      <HomeModal
        isOpen={isOpen && !onboarding?.complete}
        onClose={onModalDismiss}
        title={`Hello, ${firstName}!`}
        actions={
          <Button mods="primary" onClick={onModalDismiss}>
            Let&apos;s get started!
          </Button>
        }
      >
        <Text>
          Welcome to the Sponsor Dashboard! Once you complete onboarding, you
          will gain access to the rest of the dashboard. This is where you can
          find important dates, submit information for your perks, and gain
          access to perks as they become available.
        </Text>
      </HomeModal>
      <HomeModal
        isOpen={isOpen && !!onboarding?.complete}
        onClose={onModalDismiss}
        title="Onboarding Complete"
        actions={
          <Button mods="primary" onClick={onModalDismiss}>
            Let&apos;s go!
          </Button>
        }
      >
        <Text>Thank you for joining Hack the North 2020++!</Text>
      </HomeModal>
      <PageWrapper footer pageTitle="Home">
        {isAuthenticated ? <LoggedInContent /> : <LoggedOutContent />}
      </PageWrapper>
    </>
  );
};

export default Home;
