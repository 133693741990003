import { HackerAPIFile } from "src/shared/utils/hackerapi";

export const isImage = (file: HackerAPIFile) =>
  [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/svg",
    "jpg",
    "jpeg",
    "png",
    "svg",
  ].includes(file.type ?? file.name?.slice(-3) ?? "");

export const isPDF = (file: HackerAPIFile) =>
  ["application/pdf", "pdf"].includes(file.type ?? file.name?.slice(-3) ?? "");

export const isPreviewAvailable = (file: HackerAPIFile) =>
  isImage(file) || isPDF(file);
