import { Text, Link } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { SOCIALS, SocialPlatforms } from "src/shared/constants/social";
import { HourglassImg } from "src/static/img";

import Footnote from "./shared/footnote";

const Container = styled.div`
  max-width: 600px;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Img = styled.img`
  width: 600px;
  position: absolute;
  left: 500px;
  bottom: -60px;
  z-index: -1;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background: rgba(14, 52, 96, 0.2);
`;

export default () => {
  return (
    <OuterContainer>
      <Img src={HourglassImg} alt="" aria-hidden />
      <Container>
        <Text mods="hmobile heading">
          Thanks for applying!
          <br />
          You’ve been put on the waitlist{" "}
          <span role="img" aria-label="hourglass emoji">
            ⌛
          </span>
        </Text>
        <Text>
          Your application was impressive, but an overwhelming number of
          applications were sent in this year and we have decided to put you on
          our waitlist. This means that if a spot to attend as a hacker opens
          up, we will contact you with next steps.
        </Text>
        <br />
        <br />
        <Text>
          In the meantime, be sure to stay updated with all things Hack the
          North by following our{" "}
          <Link href={SOCIALS[SocialPlatforms.FACEBOOK]} newTab>
            Facebook
          </Link>
          ,{" "}
          <Link href={SOCIALS[SocialPlatforms.INSTA]} newTab>
            Instagram
          </Link>
          ,{" "}
          <Link href={SOCIALS[SocialPlatforms.LINKEDIN]} newTab>
            LinkedIn
          </Link>
          , and{" "}
          <Link href={SOCIALS[SocialPlatforms.TWITTER]} newTab>
            Twitter
          </Link>
          !
        </Text>
        <Separator />
      </Container>
      <Footnote />
    </OuterContainer>
  );
};
