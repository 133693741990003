import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import SidebarItem from "./SidebarItem";
import { SidebarItemDefinition } from "./useSidebarContent/sections";

export interface SidebarSectionProps {
  sectionTitle: string;
  sectionContent: SidebarItemDefinition[];
}

const SectionTitle = styled.div`
  display: inline-block;
  width: 100%;
  padding: 24px;

  text-transform: uppercase;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.borderGrey};

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const SidebarSection: React.FC<SidebarSectionProps> = ({
  sectionTitle,
  sectionContent,
}) => {
  return (
    <>
      <SectionTitle>
        <Text mods="subtle lightGrey">{sectionTitle}</Text>
      </SectionTitle>
      {sectionContent.map((item) => (
        <SidebarItem key={item.text} {...item} />
      ))}
    </>
  );
};

export default SidebarSection;
