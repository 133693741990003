export const formatTime = (time: Date) => {
  time = new Date(time);
  const day = time.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  return day;
};

export const getDaysTillEvent = () => {
  const eventDate = new Date("2021-01-15");
  const curDate = new Date();
  curDate.setHours(0, 0, 0, 0); // we don't need to compare the time of the dates

  const arrayOfDates = [];
  while (curDate < eventDate) {
    arrayOfDates.push(new Date(curDate));
    curDate.setDate(curDate.getDate() + 1);
  }
  return arrayOfDates;
};
