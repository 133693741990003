import React from "react";

import { Route } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts";

import HackerHome from "./index";

export const HackerHomeRoutes = () => (
  <Route
    path={BaseRoute.HOME}
    element={<HackerHome />}
    requirePermissions={[Permission.HACKER_DAY_OF_TOOLS_ACCESS]}
  />
);
