import { Text, Link as NorthLink } from "@hackthenorth/north";
import React from "react";

import { useUserContext } from "src/shared/contexts";

const LoggedOutContent = () => {
  const { logIn } = useUserContext();

  return (
    <Text mods="big">
      You&apos;re currently logged out.{" "}
      <NorthLink mods="big" onClick={logIn} href="#">
        Click here to log in
      </NorthLink>
      .
    </Text>
  );
};

export default LoggedOutContent;
