import { OptionTypeBase, OptionsType } from "react-select";

import { Nullable } from "src/utils/typescript";

const stringToOption = (answer: string) => {
  return { label: answer, value: answer };
};

export const answerToOption = (
  answer?: Nullable<string>
): Nullable<OptionTypeBase> => {
  return answer ? stringToOption(answer) : undefined;
};

export const answersToOptions = (
  answers: string[]
): OptionsType<OptionTypeBase> => answers.map(stringToOption);

export const optionsToAnswers = (options: OptionsType<OptionTypeBase>) =>
  options ? options.map((option) => option.value) : [];

export const timeToOption = (
  formatTime: (t: Date, endt?: Date | null) => string,
  time?: Date | null,
  endTime?: Date | null
) => {
  return time && time !== null
    ? {
        label: formatTime(time, endTime),
        value: time,
      }
    : undefined;
};

export const timesToOptions = (
  formatTime: (t: Date, endt?: Date | null) => string,
  times: Date[]
) => {
  return times.map((time) => timeToOption(formatTime, time));
};

export const timesToOptionsExplicit = (
  formatTime: (t: Date, endt?: Date | null) => string,
  times: Date[][]
) => {
  return times.map((timesSet) =>
    timeToOption(formatTime, timesSet[0], timesSet[1])
  );
};

export const formatSelectedOptions = (options: OptionsType<OptionTypeBase>) =>
  `${options.length} selected: ${options.map(({ label }) => label).join(", ")}`;

export type Options = OptionsType<OptionTypeBase>;

export type Option = OptionTypeBase;
