export const getLinkName = (link: string) => {
  if (link.includes("github.com")) return "Github";
  if (link.includes("linkedin.com")) return "LinkedIn";
  if (link.includes("behance.net")) return "Behance";
  if (link.includes("dribbble.com")) return "Dribbble";
  if (link.includes("devpost.com")) return "Devpost";
  return "Other";
};

export const getLinkUrl = (link: string) => {
  try {
    new URL(link);
    return link;
  } catch (err) {
    return `https://${link}`;
  }
};
