import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import { Tier } from "src/views/sponsor/types";

import { useGetSponsorCompaniesQuery } from "./graphql/getSponsorCompanies.generated";

export type SponsorSelectCompany = {
  id: number;
  name: string;
  tier: Tier;
  logo: HackerAPIFile;
};

export type SponsorSelectCompanies = {
  data: SponsorSelectCompany[];
  loading: boolean;
};

export const useSponsorCompanies = (tier?: Tier) => {
  const { data, loading } = useGetSponsorCompaniesQuery();

  const companyData = data?.claims
    .map((claim) => {
      const { id, name, fields } = claim;
      const logoAnswer = getFieldAnswer(fields, "logo");
      return {
        id,
        name,
        tier: getFieldAnswer(fields, "tier"),
        logo: logoAnswer ? logoAnswer[0].uri : null,
      };
    })
    .filter((company) => (tier ? company.tier === tier : true));

  return {
    data: companyData,
    loading,
  };
};

export default useSponsorCompanies;
