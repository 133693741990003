import {
  Spacer,
  Text,
  DropdownInput,
  Flex,
  Link,
  Spinner,
} from "@hackthenorth/north";
import React from "react";
import { OptionsType, OptionTypeBase } from "react-select";
import styled from "styled-components";

import { useGetFavoritedHackersQuery } from "src/api/hackers/getFavoritedHackers.generated";
import { Checkbox } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { useSponsorContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import {
  timeToOption,
  answerToOption,
  optionsToAnswers,
  formatSelectedOptions,
} from "src/shared/utils/react-select";
import { formatTime } from "src/utils/formatTime";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import { PerkActions, PerkContainer, PerkError } from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS, DURATION_OPTIONS } from "./constants";

const StyledCheckbox = styled(Checkbox)`
  border-width: 1px;
`;

interface CoffeeChatsProps {
  perk: SponsorPerks.CoffeeChats;
}

type StoredPerk = SponsorPerks.CoffeeChats;

const description = (
  <>
    <Text mods="medium">
      Coffee Chats will be 1:1 scheduled chats on Discord between
      representatives from your company and hackers who have indicated interest.
      During the event, each representative will be automatically grouped with
      hackers that you have selected here. There will be a system in place to
      view the queue and invite the next hacker to chat.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      At the scheduled start time of your Coffee Chats, your representatives
      will chat with hackers one by one until all chats finish. Note that
      hackers are essentially “lined up” for each representative to chat with,
      and they will receive an estimated time of when to be ready. For example,
      if your chats start at 3:00 PM and each chat is 10 minutes long, then the
      hacker in the 3rd position in line will expect to begin their chat at 3:30
      PM.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      We recommend your Coffee Chats to last no more than 1 and a half hours,
      since all chats need to be completed in one sitting. For example, if you
      are planning to have 10-minute chats and have 5 representatives
      participating, please select up to 45 hackers to chat with.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.start_time && !!perkData.duration;

const CoffeeChats: React.FC<CoffeeChatsProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { representatives, company } = useSponsorContext();
  const { data, loading: fetchingHackers } = useGetFavoritedHackersQuery({
    variables: {
      favoritedHackerIds:
        company?.favorited_hackers?.map((hackerId) => parseInt(hackerId)) ?? [],
    },
  });

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveCoffeeChatsPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitCoffeeChatsPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  const representativeOptions =
    representatives?.map((representative) => ({
      label: representative.name,
      value: representative.id,
    })) ?? [];

  const selectedRepresentatives = representativeOptions.filter(
    ({ value }) => value && perkData["representatives"]?.includes(value)
  );

  const hackerOptions =
    data?.claims?.map(({ id, fields }) => ({
      label: getFieldAnswer(fields, Field.FULL_NAME),
      value: id,
    })) ?? [];

  const selectedHackers = hackerOptions.filter(
    ({ value }) => value && perkData["hackers"]?.includes(value)
  );

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.COFFEE_CHATS}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Start time</Text>
        <Text mods="medium">What time will your Coffee Chats begin?</Text>
        <Spacer height={4} />
        <Text mods="italic subtle">
          Note that all times are in your browser&apos;s local time zone.
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTime, perkData["start_time"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select time"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("start_time", option.value)
          }
        />
        <Spacer height={32} />
        <Text mods="heading h3">Duration</Text>
        <Text mods="medium">How long will each Coffee Chat be?</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={answerToOption(perkData["duration"])}
          readOnly={isReadOnly}
          options={DURATION_OPTIONS}
          placeholder="Select duration"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("duration", option.value)
          }
        />
        <Spacer height={32} />
        <Text mods="heading h3">Representatives</Text>
        <Text mods="medium">
          Which representatives will be participating in Coffee Chats?
        </Text>
        <Spacer height={8} />
        <DropdownInput
          multi
          isHideDropdownIndicator={isReadOnly}
          value={selectedRepresentatives}
          readOnly={isReadOnly}
          options={representativeOptions}
          placeholder="Select sponsor representatives"
          onChange={(options: OptionsType<OptionTypeBase>) =>
            updatePerkField("representatives", optionsToAnswers(options))
          }
        />
        {selectedRepresentatives.length > 0 && (
          <>
            <Spacer height={4} />
            <Text mods="italic">
              {formatSelectedOptions(selectedRepresentatives)}
            </Text>
          </>
        )}
        <Spacer height={12} />
        <Flex>
          <StyledCheckbox
            disabled={isReadOnly}
            checked={
              selectedRepresentatives.length === representativeOptions.length &&
              representativeOptions.length !== 0
            }
            onChange={(value: boolean) =>
              updatePerkField(
                "representatives",
                value ? optionsToAnswers(representativeOptions) : []
              )
            }
          />
          <Text>Select all representatives</Text>
        </Flex>
        <Spacer height={32} />
        <Text mods="heading h3">Hackers</Text>
        <Text mods="medium">
          Which hackers would you like to talk with? Note: This list only shows
          starred hackers who have indicated interest in having a Coffee Chat
          with your company. To star a hacker, head to the{" "}
          <Link href={Route.SPONSOR_RECRUITMENT}>Recruitment page</Link>
        </Text>
        <Spacer height={8} />
        {fetchingHackers ? (
          <Spinner />
        ) : (
          <DropdownInput
            multi
            isHideDropdownIndicator={isReadOnly}
            value={selectedHackers}
            readOnly={isReadOnly}
            options={hackerOptions}
            placeholder="Select favorited hackers"
            onChange={(options: OptionsType<OptionTypeBase>) =>
              updatePerkField("hackers", optionsToAnswers(options))
            }
          />
        )}

        {selectedHackers.length > 0 && (
          <>
            <Spacer height={4} />
            <Text mods="italic">{formatSelectedOptions(selectedHackers)}</Text>
          </>
        )}
        <Spacer height={12} />
        <Flex>
          <StyledCheckbox
            disabled={isReadOnly}
            checked={
              selectedHackers.length === hackerOptions.length &&
              hackerOptions.length !== 0
            }
            onChange={(value: boolean) =>
              updatePerkField(
                "hackers",
                value ? optionsToAnswers(hackerOptions) : []
              )
            }
          />
          <Text>Select all hackers</Text>
        </Flex>
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.COFFEE_CHATS}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveCoffeeChatsPerk}
          onSubmit={submitCoffeeChatsPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.COFFEE_CHATS} error={error} />
    </>
  );
};

export default CoffeeChats;
