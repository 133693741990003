export type FAQSection = {
  label: string;
  sectionId: string;
  shareId: string;
};

const GENERAL_SHARE_ID = "4c4bff97-9488-471e-8e2c-3b4859927b48";
const SWAG_SHARE_ID = "d5f386b7-5a77-4c15-b183-ce5e23068ee9";
const ACITIVITIES_SHARE_ID = "683a9c8c-5178-4bb8-ac1c-7f97884dc7a5";
const MENTORSHIP_SHARE_ID = "d103a7ec-68fd-4f19-bc89-9d07593e8cf3";
const JUDGING_SHARE_ID = "826cc751-48f3-4347-a3ae-44eb246622ce";

export const FAQ_SECTIONS: FAQSection[] = [
  {
    label: "General",
    sectionId: "general",
    shareId: GENERAL_SHARE_ID,
  },
  {
    label: "Swag",
    sectionId: "swag",
    shareId: SWAG_SHARE_ID,
  },
  {
    label: "Activities",
    sectionId: "activities",
    shareId: ACITIVITIES_SHARE_ID,
  },
  {
    label: "Mentorship",
    sectionId: "mentorship",
    shareId: MENTORSHIP_SHARE_ID,
  },
  {
    label: "Judging",
    sectionId: "judging",
    shareId: JUDGING_SHARE_ID,
  },
];
