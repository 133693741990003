import { Spacer, Text, DropdownInput, Flex, Link } from "@hackthenorth/north";
import React from "react";
import { OptionsType, OptionTypeBase } from "react-select";
import styled from "styled-components";

import { Checkbox } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import { useSponsorContext } from "src/shared/contexts";
import {
  optionsToAnswers,
  formatSelectedOptions,
} from "src/shared/utils/react-select";
import { capitalize } from "src/utils/string";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import { availableReps } from "./utils";

const StyledCheckbox = styled(Checkbox)`
  border-width: 1px;
`;

interface HopinRepresentativesProps {
  perk: SponsorPerks.HopinRepresentatives;
}

type StoredPerk = SponsorPerks.HopinRepresentatives;

const description = (
  <>
    <Text mods="medium">
      Please select which representatives will be receiving access to our Hopin
      event. After submitting this perk, you should expect everyone to receive
      an invite email by January 8th, 2021.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      On Hopin, you will be able to customize your sponsor booth and profile -
      for more details, visit the{" "}
      <Link href={Route.SPONSOR_PLATFORM_ONBOARDING}>
        Platform Onboarding page
      </Link>
      .
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) => !!perkData.representatives;

const HopinRepresentatives: React.FC<HopinRepresentativesProps> = ({
  perk,
}) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { company, representatives } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveHopinRepsPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitHopinRepsPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  const representativeOptions =
    representatives?.map((representative) => ({
      label: representative.name,
      value: representative.id,
    })) ?? [];

  const selectedRepresentatives = representativeOptions.filter(
    ({ value }) => value && perkData["representatives"]?.includes(value)
  );

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.HOPIN_REPRESENTATIVES}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Representatives</Text>
        <Text mods="medium">
          As a {capitalize(company?.tier ?? "")} sponsor, you can have up to{" "}
          {availableReps(company?.tier)} representatives on Hopin. To add
          representatives to the sponsor dashboard, head to the{" "}
          <Link href={Route.SPONSOR_REPRESENTATIVES}>Representatives page</Link>
          .
        </Text>
        <Spacer height={8} />
        <DropdownInput
          multi
          isHideDropdownIndicator={isReadOnly}
          value={selectedRepresentatives}
          readOnly={isReadOnly}
          options={representativeOptions}
          placeholder="Select sponsor representatives"
          onChange={(options: OptionsType<OptionTypeBase>) =>
            updatePerkField("representatives", optionsToAnswers(options))
          }
        />
        {selectedRepresentatives.length > 0 && (
          <>
            <Spacer height={4} />
            <Text mods="italic">
              {formatSelectedOptions(selectedRepresentatives)}
            </Text>
          </>
        )}
        <Spacer height={12} />
        <Flex>
          <StyledCheckbox
            disabled={isReadOnly}
            checked={
              selectedRepresentatives.length === representativeOptions.length &&
              representativeOptions.length !== 0
            }
            onChange={(value: boolean) =>
              updatePerkField(
                "representatives",
                value ? optionsToAnswers(representativeOptions) : []
              )
            }
          />
          <Text>Select all representatives</Text>
        </Flex>
        <Spacer height={32} />
        <Text mods="medium">
          Optional: If you have some representatives who will only be on Hopin
          for a short period of time, or want to add a Hopin account that will
          be shared by multiple people, you can list those emails here. These
          accounts do not need to be added to your sponsor dashboard, and will
          not receive access to Discord.
        </Text>
        <Spacer height={16} />
        <Text mods="medium">
          Note: These emails also count towards your{" "}
          {availableReps(company?.tier)}-account limit.
        </Text>
        <Spacer height={16} />
        <PerkTextInput
          name={`${SponsorPerks.Type.API_WORKSHOP}--emails`}
          title=""
          value={perkData["emails"] ?? ""}
          placeholder="Comma-separated emails"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("emails", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.COFFEE_CHATS}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveHopinRepsPerk}
          onSubmit={submitHopinRepsPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.HOPIN_REPRESENTATIVES} error={error} />
    </>
  );
};

export default HopinRepresentatives;
