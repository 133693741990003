import {
  Flex,
  Checkbox,
  Text,
  Spacer,
  Button,
  Spinner,
} from "@hackthenorth/north";
import React from "react";

import { useMilestoneFlag } from "src/shared/contexts";
import * as Milestones from "src/shared/contexts/MilestonesContext/types";

export interface TCFormProps {
  termsAndConditions: Milestones.TCAccepted;
}

export const TCForm: React.FC<TCFormProps> = ({ termsAndConditions }) => {
  const { isComplete, onChange, onSubmit, loading, error } = useMilestoneFlag(
    termsAndConditions,
    "You must agree to the Terms and Conditions."
  );

  return termsAndConditions.complete ? (
    <Text mods="success">
      You&apos;ve agreed to the Terms and Conditions. Thank you!
    </Text>
  ) : (
    <>
      <Flex>
        <Checkbox checked={isComplete} onChange={onChange} />
        <Spacer width={16} />
        <Text>
          I agree to the Waiver of Liability, Rules, and Terms outlined above.
        </Text>
      </Flex>
      <Spacer height={64} />
      <Flex align="center">
        <Button mods="primary" disabled={loading} onClick={onSubmit}>
          Submit
        </Button>
        <Spacer width={16} />
        {loading && <Spinner />}
      </Flex>
      {error && (
        <>
          <Spacer height={16} />
          <Flex>
            <Text mods="error" className="terms-and-conditions--error">
              {error}
            </Text>
          </Flex>
        </>
      )}
    </>
  );
};
