const pad = (n: number) => {
  return n < 10 ? "0" + n : n;
};

export const addThirtyMinutes = (time: Date | null | undefined) => {
  if (time) {
    time = new Date(time);
    time.setMinutes(time.getMinutes() + 30);
    return time;
  }
  return null;
};

export const addTwoHours = (time: Date | null | undefined) => {
  if (time) {
    time = new Date(time);
    time.setHours(time.getHours() + 2);
    return time;
  }
  return null;
};

// Returns formatted time range in the browser's local timezone
// Defaults to a range of 1 hour
export const formatTimeRange = (time: Date, endTime?: Date | null) => {
  time = new Date(time);
  const day = time.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const hours = time.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  const hour = hours > 12 ? hours - 12 : hours;
  const minute = time.getMinutes();
  const startTime = `${hour}:${pad(minute)}`;

  if (!endTime) {
    endTime = new Date(time.getTime() + 60 * 60 * 1000); // Adds 1 hour
  }

  const endDate = new Date(endTime);
  const endHours = endDate.getHours();
  ampm = endHours >= 12 ? "PM" : "AM";
  const endHour = endHours > 12 ? endHours - 12 : endHours;
  const endMinutes = endDate.getMinutes();
  const formattedEndTime = `${endHour}:${pad(endMinutes)}`;

  return `${day}: ${startTime} - ${formattedEndTime} ${ampm}`;
};

// Returns formatted time in the browser's local timezone
// Single time, not a range
export const formatTime = (time: Date) => {
  time = new Date(time);
  const day = time.toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const hours = time.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hour = hours > 12 ? hours - 12 : hours;
  const minute = time.getMinutes();
  const startTime = `${hour}:${pad(minute)}`;

  return `${day} @ ${startTime} ${ampm}`;
};

// Returns a relative time
// now, 1 second ago, 15 seconds ago
// 1 minute ago, 2 minutes ago, 30 minutes ago
// 1 hour ago, 2:15 PM, 1:15 PM, 11:13 AM
// December 14, December 11
export const formatRelativeTime = (time: Date) => {
  time = new Date(time);
  const delta = +Date.now() - +time;
  const day = time.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
  if (delta >= 24 * 60 * 60 * 1000) {
    // Greater than a day ago
    return day;
  } else if (delta > 1.5 * 60 * 60 * 1000) {
    // Greater than 1:30 hours ago
    const hours = time.getHours();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hour = hours > 12 ? hours - 12 : hours;
    const minute = time.getMinutes();
    return `${hour}:${pad(minute)} ${ampm}`;
  } else if (delta > 0.75 * 60 * 60 * 1000) {
    // Greater than 45 minutes ago
    return "1 hour ago";
  } else if (delta >= 60 * 1000) {
    // Greater than or equal to 1 minute
    const minuteDelta = Math.floor(delta / (60 * 1000));
    return `${minuteDelta} minute${minuteDelta === 1 ? "" : "s"} ago`;
  } else if (delta >= 1000) {
    // Greater than or equal to 1 second
    const secondDelta = Math.floor(delta / 1000);
    return `${secondDelta} second${secondDelta === 1 ? "" : "s"} ago`;
  } else {
    return "now";
  }
};
