import { Spacer, Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import {
  Accordion,
  AccordionArrow,
  AccordionHeadingContainer,
  Link,
  Shimmer,
} from "src/shared/components";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import { Nullable } from "src/shared/utils/typescript";

export interface SpotlightPreviewProps {
  title: Nullable<string>;
  link: Nullable<string>;
  description: Nullable<string>;
  image: Nullable<HackerAPIFile>;
}

const ImagePreview = <Shimmer style={{ height: "100px" }} />;

const TitlePreview = <Shimmer />;

const DescPreview = <Shimmer />;

const StyledHeadingContainer = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
`;

const Preview: React.FC<SpotlightPreviewProps> = ({
  image,
  title,
  link,
  description,
}) => {
  return (
    <Accordion
      heading={
        <StyledHeadingContainer>
          <Text mods="bold">Preview</Text>
          <Spacer width={8} />
          <AccordionArrow />
        </StyledHeadingContainer>
      }
    >
      <Spacer height={8} />
      {image?.uri ? (
        <img src={image.uri} alt={image.name ?? ""} />
      ) : (
        ImagePreview
      )}
      <Spacer height={8} />
      <div>
        {title ? (
          <Link href={link ?? ""}>
            <Text mods="heading medium h3">{title}</Text>
          </Link>
        ) : (
          TitlePreview
        )}
      </div>
      {description ? <Text>{description}</Text> : DescPreview}
    </Accordion>
  );
};

export default Preview;
