import { Text } from "@hackthenorth/north";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { getShortDateString } from "src/utils/date";

import CongratsModal from "./modals/congratsModal";
import Footnote from "./shared/footnote";
import getSchoolImg from "./shared/getSchoolImg";
import Tasks from "./tasks/tasks";

const BoldSpan = styled.span`
  font-family: ${({ theme }) => theme.globalConstants.fontFamily.heading};
  font-size: ${({ theme }) => theme.globalConstants.fontSize.bodyBig}px;
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  display: inline-block;
  font-weight: 800;
`;

const TasksContainer = styled.div`
  margin: 44px 0 48px 0;
`;

const Container = styled.div`
  max-width: 750px;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Img = styled.img`
  width: 650px;
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: -1;
  margin-right: -70px;
`;

const ACCEPTED_FIELDS = [
  Field.SCHOOL,
  Field.DISPLAYED_MODAL,
  Field.RSVP_DEADLINE,
];

const DEFAULT_RSVP_DATE = new Date("2020-12-20");

export default () => {
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});
  const { updateResponses } = useHackerContext();

  const schoolImg = useMemo(() => getSchoolImg(responsesState[Field.SCHOOL]), [
    responsesState,
  ]);

  const rsvpDeadline = responsesState[Field.RSVP_DEADLINE]
    ? new Date(responsesState[Field.RSVP_DEADLINE] as string)
    : DEFAULT_RSVP_DATE;

  return (
    <OuterContainer>
      <Img src={schoolImg} alt="" aria-hidden />
      <Container>
        <CongratsModal
          isOpen={!responsesState[Field.DISPLAYED_MODAL]}
          onDismiss={() => updateResponses({ [Field.DISPLAYED_MODAL]: true })}
        />
        <Text mods="hmobile heading">
          You&apos;ve been accepted!{" "}
          <span role="img" aria-label="party emoji">
            🎉
          </span>{" "}
          Now what?
        </Text>
        <Text>
          We have reviewed your application and we’re thrilled to invite you to
          hack with us on January 15-17, 2021.
          <br />
          To reserve your spot, be sure to complete the following tasks before:{" "}
          <BoldSpan>{getShortDateString(rsvpDeadline)} at 11:59pm EST</BoldSpan>
          .
        </Text>
        <TasksContainer>
          <Tasks />
        </TasksContainer>
      </Container>
      <Footnote />
    </OuterContainer>
  );
};
