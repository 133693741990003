import { Text } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { UnstyledLink } from "src/shared/components";
import Icon from "src/shared/components/Icon";
import { PageStage } from "src/shared/contexts/HackerContext/types";
import { useDeviceSize } from "src/shared/hooks";

const STAGE_TO_LABEL = {
  [PageStage.NOT_STARTED]: "NOT STARTED",
  [PageStage.COMPLETED]: "COMPLETED",
};

interface TaskItemProps {
  title: string;
  description: string;
  status: PageStage;
  link: string;
}

const OuterContainer = styled.div`
  width: 100%;
`;

const Container = styled.div`
  background: ${({ theme }) => theme.globalConstants.color.white};
  padding: 24px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.halo};
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 250ms;

  &:hover {
    box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.dark};
    transform: scale(1.02);
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

const TextContainer = styled.div``;

const StatusLabel = styled.div<{ status: PageStage }>`
  background: ${({ theme, status }) =>
    status === PageStage.COMPLETED
      ? "#a4dbd1"
      : theme.globalConstants.color.greySecondary};
  padding: 1px 12px;
  border-radius: 10px;
  margin-left: 24px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin-left: 0;
  `};
`;

const TaskItem: React.FC<TaskItemProps> = ({
  title,
  description,
  status,
  link,
}) => {
  const isLargeMobile = useDeviceSize("largeMobile");

  return (
    <OuterContainer>
      <UnstyledLink href={link}>
        <Container>
          <TextContainer>
            <TopContainer>
              <Text mods="bold">{title}</Text>
              {!isLargeMobile && (
                <StatusLabel status={status}>
                  <Text mods="subtle">{STAGE_TO_LABEL[status]}</Text>
                </StatusLabel>
              )}
            </TopContainer>
            <Text>{description}</Text>
            {isLargeMobile && (
              <StatusLabel status={status}>
                <Text mods="subtle">{STAGE_TO_LABEL[status]}</Text>
              </StatusLabel>
            )}
          </TextContainer>
          <Icon name="chevron-side" />
        </Container>
      </UnstyledLink>
    </OuterContainer>
  );
};

export default TaskItem;
