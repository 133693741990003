import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { notNull } from "src/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.SHIRT_SIZE,
  Field.SHIRT_TYPE,
  Field.SWAG_PICKUP,
  Field.TECH_TALKS,
  Field.NETWORKING,
  Field.MENTORSHIP_INTEREST,
  Field.MENTORSHIP_TOPICS,
  Field.WICS_COFFEE_CHAT,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.SHIRT_TYPE]: notNull,
  [Field.SHIRT_SIZE]: notNull,
};
