import { Spacer, Text, Link } from "@hackthenorth/north";
import React from "react";

import { DateTextInput } from "src/shared/components";
import { validateDateString } from "src/utils/string";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import Preview from "./Preview";

interface NewsletterOpportunityProps {
  perk: SponsorPerks.NewsletterOpportunity;
}

type StoredPerk = SponsorPerks.NewsletterOpportunity;

const description = (
  <>
    <Text mods="medium">
      The Hack the North newsletter is sent out to hackers at the start of every
      month.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      All sponsors are eligible for one Opportunity each month. We ask that you
      share an opportunity our community can benefit from, including job
      openings, scholarships, conferences, etc.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please indicate in the description the opportunity type and the due date,
      if applicable.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Once your submission is included in the upcoming month&apos;s newsletter,
      you will see this perk under the Complete section. Please allow us at
      least a week following your submission for our team to review.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Check out our{" "}
      <Link
        newTab
        mods="medium"
        href="https://hackthenorth.com/sponsorship-newsletter-template.pdf"
      >
        {" "}
        sample newsletter template here
      </Link>{" "}
      to see an example of the layout.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.name &&
  !!perkData.link &&
  (!perkData.due_date ||
    (!!perkData.due_date && validateDateString(perkData.due_date)));

const NewsletterOpportunity: React.FC<NewsletterOpportunityProps> = ({
  perk,
}) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveNewsletterOpportunityPerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitNewsletterOpportunityPerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}--title`}
          title="Opportunity Type*"
          value={perkData["name"] ?? ""}
          placeholder="Job opening, scholarship, conference, fellowship, workshop, etc"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("name", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}--link`}
          title="Link*"
          value={perkData["link"] ?? ""}
          placeholder="A link to the opportunity"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("link", e.target.value)}
        />
        <Spacer height={32} />
        <Text
          mods="medium"
          className={`${SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}--due-date`}
        >
          Registration Due Date
        </Text>
        <Spacer height={8} />
        <DateTextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updatePerkField("due_date", e.target.value)
          }
          value={perkData["due_date"] ?? ""}
          readOnly={isReadOnly}
        />
        <Spacer height={48} />
        <Preview link={perkData["link"]} name={perkData["name"]} />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveNewsletterOpportunityPerk}
          onSubmit={submitNewsletterOpportunityPerk}
        />
      </PerkContainer>
      <PerkError
        name={SponsorPerks.Type.NEWSLETTER_OPPORTUNITY}
        error={error}
      />
    </>
  );
};

export default NewsletterOpportunity;
