import { Button, Text, Spacer } from "@hackthenorth/north";
import React from "react";

import Modal from "src/shared/components/Modal";

interface WithdrawModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title="Are you sure you don’t want to attend Hack the North 2020++?"
      actions={
        <>
          <Button mods="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Spacer width="20px" />
          <Button mods="primary" onClick={onConfirm}>
            Withdraw my RSVP
          </Button>
        </>
      }
    >
      <Text>
        Once you withdraw your application, you will be unable to attend Hack
        the North 2020++.
      </Text>
    </Modal>
  );
};

export default WithdrawModal;
