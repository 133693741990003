import {
  Text,
  Button,
  Modal as NorthModal,
  UnstyledButton,
} from "@hackthenorth/north";
import React, { useMemo } from "react";
import Confetti from "react-confetti";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { useUserContext } from "src/shared/contexts";
import {
  RedSmileyImg,
  BlueTriangleImg,
  GlassesRectangleImg,
} from "src/static/img";
import { getFirstName } from "src/utils/string";

interface CongratsModalProps {
  onDismiss: () => void;
  isOpen: boolean;
}

const XIconButton = styled(UnstyledButton)`
  position: absolute;
  top: 24px;
  right: 32px;
`;

const ModalContainer = styled(NorthModal)`
  background: linear-gradient(
      194.36deg,
      #fff6ee 29.28%,
      rgba(255, 255, 255, 0) 98.1%
    ),
    linear-gradient(286.07deg, #f4ac9e -4.03%, rgba(255, 255, 255, 0) 53.71%),
    linear-gradient(67.15deg, #43afde -7.09%, rgba(255, 255, 255, 0) 55.43%),
    #caeefd;
  padding: 48px;
  border-radius: 20px;
  position: relative;
  max-width: 600px;
  min-width: 400px;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > :not(:first-child) {
    margin-top: 18px;
  }
`;

const ArtContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  > :not(:first-child) {
    margin-left: 13px;
  }
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

const Img = styled.img`
  height: 72px;
`;

const CongratsModal: React.FC<CongratsModalProps> = ({ onDismiss, isOpen }) => {
  const { info } = useUserContext();
  const firstName = useMemo(() => getFirstName(info?.name), [info]);

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onDismiss}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <Confetti />
      <XIconButton onClick={onDismiss}>
        <Icon name="x" width="40px" height="40px" strokeWidth="1.2px" />
      </XIconButton>
      <Container>
        <ArtContainer>
          <Img src={BlueTriangleImg} alt="" aria-hidden />
          <Img src={GlassesRectangleImg} alt="" aria-hidden />
          <Img src={RedSmileyImg} alt="" aria-hidden />
        </ArtContainer>
        <CenteredText mods="heading h2">
          Congratulations {firstName}, you&apos;ve been accepted to Hack the
          North 2020++{" "}
          <span role="img" aria-label="party emoji">
            🎉
          </span>
        </CenteredText>
        <Button mods="primary" onClick={onDismiss}>
          RSVP your spot
        </Button>
      </Container>
    </ModalContainer>
  );
};

export default CongratsModal;
