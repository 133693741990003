/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetMilestonesQueryVariables = Types.Exact<{
  myId: Types.Scalars["Int"];
}>;

export type GetMilestonesQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<Types.Claim, "id" | "name" | "stage_id"> & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetMilestonesDocument = gql`
  query GetMilestones($myId: Int!) {
    claims(
      where: {
        AND: [
          {
            pipeline: {
              is: { slug: { equals: "hackthenorth2020-milestones" } }
            }
          }
          {
            OR: [
              { collaborators: { some: { user_id: { equals: $myId } } } }
              { user_id: { equals: $myId } }
            ]
          }
        ]
      }
    ) {
      id
      name
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetMilestonesQuery__
 *
 * To run a query within a React component, call `useGetMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMilestonesQuery({
 *   variables: {
 *      myId: // value for 'myId'
 *   },
 * });
 */
export function useGetMilestonesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >(GetMilestonesDocument, baseOptions);
}
export function useGetMilestonesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetMilestonesQuery,
    GetMilestonesQueryVariables
  >(GetMilestonesDocument, baseOptions);
}
export type GetMilestonesQueryHookResult = ReturnType<
  typeof useGetMilestonesQuery
>;
export type GetMilestonesLazyQueryHookResult = ReturnType<
  typeof useGetMilestonesLazyQuery
>;
export type GetMilestonesQueryResult = ApolloReactCommon.QueryResult<
  GetMilestonesQuery,
  GetMilestonesQueryVariables
>;
