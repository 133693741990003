import { Field, TAnswer } from "src/shared/contexts/HackerContext/types";
import { notNull, notBlank, isPhoneNumber } from "src/utils/validation";

export const ACCEPTED_FIELDS = [
  Field.LEGAL_NAME,
  Field.PREFERRED_NAME,
  Field.PHONE_NUMBER,
  Field.LOCATION,
  Field.ADDRESS_LINE_ONE,
  Field.ADDRESS_LINE_TWO,
  Field.CITY,
  Field.PROVINCE_STATE,
  Field.COUNTRY,
  Field.POSTAL_CODE,
  Field.TERMS_CONDITIONS,
  Field.RECORDING_CONSENT,
  Field.SHARING_OPT_OUT,
];

export const VALIDATORS: Partial<Record<Field, (value: TAnswer) => boolean>> = {
  [Field.LEGAL_NAME]: notBlank,
  [Field.PHONE_NUMBER]: isPhoneNumber,
  [Field.ADDRESS_LINE_ONE]: notBlank,
  [Field.CITY]: notBlank,
  [Field.PROVINCE_STATE]: notBlank,
  [Field.COUNTRY]: notBlank,
  [Field.POSTAL_CODE]: notBlank,
  [Field.TERMS_CONDITIONS]: notNull,
  [Field.RECORDING_CONSENT]: notNull,
  [Field.SHARING_OPT_OUT]: notNull,
};
