import { Text } from "@hackthenorth/north";
import React, { useMemo } from "react";
import styled from "styled-components";

import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";

import Footnote from "./shared/footnote";
import getSchoolImg from "./shared/getSchoolImg";

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background: rgba(14, 52, 96, 0.2);
`;

const Container = styled.div`
  max-width: 750px;
`;

const OuterContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const Img = styled.img`
  width: 650px;
  position: absolute;
  right: 0;
  bottom: -190px;
  margin-right: -70px;
  z-index: -1;
`;

const ACCEPTED_FIELDS = [Field.SCHOOL];

export default () => {
  const { responsesState } = useHackerState(ACCEPTED_FIELDS, {});

  const schoolImg = useMemo(() => getSchoolImg(responsesState[Field.SCHOOL]), [
    responsesState,
  ]);

  return (
    <OuterContainer>
      <Img src={schoolImg} alt="" aria-hidden />
      <Container>
        <Text mods="hmobile heading">
          You’ve withdrawn your spot for Hack the North 2020++
        </Text>
        <Text>
          It’s sad to see you leave! Nonetheless, thank you for applying and for
          letting us know so that another hacker can come to the event{" "}
          <span role="img" aria-label="smiley emoji">
            🙂
          </span>
        </Text>
        <Separator />
      </Container>
      <Footnote />
    </OuterContainer>
  );
};
