import { Spacer, Text } from "@hackthenorth/north";
import React, { useState, useCallback, useRef } from "react";

import { Dropzone, DropzoneError, FileLink } from "src/shared/components";
import { useGetFiles } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import { HackerAPIFile } from "src/shared/utils/hackerapi";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

interface SocialMediaAssetsProps {
  perk: SponsorPerks.SocialMediaAssets;
}

type StoredPerk = SponsorPerks.SocialMediaAssets;

const description = (
  <>
    <Text mods="medium" className="social-media-assets-description-1">
      The social media takeover will highlight your company on our Instagram and
      Facebook. This includes:
      <ul>
        <li>
          Ten 15-second clips (filmed vertically using a phone) highlighted as
          stories, available on Instagram and Facebook - these will be posted in
          one takeover day dedicated to your company, and will be available for
          24 hours
        </li>
        <li>
          A post on our Instagram after the takeover with links to your
          resources (permanent post)
        </li>
      </ul>
    </Text>

    <Text mods="medium" className="social-media-assets-description-2">
      Here are some suggestions for the stories:
      <ul>
        <li>Introduction (name, position, etc.)</li>
        <li>Company intro</li>
        <li>Things your company is doing at Hack the North</li>
        <li>Resources and tips for students</li>
        <li>Q&A</li>
      </ul>
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.title_one &&
  !!perkData.website_link_one &&
  !!perkData.files &&
  !!perkData.files.length &&
  perkData.files.length >= 1 &&
  perkData.files.length <= 10;

const SocialMediaAssets: React.FC<SocialMediaAssetsProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const {
    uploadFiles,
    mutationResult: { loading: isFileUploading, error: fileUploadError },
  } = useUploadFile();
  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState("");
  const dropzoneInputRef = useRef<HTMLInputElement>();
  const fetchedFiles = useGetFiles(perkData.files ?? []);

  const error =
    errorMessage || dropzoneErrorMessage || fileUploadError?.message;
  const loading = isLoading || isUpdating || isFileUploading;

  const addFiles = async (files: File[]) => {
    const hapiFiles = await uploadFiles(files);
    const nonEmptyFiles = hapiFiles.filter((file) => !!file) as HackerAPIFile[];
    const newFiles = (perkData?.files ?? []).concat(nonEmptyFiles);

    if (newFiles.length > 10)
      setDropzoneErrorMessage("Please add at most 10 files.");

    updatePerkField("files", newFiles);
  };

  const deleteFile = (index: number) => {
    updatePerkField(
      "files",
      perkData.files?.filter((_item, i) => i !== index)
    );
    if (dropzoneInputRef.current) dropzoneInputRef.current.value = "";
  };

  const onFileError = useCallback((error: DropzoneError) => {
    switch (error) {
      case DropzoneError.MIN_AMOUNT:
        setDropzoneErrorMessage("Please add at least 1 file.");
        return;
      case DropzoneError.MAX_AMOUNT:
        setDropzoneErrorMessage("Please add at most 10 files.");
        return;
      case DropzoneError.INVALID_FORMAT:
        setDropzoneErrorMessage(
          "Please add a valid file format (MP4, WEBM, OGG or FLAC)."
        );
        return;
      default:
        setDropzoneErrorMessage("An error has occurred.");
    }
  }, []);

  const saveSocialMediaAssetsPerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitSocialMediaAssetsPerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}
        error={!!error}
        readOnly={isReadOnly}
      >
        <Text mods="medium" className="social-media-assets-description-3">
          Please upload your content for your social media takeover:
        </Text>
        <Spacer height={24} />
        {!isReadOnly && (
          <Dropzone
            min={1}
            max={10}
            acceptedFileTypes={["flac", "mp4", "ogg", "webm"]}
            onFilesUpload={addFiles}
            onError={onFileError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFiles &&
          fetchedFiles.map((file, i) => (
            <>
              <Spacer height={32} />
              <FileLink file={file} deleteFile={() => deleteFile(i)} />
            </>
          ))}
        <Spacer height={32} />
        <Text mods="medium" className="social-media-assets-description-4">
          You can also provide resource links (career opportunities, blog posts,
          etc.):
        </Text>
        <Spacer height={24} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--title_one`}
          title="Link title*"
          value={perkData["title_one"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title_one", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--website_link_one`}
          title="Website link*"
          value={perkData["website_link_one"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("website_link_one", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--title_two`}
          title="Link title"
          value={perkData["title_two"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title_two", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--website_link_two`}
          title="Website link"
          value={perkData["website_link_two"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("website_link_two", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--title_three`}
          title="Link title"
          value={perkData["title_three"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title_three", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}--website_link_three`}
          title="Website link"
          value={perkData["website_link_three"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updatePerkField("website_link_three", e.target.value)
          }
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.SOCIAL_MEDIA_ASSETS}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveSocialMediaAssetsPerk}
          onSubmit={submitSocialMediaAssetsPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.SOCIAL_MEDIA_ASSETS} error={error} />
    </>
  );
};

export default SocialMediaAssets;
