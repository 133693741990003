import { Spacer, Text, Flex, Link } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Checkbox } from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import { videoLength } from "./utils";

const StyledCheckbox = styled(Checkbox)`
  border-width: 1px;
`;

interface StageTimeProps {
  perk: SponsorPerks.StageTime;
}

type StoredPerk = SponsorPerks.StageTime;

const description = (
  <Text mods="medium" className="stage-time-description">
    Please submit your opening ceremonies video (either by uploading a file or
    providing a link to a video). We really appreciate you spending the time
    beforehand to make our event smoother for our hackers. We have provided some
    best practices below to guide you in creating your video.
  </Text>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.uploaded || !!perkData.video_link;

const StageTime: React.FC<StageTimeProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { company } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveStageTimePerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitStageTimePerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.STAGE_TIME}
        error={!!error}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Content Guidelines</Text>
        <Text mods="medium">
          We want to get hackers excited about the hackathon and all the awesome
          content we prepared alongside our sponsors. Opening ceremonies is the
          first event where we gather all of the hackers. You can use this as an
          opportunity to promote your Discord channel, Hopin booth, or other
          programming! Don&apos;t feel pressured to produce a professional
          quality video; it can be conversational and filmed from wherever
          you&apos;re located.
        </Text>
        <Spacer height={16} />
        <Text mods="heading h3">Filming Guidelines</Text>
        <Text mods="medium">
          <ul>
            <li>Landscape mode, ideally 16:9 aspect ratio</li>
            <li>Natural or bright indoor lighting</li>
            <li>Video resolution of 720p or higher</li>
            <li>Mobile device or camera recommended for quality</li>
            <li>
              Feel free to wear company swag or insert graphics as you see fit
            </li>
            <li>Time constraint: {videoLength(company?.tier)}</li>
          </ul>
        </Text>
        <Spacer height={8} />
        <Text mods="heading h3">Example Video</Text>
        <Text mods="medium">
          Check out an example video filmed by one of our organizers!{" "}
          <Link href="https://youtu.be/nk_jdLd-NKI" target="_blank">
            https://youtu.be/nk_jdLd-NKI
          </Link>
        </Text>
        <Spacer height={16} />
        <Text mods="heading h3">File Upload</Text>
        <Text mods="medium">
          If you would like to upload a video from your computer, please head to
          the following link. It will be uploaded directly to our Dropbox!{" "}
          <Link
            href="https://www.dropbox.com/request/6I7R4Qtmm9V3jfyAWqAC"
            target="_blank"
          >
            https://www.dropbox.com/request/6I7R4Qtmm9V3jfyAWqAC
          </Link>
        </Text>
        <Spacer height={12} />
        <Flex>
          <StyledCheckbox
            disabled={isReadOnly}
            checked={perkData["uploaded"] === true}
            onChange={(value: boolean) => updatePerkField("uploaded", value)}
          />
          <Text>A video has been uploaded.</Text>
        </Flex>
        <Spacer height={16} />
        <Text mods="heading h3">File Link</Text>
        <Text mods="medium">You can also provide a link to a video here:</Text>
        <PerkTextInput
          name={`${SponsorPerks.Type.API_WORKSHOP}--video_link`}
          title=""
          value={perkData["video_link"] ?? ""}
          placeholder="Link to pre-recorded video (YouTube, Dropbox, Google Drive, etc.)"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("video_link", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.STAGE_TIME}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveStageTimePerk}
          onSubmit={submitStageTimePerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.STAGE_TIME} error={error} />
    </>
  );
};

export default StageTime;
