import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";
import { useMilestonesContext } from "src/shared/contexts";

import { COCForm } from "./Form";

const SHARE_ID = "8881b82d-2ef4-485a-b720-7e135d091f6e";

const CodeOfConduct: React.FC = () => {
  const { codeOfConduct } = useMilestonesContext();

  return (
    <PageWrapper>
      <OutlineDocument shareId={SHARE_ID} />

      {codeOfConduct && <COCForm codeOfConduct={codeOfConduct} />}
    </PageWrapper>
  );
};
export default CodeOfConduct;
