import React from "react";
import styled from "styled-components";

import Icon, { IconProps } from "src/shared/components/Icon";

import { useAccordionContext } from "./context";

export interface ArrowProps extends Omit<IconProps, "name"> {}

const ArrowContainer = styled.div<{ isOpen: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
  svg {
    transition: transform 100ms;
    transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
  }
`;

const Arrow: React.FC<ArrowProps> = ({ className, ...rest }) => {
  const { isOpen } = useAccordionContext();

  return (
    <ArrowContainer isOpen={isOpen} className={className}>
      <Icon name="chevron" {...rest} />
    </ArrowContainer>
  );
};

export default Arrow;
