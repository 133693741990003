/* ============================= */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import { HackerFragmentFragmentDoc } from "../../shared/contexts/HackerContext/graphql/hackerFragment.generated";
import { HackerFragmentFragment } from "../../shared/contexts/HackerContext/graphql/hackerFragment.generated";
import * as Types from "../types.generated";
export type GetHackersQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Array<Types.FieldAnswerWhereInput>>;
  skip: Types.Scalars["Int"];
  take: Types.Scalars["Int"];
}>;

export type GetHackersQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & {
      user: { __typename?: "User" } & Pick<Types.User, "email">;
    } & HackerFragmentFragment
  >;
};

export const GetHackersDocument = gql`
  query GetHackers(
    $filters: [FieldAnswerWhereInput!]
    $skip: Int!
    $take: Int!
  ) {
    claims(
      where: {
        AND: [
          {
            pipeline: {
              is: { slug: { equals: "hackthenorth2020-applications" } }
            }
          }
          {
            OR: [{ stage_id: { equals: 1514 } }, { stage_id: { equals: 1515 } }]
          }
          {
            user: {
              is: {
                event_attendees: {
                  some: {
                    role: { equals: "hacker" }
                    AND: {
                      event: { is: { slug: { equals: "hackthenorth2020" } } }
                    }
                  }
                }
              }
            }
          }
          { field_answers: { some: { AND: $filters } } }
        ]
      }
      skip: $skip
      take: $take
    ) {
      ...hackerFragment
      user {
        email
      }
    }
  }
  ${HackerFragmentFragmentDoc}
`;

/**
 * __useGetHackersQuery__
 *
 * To run a query within a React component, call `useGetHackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHackersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetHackersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHackersQuery,
    GetHackersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetHackersQuery, GetHackersQueryVariables>(
    GetHackersDocument,
    baseOptions
  );
}
export function useGetHackersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHackersQuery,
    GetHackersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetHackersQuery,
    GetHackersQueryVariables
  >(GetHackersDocument, baseOptions);
}
export type GetHackersQueryHookResult = ReturnType<typeof useGetHackersQuery>;
export type GetHackersLazyQueryHookResult = ReturnType<
  typeof useGetHackersLazyQuery
>;
export type GetHackersQueryResult = ApolloReactCommon.QueryResult<
  GetHackersQuery,
  GetHackersQueryVariables
>;
