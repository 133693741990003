/* eslint-disable @typescript-eslint/camelcase */
import {
  TextInput,
  Text,
  Spacer,
  Link,
  Spinner,
  Flex,
  Button,
} from "@hackthenorth/north";
import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import { PageWrapper, Icon } from "src/shared/components";
import {
  Accordion,
  AccordionHeadingContainer,
  AccordionArrow,
} from "src/shared/components";
import {
  MENTORSHIP_REQUEST_STAGE_ID_CLAIMED,
  MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED,
  MENTORSHIP_REQUEST_STAGE_ID_RESOLVED,
} from "src/shared/constants/mentorship";
import { useMentorContext, useUserContext } from "src/shared/contexts";
import { useGetClaimedRequestsQuery } from "src/shared/contexts/MentorContext/graphql/getClaimedRequests.generated";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";

import ClaimedRequestCard from "./ClaimedRequestCard";

const StyledTextInput = styled(TextInput)`
  width: 616px !important;
  margin-top: 12px;
`;

const RequestContainer = styled.div`
  margin-top: 24px;
`;

const StyledAccordionArrow = styled(AccordionArrow)`
  margin-bottom: 0.2em;
`;

const AccordionHeading = styled(AccordionHeadingContainer)`
  display: flex;
  align-items: center;
  height: 100%;

  &:focus {
    outline: none;
    transform: ${({ disabled }) => (disabled ? `none` : `translateX(8px)`)};
  }
`;

const RefreshButton = styled(Button)`
  && {
    border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
    border: 1px solid
      ${({ theme }) => theme.globalConstants.color.navySecondary};
    padding: 2px 10px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary3};
  }

  margin-left: 12px;
`;

const RefreshText = styled(Text)`
  padding-left: 6px;
`;

const ClaimedRequests: React.FC = () => {
  const { updateRequest } = useMentorContext();
  const { id } = useUserContext();
  const { data: requestData, loading, refetch } = useGetClaimedRequestsQuery({
    variables: {
      mentorId: id ? id.toString() : "",
      claimedStageId: MENTORSHIP_REQUEST_STAGE_ID_CLAIMED,
      notifiedStageId: MENTORSHIP_REQUEST_STAGE_ID_NOTIFIED,
      resolvedStageId: MENTORSHIP_REQUEST_STAGE_ID_RESOLVED,
    },
  });

  const [query, setQuery] = useState<string | undefined>(undefined);
  const [displayedRequests, setDisplayedRequests] = useState<
    MentorshipRequests.Request[]
  >([]);
  const [displayedClaimedRequests, setDisplayedClaimedRequests] = useState<
    MentorshipRequests.Request[]
  >([]);
  const [displayedResolvedRequests, setDisplayedResolvedRequests] = useState<
    MentorshipRequests.Request[]
  >([]);

  const resolveRequest = useCallback(
    (request: MentorshipRequests.Request) => {
      request.stage = "RESOLVED";
      updateRequest(request);
      refetch();
    },
    [updateRequest, refetch]
  );

  const unclaimRequest = useCallback(
    (request: MentorshipRequests.Request) => {
      request.stage = "OPEN";
      request.reopened = true;
      updateRequest(request);
      refetch();
    },
    [updateRequest, refetch]
  );

  useEffect(() => {
    const claimedRequests = (requestData?.claims || []).map((request) => ({
      id: request.id,
      title: getFieldAnswer(request.fields, "title"),
      description: getFieldAnswer(request.fields, "description"),
      category: getFieldAnswer(request.fields, "category"),
      priority: getFieldAnswer(request.fields, "priority"),
      created_at: request.created_at,
      reopened: getFieldAnswer(request.fields, "reopened"),
      mentee_name: getFieldAnswer(request.fields, "mentee_name"),
      mentee_id: getFieldAnswer(request.fields, "mentee_id"),
      mentor_id: getFieldAnswer(request.fields, "mentor_id"),
      stage: MentorshipRequests.Stage[request.stage_id],
      mentee_discord_id: getFieldAnswer(request.fields, "mentee_discord_tag"),
    }));
    if (query && requestData) {
      const requests = claimedRequests.filter(
        (request: MentorshipRequests.Request) =>
          request.title?.toLowerCase().includes(query) ||
          request.description?.toLowerCase().includes(query) ||
          request.mentee_name?.toLowerCase().includes(query)
      );
      setDisplayedRequests(requests);
    } else {
      setDisplayedRequests(claimedRequests);
    }
  }, [requestData, query]);

  useEffect(() => {
    console.log(displayedRequests);
    const claimedRequests = displayedRequests.filter(
      (request) => request.stage === "CLAIMED" || request.stage === "NOTIFIED"
    );
    const resolvedRequests = displayedRequests.filter(
      (request) => request.stage === "RESOLVED"
    );
    setDisplayedClaimedRequests(claimedRequests);
    setDisplayedResolvedRequests(resolvedRequests);
  }, [displayedRequests]);

  return (
    <PageWrapper>
      <Accordion
        heading={
          <AccordionHeading>
            <Text mods="h3 heading">Can&apos;t find your mentee?</Text>
            <StyledAccordionArrow />
          </AccordionHeading>
        }
      >
        <Text>
          Look up their Discord tag by Discord ID using{" "}
          <Link href="https://discord.id/" target="_blank">
            this site
          </Link>
          . If their Discord ID is not available in the request card, contact
          Help Desk and they will help you find them.
        </Text>
        <Spacer height={"12px"} />
      </Accordion>
      <Flex align="center" justify="start">
        <Text mods="h2 heading nowrap">Your requests</Text>
        <RefreshButton onClick={() => refetch()}>
          <Flex align="center">
            <Icon name="refresh" />
            <RefreshText mods="big darkBlue nowrap">Refresh List</RefreshText>
          </Flex>
        </RefreshButton>
      </Flex>
      <StyledTextInput
        placeholder="Filter by title, description, or name..."
        value={query}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setQuery(e.target.value);
        }}
      />
      <RequestContainer>
        {loading && <Spinner />}
        <Text mods="h3 heading nowrap">Claimed requests</Text>
        {displayedClaimedRequests.map((request) => (
          <div key={request.id}>
            <ClaimedRequestCard
              request={request}
              key={request.id}
              onResolve={resolveRequest}
              onUnclaim={unclaimRequest}
              showActions
            />
          </div>
        ))}
        <Text mods="h3 heading nowrap">Resolved requests</Text>
        {displayedResolvedRequests.map((request) => (
          <div key={request.id}>
            <ClaimedRequestCard
              request={request}
              key={request.id}
              onResolve={resolveRequest}
              onUnclaim={unclaimRequest}
            />
          </div>
        ))}
      </RequestContainer>
    </PageWrapper>
  );
};

export default ClaimedRequests;
