/* eslint-disable @typescript-eslint/camelcase */
import { Flex, Text } from "@hackthenorth/north";
import React, { useCallback, useState, useMemo } from "react";
import { CellProps } from "react-table";
import { useTable, useSortBy } from "react-table";
import styled, { css } from "styled-components";

import { PRIORITY_TO_LABEL } from "src/shared/constants/mentorship";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { formatRelativeTime } from "src/utils/formatTime";

import Arrow from "./Arrow";
import Categories from "./Categories";
import { ClaimRequestModal } from "./ClaimRequestModal";
import InfoTag from "./InfoTag";

// Interface required to fix type errors
// https://github.com/tannerlinsley/react-table/discussions/2664#discussioncomment-92392
export interface RequestsTableHeaders extends MentorshipRequests.Request {
  claim: string;
  view_more: string;
}

interface RequestsTableProps {
  data: RequestsTableHeaders[];
  loading: boolean;
  error?: string;
  onRefresh: () => void;
}

// Makes the table scrollable when too small
const TableWrapper = styled.div`
  display: block;
  height: 100%;
  max-width: 100%;
  overflow: auto;
`;

const Table = styled.table`
  border-spacing: 0;
`;

const tableCellStyles = css`
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.greySecondary};

  &:first-child {
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  /* Make table full-width - each cell should grow equally */
  /* https://react-table.tanstack.com/docs/examples/full-width-table */
  width: 1%;
  /* Make collapsed cells as small as possible, so only some columns grow */
  &.collapse {
    width: 0.0000000001%;
  }
`;

const TableHeader = styled.th<{ minWidth?: number }>`
  ${tableCellStyles}
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  text-transform: uppercase;
  text-align: left;
  min-width: ${({ minWidth }) => minWidth ?? 0}px;
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.greySecondary};
  /* Make the table header fixed at the top */
  position: sticky;
  top: 0;
`;

const TableData = styled.td`
  ${tableCellStyles}
`;

const TableRow = styled.tr`
  background: ${({ theme }) => theme.globalConstants.color.white};
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.greySecondary};

  &:last-child ${TableData} {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary3Light};
    cursor: pointer;
  }
`;

const FlexWrapper = styled(Flex)`
  overflow: hidden;
`;

const CategoryWrapper = styled(Categories)`
  overflow: hidden;
  max-height: 55px; /* Magic number to limit the categories to 2 rows */
`;

const OverflowText = styled.div`
  overflow: hidden;
  max-height: 55px; /* Magic number to limit the categories to 2 rows */
`;

const RequestsTable: React.FC<RequestsTableProps> = ({ data, onRefresh }) => {
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<
    MentorshipRequests.Request | undefined
  >(undefined);

  const viewRequest = useCallback((data: MentorshipRequests.Request) => {
    setCurrentRequest(data);
    setIsViewModalOpen(true);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Subject",
        accessor: "title" as keyof RequestsTableHeaders,
        disableSortBy: true,
        minWidth: 500,
        Cell: ({ cell: { value } }: CellProps<RequestsTableHeaders>) => (
          <OverflowText>
            <Text mods="medium big lh-small darkBlue">{value}</Text>
          </OverflowText>
        ),
      },
      {
        Header: "Created",
        accessor: "created_at" as keyof RequestsTableHeaders,
        sortType: "datetime",
        sortInverted: true, // Sort inverted so oldest requests are seen as highest
        collapse: true, // Don't grow in size
        minWidth: 150,
        Cell: ({ cell: { value } }: CellProps<RequestsTableHeaders>) => (
          <Text mods="medium nowrap darkBlue">{formatRelativeTime(value)}</Text>
        ),
      },
      {
        Header: "Categories",
        accessor: "category" as keyof RequestsTableHeaders,
        disableSortBy: true,
        collapse: true, // Don't grow in size
        minWidth: 300,
        Cell: ({ cell: { value } }: CellProps<RequestsTableHeaders>) => (
          <CategoryWrapper categories={value as string[]} />
        ),
      },
      {
        Header: "Priority",
        accessor: "priority" as keyof RequestsTableHeaders,
        collapse: true, // Don't grow in size
        minWidth: 120,
        Cell: ({ cell: { value } }: CellProps<RequestsTableHeaders>) => (
          <Flex>
            <InfoTag>{PRIORITY_TO_LABEL[value]}</InfoTag>
          </Flex>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable<RequestsTableHeaders>(
    {
      columns,
      data,
      initialState: {
        // Initially sort with oldest requests first
        sortBy: [
          { id: "created_at", desc: true },
          { id: "priority", desc: true },
        ],
      },
      disableSortRemove: true, // Always sort by something
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      <ClaimRequestModal
        isOpen={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
          onRefresh();
        }}
        request={currentRequest}
      />

      <TableWrapper>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              // Destructure key out of props and pass it manually to avoid type errors
              // https://github.com/tannerlinsley/react-table/discussions/2647#discussioncomment-57580
              const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
              return (
                <TableRow key={key} {...headerProps}>
                  {
                    // Loop over the headers in each row
                    // Cast header groups as CustomHeaderGroup to incorporate react table hooks
                    headerGroup.headers.map((column) => {
                      const { key, ...columnProps } = column.getHeaderProps(
                        column.getSortByToggleProps({
                          className: column.collapse ? "collapse" : "",
                        })
                      );
                      return (
                        <TableHeader
                          key={key}
                          {...columnProps}
                          minWidth={column.minWidth}
                        >
                          <FlexWrapper justify="space-between">
                            <Text mods="medium darkBlue">
                              {column.render("Header")}
                            </Text>
                            {column.isSorted && (
                              <Arrow isOpen={!column.isSortedDesc}></Arrow>
                            )}
                          </FlexWrapper>
                        </TableHeader>
                      );
                    })
                  }
                </TableRow>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const { key, ...rowProps } = row.getRowProps();
              return (
                <TableRow
                  key={key}
                  onClick={() => viewRequest(row.original)}
                  {...rowProps}
                >
                  {
                    // Loop over the cells in each row
                    row.cells.map((cell) => {
                      const { key, ...cellProps } = cell.getCellProps({
                        className: cell.column.collapse ? "collapse" : "",
                      });
                      return (
                        <TableData key={key} {...cellProps}>
                          {cell.render("Cell")}
                        </TableData>
                      );
                    })
                  }
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default RequestsTable;
