import { Spacer, Text, DropdownInput } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";

import { useSponsorContext } from "src/shared/contexts/SponsorContext";
import { answerToOption, timeToOption } from "src/shared/utils/react-select";
import { formatTimeRange } from "src/utils/formatTime";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkRadioButtons,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

interface CareerDevelopmentProps {
  perk: SponsorPerks.CareerDevelopment;
}

type StoredPerk = SponsorPerks.CareerDevelopment;

const description = (
  <>
    <Text mods="medium">
      The Career Development Session is a workshop-style event that will be
      scheduled between January 12-15. Some ideas include a resume workshop,
      interview tips and tricks, and bringing in speakers. The session can be
      anywhere from 30 minutes to an hour long. Please provide a title and
      description of your session.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please rank the times that work best for you from those listed below. Once
      this perk has been submitted, our team will be in contact to finalize your
      time. We strongly encourage you to hold your session in these time slots
      since we’ve reserved them for sponsors. If these times don&apos;t work for
      you, or you have a specific time that you want to run your session, please
      email sponsor@hackthenorth.com.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.representative &&
  !!perkData.time_one &&
  !!perkData.time_two &&
  !!perkData.time_three &&
  // check that all date values are unique
  perkData.time_one !== perkData.time_two &&
  perkData.time_three !== perkData.time_two &&
  perkData.time_one !== perkData.time_three;

const CareerDevelopment: React.FC<CareerDevelopmentProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { representatives } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveCareerDevelopmentPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitCareerDevelopmentPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.CAREER_DEVELOPMENT}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.CAREER_DEVELOPMENT}--title`}
          title="Title"
          value={perkData["title"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.CAREER_DEVELOPMENT}--description`}
          title="Description (max 150 words)"
          value={perkData["description"] ?? ""}
          placeholder=""
          wordLimit={150}
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("description", e.target.value)}
        />
        <Spacer height={32} />
        <Text mods="heading h3">Timeslot Rankings</Text>
        <Text mods="italic">
          Note that all times are in your browser&apos;s local time zone.
        </Text>
        <Spacer height={8} />
        <Text mods="medium">First Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTimeRange, perkData["time_one"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_one", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Second Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTimeRange, perkData["time_two"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_two", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Third Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTimeRange, perkData["time_three"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_three", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">
          Who will be running the workshop? (This person will have access to the
          livestream and be able to add/remove speakers and moderate the chat)
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={answerToOption(perkData["representative"])}
          readOnly={isReadOnly}
          options={
            representatives?.map((representative) =>
              answerToOption(representative.name)
            ) ?? []
          }
          onChange={(option: OptionTypeBase) =>
            updatePerkField("representative", option.value)
          }
          placeholder="Select representative"
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["accepted_hackers_only"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.CAREER_DEVELOPMENT}--accepted-hackers`}
          title="Would you like your session attendees to be limited to accepted hackers only?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("accepted_hackers_only", true);
            } else {
              updatePerkField("accepted_hackers_only", false);
            }
          }}
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["recording_available"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.CAREER_DEVELOPMENT}--recording`}
          title="Would you like a recording of your session to be made publicly available after the event?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("recording_available", true);
            } else {
              updatePerkField("recording_available", false);
            }
          }}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.CAREER_DEVELOPMENT}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveCareerDevelopmentPerk}
          onSubmit={submitCareerDevelopmentPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.CAREER_DEVELOPMENT} error={error} />
    </>
  );
};

export default CareerDevelopment;
