import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";

const SHARE_ID = "81d452ee-2cdf-4eb8-aef5-5d77467f0134";

const SponsorPlatformOnboarding: React.FC = () => {
  return (
    <PageWrapper scrollToTop>
      <OutlineDocument shareId={SHARE_ID} />
    </PageWrapper>
  );
};

export default SponsorPlatformOnboarding;
