import { getFieldAnswer } from "src/shared/utils/hackerapi";

import { useGetTechTalkProposalsQuery } from "./graphql/getTechTalks.generated";

export type TTechTalk = {
  id: number;
  title: string;
  description: string;
};

export type TTechTalks = {
  data: TTechTalk[];
  loading: boolean;
};

export const useTechTalks = () => {
  const { data, loading } = useGetTechTalkProposalsQuery();

  const techTalkData = data?.claims
    .map((claim) => {
      const { id, fields } = claim;
      const { title, description } = getFieldAnswer(fields, "data");
      return {
        id,
        title,
        description,
      };
    })
    .filter((talk) => talk.title && talk.description);

  return {
    data: techTalkData,
    loading,
  };
};

export default useTechTalks;
