import { JWTRole } from "@hackthenorth/north";
import { useMemo } from "react";

import {
  useUserContext,
  usePermissionsContext,
  Permission,
} from "src/shared/contexts";
import { IS_PRODUCTION } from "src/utils/env";

import {
  HACKER_BASE_SIDEBAR_SECTIONS,
  SPONSOR_BASE_SIDEBAR_SECTIONS,
  MENTOR_BASE_SIDEBAR_SECTIONS,
  UNAUTHED_SIDEBAR_SECTIONS,
  PERMISSION_BASED_SECTIONS,
  INTERNAL_TESTING_SECTIONS,
  SidebarSectionDefinition,
} from "./sections";
import { useTopContent } from "./topContent";

export const useSidebarContent = () => {
  const { isAuthenticated, roles } = useUserContext();
  const { hasPermission } = usePermissionsContext();
  const isSponsor = roles.includes(JWTRole.SPONSOR);
  const isIndependentMentor =
    roles.includes(JWTRole.MENTOR) && !roles.includes(JWTRole.HACKER);

  const BASE_SECTIONS = isSponsor
    ? SPONSOR_BASE_SIDEBAR_SECTIONS
    : isIndependentMentor
    ? MENTOR_BASE_SIDEBAR_SECTIONS
    : HACKER_BASE_SIDEBAR_SECTIONS;

  const { logo, title, subtitle } = useTopContent();
  const sections: SidebarSectionDefinition[] = useMemo(
    () => [
      // for hackers, only show the permission based section
      ...(!isAuthenticated ? UNAUTHED_SIDEBAR_SECTIONS : BASE_SECTIONS),
      ...(isAuthenticated
        ? Object.entries(PERMISSION_BASED_SECTIONS).flatMap(([p, sections]) =>
            hasPermission(p as Permission)
              ? (sections as SidebarSectionDefinition[])
              : []
          )
        : []),
      ...(!IS_PRODUCTION ? INTERNAL_TESTING_SECTIONS : []),
    ],
    [isAuthenticated, hasPermission, BASE_SECTIONS]
  );

  return { logo, title, subtitle, sections };
};
