/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpdateHackerStageMutationVariables = Types.Exact<{
  id: Types.Scalars["Int"];
  newStage: Types.Scalars["Int"];
}>;

export type UpdateHackerStageMutation = { __typename?: "Mutation" } & {
  updateClaim: { __typename?: "Claim" } & Pick<Types.Claim, "id" | "stage_id">;
};

export const UpdateHackerStageDocument = gql`
  mutation UpdateHackerStage($id: Int!, $newStage: Int!) {
    updateClaim(data: { id: $id, stage_id: $newStage }) {
      id
      stage_id
    }
  }
`;

/**
 * __useUpdateHackerStageMutation__
 *
 * To run a mutation, you first call `useUpdateHackerStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHackerStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHackerStageMutation, { data, loading, error }] = useUpdateHackerStageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newStage: // value for 'newStage'
 *   },
 * });
 */
export function useUpdateHackerStageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHackerStageMutation,
    UpdateHackerStageMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateHackerStageMutation,
    UpdateHackerStageMutationVariables
  >(UpdateHackerStageDocument, baseOptions);
}
export type UpdateHackerStageMutationHookResult = ReturnType<
  typeof useUpdateHackerStageMutation
>;
export type UpdateHackerStageMutationResult = ApolloReactCommon.MutationResult<UpdateHackerStageMutation>;
export type UpdateHackerStageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateHackerStageMutation,
  UpdateHackerStageMutationVariables
>;
