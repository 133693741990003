import { Button, Text, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import Modal from "src/shared/components/Modal";

interface RsvpModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const Emoji = styled.span.attrs({
  role: "img",
  ariaLabel: "emoji",
})`
  font-size: 18px;
`;

const RsvpModal: React.FC<RsvpModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => (
  /* eslint-disable jsx-a11y/accessible-emoji */
  <Modal
    isOpen={isOpen}
    onClose={onCancel}
    title="Ready to submit?"
    actions={
      <>
        <Button mods="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Spacer width="20px" />
        <Button mods="primary" onClick={onConfirm}>
          Submit my RSVP <Emoji>🎉</Emoji>
        </Button>
      </>
    }
  >
    <Text>
      Please make sure all your information is correct before submitting your
      RSVP! You will not be able to change your information after you submit{" "}
      <Emoji>😄</Emoji>
    </Text>
  </Modal>
);

export default RsvpModal;
