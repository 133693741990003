/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetSponsorCompanyClaimQueryVariables = Types.Exact<{
  companyClaimId: Types.Scalars["Int"];
}>;

export type GetSponsorCompanyClaimQuery = { __typename?: "Query" } & {
  claim: { __typename?: "Claim" } & Pick<Types.Claim, "id" | "stage_id"> & {
      fields: Array<
        { __typename?: "FieldWithAnswer" } & Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
            answer?: Types.Maybe<
              { __typename?: "FieldAnswer" } & Pick<
                Types.FieldAnswer,
                "id" | "value" | "updated_at"
              >
            >;
          }
      >;
    };
};

export const GetSponsorCompanyClaimDocument = gql`
  query GetSponsorCompanyClaim($companyClaimId: Int!) {
    claim(id: $companyClaimId) {
      id
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetSponsorCompanyClaimQuery__
 *
 * To run a query within a React component, call `useGetSponsorCompanyClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorCompanyClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorCompanyClaimQuery({
 *   variables: {
 *      companyClaimId: // value for 'companyClaimId'
 *   },
 * });
 */
export function useGetSponsorCompanyClaimQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorCompanyClaimQuery,
    GetSponsorCompanyClaimQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorCompanyClaimQuery,
    GetSponsorCompanyClaimQueryVariables
  >(GetSponsorCompanyClaimDocument, baseOptions);
}
export function useGetSponsorCompanyClaimLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorCompanyClaimQuery,
    GetSponsorCompanyClaimQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorCompanyClaimQuery,
    GetSponsorCompanyClaimQueryVariables
  >(GetSponsorCompanyClaimDocument, baseOptions);
}
export type GetSponsorCompanyClaimQueryHookResult = ReturnType<
  typeof useGetSponsorCompanyClaimQuery
>;
export type GetSponsorCompanyClaimLazyQueryHookResult = ReturnType<
  typeof useGetSponsorCompanyClaimLazyQuery
>;
export type GetSponsorCompanyClaimQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorCompanyClaimQuery,
  GetSponsorCompanyClaimQueryVariables
>;
