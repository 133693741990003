import { Spinner } from "@hackthenorth/north";
import React from "react";

import { PageWrapper } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { HackerStage } from "src/shared/contexts/HackerContext/types";

import AcceptedComponent from "./accepted";
import ConfirmedComponent from "./confirmed";
import ExpiredComponent from "./expired";
import WaitlistedComponent from "./waitlisted";
import WithdrawnComponent from "./withdrawn";

const COMPONENT_MAP = {
  [HackerStage.ACCEPTED]: <AcceptedComponent />,
  [HackerStage.CONFIRMED]: <ConfirmedComponent />,
  [HackerStage.CHECKED_IN]: <ConfirmedComponent />,
  [HackerStage.WAITLISTED]: <WaitlistedComponent />,
  [HackerStage.EXPIRED]: <ExpiredComponent />,
  [HackerStage.WITHDRAWN]: <WithdrawnComponent />,
};

export default () => {
  const { isLoading, stage } = useHackerContext();
  return (
    <PageWrapper>{isLoading ? <Spinner /> : COMPONENT_MAP[stage]}</PageWrapper>
  );
};
