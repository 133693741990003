import { useState, useEffect } from "react";
/* eslint-disable @typescript-eslint/camelcase */
import { unstable_batchedUpdates } from "react-dom";

import { Field, TAnswers, TAnswer } from "./types";

import { useHackerContext } from "./index";

const filterClaimData = (accepted: Field[], claimData: TAnswers) =>
  Object.keys(claimData)
    .filter((field) => accepted.includes(field as Field))
    .reduce((obj, field) => {
      obj[field] = claimData[field];
      return obj;
    }, {}) as Partial<TAnswers>;

export type TSetResponsesState = (field: Field, value: TAnswers[Field]) => void;

export const useHackerState = (
  fields: Field[],
  validators: Partial<Record<Field, (value: TAnswer) => boolean>>
) => {
  const { claimData, isFetchingData, isUpdatingData } = useHackerContext();

  // Only selected fields would be included in state
  const [responsesState, setResponses] = useState<Partial<TAnswers>>(
    filterClaimData(fields, claimData)
  );

  // Flag that determines whether lock state has been re-hydrated with server data
  const [isResponseUpdated, setResponseUpdated] = useState(false);

  // Update state when claim is re-fetched
  useEffect(() => {
    if (!isFetchingData && !isUpdatingData) {
      // Batch updates so isResponseUpdated is accurate
      unstable_batchedUpdates(() => {
        setResponses(filterClaimData(fields, claimData));
        setResponseUpdated(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingData, isUpdatingData]);

  // Updates local state
  const setResponsesState: TSetResponsesState = (field, value) => {
    if (fields.includes(field))
      setResponses((prevData) => ({ ...prevData, [field]: value }));
  };

  const isValid = Object.entries(validators).every(
    ([field, validator]) => validator && validator(responsesState[field])
  );

  return {
    responsesState,
    setResponsesState,
    isValid,
    isResponseUpdated,
  };
};
