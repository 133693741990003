/* eslint-disable @typescript-eslint/camelcase */
import { Text, Link, Flex, Spacer, UnstyledButton } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Icon, Card } from "src/shared/components";
import { Route } from "src/shared/constants/route";
import * as Milestones from "src/shared/contexts/MilestonesContext/types";
import { Nullable } from "src/shared/utils/typescript";
import { getShortDateString } from "src/utils/date";

export interface ActionItemCopy {
  title: string;
  actionText: string;
  href?: string;
  onClick?: () => unknown;
}

export interface ActionItemProps {
  name: Milestones.Name;
  isIndividual: boolean; // whether or not the milestone is specific to the user
  isComplete: boolean;
  completedBy: Nullable<string>; // who completed it, if we know
  dueDate: Date | null;
  data: Milestones.Milestone["data"];
}

const realCopy: Partial<Record<Milestones.Name, ActionItemCopy>> = {
  [Milestones.Name.TC_ACCEPTED]: {
    title: "Accept the Terms & Conditions",
    actionText: "Go to document",
    href: Route.DOC_TAC,
  },
  [Milestones.Name.COC_ACCEPTED]: {
    title: "Accept our Code of Conduct",
    actionText: "Go to document",
    href: Route.DOC_COC,
  },
  [Milestones.Name.PROFILE_UPDATED]: {
    title: "Update your Profile",
    actionText: "Go to profile page",
    href: Route.SPONSOR_PROFILE,
  },
};

const RightChevron = styled(Icon).attrs({
  name: "chevron",
  color: "bluePrimary2",
  size: "body",
})`
  transform: rotate(270deg) translate(-1px, 2px);
`;

const Container = styled(Card)`
  border-radius: 5px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 2;
`;

const ActionItem: React.FC<ActionItemProps> = (item) => {
  const copy = realCopy[item.name];

  return (
    <Container>
      <Flex align="center">
        <Text mods="medium">
          {copy?.title ??
            (item.data as Milestones.CustomActionItem["data"])?.custom_title}
        </Text>
        <Spacer display="inline-block" width={5} />
        {item.isComplete && <Icon name="check" color="greenSecondary" />}
      </Flex>
      <Spacer height={8} />

      {item.dueDate && !item.isComplete ? ( // todo: make this a relative date ("in 2 days")
        <>
          <Flex align="center">
            <Icon name="clock" color="textDark" />
            <Spacer display="inline-block" width={5} />
            <Text>{getShortDateString(item.dueDate)}</Text>
          </Flex>
          <Spacer height={8} />
        </>
      ) : null}

      {!item.isIndividual && item.isComplete && item.completedBy ? (
        <>
          <Flex align="center">
            <Icon name="users" />
            <Spacer display="inline-block" width={5} />
            <Text>{item.completedBy}</Text>
          </Flex>
          <Spacer height={8} />
        </>
      ) : null}

      {copy?.href ? (
        <>
          <Link href={copy?.href}>
            <Flex align="center">
              <Text mods="link">{copy?.actionText}</Text>
              <RightChevron />
            </Flex>
          </Link>
        </>
      ) : copy?.onClick ? (
        <UnstyledButton onClick={copy?.onClick}>
          <Flex align="center">
            <Text mods="link">{copy?.actionText}</Text>
            <RightChevron />
          </Flex>
        </UnstyledButton>
      ) : null}
    </Container>
  );
};

export default ActionItem;
