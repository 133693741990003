import {
  Text,
  Spacer,
  TextInput,
  Flex,
  Button,
  Link,
} from "@hackthenorth/north";
import throttle from "lodash.throttle";
import React from "react";
import styled from "styled-components";
import { useClipboard } from "use-clipboard-copy";

import { PageWrapper } from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts";
import { IS_PRODUCTION } from "src/utils/env";
import { isEven } from "src/utils/number";

import RepresentativeItem from "./RepresentativeItem";

const FADE_OUT_MS = 3000;

const StyledTextInput = styled(TextInput)`
  max-width: 440px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;

  padding: 8px 16px;
`;

const CopySuccessContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.globalConstants.color.textDark};

  width: 440px;
  height: 48px;
  border-radius: 8px;

  animation-duration: ${FADE_OUT_MS}ms;
  animation-name: fadeInOut;
  animation-timing-function: ease-in;

  @keyframes fadeInOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const SponsorRepresentatives = () => {
  const { company, loading, representatives } = useSponsorContext();
  const clipboard = useClipboard({
    copiedTimeout: FADE_OUT_MS,
  });

  const inviteLink = `https://auth.hackthenorth.com/signup?redirect=${
    !IS_PRODUCTION
      ? "release-staging--my-hackthenorth-com.netlify.app"
      : "my.hackthenorth.com"
  }&r=sponsor&sc=${company?.invite_code ?? ""}${
    !IS_PRODUCTION ? "&staging" : ""
  }`;

  return (
    <PageWrapper footer pageTitle="Representatives">
      <Text mods="big medium">
        The following people have officially signed up as part of your company
        to attend Hack the North 2020++. You can invite more people by emailing
        the link directly or sharing the link below.
      </Text>
      <Spacer height={12} />
      <Text mods="big medium">
        In order to receive access to Hopin and Discord, representatives must
        sign up using the invite link.
      </Text>
      <Spacer height={12} />
      <Text mods="big medium">
        There is no limit to the number of representatives who sign up using the
        link, and all representatives will be able to access Discord. However,
        there will be a limit to the number of accounts on Hopin - you will be
        able to select which accounts have access to Hopin at a later date.
      </Text>
      <Spacer height={12} />
      <Text mods="big medium">
        If you have any questions, feel free to reach out to{" "}
        <Link mods="big medium" href="mailto:sponsor@hackthenorth.com">
          sponsor@hackthenorth.com
        </Link>
        .
      </Text>
      <Spacer height={64} />
      <Text mods="h2 bold">Role Descriptions</Text>
      <Spacer height={32} />
      <Text mods="big medium">
        <Text mods="big bold">Admins</Text> - able to access, edit, and submit
        perks, as well as view hacker resumes (once you have access to them).
      </Text>
      <Spacer height={12} />
      <Text mods="big medium">
        <Text mods="big bold">Members</Text> - do not have access to perks.
      </Text>
      <Spacer height={64} />
      <Text mods="h2 bold">Invite Representatives</Text>
      <Spacer height={32} />
      <Text mods="big medium">Invite Link</Text>
      <Spacer height={12} />
      <Flex>
        <StyledTextInput
          readOnly={true}
          ref={clipboard.target}
          value={inviteLink}
        />

        <Spacer width={24} />
        <Button mods="primary" onClick={throttle(clipboard.copy, FADE_OUT_MS)}>
          Copy link
        </Button>
      </Flex>
      {clipboard.copied && (
        <>
          <Spacer height={6} />
          <CopySuccessContainer justify="center" align="center">
            <Text mods="big medium white">Link copied!</Text>
          </CopySuccessContainer>
        </>
      )}
      <Spacer height={64} />
      {company && !loading && (
        <>
          <Text mods="h2 bold">{company.name} Representatives</Text>
          <Spacer height={32} />
          <Grid>
            <Text mods="big bold">Name</Text>
            <Text mods="big bold">Role</Text>
          </Grid>

          {representatives?.map((representative, i) => (
            <RepresentativeItem
              key={i}
              representative={representative}
              isColored={isEven(i)}
            />
          ))}
        </>
      )}
    </PageWrapper>
  );
};

export default SponsorRepresentatives;
