/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type CreateMentorProfileMutationVariables = Types.Exact<{
  profileData: Types.ClaimCreateInput;
}>;

export type CreateMentorProfileMutation = { __typename?: "Mutation" } & {
  createClaim: { __typename?: "Claim" } & Pick<
    Types.Claim,
    "id" | "stage_id"
  > & {
      fields: Array<
        { __typename?: "FieldWithAnswer" } & Pick<
          Types.FieldWithAnswer,
          "id" | "field_type" | "slug" | "valid_values" | "updated_at"
        > & {
            answer?: Types.Maybe<
              { __typename?: "FieldAnswer" } & Pick<
                Types.FieldAnswer,
                "id" | "value" | "updated_at"
              >
            >;
          }
      >;
    };
};

export const CreateMentorProfileDocument = gql`
  mutation CreateMentorProfile($profileData: ClaimCreateInput!) {
    createClaim(data: $profileData) {
      id
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useCreateMentorProfileMutation__
 *
 * To run a mutation, you first call `useCreateMentorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMentorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMentorProfileMutation, { data, loading, error }] = useCreateMentorProfileMutation({
 *   variables: {
 *      profileData: // value for 'profileData'
 *   },
 * });
 */
export function useCreateMentorProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMentorProfileMutation,
    CreateMentorProfileMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateMentorProfileMutation,
    CreateMentorProfileMutationVariables
  >(CreateMentorProfileDocument, baseOptions);
}
export type CreateMentorProfileMutationHookResult = ReturnType<
  typeof useCreateMentorProfileMutation
>;
export type CreateMentorProfileMutationResult = ApolloReactCommon.MutationResult<CreateMentorProfileMutation>;
export type CreateMentorProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMentorProfileMutation,
  CreateMentorProfileMutationVariables
>;
