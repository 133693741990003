import { Spacer, Text, Link } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { HopinImg, DiscordImg, DevpostImg } from "src/static/img";

const UsefulLinkContainer = styled(Link).attrs({ removeSharedStyles: true })`
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 12px;
  align-items: center;
  padding: 10px 8px;
  text-align: left;
  background-color: ${({ theme, color = "white" }) =>
    theme.globalConstants.color[color]};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.globalConstants.color.greySecondary};
  &:hover,
  &:focus {
    border: 2px solid ${({ theme }) => theme.globalConstants.color.bluePrimary1};
  }
`;

const Img = styled.img`
  width: 100%;
`;

const UsefulLinks: React.FC = () => (
  <>
    <Text mods="bold">Useful Links</Text>
    <Spacer height="16px" />
    <UsefulLinkContainer
      href="https://app.hopin.com/events/hack-the-north-2020"
      target="_blank"
    >
      <Img src={HopinImg} alt="Hopin" aria-hidden />
      <Text mods="lh-regular">Where all our events take place </Text>
    </UsefulLinkContainer>
    <Spacer height="12px" />
    <UsefulLinkContainer
      href="https://discord.gg/hack-the-north"
      target="_blank"
    >
      <Img src={DiscordImg} alt="Discord" aria-hidden />
      <Text mods="lh-regular">Connect with hackers + fun </Text>
    </UsefulLinkContainer>
    <Spacer height="12px" />
    <UsefulLinkContainer
      href="https://hackthenorth2020.devpost.com"
      target="_blank"
    >
      <Img src={DevpostImg} alt="Devpost" aria-hidden />
      <Text mods="lh-regular">Submit your project</Text>
    </UsefulLinkContainer>
  </>
);

export default UsefulLinks;
