import { Spinner } from "@hackthenorth/north";
import React from "react";

import TechTalk from "./TechTalk";
import useTechTalks from "./useTechTalks";

type TTechTalkSelectProps = {
  disabled?: boolean;
  selectedTalks: string[];
  onChange: (options: string[]) => void;
  max: number;
};

const TechTalkSelect: React.FC<TTechTalkSelectProps> = ({
  disabled,
  selectedTalks,
  onChange,
  max,
}) => {
  const { data, loading } = useTechTalks();

  // convert selected talks from string[] to number[]
  const selectedIds = selectedTalks.map((s) => parseInt(s));

  if (loading) return <Spinner />;

  return (
    <>
      {data?.map((talk) => (
        <TechTalk
          disabled={disabled}
          talk={talk}
          key={talk.id}
          checked={selectedIds.includes(talk.id)}
          onChange={(selected: boolean) => {
            const newOptions =
              selected && selectedIds.length < max
                ? [...selectedIds, talk.id]
                : selectedIds.filter((talkId) => talkId !== talk.id);

            // convert talks back from number[] to string[]
            onChange(newOptions.map((num) => num.toString()));
          }}
        />
      ))}
    </>
  );
};

export default TechTalkSelect;
