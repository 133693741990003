import { Text, Spacer } from "@hackthenorth/north";
import React, { useMemo } from "react";

import {
  PageWrapper,
  SharedAccordionContextProvider,
} from "src/shared/components";
import { useSponsorPerksContext } from "src/views/sponsor/perks/SponsorPerksContext";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import { PerksSection, TogglePerksButton } from "./components";

export const STAGE_TO_STATUS = {
  [SponsorPerks.Stage.PENDING]: SponsorPerks.Status.NOT_STARTED,
  [SponsorPerks.Stage.STARTED]: SponsorPerks.Status.INCOMPLETE,
  [SponsorPerks.Stage.SUBMITTED]: SponsorPerks.Status.UNDER_REVIEW,
  [SponsorPerks.Stage.APPROVED]: SponsorPerks.Status.COMPLETE,
};

export const getPerkStatus = (perk: SponsorPerks.Perk): SponsorPerks.Status =>
  STAGE_TO_STATUS[perk.stage];

const SponsorPerksPage = () => {
  const { perks } = useSponsorPerksContext();

  // group perks by status
  const perksByStatus = useMemo(
    () =>
      perks.reduce((acc, curPerk) => {
        const perkStatus = getPerkStatus(curPerk);
        const accPerksWithStatus = acc[perkStatus] || [];
        accPerksWithStatus.push(curPerk);
        acc[perkStatus] = accPerksWithStatus;
        return acc;
      }, {}),
    [perks]
  );

  return (
    <PageWrapper footer pageTitle="Perks">
      <Text className="perks-description">
        We are excited to have you at Hack the North 2020++! To maximize your
        experience and exposure at the event, please ensure that you submit
        information for the following perks.
      </Text>
      <Spacer height={46} />
      <SharedAccordionContextProvider>
        <TogglePerksButton />
        {Object.values(SponsorPerks.Status).map((status) => (
          <PerksSection
            key={status}
            status={status}
            perks={perksByStatus[status] || []}
            numTotalPerks={perks.length}
          />
        ))}
      </SharedAccordionContextProvider>
    </PageWrapper>
  );
};
export default SponsorPerksPage;
