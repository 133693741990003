import React, { ComponentPropsWithoutRef, useEffect } from "react";

import Collapsible from "src/shared/components/Collapsible";

import { useAccordionContext } from "./context";

interface CollapsibleProps extends ComponentPropsWithoutRef<"div"> {
  disabled?: boolean;
  showOverflow?: boolean;
}

const AccordionCollapsible: React.FC<CollapsibleProps> = ({
  disabled = false,
  showOverflow = false,
  ...rest
}) => {
  const { isOpen, setLocked } = useAccordionContext();

  useEffect(() => {
    if (disabled) setLocked();
  }, [disabled, setLocked]);

  return (
    <Collapsible
      isOpen={isOpen}
      showOverflow={showOverflow}
      aria-hidden={!isOpen}
      {...rest}
    />
  );
};
export default AccordionCollapsible;
