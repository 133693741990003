import isEqual from "lodash.isequal";
import { useEffect, useCallback } from "react";

import { useHackerContext } from "src/shared/contexts";
import { Field } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { Badge, BADGES_DATA } from "src/views/hacker/badges/constants";

const ACCEPTED_FIELDS = [Field.BADGES];

const getBadgeCode = (badge: Badge) => BADGES_DATA[badge].badgeCode.toString();

export const useMentorshipBadges = (
  mentorshipRequests: MentorshipRequests.Request[]
) => {
  const { updateResponses, claimData } = useHackerContext();
  const {
    responsesState,
    isResponseUpdated,
    setResponsesState,
  } = useHackerState(ACCEPTED_FIELDS, {});

  const hasBadge = useCallback(
    (badge: Badge) =>
      (responsesState[Field.BADGES] ?? []).includes(getBadgeCode(badge)),
    [responsesState]
  );

  // Updates badges on HAPI when state is updated
  useEffect(() => {
    const badges = responsesState[Field.BADGES] ?? [];
    if (
      isResponseUpdated &&
      badges.length > 0 &&
      !isEqual(badges, claimData[Field.BADGES])
    ) {
      updateResponses({ [Field.BADGES]: responsesState[Field.BADGES] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsesState, isResponseUpdated]);

  // MENTORSHIP BADGES
  useEffect(() => {
    const currentBadges = responsesState[Field.BADGES] ?? [];

    if (!isResponseUpdated && currentBadges.length > 0) return;

    const badges: string[] = [];

    [
      [Badge.MENTORSHIP_SUBMITTED_ONE, 1],
      [Badge.MENTORSHIP_SUBMITTED_FIVE, 5],
      [Badge.MENTORSHIP_SUBMITTED_TEN, 10],
    ].forEach(([badge, requestCount]) => {
      if (!hasBadge(badge) && mentorshipRequests.length >= requestCount)
        badges.push(getBadgeCode(badge).toString());
    });

    if (badges.length > 0)
      setResponsesState(Field.BADGES, [...currentBadges, ...badges]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasBadge, isResponseUpdated, responsesState, mentorshipRequests]);
};
