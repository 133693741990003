/* eslint-disable @typescript-eslint/camelcase */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useUpdateClaimMutation } from "src/api/claims/updateClaim.generated";
import { MENTORSHIP_REQUEST_PIPELINE_ID } from "src/shared/constants/mentorship";
import { useUserContext, useHackerContext } from "src/shared/contexts";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer, parseState } from "src/shared/utils/hackerapi";

import { useGetMentorshipRequestsLazyQuery } from "./graphql/getMentorshipRequests.generated";
import { useSubmitMentorshipRequestMutation } from "./graphql/submitMentorshipRequest.generated";

interface MentorshipRequestContextState {
  requests: MentorshipRequests.Request[];
  isLoading: boolean;
  submitRequest: (data: MentorshipRequests.RequestInput) => Promise<unknown>;
  updateRequest: (data: MentorshipRequests.Request) => Promise<unknown>;
  loadError?: string;
  updateError?: string;
  submitError?: string;
}

const DEFAULT_STATE: MentorshipRequestContextState = {
  requests: [],
  isLoading: false,
  submitRequest: () =>
    Promise.reject("No parent MentorshipRequestContextProvider found"),
  updateRequest: () =>
    Promise.reject("No parent MentorshipRequestContextProvider found"),
};

const MentorshipRequestContext: React.Context<MentorshipRequestContextState> = createContext(
  DEFAULT_STATE
);

MentorshipRequestContext.displayName = "MentorshipRequestContext";

export const useMentorshipRequestContext = () =>
  useContext(MentorshipRequestContext);

export const MentorshipRequestContextProvider: React.FC = ({ children }) => {
  const { id, info } = useUserContext();
  const { claimData } = useHackerContext();

  const [
    getMentorshipRequestsQuery,
    { data, loading, error: loadError },
  ] = useGetMentorshipRequestsLazyQuery();
  const [
    submitMentorshipRequestMutation,
    { loading: submitLoading, error: submitError },
  ] = useSubmitMentorshipRequestMutation();
  const [
    updateClaimMutation,
    { loading: updateLoading, error: updateError },
  ] = useUpdateClaimMutation();

  useEffect(() => {
    if (id) {
      getMentorshipRequestsQuery({
        variables: { menteeId: id.toString() },
      });
    }
  }, [id, getMentorshipRequestsQuery]);

  const requests: MentorshipRequests.Request[] = useMemo(
    () =>
      (data?.claims || []).map((request) => ({
        id: request.id,
        title: getFieldAnswer(request.fields, "title"),
        description: getFieldAnswer(request.fields, "description"),
        category: getFieldAnswer(request.fields, "category"),
        priority: getFieldAnswer(request.fields, "priority"),
        created_at: request.created_at,
        reopened: getFieldAnswer(request.fields, "reopened"),
        mentee_name: getFieldAnswer(request.fields, "mentee_name"),
        mentee_id: getFieldAnswer(request.fields, "mentee_id"),
        mentor_id: getFieldAnswer(request.fields, "mentor_id"),
        mentee_discord_id: getFieldAnswer(request.fields, "mentee_discord_tag"),
        stage: MentorshipRequests.Stage[request.stage_id],
      })),
    [data]
  );

  const submitRequest: MentorshipRequestContextState["submitRequest"] = useCallback(
    (data: MentorshipRequests.RequestInput) => {
      if (id && info) {
        const parsedData = parseState({
          ...data,
          mentee_id: id,
          mentee_name: info.name,
          reopened: false,
          mentee_discord_tag: claimData?.discord_id || "",
        });

        return submitMentorshipRequestMutation({
          variables: {
            requestData: {
              name: data.title || "Mentorship Request",
              pipeline_id: MENTORSHIP_REQUEST_PIPELINE_ID,
              stage_id: MentorshipRequests.Stage["OPEN"],
              answers: parsedData,
            },
          },
        });
      }
      return Promise.resolve();
    },
    [submitMentorshipRequestMutation, id, info, claimData]
  );

  const updateRequest: MentorshipRequestContextState["updateRequest"] = useCallback(
    (data: MentorshipRequests.Request) => {
      const {
        id,
        title,
        description,
        category,
        priority,
        stage,
        mentor_id,
      } = data;
      const stage_id = MentorshipRequests.Stage[stage];
      const answers = parseState({
        title,
        description,
        category,
        priority,
        mentor_id,
      });
      return updateClaimMutation({
        variables: {
          updatedData: {
            id,
            stage_id,
            answers,
          },
        },
      });
    },
    [updateClaimMutation]
  );

  const state: MentorshipRequestContextState = {
    requests,
    isLoading: loading || submitLoading || updateLoading,
    submitRequest,
    updateRequest,
    loadError: loadError?.message,
    submitError: submitError?.message,
    updateError: updateError?.message,
  };

  return (
    <MentorshipRequestContext.Provider value={state}>
      {children}
    </MentorshipRequestContext.Provider>
  );
};

MentorshipRequestContextProvider.displayName =
  "MentorshipRequestContextProvider";
