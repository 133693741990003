import { Spacer, Text } from "@hackthenorth/north";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import { BLOCK_HEIGHT, HOURS_LIST, SMALL_COLUMN_WIDTH } from "../constants";
import { TComputedBannerEvents, TComputedEvents } from "../types";

import { EventsColumn } from "./EventsColumn";
import { TimeColumn } from "./TimeColumn";
import {
  CurrentTimeIndicator,
  CurrentTimeIndicatorInner,
} from "./TimeIndicator";

interface CalendarProps {
  inDayView: boolean;
  daysViewDay: number;
  daysToShow: string[];
  calendarEvents: TComputedEvents;
  bannerEvents: TComputedBannerEvents;
}

const Container = styled.div`
  background: ${({ theme }) => theme.globalConstants.color.white};
  border: 1px solid #c3ccd7;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

const EventsColumnsContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const SubContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow-y: scroll;
`;

const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #c3ccd7;
`;

const HeaderItemInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;

const HeaderItemInnerDayView = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 12px 0;
`;

const HeaderItemSmall = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 ${SMALL_COLUMN_WIDTH}px;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #c3ccd7;
`;

const BannerEvent = styled.div`
  border: 2px solid ${({ theme }) => theme.globalConstants.color.navySecondary};
  box-sizing: border-box;
  border-radius: 9px;
  background: ${({ theme }) => theme.globalConstants.color.creamSecondary};
  display: flex;
  justify-content: center;
  padding: 4px;
  width: 100%;
`;

const ExtraSubtleText = styled(Text)`
  font-size: 10px !important;
  line-height: 18px !important;
`;

const CurrentDayIndicator = styled.div`
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  border-radius: 50px;
  padding: 0 12px;
`;

const CurrentDayIndicatorDayView = styled.div`
  background: ${({ theme }) => theme.globalConstants.color.bluePrimary3};
  border-radius: 50px;
  padding: 7px 32px;
`;

const TimeLinesContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
`;

const CalendarTimeColumn = styled.div`
  flex: 1;
  border-left: 1px solid #c3ccd7;
  border-bottom: 1px solid #c3ccd7;
  position: relative;
  overflow-y: hidden;
`;

const EventsTimeBlock = styled.div`
  height: ${BLOCK_HEIGHT}px;
  width: 100%;
  :not(:first-child) {
    border-top: 1px solid #c3ccd7;
  }
`;

export const Calendar: React.FC<CalendarProps> = ({
  inDayView,
  daysViewDay,
  daysToShow,
  calendarEvents,
  bannerEvents,
}) => {
  // Scroll to current time
  const scrollRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => scrollRef.current?.scrollIntoView();
  useEffect(executeScroll, []);

  const getWeekday = useCallback(
    (date: moment.Moment) => date.format("dddd"),
    []
  );
  const getDay = useCallback(
    (date: moment.Moment) => date.format("MMM D").toUpperCase(),
    []
  );
  const getDayViewDate = useCallback(
    (date: moment.Moment) => date.format("dddd, MMMM D"),
    []
  );
  const timezone = useMemo(
    () =>
      new Date()
        .toLocaleTimeString("en-us", { timeZoneName: "short" })
        .split(" ")[2],
    []
  );

  const getTimePosition = () =>
    moment
      .duration(moment().diff(moment().startOf("day").subtract(12, "hours")))
      .asHours();

  const [timePosition, setTimePosition] = useState(getTimePosition());
  const [currentDay, setCurrentDay] = useState(
    moment(moment.now()).subtract(50, "days").subtract(12, "hours")
  ); // Change time to "2021-01-13T10:46:05+0000" for testing

  console.log({ currentDay });

  const timeSetter = useCallback(() => {
    setTimePosition(getTimePosition());
    setCurrentDay(
      moment(moment.now()).subtract(50, "days").subtract(12, "hours")
    );
  }, []);

  useEffect(() => {
    const id = setInterval(timeSetter, 60000);
    return () => clearInterval(id);
  }, [timeSetter]);

  return (
    <Container>
      <HeaderContainer>
        <HeaderItemSmall>
          <ExtraSubtleText mods="lightGrey">TIME ZONE</ExtraSubtleText>
          <Spacer height={4} />
          <Text>{timezone}</Text>
        </HeaderItemSmall>

        {daysToShow.map((day) => (
          <HeaderItem key={day}>
            {inDayView ? (
              <HeaderItemInnerDayView>
                <CurrentDayIndicatorDayView>
                  <Text>
                    <strong>Day {daysViewDay + 1} — </strong>{" "}
                    {getDayViewDate(moment(day))}
                  </Text>
                </CurrentDayIndicatorDayView>
              </HeaderItemInnerDayView>
            ) : (
              <HeaderItemInner>
                <ExtraSubtleText mods="lightGrey">
                  {getDay(moment(day))}
                </ExtraSubtleText>
                <Spacer height={4} />
                {currentDay.isSame(day) ? (
                  <CurrentDayIndicator>
                    <Text>{getWeekday(moment(day))}</Text>
                  </CurrentDayIndicator>
                ) : (
                  <Text>{getWeekday(moment(day))}</Text>
                )}
              </HeaderItemInner>
            )}
            {bannerEvents[day]?.map((event) => (
              <BannerEvent key={event.id}>
                <Text mods="subtle">{event.title}</Text>
              </BannerEvent>
            ))}
          </HeaderItem>
        ))}
      </HeaderContainer>
      <SubContainer>
        <ScrollContainer>
          <TimeColumn />
          <EventsColumnsContainer>
            <TimeLinesContainer>
              {daysToShow.map((_, i) => (
                <CalendarTimeColumn key={i}>
                  {HOURS_LIST.map((hour) => (
                    <EventsTimeBlock key={hour} />
                  ))}
                </CalendarTimeColumn>
              ))}
            </TimeLinesContainer>
            <CurrentTimeIndicator
              blocksFromTop={timePosition}
              ref={scrollRef}
            />
            {daysToShow.map((day, i) => (
              <>
                {currentDay.isSame(day) && (
                  <CurrentTimeIndicatorInner
                    inDayView={inDayView}
                    blocksFromTop={timePosition}
                    blocksFromLeft={i}
                    totalBlocks={daysToShow.length}
                  />
                )}
                <EventsColumn
                  key={day}
                  events={calendarEvents[day] ?? []}
                  inDayView={inDayView}
                />
              </>
            ))}
          </EventsColumnsContainer>
        </ScrollContainer>
      </SubContainer>
    </Container>
  );
};
