import { Spacer, Text } from "@hackthenorth/north";
import React from "react";

import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

interface TechTalkProposalProps {
  perk: SponsorPerks.TechTalkProposal;
}

type StoredPerk = SponsorPerks.TechTalkProposal;

const description = (
  <>
    <Text mods="medium">
      Tech Talks will take place from January 12 to January 15, and can be about
      any tech topic. These will be live sessions anywhere from 45 minutes to an
      hour long. Hackers will vote on their favourite options, and the top few
      will be selected to be presented at Hack the North.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      You have the opportunity to submit two tech talk proposals! If you are
      submitting two proposals, you must be able to present both topics at Hack
      the North, and you will only be able to present a maximum of one tech
      talk.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.title && !!perkData.description;

const TechTalkProposal: React.FC<TechTalkProposalProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveTechTalkProposalPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitTechTalkProposalPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.TECH_TALK_PROPOSAL}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.TECH_TALK_PROPOSAL}--title-1`}
          title="Title*"
          value={perkData["title"] ?? ""}
          placeholder="Title of first tech talk proposal"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.TECH_TALK_PROPOSAL}--description-1`}
          title="Description* (max 150 words)"
          value={perkData["description"] ?? ""}
          placeholder="Description of first tech talk proposal (max 150 words)"
          wordLimit={150}
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("description", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.TECH_TALK_PROPOSAL}--title-2`}
          title="Title"
          value={perkData["title_optional"] ?? ""}
          placeholder="(Optional) Title of second tech talk proposal"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title_optional", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.TECH_TALK_PROPOSAL}--description-2`}
          title="Description (max 150 words)"
          value={perkData["description_optional"] ?? ""}
          placeholder="(Optional) Description of second tech talk proposal (max 150 words)"
          wordLimit={150}
          isReadOnly={isReadOnly}
          onChange={(e) =>
            updatePerkField("description_optional", e.target.value)
          }
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.TECH_TALK_PROPOSAL}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveTechTalkProposalPerk}
          onSubmit={submitTechTalkProposalPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.TECH_TALK_PROPOSAL} error={error} />
    </>
  );
};

export default TechTalkProposal;
