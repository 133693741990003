import {
  Text,
  Button,
  Spacer,
  Spinner,
  TextInput,
  DropdownInput,
  Flex,
} from "@hackthenorth/north";
import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";

import { PageWrapper, Divider } from "src/shared/components";
import { useMentorshipRequestContext } from "src/shared/contexts";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import {
  answersToOptions,
  Option,
  answerToOption,
} from "src/shared/utils/react-select";
import { MentorshipDecorationImg } from "src/static/img";

import Instructions from "./Instructions";
import RequestCard from "./RequestCard";
import RequestModal from "./RequestModal";
import { useMentorshipBadges } from "./useMentorshipBadges";

const STAGE_OPTIONS = [
  "Unclaimed",
  "Claimed",
  "Notified",
  "Resolved",
  "All Stages",
];

const Container = styled.div`
  max-width: 750px;
`;

const StyledImg = styled.img`
  width: 315px;
  height: 189px;
`;

const StyledTextInput = styled(TextInput)`
  margin-right: 12px;
`;

const StyledDropdownInput = styled(DropdownInput)`
  max-width: 150px;
  height: 48px;
  .select__control {
    height: 48px;
  }
`;

const Home: React.FC = () => {
  const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);
  const [requestToEdit, setRequestToEdit] = useState<
    MentorshipRequests.Request | undefined
  >(undefined);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [displayedRequests, setDisplayedRequests] = useState<
    MentorshipRequests.Request[]
  >([]);
  const [stageFilter, setStageFilter] = useState<string | undefined>(
    "All Stages"
  );

  useMentorshipBadges(displayedRequests);

  const {
    requests,
    isLoading,
    submitRequest,
    updateRequest,
  } = useMentorshipRequestContext();

  useEffect(() => {
    let displayedRequests = requests;
    if (query) {
      displayedRequests = displayedRequests.filter(
        (request: MentorshipRequests.Request) =>
          request.title?.toLowerCase().includes(query) ||
          request.description?.toLowerCase().includes(query)
      );
    }
    if (stageFilter) {
      displayedRequests = displayedRequests.filter(
        (request: MentorshipRequests.Request) => {
          switch (stageFilter) {
            case "Unclaimed":
              return request.stage === "OPEN";
            case "Claimed":
              return request.stage === "CLAIMED";
            case "Notified":
              return request.stage === "NOTIFIED";
            case "Resolved":
              return request.stage === "RESOLVED";
            default:
              return true;
          }
        }
      );
    }
    setDisplayedRequests(displayedRequests);
  }, [requests, query, stageFilter]);

  const startNewRequest = () => {
    setRequestToEdit(undefined);
    setIsRequestModalOpen(true);
  };

  const submit = async (
    title: string,
    description: string,
    category: string[],
    priority: number
  ) => {
    await submitRequest({ title, description, category, priority });
    setIsRequestModalOpen(false);
    window.location.reload();
  };

  // repeat code from claimed-requests
  const resolveRequest = useCallback(
    (request: MentorshipRequests.Request) => {
      request.stage = "RESOLVED";
      updateRequest(request);
    },
    [updateRequest]
  );

  const unclaimRequest = useCallback(
    (request: MentorshipRequests.Request) => {
      request.stage = "OPEN";
      request.reopened = true;
      updateRequest(request);
    },
    [updateRequest]
  );

  return (
    <PageWrapper>
      <RequestModal
        isOpen={isRequestModalOpen}
        onCancel={() => setIsRequestModalOpen(false)}
        onSubmit={submit}
        requestToEdit={requestToEdit}
        newRequest={true}
      />
      <Flex justify="space-between" align="center">
        <div>
          <Text mods="h2 heading">Have a question? Ask our mentors!</Text>
          <Spacer height={10} />
          <Text>
            Our mentors are here to support you throughout the event by helping
            you with technical questions, career-related questions,
            troubleshooting, and everything in between! <br />
            <b>IMPORTANT:</b> Please provide your Discord tag in your request
            subject line to ensure the fastest assistance
          </Text>
        </div>
        <Spacer width={48} />
        <StyledImg src={MentorshipDecorationImg} alt="" />
      </Flex>
      <Container>
        <Spacer height={20} />
        <Instructions />
        <Spacer height={24} />
        <Divider />
        <Spacer height={24} />
        <Flex align="center" justify="start">
          <Text mods="h2 heading nowrap">Your requests</Text>
          <Button
            mods="primary nowrap"
            onClick={startNewRequest}
            style={{
              marginLeft: "12px",
            }}
          >
            New Request
          </Button>
        </Flex>
        <Spacer height={20} />
        <Flex align="center" justify="start">
          <StyledTextInput
            placeholder="Search by title, description..."
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
          />
          <StyledDropdownInput
            placeholder="Filter by Stage"
            value={answerToOption(stageFilter)}
            options={answersToOptions(STAGE_OPTIONS)}
            onChange={(option: Option) => {
              setStageFilter(option.value);
            }}
          />
        </Flex>
        <Spacer height={20} />
        {!isLoading && requests.length === 0 && <Text>No requests found.</Text>}
      </Container>
      {isLoading && <Spinner />}
      {displayedRequests
        .slice(0)
        .reverse()
        .map((request) => (
          <RequestCard
            request={request}
            key={request.id}
            updateRequest={updateRequest}
            onResolve={resolveRequest}
            onUnclaim={unclaimRequest}
          />
        ))}
    </PageWrapper>
  );
};

export default Home;
