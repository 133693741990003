import React from "react";
import { Routes } from "react-router";

import { Route } from "src/shared/components";
import { DocumentRoute } from "src/shared/constants/route";
import NotFound from "src/views/404";

import CodeOfConduct from "./code-of-conduct";
import PrivacyPolicy from "./privacy-policy";
import TermsAndConditions from "./terms-and-conditions";

export const DocumentRoutes = () => (
  <Routes>
    <Route path={DocumentRoute.CODE_OF_CONDUCT} element={<CodeOfConduct />} />
    <Route path={DocumentRoute.PRIVACY_POLICY} element={<PrivacyPolicy />} />
    <Route
      path={DocumentRoute.TERMS_AND_CONDITIONS}
      element={<TermsAndConditions />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
