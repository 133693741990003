import styled from "styled-components";

import { BLOCK_HEIGHT } from "../constants";

export const CurrentTimeIndicator = styled.div<{ blocksFromTop: number }>`
  z-index: 2;
  width: 100%;
  position: absolute;
  top: ${({ blocksFromTop }) => blocksFromTop * BLOCK_HEIGHT}px;
  left: 0;
  border-bottom: 2px dashed
    ${({ theme }) => theme.globalConstants.color.tealSecondary};
`;

export const CurrentTimeIndicatorInner = styled.div<{
  blocksFromTop: number;
  blocksFromLeft: number;
  totalBlocks: number;
  inDayView: boolean;
}>`
  position: absolute;
  top: ${({ blocksFromTop }) => blocksFromTop * BLOCK_HEIGHT}px;
  left: calc(
    ${({ blocksFromLeft, totalBlocks }) =>
      `${blocksFromLeft} * 100% / ${totalBlocks}`}
  );
  width: calc(
    ${({ totalBlocks, inDayView }) =>
      inDayView ? `(100% / ${totalBlocks}) - 4px` : `100% / ${totalBlocks}`}
  );
  border-bottom: 2px solid
    ${({ theme }) => theme.globalConstants.color.tealSecondary};
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -4px;
    background: ${({ theme }) => theme.globalConstants.color.tealSecondary};
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }

  &::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -4px;
    background: ${({ theme }) => theme.globalConstants.color.tealSecondary};
    border-radius: 50%;
    height: 8px;
    width: 8px;
  }
`;
