import { Flex, Text, Spacer } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { GradientText } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { SomethingWentWrongImg } from "src/static/img";

interface ErrorModalProps {
  error: Error | null;
  onCancel: () => void;
}

const FlexWrapper = styled(Flex)`
  max-height: 400px;
`;

const Img = styled.img`
  width: 200px;
`;

export const ErrorModal: React.FC<ErrorModalProps> = ({ error, onCancel }) => {
  return (
    <Modal isOpen={!!error} onClose={onCancel} width="700px">
      <Flex align="center">
        <Img src={SomethingWentWrongImg} alt="" aria-hidden />
        <Spacer width={40} />
        <FlexWrapper column justify="center">
          <GradientText gradient="gradientRedBlue172" mods="heading h2">
            Aw, snap!
          </GradientText>
          <Spacer height={12} />
          <Text mods="heading big">
            {error?.message ??
              "Something went wrong while displaying this webpage. Try reloading?"}
          </Text>
        </FlexWrapper>
      </Flex>
    </Modal>
  );
};
