import React from "react";

const HACKTHENORTH_SPOTIFY_ALBUM_URI =
  "spotify:playlist:4wqSwMyX380vkAJjZ4sVSs";
const SPOTIFY_EMBED_URL = `https://embed.spotify.com/?uri=${HACKTHENORTH_SPOTIFY_ALBUM_URI}`;

type TSpotifyPlayerProps = {
  width: number;
  height: number;
  view?: "list" | "coverart";
};

const SpotifyPlayer: React.FC<TSpotifyPlayerProps> = ({ width, height }) => (
  <iframe
    title="Spotify"
    className="SpotifyPlayer"
    src={SPOTIFY_EMBED_URL}
    width={width}
    height={height}
    frameBorder={0}
    allow="encrypted-media"
    allowTransparency
  />
);

export default SpotifyPlayer;
