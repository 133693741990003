/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetSponsorIndividualClaimQueryVariables = Types.Exact<{
  userId?: Types.Maybe<Types.Scalars["String"]>;
}>;

export type GetSponsorIndividualClaimQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<Types.Claim, "id" | "stage_id"> & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetSponsorIndividualClaimDocument = gql`
  query GetSponsorIndividualClaim($userId: String) {
    claims(
      where: {
        pipeline: {
          is: { slug: { equals: "hackthenorth2020-sponsor-individuals" } }
        }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "user_id" } } } }
              { value: { equals: $userId } }
            ]
          }
        }
      }
      take: 1
    ) {
      id
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetSponsorIndividualClaimQuery__
 *
 * To run a query within a React component, call `useGetSponsorIndividualClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorIndividualClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorIndividualClaimQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetSponsorIndividualClaimQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSponsorIndividualClaimQuery,
    GetSponsorIndividualClaimQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetSponsorIndividualClaimQuery,
    GetSponsorIndividualClaimQueryVariables
  >(GetSponsorIndividualClaimDocument, baseOptions);
}
export function useGetSponsorIndividualClaimLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSponsorIndividualClaimQuery,
    GetSponsorIndividualClaimQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSponsorIndividualClaimQuery,
    GetSponsorIndividualClaimQueryVariables
  >(GetSponsorIndividualClaimDocument, baseOptions);
}
export type GetSponsorIndividualClaimQueryHookResult = ReturnType<
  typeof useGetSponsorIndividualClaimQuery
>;
export type GetSponsorIndividualClaimLazyQueryHookResult = ReturnType<
  typeof useGetSponsorIndividualClaimLazyQuery
>;
export type GetSponsorIndividualClaimQueryResult = ApolloReactCommon.QueryResult<
  GetSponsorIndividualClaimQuery,
  GetSponsorIndividualClaimQueryVariables
>;
