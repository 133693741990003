import React from "react";

import { Route } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";

import Home from "./root";

export const HomeRoutes = () => (
  <Route path={BaseRoute.HOME} element={<Home />} />
);
