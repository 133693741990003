import { Field, RSVPData } from "./types";

/* eslint-disable @typescript-eslint/camelcase */
export const DEFAULT_DATA: RSVPData = {
  // Account
  [Field.AVATAR]: "red",
  [Field.SAVED_EVENTS]: [],
  [Field.INTERESTS]: [],

  // Personal Information
  [Field.LEGAL_NAME]: null,
  [Field.PREFERRED_NAME]: null,
  [Field.PHONE_NUMBER]: null,
  [Field.LOCATION]: null,

  [Field.ADDRESS_LINE_ONE]: null,
  [Field.ADDRESS_LINE_TWO]: null,
  [Field.CITY]: null,
  [Field.PROVINCE_STATE]: null,
  [Field.COUNTRY]: null,
  [Field.POSTAL_CODE]: null,

  [Field.AGE_CONSENT]: null, // 18 years old as of January 15, 2021
  [Field.TERMS_CONDITIONS]: null,
  [Field.SHARING_OPT_OUT]: null, // if the hacker opted out of sharing info with MLH
  [Field.WAIVER_CONSENT]: null,
  [Field.RECORDING_CONSENT]: null,

  // Event Information
  [Field.SHIRT_TYPE]: null,
  [Field.SHIRT_SIZE]: null,
  [Field.SWAG_PICKUP]: null,
  [Field.APPAREL_ACCOMODATION]: null, // apparel accomodation requests (ex. hijab-friendly shirt)
  [Field.NETWORKING]: null,
  [Field.MENTORSHIP_INTEREST]: null, // if they would be interested in being a mentor for beginner hackers
  [Field.MENTORSHIP_TOPICS]: null,
  [Field.TECH_TALKS]: null,
  [Field.WICS_COFFEE_CHAT]: null, // if they are eligible for and would like to sign up for a WiCS coffee chat

  // Career
  [Field.RESUME]: null,
  [Field.RECRUITMENT_CONSENT]: null, // consent to provide data to sponsors for recruitment
  [Field.CITIZENSHIP]: null, // canadian citizen
  [Field.PERMANENT_RESIDENT]: null, // permanent resident of canada
  [Field.INTERNSHIP_OPPORTUNITIES]: null, // looking for internship opportunities
  [Field.FULL_TIME_OPPORTUNITIES]: null, // looking for full-time opportunities
  [Field.COFFEE_CHAT]: null,
  [Field.COMPANY_INTEREST]: null,

  // Day-of-event
  [Field.BADGES]: null,
  [Field.DISCORD_ID]: null,
};
