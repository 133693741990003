import { Spacer, Text, DropdownInput } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";

import { useSponsorContext } from "src/shared/contexts";
import { timeToOption, answerToOption } from "src/shared/utils/react-select";
import { formatTimeRange } from "src/utils/formatTime";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
} from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

interface LightningChallengeProps {
  perk: SponsorPerks.LightningChallenge;
}

type StoredPerk = SponsorPerks.LightningChallenge;

const description = (
  <>
    <Text mods="medium">
      The Lightning Challenge is a 30 to 45-minute event during the weekend of
      Hack the North. This is an opportunity for you to challenge hackers with a
      fun and interesting prompt. Many sponsors in the past have advertised a
      small prize to incentivize hackers to come out as well!
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please provide a description of your planned lightning challenge for our
      event schedule, as well as a preferred start time. Once this perk has been
      moved to the Complete section, your time will be finalized.
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.description && !!perkData.start_time && !!perkData.representative;

const LightningChallenge: React.FC<LightningChallengeProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { representatives } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveCurPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitCurPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.LIGHTNING_CHALLENGE}
        readOnly={isReadOnly}
      >
        <PerkTextArea
          name={`${SponsorPerks.Type.LIGHTNING_CHALLENGE}--website-link`}
          title="Description of lightning challenge for our schedule (max 250
            characters)"
          value={perkData["description"] ?? ""}
          placeholder="Your company's home or recruiting page"
          onChange={(e) => updatePerkField("description", e.target.value)}
          isReadOnly={isReadOnly}
          wordLimit={250}
        />
        <Spacer height={32} />
        <Text mods="medium">Time selection</Text>
        <Spacer height={4} />
        <Text mods="italic">
          Note that all times are in your browser&apos;s local time zone.
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(formatTimeRange, perkData["start_time"])}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select time"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("start_time", option.value)
          }
        />
        <Spacer height={32} />
        <Text mods="medium">
          Who will be moderating the lightning challenge? (This person will have
          access to the Hopin call and be able to add/remove speakers)
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={answerToOption(perkData["representative"])}
          readOnly={isReadOnly}
          options={
            representatives?.map((representative) =>
              answerToOption(representative.name)
            ) ?? []
          }
          placeholder="Select a sponsor representative"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("representative", option.value)
          }
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.LIGHTNING_CHALLENGE}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveCurPerk}
          onSubmit={submitCurPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.LIGHTNING_CHALLENGE} error={error} />
    </>
  );
};

export default LightningChallenge;
