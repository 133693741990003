import { Link, Text } from "@hackthenorth/north";
import React from "react";

import { BaseRoute } from "src/shared/constants/route";
import {
  LightBlueSmileyFaceImg,
  PinkSmileyImg,
  DevpostIconImg,
  BlueBallLaptopImg,
  CalendlyImg,
  DiscordIconImg,
  PinkBallLaptopImg,
  PinkVideoImg,
  SponsorDiscordImg,
  SubmitYourProjectImg,
  WaitingRoomImg,
} from "src/static/img";

import {
  SponsorbayImg,
  OpeningImg,
  CreativeIdeationImg,
  DrawphoneImg,
  IntroSecurityImg,
  BlockchainImg,
  YogaImg,
  AerobicsImg,
  CreatingImg,
  InternationalImg,
  FromImg,
  IntroVRImg,
  TechNeuroscienceImg,
  DesignersMeetUpImg,
  HackerHackerImg,
  CodingEthImg,
  FoundingImg,
  HighImg,
  WebBackendImg,
  EmbeddedImg,
  HackerSponsorImg,
  EmpowermentImg,
  LadiesImg,
  WiCSImg,
  NetworkingImg,
  LGBTQImg,
  CrackingImg,
  DesigningImg,
  InteractiveImg,
  GameImg,
  MLImg,
  HowToPitchImg,
  TriviaImg,
  HardwareImg,
  WerewolfImg,
  HackathonOrganizersImg,
  ReactImg,
  DesignImg,
  ClosingImg,
} from "../../../static/img/eventImgs";

export type TMessage = {
  text: string;
  emojis?: string[];
};

// one of the emojis will be shown at random each time the message appears
export const MESSAGES: TMessage[] = [
  { text: "remember to grab a bite to eat", emojis: ["🍲", "🍝", "🌮"] },
  { text: "please eat something healthy", emojis: ["🍎", "🍌", "🥗"] },
  { text: "take a sip of water please", emojis: ["💧", "🚰"] },
  { text: "remember to get up and stretch your legs", emojis: ["🏃🏿‍♀️", "🏃🏻‍♂️"] },
  { text: "take some time to move and stretch", emojis: ["🤸‍♀️", "🤸🏾‍♂️"] },
  { text: "remember to check in with your team 💙" },
  { text: "keep your hands off your face 🚫🙈" },
  { text: "take some time to sleep", emojis: ["😴", "💤"] },
  { text: "please take a deep breath 🌱👃" },
  { text: "take some time to play a song you enjoy 🎵" },
  { text: "time for a posture check 🙌" },
  { text: "don't forget to ask for help if you need it 💙" },
  { text: "please wash your hands with soap and water 🧼🚿" },
  { text: "get some fresh air if you can 🍃" },
  { text: "rest your eyes for a bit", emojis: ["😴", "🌻"] },
  { text: "remember to drink some water", emojis: ["💧", "🚰"] },
  { text: "connect with someone new 🌐" },
];

export type TAnnoucement = {
  title: string;
  link: string;
  start: string; // format  "YYYY-MM-DD HH:mm"
  isWorkshop?: boolean;
};

export const ANNOUNCEMENTS: TAnnoucement[] = [
  {
    title: "The virtual photobooth is now open!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-15 18:00",
  },
  {
    title: "Sponsor Bay is opening in 5 minutes!",
    link: "https://app.hopin.com/events/hack-the-north-2020/expo",
    start: "2021-01-15 18:25",
  },
  {
    title: "Opening Ceromonies starts in 15 minutes at 8 PM EST",
    link:
      "https://app.hopin.com/events/hack-the-north-2020/stages/569d7002-5036-45db-8a04-5786ec63733c",
    start: "2021-01-15 19:45",
  },
  {
    title: "Opening Ceromonies are starting now!",
    link:
      "https://app.hopin.com/events/hack-the-north-2020/stages/569d7002-5036-45db-8a04-5786ec63733c",
    start: "2021-01-15 20:00",
  },
  {
    title: "Sponsor Bay is happening in 5 minutes!",
    link: "https://app.hopin.com/events/hack-the-north-2020/expo",
    start: "2021-01-15 21:25",
  },
  {
    title: "Mentorship is available to you!",
    link: BaseRoute.MENTOR,
    start: "2021-01-15 23:30",
  },
  {
    title: "Drawphone will be starting at 12:00AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-15 23:55",
  },
  {
    title: "New custom emojis are up!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-15 23:55",
  },
  {
    title: "Just a reminder that the virtual photobooth is open!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-16 02:00",
  },
  {
    title: "Our mentorship tool is here to help you!",
    link: BaseRoute.MENTOR,
    start: "2021-01-16 05:00",
  },
  {
    title: "Snap some photos at our virtual photobooth!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-16 08:15",
  },
  {
    title: "Yoga will be starting at 9 AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 08:30",
  },
  {
    title: "Aerobics will be starting at 10 AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 09:30",
  },
  {
    title: "Our mentorship tool is here to help you!",
    link: BaseRoute.MENTOR,
    start: "2021-01-16 10:00",
  },
  {
    title: "International Hackers Meet-up will be starting at 10 AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 10:00",
  },
  {
    title: "Yoga will be starting soon at 12 PM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 11:30",
  },
  {
    title: "Designers + Artists Meet-up will be starting at 12:30 PM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 12:00",
  },
  {
    title: "Snap some photos at our virtual photobooth!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-16 13:45",
  },
  {
    title: "Hacker <> Hacker Networking will be starting at 1:30 PM EST",
    link: "https://app.hopin.com/events/hack-the-north-2020/reception",
    start: "2021-01-16 13:00",
  },
  {
    title: "Check out our mentorship tool!",
    link: BaseRoute.MENTOR,
    start: "2021-01-16 14:00",
  },
  {
    title: "High School Hackers Meet-Up 📚 is happening at 3 PM EST",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 14:30",
  },
  {
    title:
      "Web Backend Meet-Up 💻 is happening at 4:00 PM EST in #Meetup Room 3!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 15:30",
  },
  {
    title:
      "Join our Empowerment Lounge 🌈  voice channel from 5 PM to 9 PM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 16:50",
  },
  {
    title: "Our LGBTQ+ Hackers Meet-Up 🏳️‍🌈 is starting at 6 PM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 17:50",
  },
  {
    title: "BIPOC Hackers Meet-Up 💛 is happening at 8 PM EST",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 19:50",
  },
  {
    title: "JS/React Meet-Up is happening at 9 PM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 20:30",
  },
  {
    title: "Game Dev Meet-Up 🧑‍💻 is happening at 10 PM EST",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 21:30",
  },
  {
    title: "Trivia 🧐 will be starting at 10:30 PM EST",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 22:00",
  },
  {
    title: "Hardware Meet-Up ⚙️ will be starting at 11:15 PM EST",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 22:45",
  },
  {
    title: "Devpost submissions are due in 12 hours!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-16 23:00",
  },
  {
    title: "Werewolf 🐺 will be starting at 12 AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-16 23:30",
  },
  {
    title: "Create fun memories with our virtual photobooth!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-16 23:45",
  },
  {
    title: "Hackathon Organizers Meet-Up is starting at 12:30 AM EST!",
    link: "https://discord.gg/hack-the-north",
    start: "2021-01-17 00:00",
  },
  {
    title: "It's crunch time! Make sure to leverage our mentorship tool!",
    link: BaseRoute.MENTOR,
    start: "2021-01-17 00:00",
  },
  {
    title: "Devpost submissions are due in 8 hours!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-17 03:00",
  },
  {
    title: "You're so close! Make sure to leverage our mentorship tool!",
    link: BaseRoute.MENTOR,
    start: "2021-01-17 05:00",
  },
  {
    title: "Devpost submissions are due in 3 hours!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-17 12:00", // 7am EST
  },
  {
    title: "Last call for mentorship!",
    link: BaseRoute.MENTOR,
    start: "2021-01-17 08:00",
  },
  {
    title: "Devpost submissions are due in 1 hour!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-17 14:00", // 7am EST
  },
  {
    title: "Devpost submissions are due in 30 minutes!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-17 14:30", // 9:30am EST
  },
  {
    title: "Devpost submissions are now closed!",
    link: "https://hackthenorth2020.devpost.com",
    start: "2021-01-17 15:00", // 10am EST
  },
  {
    title: "Create fun memories with our virtual photobooth!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-17 16:30", // 11am EST
  },
  {
    title: "Last chance to checkout our virtual photobooth!",
    link: "hackthenorth.virtualbooth.co",
    start: "2021-01-17 22:00", // 5pm EST
  },
  {
    title: "Closing Ceromonies starts in 15 minutes at 6:30 PM EST",
    link:
      "https://app.hopin.com/events/hack-the-north-2020/stages/8288dc64-dae0-40b9-bfc2-4b5f3e259de6",
    start: "2021-01-17 23:15", // 6:15pm EST
  },
  {
    title: "Closing Ceromonies are starting now!",
    link:
      "https://app.hopin.com/events/hack-the-north-2020/stages/8288dc64-dae0-40b9-bfc2-4b5f3e259de6",
    start: "2021-01-17 23:30", // 6:35pm EST
  },
];

export const INTERESTS = [
  "JS/React",
  "Mobile applications",
  "Software",
  "Hardware",
  "Design",
  "Frontend",
  "Backend",
  "Data science",
  "Computer vision",
  "Machine learning",
  "Blockchain",
  "Android",
  "Security",
];

export const InterestsToTagsMap = {
  "JS/React": ["web", "frontend-web"],
  "Mobile applications": ["android"],
  Software: [
    "web",
    "frontend-web",
    "backend-web",
    "machine-learning",
    "android",
    "security",
    "blockchain",
  ],
  Design: ["design"],
  Frontend: ["web", "frontend-web"],
  Backend: ["web", "backend-web", "machine-learning"],
  "Data science": ["machine-learning"],
  "Computer vision": ["machine-learning"],
  "Machine learning": ["machine-learning"],
  Blockchain: ["blockchain"],
  Android: ["android"],
  Security: ["security"],
};

export type TJudgingDetails = {
  title: string;
  blueTitle?: string;
  description: React.ReactNode;
  image: string;
  showImageInModal?: boolean;
};

export const HtnJudgingCards: TJudgingDetails[] = [
  {
    title: "Submit your project to Devpost by 10:00 AM EST",
    description: (
      <Text>
        You must submit your project by 10:00 AM EST on{" "}
        <Link href="https://hackthenorth2020.devpost.com" target="_blank">
          Devpost
        </Link>
        . This is a reminder that by submitting your project you confirm that
        you will attend live judging at your assigned time. You may be assigned
        any time between <Text mods="bold">1:00 PM EST and 5:00 PM EST.</Text>
      </Text>
    ),
    image: DevpostIconImg,
  },
  {
    title: "Check judging time check-in time on Discord (~11:30 AM EST)",
    description: (
      <Text>
        Check your judging time check-in time on Discord via the spreadsheet
        that will be posted to #announcements on Discord at{" "}
        <Text mods="bold">11:30 AM EST</Text>. If you have any issues finding
        your scheduled time, send a message in #help-desk or message an
        organizer.
      </Text>
    ),
    image: DiscordIconImg,
  },
  {
    title: "Submit to Discord with",
    blueTitle: "!submit<devpost link>",
    description: (
      <Text>
        On Sunday at 11:00 AM EST, the #submit-your-project channel will become
        visible.{" "}
        <Text mods="bold">
          You must have submitted to Devpost by the 10:00 AM EST deadline for
          this command to work.
        </Text>
        <br />
        <br />
        Anytime before your assigned time (at least 15 minutes in advance), send
        a DM to Discord bot @Hack the North with the command{" "}
        <Text mods="bold">!submit {`<Devpost link>`}</Text>
        where {`<Devpost link>`} is the link to the Devpost project. DO NOT
        INCLUDE THE {`<AND>`} SYMBOLS IN YOUR MESSAGE. (Example: !submit
        hackthenorth2020.devpost.com)
        <br />
        <br />
        You will receive a success message from the bot upon successful
        verification.{" "}
        <Text mods="bold">
          Each member of your team must do this separately.
        </Text>
        <br />
        <br />
        You should be able to see your judging channels once this step is
        completed. If you have any issues or error messages, send a message in
        #help-desk or message an organizer.
      </Text>
    ),
    image: SubmitYourProjectImg,
  },
  {
    title:
      "Join your assigned Waiting Room voice channel at your scheduled check-in time",
    description: (
      <Text>
        Check your judging check-in time and Waiting Room assignment on the
        spreadsheet of scheduled times that will be posted to #announcements on
        Sunday at <Text mods="bold">11:00 AM EST</Text> on Discord. You should
        also be able to see your Judging Room and check-in time upon submitting
        to the bot in Step 3. Please have your whole team join the Waiting Room
        on time{" "}
        <Text mods="bold">
          You must determine beforehand who on your team will be presenting
          their screen.
        </Text>{" "}
        Please check that screen sharing and audio is working before joining the
        waiting room.{" "}
        <Text mods="bold">
          Only one person on your team may present their screen.
        </Text>{" "}
        <br />
        <Text mods="bold">Note:</Text> Even if you plan to present a live pitch,
        we{" "}
        <Text mods="bold">
          strongly encourage your team to have a pre-recorded video
        </Text>{" "}
        of your project ready and include it in your Devpost submission in case
        there are any technical difficulties during judging
      </Text>
    ),
    image: WaitingRoomImg,
  },
  {
    title: "Present to judges on Discord",
    description: (
      <Text>
        When the judges are ready, your team will be pulled into the Judging
        Room voice channel. Your team will have{" "}
        <Text mods="bold">
          3 minutes for your pitch and 1 minute for questions.
        </Text>{" "}
        During your pitch time, your team has the option to play a pre-recorded
        video (that does not exceed 3 minutes) or present a live pitch. We
        encourage teams to turn their videos on. An organizer will be leading
        the room and letting you know when you have run out of time. Good luck!
      </Text>
    ),
    image: BlueBallLaptopImg,
  },
  {
    title: "All done!",
    description: <Text>Good luck and have fun!</Text>,
    image: LightBlueSmileyFaceImg,
    showImageInModal: true,
  },
];

export const SponsorLiveJudgingCards: TJudgingDetails[] = [
  {
    title: "Join sponsor’s Discord channel",
    description: (
      <Text>
        Go to the #browse-sponsors channel in the Sponsors category and react to
        the sponsor corresponding to the sponsor prize you are interested in.
        <br />
        <br />
        Sponsors prizes taking part in live judging:
        <ul>
          <li>#ibm for IBM API Prize</li>
        </ul>{" "}
        Go to{" "}
        <Link href="https://hackthenorth2020.devpost.com/" target="_blank">
          https://hackthenorth2020.devpost.com/
        </Link>{" "}
        for more details on each prize.
      </Text>
    ),
    image: SponsorDiscordImg,
  },
  {
    title: "Include a video in submission",
    description: (
      <Text>
        <Text mods="bold">
          A video submission is required for any projects that opt into a
          sponsor prize.
        </Text>{" "}
        We recommend that you upload a video to YouTube and copy a link to that
        video in your Devpost submission.
      </Text>
    ),
    image: PinkVideoImg,
  },
  {
    title: "Opt into sponsor prize in Devpost submission",
    description: (
      <Text>
        By submitting to a sponsor prize, you are confirming that you have used
        the sponsor’s technology in your project and abided by any requirements
        outlined in their description (as shown on{" "}
        <Link href="https://hackthenorth2020.devpost.com" target="_blank">
          Devpost
        </Link>
        ).
      </Text>
    ),
    image: DevpostIconImg,
  },
  {
    title: "Select a timeslot on Calendly",
    description: (
      <Text>
        Links to sign up for Calendly time slots will be sent out around 12:00
        PM EST in each sponsor’s channel in Discord and closed at 12:30 PM EST
        on a first-come first-serve basis.{" "}
        <Text mods="bold">
          You must select a time that is at least 1 hour after your assigned
          Hack the North time to prevent conflict.
        </Text>{" "}
        Also, please ensure only{" "}
        <Text mods="bold">
          one member from each team signs up for a timeslot.
        </Text>
      </Text>
    ),
    image: CalendlyImg,
  },
  {
    title: "Present to sponsors",
    description: (
      <Text>
        Your team is responsible for arriving at the sponsor’s Hopin booth{" "}
        <Text mods="bold">at least 5 minutes</Text> before your scheduled time.
        When it’s your turn to present, members{" "}
        <Text mods="bold">should request to share your video.</Text> You may
        choose to present live or play your pre-recorded video. Sponsor judges
        will ask questions at the end of your pitch
      </Text>
    ),
    image: PinkBallLaptopImg,
  },
  {
    title: "All done!",
    description: <Text>Good luck and have fun!</Text>,
    image: PinkSmileyImg,
    showImageInModal: true,
  },
];

export const SponsorDevpostJudgingCards: TJudgingDetails[] = [
  {
    title: "Join sponsor’s Discord channel",
    description: (
      <Text>
        Go to the #browse-sponsors channel in the Sponsors category and react to
        the sponsor corresponding to the sponsor prize you are interested in.
        <br />
        <br />
        Sponsors prizes taking part in live judging:
        <ul>
          <li>#cockroach-labs for Best Use of CockroackDB</li>
          <li>#voiceflow for Best Use of Voiceflow App</li>{" "}
          <li>#dropbase for Dropbase API Prize</li>{" "}
          <li>#facebook for Facebook API Prize</li>
          <li> #vonage for Vonage API Prize</li>
          <li> #microsoft for Azure Champ Prize - Hack for Good</li>
          <li>#hootsuite for Hootsuite API Prize</li>{" "}
          <li> #mlh for Best Use of Google Cloud </li>
          <li>#mlh for Best Domain Name from Domain.com</li>{" "}
          <li> #mlh for Best Hardware Hack Sponsored by Digi-Key</li>
        </ul>
        Go to{" "}
        <Link href="https://hackthenorth2020.devpost.com/" target="_blank">
          https://hackthenorth2020.devpost.com/
        </Link>{" "}
        for more details on each prize.
      </Text>
    ),
    image: SponsorDiscordImg,
  },
  {
    title: "Include a video in submission",
    description: (
      <Text>
        <Text mods="bold">
          A video submission is required for any projects that opt into a
          sponsor prize.
        </Text>{" "}
        We recommend that you upload a video to YouTube and copy a link to that
        video in your Devpost submission.
      </Text>
    ),
    image: PinkVideoImg,
  },
  {
    title: "Opt into sponsor prize in Devpost submission",
    description: (
      <Text>
        By submitting to a sponsor prize, you are confirming that you have used
        the sponsor’s technology in your project and abided by any requirements
        outlined in their description (as shown on{" "}
        <Link href="https://hackthenorth2020.devpost.com" target="_blank">
          Devpost
        </Link>
        ).
      </Text>
    ),
    image: DevpostIconImg,
  },
  {
    title: "All done!",
    description: <Text>Good luck and have fun!</Text>,
    image: PinkSmileyImg,
    showImageInModal: true,
  },
];

export const EventIdToImgMap = {
  733: SponsorbayImg,
  734: OpeningImg,
  735: SponsorbayImg,
  737: CreativeIdeationImg,
  760: DrawphoneImg,
  768: IntroSecurityImg,
  738: BlockchainImg,
  775: YogaImg,
  776: AerobicsImg,
  773: CreatingImg,
  753: InternationalImg,
  740: FromImg,
  777: YogaImg,
  741: IntroVRImg,
  742: TechNeuroscienceImg,
  752: DesignersMeetUpImg,
  743: SponsorbayImg,
  744: HackerHackerImg,
  739: CodingEthImg,
  745: FoundingImg,
  754: HighImg,
  755: WebBackendImg,
  746: EmbeddedImg,
  747: HackerSponsorImg,
  758: EmpowermentImg,
  756: LadiesImg,
  778: WiCSImg,
  748: NetworkingImg,
  780: LGBTQImg,
  749: CrackingImg,
  750: DesigningImg,
  751: InteractiveImg,
  761: GameImg,
  781: MLImg,
  774: HowToPitchImg,
  762: TriviaImg,
  763: HardwareImg,
  764: WerewolfImg,
  765: HackathonOrganizersImg,
  759: ReactImg,
  732: DesignImg,
  779: DesignImg,
  757: DesignImg,
  772: ClosingImg,
};
