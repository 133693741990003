import { Flex, Text } from "@hackthenorth/north";
import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";

import { TOPNAVBAR_HEIGHT, Link } from "src/shared/components";
import { useSidebarContext } from "src/shared/contexts";
import { hideSidebarStyles } from "src/shared/contexts/SidebarContext/styles";
import { Nullable } from "src/shared/utils/typescript";
import { useGetEventScheduleQuery } from "src/views/schedule/ScheduleContext/graphql/getEventSchedule.generated";
import {
  computeBaseCopyWithTimezone,
  formatEventsFromQuery,
} from "src/views/schedule/ScheduleContext/utils";

import { TAnnoucement, ANNOUNCEMENTS } from "./constants";
import Slideshow from "./Slideshow";

const StyledCard = styled.div<{ showSidebar: boolean }>`
  position: fixed;
  top: ${TOPNAVBAR_HEIGHT}px;
  height: 37px;
  padding: 4px 0;
  background-color: ${({ theme }) => theme.globalConstants.color.bluePrimary3};

  ${hideSidebarStyles}
`;

const Annoucements: React.FC = () => {
  const { data } = useGetEventScheduleQuery();
  const events = data?.eventSchedule ?? [];

  const filteredEvents = computeBaseCopyWithTimezone(
    formatEventsFromQuery(
      events.filter(
        (event) =>
          event.tags &&
          event.tags.includes("hack_the_north") &&
          !event.tags.includes("banner") &&
          event.tags.includes("workshop")
      )
    )
  );

  const eventAnnoucements: TAnnoucement[] = filteredEvents.map((event) => ({
    title: event.title,
    link: event.links[0],
    start: event.start.subtract(30, "minutes").toString(),
    isWorkshop: true,
  }));

  const [annoucements, setAnnoucements] = useState<Nullable<TAnnoucement[]>>(
    null
  );

  const getAnnoucement = useCallback(() => {
    const now = moment(moment.now()).subtract(50, "days").subtract(12, "hours");

    const currentAnnoucements = ANNOUNCEMENTS.concat(eventAnnoucements).filter(
      (annoucement) =>
        now >= moment.utc(annoucement.start) &&
        now <
          moment
            .utc(annoucement.start)
            .add(annoucement.isWorkshop ? 30 : 10, "minutes")
    );

    return currentAnnoucements.length ? currentAnnoucements : null;
  }, [eventAnnoucements]);

  useEffect(() => {
    setInterval(() => {
      setAnnoucements(getAnnoucement());
    }, 300000); // check for new annoucement every 5 minutes
  }, [getAnnoucement]);

  const { showSidebar } = useSidebarContext();

  return annoucements ? (
    <StyledCard showSidebar={showSidebar} color="bluePrimary3">
      <Flex align="center" justify="center">
        <Slideshow
          items={annoucements.map((cur) => (
            <>
              <Link href={cur.link} target="_blank">
                <Text mods="heading h3 bluePrimary2">
                  {cur.title}
                  {cur.isWorkshop &&
                    ` will be starting at 
                      ${moment(cur.start)
                        .add(30, "minutes")
                        .format("hh:mm A")}!`}
                </Text>
              </Link>
            </>
          ))}
          numItemsToShow={1}
          isWrapAround
          isAutoPlay
        />
      </Flex>
    </StyledCard>
  ) : null;
};

export default Annoucements;
