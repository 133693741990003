import { Button, Flex, Spacer } from "@hackthenorth/north";
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

import { Icon } from "src/shared/components";
import { useDeviceSize } from "src/shared/hooks";
import { focusStyles } from "src/theme/sharedStyles";

const Container = styled(Flex).attrs({ align: "center" })`
  position: relative;
  height: 100%;
`;

const ShownItems = styled(Flex).attrs({ justify: "start" })`
  width: 100%;
`;

const NoStyleButton = styled(Button).attrs({ removeSharedStyles: true })<{
  isHidden: boolean;
}>`
  height: 24px;
  position: absolute;
  ${focusStyles}
  cursor: ${({ isHidden }) => isHidden && "unset"};
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};

  * {
    opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  }
`;

const LeftButton = styled(NoStyleButton)`
  padding-right: 12px;
  left: -30px;
  transition: 250ms;

  &:hover {
    transform: scale(1.35);
  }
`;

const RightButton = styled(NoStyleButton)`
  padding-left: 12px;
  right: -30px;
  transition: 250ms;

  &:hover {
    transform: scale(1.35);
  }
`;

const LeftChevron = styled(Icon).attrs({ name: "chevron-side" })`
  transform: rotate(180deg);
`;

const ItemWrapper = styled(Flex)`
  @keyframes anim {
    from {
      opacity: 0%;
    }
    to {
      opacity: 1;
    }
  }
  * {
    animation: anim 1s;
  }
  flex: 1;
`;

interface SlideshowProps {
  items: React.ReactNode[];
  paddingBetween?: number;
  numItemsToShow?: number;
  isWrapAround?: boolean; // allows continuous looping of items
  isAutoPlay?: boolean; // autoplay slideshow when element is inview
}

// disclaimer: isWrapAround only works when showing one item in the slideshow for now
const Slideshow: React.FC<SlideshowProps> = ({
  items,
  paddingBetween = 0,
  numItemsToShow = 4,
  isWrapAround = false,
  isAutoPlay = false,
}) => {
  const totalItems = items.length;
  const isExtraLargeOrSmaller = useDeviceSize("extraLarge");
  const [ref, isInView] = useInView();
  const autoplay = useRef<NodeJS.Timeout | null>(null);
  const [isHovering, setIsHovering] = useState(false);

  // show more items on larger screens
  const computedNumItemsToShow =
    isExtraLargeOrSmaller || numItemsToShow === 1
      ? numItemsToShow
      : numItemsToShow + 1;

  // items with index >= startIndex and index < startIndex + computedNumItemsToShow will be shown
  const [startIndex, setStartIndex] = useState(0);

  const shouldShowLeftArrow =
    startIndex > 0 || (isWrapAround && totalItems > 1);
  const shouldShowRightArrow =
    startIndex + computedNumItemsToShow < totalItems ||
    (isWrapAround && totalItems > 1);

  const getShouldShowIndex = useCallback(
    (index: number) => {
      const expectedEndIndex = startIndex + computedNumItemsToShow;
      let shouldShow =
        index >= startIndex && index < startIndex + computedNumItemsToShow;

      if (isWrapAround && expectedEndIndex >= totalItems) {
        shouldShow =
          index >= startIndex ||
          (index < startIndex && index + totalItems < expectedEndIndex);
      }
      return shouldShow;
    },
    [computedNumItemsToShow, isWrapAround, startIndex, totalItems]
  );

  const indicesToShow: number[] = useMemo(
    () =>
      Array.from(Array(items.length).keys()).filter((index) =>
        getShouldShowIndex(index)
      ),
    [getShouldShowIndex, items.length]
  );

  const onLeftClick = () => {
    if (isWrapAround && startIndex === 0) {
      setStartIndex(totalItems - 1);
    } else if (shouldShowLeftArrow) {
      setStartIndex((prev) => prev - computedNumItemsToShow);
    }
  };

  const onRightClick = useCallback(() => {
    if (isWrapAround && startIndex + computedNumItemsToShow >= totalItems) {
      setStartIndex((prev) => prev + computedNumItemsToShow - totalItems);
    } else if (shouldShowRightArrow) {
      setStartIndex((prev) => prev + computedNumItemsToShow);
    }
  }, [
    computedNumItemsToShow,
    shouldShowRightArrow,
    startIndex,
    totalItems,
    isWrapAround,
  ]);

  useEffect(() => {
    if (isInView && isAutoPlay && !isHovering) {
      autoplay.current = setTimeout(() => {
        onRightClick();
      }, 10000); // change slide every 10 seconds
    } else if (autoplay.current) {
      clearTimeout(autoplay.current);
    }
  }, [autoplay, isAutoPlay, isHovering, isInView, onRightClick]);

  /**
   *  render "blank spaces" to make sure items always take up the same amount of space
   *  ex. if you have 7 items, and show 5 on the first page and 2 on the next, we
   *  want to make surr all 7 items are the same size
   *  */
  const blankSpaces = Array.from(
    Array(computedNumItemsToShow - indicesToShow.length).keys()
  ).map((index) => <ItemWrapper key={index} />);

  return (
    <Container
      ref={ref}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <LeftButton onClick={onLeftClick} isHidden={!shouldShowLeftArrow}>
        <LeftChevron />
      </LeftButton>
      <ShownItems>
        {indicesToShow.map((val, indexInArray) => (
          <>
            <ItemWrapper>{items[val]}</ItemWrapper>
            {indexInArray !== indicesToShow.length - 1 && (
              <Spacer width={paddingBetween} />
            )}
          </>
        ))}
        {blankSpaces}
      </ShownItems>
      <RightButton onClick={onRightClick} isHidden={!shouldShowRightArrow}>
        <Icon name="chevron-side" />
      </RightButton>
    </Container>
  );
};

export default Slideshow;
