import { Spacer, Text, TextInput } from "@hackthenorth/north";
import React from "react";

type TPerkTextInputProps = {
  name: string;
  title: string;
  value: string;
  placeholder: string;
  isReadOnly: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
};

const PerkTextInput: React.FC<TPerkTextInputProps> = ({
  name,
  title,
  value,
  placeholder,
  isReadOnly,
  onChange,
}) => (
  <>
    <Text mods="medium" className={name}>
      {title}
    </Text>
    <Spacer height={8} />
    <TextInput
      className={`${name}--text-input`}
      onChange={onChange}
      placeholder={placeholder}
      readOnly={isReadOnly}
      value={value}
    />
  </>
);

export default PerkTextInput;
