import { Text, TextAreaInput, TextAreaInputProps } from "@hackthenorth/north";
import React, { useCallback, ChangeEvent } from "react";
import styled from "styled-components";

export interface WordLimitTextAreaProps
  extends Omit<TextAreaInputProps, "theme"> {
  wordLimit: number;
}

const WordCounter = styled(Text)`
  
  left: 26px;
  bottom: 24px;
`;

const Container = styled.div`
  position: relative;
`;

const WordLimitTextArea: React.FC<WordLimitTextAreaProps> = ({
  wordLimit,
  onChange,
  value,
  ...rest
}) => {
  const wordCount =
    (value as string | undefined)?.split(" ")?.filter((word) => !!word)
      .length || 0;

  const internalOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const wordList = e.target.value.split(" ").filter((word) => !!word);
      if (wordList.length > wordLimit) {
        e.target.value = `${wordList.slice(0, wordLimit).join(" ")} `;
        return;
      }
      if (onChange) onChange(e);
    },
    [onChange, wordLimit]
  );

  return (
    <Container>
      <TextAreaInput value={value} {...rest} onChange={internalOnChange} />
      <WordCounter mods="lightGrey">
        {wordCount}/{wordLimit} words
      </WordCounter>
    </Container>
  );
};

export default WordLimitTextArea;
