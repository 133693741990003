import { Spacer, Text, Link } from "@hackthenorth/north";
import React, { useState, useCallback, useRef } from "react";

import { Dropzone, DropzoneError, FileLink } from "src/shared/components";
import { useGetFile } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import Preview from "./Preview";

interface NewsletterFeatureProps {
  perk: SponsorPerks.NewsletterFeature;
}

type StoredPerk = SponsorPerks.NewsletterFeature;

const description = (
  <>
    <Text mods="medium">
      The Hack the North newsletter is sent out to hackers at the start of every
      month.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      The Spotlight will appear at the top of the newsletter email. Due to
      limited space, we may not be able to highlight your Spotlight until a
      later newsletter. Gold sponsors are given priority for this perk as there
      is only one Spotlight per newsletter. If you have an item you would really
      like to share for a specific month, please email us with the subject line
      &quot;[Company Name] Newsletter Spotlight Request&quot;.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      If your submission is selected for the upcoming month&apos;s newsletter,
      you will see this perk under the Complete section. Please allow us at
      least a week following your submission for our team to review.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Check out our{" "}
      <Link
        newTab
        mods="medium"
        href="https://hackthenorth.com/sponsorship-newsletter-template.pdf"
      >
        {" "}
        sample newsletter template here
      </Link>{" "}
      to see an example of the layout.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please submit an image in SVG or PNG form (SVG is preferred).
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.file &&
  !!perkData.title &&
  !!perkData.website_link &&
  !!perkData.description;

const NewsletterFeature: React.FC<NewsletterFeatureProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const {
    uploadFile,
    mutationResult: { loading: isFileUploading, error: fileUploadError },
  } = useUploadFile();
  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState("");
  const dropzoneInputRef = useRef<HTMLInputElement>();
  const fetchedFile = useGetFile(perkData.file);

  const error =
    errorMessage || dropzoneErrorMessage || fileUploadError?.message;
  const loading = isLoading || isUpdating || isFileUploading;

  const addFile = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const hapiFile = await uploadFile(file);

      updatePerkField("file", hapiFile);
    }
  };

  const deleteFile = () => {
    updatePerkField("file", null);
    if (dropzoneInputRef.current) dropzoneInputRef.current.value = "";
  };

  const onError = useCallback((error: DropzoneError) => {
    switch (error) {
      case DropzoneError.MAX_AMOUNT:
        setDropzoneErrorMessage("Please upload one file maximum.");
        return;
      case DropzoneError.INVALID_FORMAT:
        setDropzoneErrorMessage("Please upload a valid file format.");
        return;
      default:
        return;
    }
  }, []);

  const saveNewsletterFeaturePerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitNewsletterFeaturePerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.NEWSLETTER_FEATURE}
        readOnly={isReadOnly}
      >
        {!isReadOnly && (
          <Dropzone
            max={1}
            acceptedFileTypes={["png", "svg"]}
            onFilesUpload={addFile}
            onError={onError}
            ref={dropzoneInputRef}
            onBlur={() => setDropzoneErrorMessage("")}
          />
        )}
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <FileLink file={fetchedFile} deleteFile={deleteFile} />
          </>
        )}
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.NEWSLETTER_FEATURE}--title`}
          title="Title*"
          value={perkData["title"] ?? ""}
          placeholder="Your company's home or recruiting page"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextInput
          name={`${SponsorPerks.Type.NEWSLETTER_FEATURE}--website-link`}
          title="Website Link*"
          value={perkData["website_link"] ?? ""}
          placeholder="Ex. Home page"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("website_link", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.NEWSLETTER_FEATURE}--description`}
          title="Description* (max 40 words)"
          value={perkData["description"] ?? ""}
          placeholder="Share a message with hackers"
          wordLimit={40}
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("description", e.target.value)}
        />
        {fetchedFile && (
          <>
            <Spacer height={32} />
            <Preview
              image={fetchedFile}
              title={perkData["title"]}
              link={perkData["website_link"]}
              description={perkData["description"]}
            />
          </>
        )}
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.NEWSLETTER_FEATURE}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveNewsletterFeaturePerk}
          onSubmit={submitNewsletterFeaturePerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.NEWSLETTER_FEATURE} error={error} />
    </>
  );
};

export default NewsletterFeature;
