import { Text, UnstyledButton } from "@hackthenorth/north";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Link } from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { HackerStage } from "src/shared/contexts/HackerContext/types";

import WithdrawModal from "../modals/withdrawModal";

const FootnoteContainer = styled.div`
  max-width: 500px;
`;

export default () => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const { updateStage, stage } = useHackerContext();

  const withdrawApplication = useCallback(() => {
    updateStage(HackerStage.WITHDRAWN);
    setIsWithdrawModalOpen(false);
  }, [updateStage]);

  const hasWithdrawn = stage === HackerStage.WITHDRAWN;

  return (
    <>
      <WithdrawModal
        isOpen={isWithdrawModalOpen}
        onCancel={() => setIsWithdrawModalOpen(false)}
        onConfirm={withdrawApplication}
      />
      <FootnoteContainer>
        <Text mods="bold bodyBig">Have any questions?</Text>
        <br />
        <br />
        <Text>
          Feel free to message us at{" "}
          <Link href="mailto:hello@hackthenorth.com">
            hello@hackthenorth.com
          </Link>
          !
        </Text>
        <br />
        <br />
        {!hasWithdrawn && (
          <Text>
            Can’t make the event? If you cannot attend, please{" "}
            <UnstyledButton onClick={() => setIsWithdrawModalOpen(true)}>
              <Text mods="link">withdraw</Text>
            </UnstyledButton>{" "}
            your application so that we can give your spot to someone on the
            waitlist.
          </Text>
        )}
        <br />
        <br />
        <Text mods="bold bodyBig">
          - Hack the North Team{" "}
          <span role="img" aria-label="gear emoji">
            ⚙️
          </span>
        </Text>
      </FootnoteContainer>
    </>
  );
};
