import { Flex, Spacer, Text } from "@hackthenorth/north";
import React from "react";

type TPerkErrorProps = {
  error?: string;
  name: string;
};

const PerkError: React.FC<TPerkErrorProps> = ({ name, error }) =>
  error ? (
    <>
      <Spacer height={16} />
      <Flex>
        <Spacer width={48} />
        <Text mods="error" className={`${name}--error`}>
          {error}
        </Text>
      </Flex>
    </>
  ) : null;

export default PerkError;
