import { timesToOptionsExplicit } from "src/shared/utils/react-select";
import { formatTimeRange } from "src/utils/formatTime";

const TECH_TALK_TIMES = [
  [
    new Date(Date.UTC(2021, 0, 12, 21, 0, 0)),
    new Date(Date.UTC(2021, 0, 12, 23, 0, 0)),
  ],
  [
    new Date(Date.UTC(2021, 0, 13, 21, 0, 0)),
    new Date(Date.UTC(2021, 0, 13, 23, 0, 0)),
  ],
  [
    new Date(Date.UTC(2021, 0, 14, 21, 0, 0)),
    new Date(Date.UTC(2021, 0, 14, 23, 0, 0)),
  ],
  [
    new Date(Date.UTC(2021, 0, 15, 21, 0, 0)),
    new Date(Date.UTC(2021, 0, 15, 23, 0, 0)),
  ],
];

export const TIME_OPTIONS = timesToOptionsExplicit(
  formatTimeRange,
  TECH_TALK_TIMES
);
