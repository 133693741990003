import moment from "moment";

export const SMALL_COLUMN_WIDTH = 94;
export const BLOCK_HEIGHT = 80;

export const PRIVATE_CALENDAR_LINK =
  "https://calendar.google.com/calendar/u/1?cid=Y19pNTgzazNtaWI2dDA1Y2tva3QycGU2aGRrOEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t";
export const PUBLIC_CALENDAR_LINK =
  "https://calendar.google.com/calendar/u/1?cid=Y180dXM3MzJiNDJkZnIyaXY2OHYzOGZyYThuZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t";

export const GEAR_UP_START = moment.utc([2021, 0, 12, 8, 59]).local();
export const GEAR_UP_END = moment.utc([2021, 0, 15, 10, 59]).local();
export const HACK_THE_NORTH_START = moment.utc([2021, 0, 15, 11]).local();
export const HACK_THE_NORTH_END = moment.utc([2021, 0, 18, 1, 59]).local();

export const HOURS_LIST = [
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];
