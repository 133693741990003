import _ from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";

import {
  TBaseEvent,
  TEvent,
  TComputedEvents,
  TBannerEvent,
  EventCategory,
} from "../types";

export type THalfBakedEvent = Omit<TEvent, "overlapLevel" | "overlapStackSize">;

// Workaround for https://github.com/rotaready/moment-range/issues/263
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const moment = extendMoment(Moment as any);

// Split the events into their respsective days
// We can't hardcode this because this must be run after computeBaseCopyWithTimezone
const separateBaseCopyIntoDays = (baseCopy: THalfBakedEvent[]) => {
  const eventsMap = {};

  baseCopy.forEach((event) => {
    // To sort the events by day, we use the day as the key
    const eventStartDate = event.localStart.clone().startOf("day");

    // Set size of event block
    event.numberOfBlocks = moment
      .duration(event.localEnd.diff(event.localStart))
      .asHours();

    // Set distance-from-top of event block
    const startOfDay = event.localStart.clone().startOf("day");
    event.blocksFromTop = moment
      .duration(event.localStart.diff(startOfDay))
      .asHours();

    // Add it to the appropriate day's events
    (eventsMap[eventStartDate.format()] =
      eventsMap[eventStartDate.format()] ?? []).push(event);

    // If the event spilled over to the next day, add it to the next day's events too
    const eventEndDate = event.localEnd.clone().startOf("day");
    if (!moment(eventEndDate).isSame(eventStartDate)) {
      // The distance-from-top of event block will be different
      const startOfDay = event.localEnd.clone().startOf("day");
      const nextDayEvent = _.cloneDeep(event);
      nextDayEvent.blocksFromTop = moment
        .duration(event.localStart.diff(startOfDay))
        .asHours();
      nextDayEvent.isDuplicate = true;

      (eventsMap[eventEndDate.format()] =
        eventsMap[eventEndDate.format()] ?? []).push(nextDayEvent);
    }
  });

  return eventsMap;
};

const computeEventWithTimezone = (event: TBaseEvent): THalfBakedEvent => {
  // Get time locally
  const localStart = event.start.local();
  const localEnd = event.end.local();

  // Set time locally
  const displayedStart = localStart.format("h:mm A");
  const displayedEnd = localEnd.format("h:mm A");

  // Set size of event block
  const numberOfBlocks = moment.duration(localEnd.diff(localStart)).asHours();

  // Set distance-from-top of event block
  const startOfDay = localStart.clone().startOf("day");
  const blocksFromTop = moment.duration(localStart.diff(startOfDay)).asHours();

  return {
    ...event,
    localStart,
    localEnd,
    displayedStart,
    displayedEnd,
    numberOfBlocks,
    blocksFromTop,
  };
};

// To each event, add fields denoting the local start and end times
export const computeBaseCopyWithTimezone = (events: TBaseEvent[]) => {
  return events.map(computeEventWithTimezone);
};

const eventComparator = (event1: THalfBakedEvent, event2: THalfBakedEvent) => {
  const startTime1 = moment(event1.start);
  const endTime1 = moment(event1.end);
  const duration1 = endTime1.diff(startTime1, "minutes", true);

  const startTime2 = moment(event2.start);
  const endTime2 = moment(event2.end);
  const duration2 = endTime2.diff(startTime2, "minutes", true);

  return duration2 < duration1 ? -1 : 1;
};

// Sort the events in each day from longest -> shortest
// Necessary for overlapping to work properly
const computeCopySorted = (copy: Record<string, THalfBakedEvent[]>) => {
  Object.keys(copy).forEach((day) => copy[day].sort(eventComparator));
  return copy;
};

// An explanation: overlapLevel and overlapStackSize
// overlapLevel: determines the number of indents to the right that the event will be displayed with
// overlapStackSize: determines the size of each indent
const computeEventsWithOverlap = (events: THalfBakedEvent[]) => {
  const newEvents = events as TEvent[];
  for (let i = 0; i < newEvents.length; ++i) {
    const range = moment.range(newEvents[i].start, newEvents[i].end);

    // Out of the events we've already gone through,
    // get the maximum overlapLevel out of the events whose time range this event overlaps with
    // and add 1 to it to get this event's overlapLevel.
    // Also keep track of events whose overlapStackSize will have to be updated
    const eventsInStack = [newEvents[i]];
    let maxOverlapLevel = 0;
    for (let j = 0; j < i; ++j) {
      const otherRange = moment.range(newEvents[j].start, newEvents[j].end);
      if (range.overlaps(otherRange)) {
        eventsInStack.push(newEvents[j]); // pass by reference
        if (
          newEvents[j].overlapLevel &&
          newEvents[j].overlapLevel > maxOverlapLevel
        )
          maxOverlapLevel = newEvents[j].overlapLevel;
      }
    }

    // Update overlapStackSize of events
    eventsInStack.forEach(
      (newEvent) => (newEvent.overlapStackSize = maxOverlapLevel + 1)
    );

    newEvents[i].overlapLevel = maxOverlapLevel + 1;
  }
};

const computeCopyWithOverlap = (copy: Record<string, THalfBakedEvent[]>) => {
  Object.keys(copy).forEach((day) => computeEventsWithOverlap(copy[day]));
  return copy as TComputedEvents;
};

const getEventCategory = (tags: string[]) => {
  if (tags.includes("activity")) {
    return EventCategory.ACTIVITY;
  } else if (tags.includes("sponsors")) {
    return EventCategory.SPONSORS;
  } else if (tags.includes("tech_talks")) {
    return EventCategory.TECH_TALKS;
  } else if (tags.includes("workshop")) {
    return EventCategory.WORKSHOP;
  } else {
    return EventCategory.EVENT;
  }
};

// TODO: Fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatEventsFromQuery = (events: any[]) => {
  return events.map((event) => {
    return {
      id: event.id,
      title: event.title,
      start: moment(event.start_time),
      end: moment(event.end_time),
      category: getEventCategory(event.tags),
      description: event.description,
      location: event.location,
      links: event.links ?? [],
    };
  });
};

// TODO: Fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const computeEvents = (events: any[]) =>
  computeCopyWithOverlap(
    computeCopySorted(
      separateBaseCopyIntoDays(
        computeBaseCopyWithTimezone(formatEventsFromQuery(events))
      )
    )
  );

// TODO: Fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatBannerEventsFromQuery = (events: any[]) => {
  return events.map((event) => {
    return {
      id: event.id,
      title: event.title,
      start: moment(event.start_time),
    };
  });
};

const separateBannerEventsByDay = (bannerEvents: TBannerEvent[]) => {
  const eventsMap = {};

  bannerEvents.forEach((event) => {
    // To sort the events by day, we use the day as the key
    const eventLocalStartDate = event.start.local().clone().startOf("day");

    // Set the time in the title
    event.title += `—${event.start.local().format("hA")}`;

    // Add it to the appropriate day's events
    (eventsMap[eventLocalStartDate.format()] =
      eventsMap[eventLocalStartDate.format()] ?? []).push(event);
  });

  return eventsMap;
};

// TODO: Fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const computeBannerEvents = (bannerEvents: any[]) =>
  separateBannerEventsByDay(formatBannerEventsFromQuery(bannerEvents));
