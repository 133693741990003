/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type GetCompanyRepresentativesQueryVariables = Types.Exact<{
  companyClaimId: Types.Scalars["String"];
}>;

export type GetCompanyRepresentativesQuery = { __typename?: "Query" } & {
  claims: Array<
    { __typename?: "Claim" } & Pick<Types.Claim, "id" | "name" | "stage_id"> & {
        fields: Array<
          { __typename?: "FieldWithAnswer" } & Pick<
            Types.FieldWithAnswer,
            "id" | "field_type" | "slug" | "valid_values" | "updated_at"
          > & {
              answer?: Types.Maybe<
                { __typename?: "FieldAnswer" } & Pick<
                  Types.FieldAnswer,
                  "id" | "value" | "updated_at"
                >
              >;
            }
        >;
      }
  >;
};

export const GetCompanyRepresentativesDocument = gql`
  query GetCompanyRepresentatives($companyClaimId: String!) {
    claims(
      where: {
        pipeline: {
          is: { slug: { equals: "hackthenorth2020-sponsor-individuals" } }
        }
        field_answers: {
          some: {
            AND: [
              { field: { is: { slug: { equals: "company_claim_id" } } } }
              { value: { equals: $companyClaimId } }
            ]
          }
        }
      }
    ) {
      id
      name
      stage_id
      fields {
        id
        field_type
        slug
        answer {
          id
          value
          updated_at
        }
        valid_values
        updated_at
      }
    }
  }
`;

/**
 * __useGetCompanyRepresentativesQuery__
 *
 * To run a query within a React component, call `useGetCompanyRepresentativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyRepresentativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyRepresentativesQuery({
 *   variables: {
 *      companyClaimId: // value for 'companyClaimId'
 *   },
 * });
 */
export function useGetCompanyRepresentativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetCompanyRepresentativesQuery,
    GetCompanyRepresentativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetCompanyRepresentativesQuery,
    GetCompanyRepresentativesQueryVariables
  >(GetCompanyRepresentativesDocument, baseOptions);
}
export function useGetCompanyRepresentativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompanyRepresentativesQuery,
    GetCompanyRepresentativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetCompanyRepresentativesQuery,
    GetCompanyRepresentativesQueryVariables
  >(GetCompanyRepresentativesDocument, baseOptions);
}
export type GetCompanyRepresentativesQueryHookResult = ReturnType<
  typeof useGetCompanyRepresentativesQuery
>;
export type GetCompanyRepresentativesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyRepresentativesLazyQuery
>;
export type GetCompanyRepresentativesQueryResult = ApolloReactCommon.QueryResult<
  GetCompanyRepresentativesQuery,
  GetCompanyRepresentativesQueryVariables
>;
