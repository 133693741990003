/* eslint-disable @typescript-eslint/camelcase */
import { Text, Spacer, Button, Flex } from "@hackthenorth/north";
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";

import { GradientText } from "src/shared/components";
import Modal from "src/shared/components/Modal";
import { PRIORITY_TO_LABEL } from "src/shared/constants/mentorship";
import { useMentorContext, useUserContext } from "src/shared/contexts";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { SomethingWentWrongImg } from "src/static/img";
import { formatTime } from "src/utils/formatTime";

import { useGetMentorshipRequestLazyQuery } from "../graphql/getMentorshipRequest.generated";

import Categories from "./Categories";

interface ClaimRequestModalProps {
  onClose: () => void;
  isOpen: boolean;
  request?: MentorshipRequests.Request;
}

const StyledModal = styled(Modal)`
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
`;

const CategoryWrapper = styled.div`
  display: inline-block;
`;

const TextWrapper = styled.div`
  display: inline-block;
  width: 120px;
  margin-bottom: 10px;
`;

const FlexWrapper = styled(Flex)`
  max-height: 400px;
`;

const Img = styled.img`
  width: 200px;
`;

export const ClaimRequestModal: React.FC<ClaimRequestModalProps> = ({
  onClose,
  isOpen,
  request,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [readyToClaim, setReadyToClaim] = useState(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);

  const { updateRequest, refetchClaimedRequests } = useMentorContext();
  const { id } = useUserContext();
  const [
    getMentorshipRequest,
    { data: requestData },
  ] = useGetMentorshipRequestLazyQuery();

  const claimRequest = useCallback(() => {
    if (request && id) {
      request.stage = "CLAIMED";
      request.mentor_id = id;
      updateRequest(request);
      onClose();
    }
  }, [request, updateRequest, id, onClose]);

  useEffect(() => {
    if (readyToClaim) {
      claimRequest();
      setReadyToClaim(false);
      refetchClaimedRequests();
    }
  }, [readyToClaim, request, claimRequest, refetchClaimedRequests]);

  useEffect(() => {
    if (requestData) {
      const stage = requestData.claims[0].stage_id;
      if (stage !== MentorshipRequests.Stage["OPEN"]) {
        setAlreadyClaimed(true);
      } else {
        setReadyToClaim(true);
      }
    }
  }, [requestData]);

  const handleCancel = () => {
    setShowConfirm(false);
    onClose();
  };

  const handleConfirm = useCallback(() => {
    if (request) {
      getMentorshipRequest({
        variables: {
          claimId: request.id,
        },
      });
    }
  }, [request, getMentorshipRequest]);

  if (alreadyClaimed) {
    return (
      <StyledModal
        isOpen={isOpen}
        onClose={onClose}
        width="780px"
        actions={
          <Button mods="secondary" onClick={handleCancel}>
            Close and Refresh
          </Button>
        }
      >
        <Flex align="center">
          <Img src={SomethingWentWrongImg} alt="" aria-hidden />
          <Spacer width={40} />
          <FlexWrapper column justify="center">
            <GradientText gradient="gradientRedBlue172" mods="heading h2">
              Aw, snap!
            </GradientText>
            <Spacer height={12} />
            <Text mods="heading big">
              Looks like this request has already been claimed.
            </Text>
          </FlexWrapper>
        </Flex>
      </StyledModal>
    );
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={onClose}
      width="780px"
      actions={
        <>
          <Button mods="secondary" onClick={handleCancel}>
            Close
          </Button>
          <Spacer width="20px" />
          {!showConfirm && (
            <Button mods="primary" onClick={() => setShowConfirm(true)}>
              Claim
            </Button>
          )}
          {showConfirm && (
            <Button mods="confirm" onClick={handleConfirm}>
              Confirm
            </Button>
          )}
        </>
      }
    >
      <Text mods="heading h2">{request?.title}</Text>
      <Spacer height={16} />
      <Text mods="medium">
        <Text mods="bold">Subject: </Text> {request?.description}
      </Text>
      <Spacer height={16} />
      <div>
        <Flex>
          <TextWrapper>
            <Text mods="big medium">Category</Text>
          </TextWrapper>
          <CategoryWrapper>
            <Categories categories={request?.category} />
          </CategoryWrapper>
        </Flex>
        <div>
          <TextWrapper>
            <Text mods="big medium">Priority</Text>
          </TextWrapper>
          <Text mods="medium">
            {request &&
              request.priority !== null &&
              request.priority !== undefined &&
              PRIORITY_TO_LABEL[request.priority]}
          </Text>
        </div>
        <div>
          <TextWrapper>
            <Text mods="big medium">Requester</Text>
          </TextWrapper>
          <Text mods="medium">{request?.mentee_name}</Text>
        </div>
        <div>
          <TextWrapper>
            <Text mods="big medium">Created</Text>
          </TextWrapper>
          <Text mods="medium">
            {request &&
              request.created_at !== null &&
              request.created_at !== undefined &&
              formatTime(request.created_at)}
          </Text>
        </div>
      </div>
    </StyledModal>
  );
};
