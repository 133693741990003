import React, { useEffect } from "react";
import styled from "styled-components";

import { useAccordionContext } from "./context";
import { useSharedAccordionContext } from "./SharedContext";

interface HeadingContainerProps extends React.ComponentPropsWithoutRef<"div"> {
  disabled?: boolean;
}

const Container = styled.div<{ disabled?: boolean }>`
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const HeadingContainer: React.FC<HeadingContainerProps> = ({
  disabled = false,
  children,
  ...rest
}) => {
  const { toggleOpen, setOpen } = useAccordionContext();
  const { isUsingSharedContext, isSharedOpen } = useSharedAccordionContext();

  useEffect(() => {
    isUsingSharedContext && setOpen(isSharedOpen);
  }, [setOpen, isUsingSharedContext, isSharedOpen]);

  return (
    <Container
      onClick={toggleOpen}
      tabIndex={0}
      onKeyPress={(e) => {
        // enter (13)
        if (e.charCode === 13) toggleOpen();
      }}
      role="button"
      disabled={disabled}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default HeadingContainer;
