import React from "react";

import { OutlineDocument, PageWrapper } from "src/shared/components";
import { usePermissionsContext, Permission } from "src/shared/contexts";

const GENERAL_HELP_ID = "ebc1bcde-a66d-4c76-8b9a-7f138750d4e5";
const PERMISSION_BASED_HELP_DOCUMENTS: Partial<Record<Permission, string>> = {
  [Permission.SPONSOR]: "ecaf2a07-7e28-48cb-9b79-58d8212c6807",
};

const HelpHome: React.FC = () => {
  const { hasPermission } = usePermissionsContext();

  // map roles to document IDs
  const documentsToDisplay = Object.entries(PERMISSION_BASED_HELP_DOCUMENTS)
    .map(([p, id]) => (hasPermission(p as Permission) ? id : null))
    .filter((id) => id !== null) as string[];

  return (
    <PageWrapper>
      <OutlineDocument shareId={GENERAL_HELP_ID} />
      {documentsToDisplay.map((id) => (
        <OutlineDocument key={id} shareId={id} />
      ))}
    </PageWrapper>
  );
};

export default HelpHome;
