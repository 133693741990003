import { Spinner } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import * as Sponsor from "src/views/sponsor/types";

import Checkbox from "./Checkbox";
import useSponsorCompanies from "./useSponsorCompanies";

const CheckboxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 180px);
  grid-gap: 15px;
  justify-content: space-between;

  & > * {
    display: flex;
    width: 180px;
    height: 100px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      width: 100%;
      height: 130px;
    `}
  }
`;

const CheckboxLogo = styled.img`
  margin: auto;
  max-width: 60%;
  max-height: 60%;
`;

type CompanySelectProps = {
  tiers: Sponsor.Tier[];
  onChange: (companyIds: string[]) => void;
  value: string[];
  max?: number;
  disabled?: boolean;
};

const CompanySelect: React.FC<CompanySelectProps> = ({
  value,
  max = Infinity,
  disabled = false,
  onChange,
  tiers,
}) => {
  const { data, loading } = useSponsorCompanies();

  if (loading) return <Spinner />;

  const companies =
    data?.filter((company) => tiers.includes(company.tier)) ?? data;

  return (
    <CheckboxGrid>
      {companies?.map((company) => (
        <Checkbox
          disabled={disabled}
          key={company.id}
          value={value.includes(company.id.toString())}
          onChange={(selected: boolean) => {
            const newOptions =
              selected && value.length < max
                ? [...value, company.id.toString()]
                : value.filter(
                    (companyId) => companyId !== company.id.toString()
                  );
            onChange(newOptions);
          }}
        >
          <CheckboxLogo src={company.logo} alt={company.name} />
        </Checkbox>
      ))}
    </CheckboxGrid>
  );
};

export default CompanySelect;
