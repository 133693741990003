import {
  Text,
  Spacer,
  DropdownInput,
  Flex,
  Button,
  Spinner,
} from "@hackthenorth/north";
import React, { useState } from "react";
import styled from "styled-components";

import {
  PageWrapper,
  MultiSelect,
  Checkbox,
  Divider,
} from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import {
  answersToOptions,
  optionsToAnswers,
  answerToOption,
  Options,
  Option,
} from "src/shared/utils/react-select";
import { notNull } from "src/utils/validation";

import { ContentCard, Popup } from "../LayoutComponents";

import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";
import SizeChart from "./SizeChart";
import TechTalkSelect from "./TechTalkSelect";

const MEETUP_OPTIONS = [
  "Artists in tech",
  "BIPOC hackers",
  "Black hackers",
  "Blockchain hackers",
  "Data / machine learning hackers",
  "Designers",
  "Game developers",
  "Grad school hackers",
  "Graphics hackers",
  "Hackathon organizers",
  "Hardware hackers",
  "High school hackers",
  "International hackers",
  "JS/React hackers",
  "Ladies Storm Hackathons",
  "Latinx hackers",
  "LGBTQ+ hackers",
  "Making accessible products",
  "Mobile applications hackers",
  "VR/AR hackers",
  "Web Backend hackers",
];

const MENTORSHIP_OPTIONS = [
  "Software",
  "Hardware",
  "Design",
  "Web",
  "Frontend",
  "Backend",
  "Data science",
  "Computer vision",
  "Machine learning",
  "Blockchain",
  "Android",
  "iOS",
  "Game development",
  "Virtual Reality",
  "Augmented Reality",
  "Infrastructure",
  "Cloud Computing",
  "Security",
  "Embedded",
  "Robotics",
];

const SHIRT_TYPE_OPTIONS = ["Unisex", "Fitted"];
const UNISEX_SIZE_OPTIONS = ["XS", "S", "M", "L", "XL", "2XL"];
const FITTED_SIZE_OPTIONS = ["S", "M", "L", "XL"];

const DropdownContainer = styled.div`
  text-align: left;
  width: 48%;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ErrorText = styled(Text).attrs({ mods: "error" })`
  display: inline-block !important;
  margin: 4px 0 0 12px !important;
`;

const EventPage: React.FC = () => {
  const {
    updateResponses,
    isLoading,
    isReadOnly,
    navigateToRSVP,
    navigateNext,
  } = useHackerContext();

  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const [showErrors, setShowErrors] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const isShirtTypeValid = notNull(responsesState[Field.SHIRT_TYPE]);
  const isShirtSizeValid = notNull(responsesState[Field.SHIRT_SIZE]);

  const onSave = async () => {
    setShowErrors(true);

    if (isValid) {
      await updateResponses({
        ...responsesState,
        [Field.EVENT_RSVP_STAGE]: PageStage.COMPLETED,
      });
      navigateNext();
    } else {
      window.scrollTo(0, 0);
      setShowErrorModal(true);
    }
  };

  const ErrorTextComponent = showErrors && (
    <ErrorText>This field is required</ErrorText>
  );

  return (
    <PageWrapper pageTitle="Event Information">
      <Text mods="big medium" className="event-info-description">
        Help us curate your Hack the North experience by providing information
        about yourself and your preferences.
      </Text>
      <Spacer height={46} />
      <ContentCard>
        <Text mods="h2 heading">Swag</Text>
        <Text mods="big medium">
          We will try our best to accommodate your preferred size but it is not
          a guarantee.
        </Text>
        <Spacer height={48} />
        <Flex justify="space-around" wrap="wrap">
          <SizeChart unisex />
          <SizeChart />
        </Flex>
        <Spacer height={48} />
        <Flex justify="space-between" wrap="wrap">
          <DropdownContainer>
            <Text mods="medium">Shirt type</Text>
            <Spacer height={10} />
            <DropdownInput
              disabled={isReadOnly}
              isHideDropdownIndicator={isReadOnly}
              value={answerToOption(responsesState[Field.SHIRT_TYPE])}
              options={answersToOptions(SHIRT_TYPE_OPTIONS)}
              placeholder="Unisex or Fitted"
              onChange={(option: Option) =>
                setResponsesState(Field.SHIRT_TYPE, option.value)
              }
            />
            {!isShirtTypeValid && ErrorTextComponent}
          </DropdownContainer>
          <DropdownContainer>
            <Text mods="medium">Shirt size</Text>
            <Spacer height={10} />
            <DropdownInput
              disabled={isReadOnly || !isShirtTypeValid}
              isHideDropdownIndicator={isReadOnly}
              value={answerToOption(responsesState[Field.SHIRT_SIZE])}
              readOnly={!responsesState[Field.SHIRT_TYPE]}
              placeholder="Size"
              options={
                !!responsesState[Field.SHIRT_TYPE] &&
                responsesState[Field.SHIRT_TYPE] === "Unisex"
                  ? answersToOptions(UNISEX_SIZE_OPTIONS)
                  : answersToOptions(FITTED_SIZE_OPTIONS)
              }
              onChange={(option: Option) =>
                setResponsesState(Field.SHIRT_SIZE, option.value)
              }
            />
            {!isShirtSizeValid && ErrorTextComponent}
          </DropdownContainer>
        </Flex>
        <Spacer height={32} />
        <Text mods="medium">
          If you are in the Waterloo/Kitchener area for the week of January
          11th-15th, would you be able to pickup swag at the University of
          Waterloo?
        </Text>
        <Spacer height={8} />
        <Text mods="italic">
          Help us and the environment out by picking up your swag!
        </Text>
        <Spacer height={16} />
        <CheckboxContainer>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.SWAG_PICKUP]}
            onChange={(change: boolean) =>
              setResponsesState(Field.SWAG_PICKUP, change)
            }
          />
          <Text>
            Yes, I will be able to pick up my swag curbside from the University
            of Waterloo campus
          </Text>
        </CheckboxContainer>
        <Spacer height={32} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">Tech Talks</Text>
        <Text mods="medium">
          Select up to 3 of the following tech talks you are interested in.
        </Text>
        <Spacer height={26} />
        <TechTalkSelect
          disabled={isReadOnly}
          selectedTalks={responsesState[Field.TECH_TALKS] || []}
          max={3}
          onChange={(options: string[]) => {
            setResponsesState(Field.TECH_TALKS, options);
          }}
        />
        <Spacer height={53} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">Meetups</Text>
        <Text mods="medium">
          What meetups would you be interested in attending?
        </Text>
        <Spacer height={8} />
        <Text mods="italic">
          Please select any topics you’re interested in and feel free to suggest
          your own!
        </Text>
        <Spacer height={10} />
        <MultiSelect
          creatable
          isDisabled={isReadOnly}
          value={answersToOptions(responsesState[Field.NETWORKING] || [])}
          options={answersToOptions(MEETUP_OPTIONS)}
          onChange={(selectedOptions: Options) =>
            setResponsesState(
              Field.NETWORKING,
              optionsToAnswers(selectedOptions)
            )
          }
          placeholder="Type to add option"
        />
        <Spacer height={53} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">Mentorship</Text>
        <Text mods="medium">
          Would you be interested in being a mentor for beginner hackers?
        </Text>
        <Spacer height={24} />
        <CheckboxContainer>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.MENTORSHIP_INTEREST]}
            onChange={(change: boolean) =>
              setResponsesState(Field.MENTORSHIP_INTEREST, change)
            }
          />
          <Text>Yes, I would be interested in being a mentor</Text>
        </CheckboxContainer>
        {responsesState[Field.MENTORSHIP_INTEREST] && (
          <>
            <Spacer height={24} />
            <Text mods="medium">Areas of expertise</Text>
            <Spacer height={10} />
            <MultiSelect
              isDisabled={
                !responsesState[Field.MENTORSHIP_INTEREST] || isReadOnly
              }
              value={answersToOptions(
                responsesState[Field.MENTORSHIP_TOPICS] || []
              )}
              options={answersToOptions(MENTORSHIP_OPTIONS)}
              onChange={(selectedOptions: Options) =>
                setResponsesState(
                  Field.MENTORSHIP_TOPICS,
                  optionsToAnswers(selectedOptions)
                )
              }
              placeholder="Type to add option"
            />
          </>
        )}
        <Spacer height={50} />
        <Divider />
        <Spacer height={38} />
        <Text mods="h2 heading">
          WiCS (Women in Computer Science) Coffee Chats
        </Text>
        <Spacer height={24} />
        <CheckboxContainer>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.WICS_COFFEE_CHAT]}
            onChange={(change: boolean) =>
              setResponsesState(Field.WICS_COFFEE_CHAT, change)
            }
          />
          <Text>
            I am a female-identifying high school student, and would be
            interested in signing up for a WiCS coffee chat
          </Text>
        </CheckboxContainer>
        <Spacer height={50} />
        <Flex justify="flex-end">
          <Button mods="secondary" onClick={navigateToRSVP}>
            Back to RSVP
          </Button>
          {!isReadOnly && (
            <>
              <Spacer width={24} />
              <Button mods="primary" disabled={isLoading} onClick={onSave}>
                {isLoading ? (
                  <Flex align="center" justify="center">
                    <Spinner />
                    <Spacer width={10} />
                    Saving
                  </Flex>
                ) : (
                  "Save and continue"
                )}
              </Button>
            </>
          )}
        </Flex>
      </ContentCard>
      <Popup isOpen={showErrorModal}>
        <Text mods="white">Please fill all the required fields</Text>
      </Popup>
    </PageWrapper>
  );
};

export default EventPage;
