import {
  Link as NorthLink,
  UnstyledLink as UnstyledNorthLink,
} from "@hackthenorth/north";
import React, { useMemo } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

import { linkSharedStyles } from "src/theme/componentStyles/link";

type LinkProps = React.ComponentPropsWithoutRef<"a">;

const UnstyledReactRouterLink = styled(ReactRouterLink)`
  text-decoration: none;
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  ${linkSharedStyles}
`;

const isRelativeHref = (href: string) => {
  try {
    new URL(href);
    return false;
  } catch (err) {
    return true;
  }
};

export const UnstyledLink: React.FC<LinkProps> = ({ href = "", ...rest }) => {
  const isInternal: boolean = useMemo(() => isRelativeHref(href), [href]);

  return isInternal ? (
    <UnstyledReactRouterLink to={href} {...rest}></UnstyledReactRouterLink>
  ) : (
    <UnstyledNorthLink href={href} {...rest}></UnstyledNorthLink>
  );
};

const Link: React.FC<LinkProps> = ({ href = "", ...rest }) => {
  const isInternal: boolean = useMemo(() => isRelativeHref(href), [href]);

  return isInternal ? (
    <StyledReactRouterLink to={href} {...rest}></StyledReactRouterLink>
  ) : (
    <NorthLink href={href} {...rest}></NorthLink>
  );
};

export default Link;
