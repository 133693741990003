import Cookies from "js-cookie";

import { Route } from "src/shared/constants/route";
import { Permission } from "src/shared/contexts/PermissionsContext/types";
import {
  DeepReplaceKeys,
  DeepReplace,
  DeepReplaceType,
} from "src/shared/utils/typescript";

import { SidebarItem } from "../SidebarItem";
import { SidebarSectionProps } from "../SidebarSection";

// replaces `locked` field with `requiredPermissions` field
type SidebarItemWithPermissionKey = DeepReplaceKeys<
  SidebarItem,
  { locked: "requiredPermissions" }
>;

// replaces boolean type of `requiredPermissions` with Permission[]
export type SidebarItemDefinition = DeepReplace<
  SidebarItemWithPermissionKey,
  { requiredPermissions: Permission[] }
>;

export type SidebarSectionDefinition = DeepReplaceType<
  SidebarSectionProps,
  [SidebarItem, SidebarItemDefinition]
>;

// todo: mergeDeep sections together so sponsor > documents can appear under the General > Documents section
// todo: add correct sections
export const UNAUTHED_SIDEBAR_SECTIONS: SidebarSectionDefinition[] = [
  {
    sectionTitle: "General",
    sectionContent: [
      { text: "Home", href: Route.HOME },
      { text: "Schedule", href: Route.SCHEDULE },
    ],
  },
];

export const MENTOR_BASE_SIDEBAR_SECTIONS: SidebarSectionDefinition[] = [
  {
    sectionTitle: "General",
    sectionContent: [
      { text: "Home", href: Route.HOME },
      {
        text: "Schedule",
        href: Route.SCHEDULE,
      },
      {
        text: "Documents",
        subsectionContent: [
          {
            text: "Code of Conduct",
            href: Route.DOC_COC,
          },
          {
            text: "Terms & Conditions",
            href: Route.DOC_TAC,
          },
          {
            text: "Privacy Policy",
            href: Route.DOC_PRIVACY,
          },
        ],
      },
      { text: "Help", href: Route.HELP },
    ],
  },
];

export const SPONSOR_BASE_SIDEBAR_SECTIONS: SidebarSectionDefinition[] = [
  {
    sectionTitle: "General",
    sectionContent: [
      { text: "Home", href: Route.HOME },
      {
        text: "Schedule",
        href: Route.SCHEDULE,
      },
      {
        text: "Documents",
        subsectionContent: [
          {
            text: "Code of Conduct",
            href: Route.DOC_COC,
          },
          {
            text: "Terms & Conditions",
            href: Route.DOC_TAC,
          },
          {
            text: "Privacy Policy",
            href: Route.DOC_PRIVACY,
          },
        ],
      },
      { text: "Help", href: Route.HELP },
    ],
  },
];

export const HACKER_BASE_SIDEBAR_SECTIONS: SidebarSectionDefinition[] = [
  {
    sectionTitle: "General",
    sectionContent: [
      { text: "Home", href: Route.HOME },
      {
        text: "Schedule",
        href: Route.SCHEDULE,
      },
    ],
  },
];

export const PERMISSION_BASED_SECTIONS: Partial<
  Record<Permission, SidebarSectionDefinition[]>
> = {
  [Permission.SPONSOR]: [
    {
      sectionTitle: "Sponsors",
      sectionContent: [
        {
          text: "Perks",
          href: Route.SPONSOR_PERKS,
          requiredPermissions: [
            Permission.SPONSOR_FEATURES_ACCESS,
            Permission.SPONSOR_ADMIN,
          ],
          lockedTooltipErrors: {
            [Permission.SPONSOR_FEATURES_ACCESS]:
              "Complete Onboarding items to get access to this page.",
            [Permission.SPONSOR_ADMIN]:
              "You must be an Admin access to this page.",
          },
        },
        {
          text: "Representatives",
          href: Route.SPONSOR_REPRESENTATIVES,
          requiredPermissions: [Permission.SPONSOR_FEATURES_ACCESS],
          lockedTooltipErrors: {
            [Permission.SPONSOR_FEATURES_ACCESS]:
              "Complete Onboarding items to get access to this page.",
          },
        },
        {
          text: "Recruitment",
          href: Route.SPONSOR_RECRUITMENT,
          requiredPermissions: [
            Permission.SPONSOR_FEATURES_ACCESS,
            Permission.SPONSOR_ADMIN,
            Permission.SPONSOR_RECRUITMENT_ACCESS,
          ],
          lockedTooltipErrors: {
            [Permission.SPONSOR_FEATURES_ACCESS]:
              "Complete Onboarding items to get access to this page.",
            [Permission.SPONSOR_ADMIN]:
              "You must be an Admin access to this page.",
            [Permission.SPONSOR_RECRUITMENT_ACCESS]:
              "You will have access to this page one week after the event.",
          },
        },
        {
          text: "Platform Onboarding",
          href: Route.SPONSOR_PLATFORM_ONBOARDING,
        },
      ],
    },
  ],
  [Permission.HACKER_RSVP]: [
    {
      sectionTitle: "RSVP",
      sectionContent: [
        {
          text: "Application",
          subsectionContent: [
            {
              text: "RSVP",
              href: Route.HACKER_RSVP,
            },
            {
              text: "Personal",
              href: Route.HACKER_PERSONAL,
              requiredPermissions: [Permission.HACKER_RSVP_NOT_RESTRICTED],
              lockedTooltipErrors: {
                [Permission.HACKER_RSVP_NOT_RESTRICTED]:
                  "RSVP items are locked as you have withdrawn your application.",
              },
            },
            {
              text: "Event",
              href: Route.HACKER_EVENT,
              requiredPermissions: [Permission.HACKER_RSVP_NOT_RESTRICTED],
              lockedTooltipErrors: {
                [Permission.HACKER_RSVP_NOT_RESTRICTED]:
                  "RSVP items are locked as you have withdrawn your application.",
              },
            },
            {
              text: "Career",
              href: Route.HACKER_CAREER,
              requiredPermissions: [Permission.HACKER_RSVP_NOT_RESTRICTED],
              lockedTooltipErrors: {
                [Permission.HACKER_RSVP_NOT_RESTRICTED]:
                  "RSVP items are locked as you have withdrawn your application.",
              },
            },
          ],
        },
      ],
    },
  ],
  [Permission.HACKER_DAY_OF_TOOLS_ACCESS]: [
    {
      sectionTitle: "Hackers",
      sectionContent: [
        {
          text: "Getting Started",
          href: Route.HACKER_GETTING_STARTED,
        },
        {
          text: "Mentorship Tool",
          href: Route.HACKER_MENTORSHIP,
        },
        {
          text: "Badges",
          href: Route.HACKER_BADGES,
        },
        {
          text: "Information",
          subsectionContent: [
            {
              text: "Workshops",
              href: Route.HACKER_WORKSHOPS,
            },
            {
              text: "Judging",
              href: Route.HACKER_JUDGING,
            },
            {
              text: "Community",
              href: Route.HACKER_COMMUNITY,
            },
          ],
        },
        {
          text: "FAQ",
          href: Route.HACKER_FAQ,
        },
      ],
    },
  ],
  [Permission.MENTOR]: [
    {
      sectionTitle: "Mentorship",
      sectionContent: [
        {
          text: "Open Requests",
          href: Route.MENTOR_REQUESTS,
        },
        {
          text: "Claimed Requests",
          href: Route.MENTOR_CLAIMED_REQUESTS,
        },
        {
          text: "Profile",
          href: Route.MENTOR_PROFILE,
        },
      ],
    },
  ],
};

export const INTERNAL_TESTING_SECTIONS: SidebarSectionDefinition[] = [
  {
    sectionTitle: "INTERNAL TESTING",
    sectionContent: [
      { text: "Playground", href: Route.PLAYGROUND },
      {
        text: "Impersonate",
        onClick: () => {
          const token = window.prompt("JWT hackerapi access token");

          if (token) {
            Cookies.set("accessJwt", token, {
              expires: Date.now() + 345600000,
              domain: "",
            });
          }
        },
      },
    ],
  },
];
