import { Link, Spacer, Text, Flex } from "@hackthenorth/north";
import React from "react";
import styled from "styled-components";

import { Card } from "src/shared/components";
import { DownloadIcon } from "src/static/icons";
import {
  Poster10mg,
  Poster11Img,
  Poster12Img,
  Poster13mg,
  Poster14mg,
  Poster15mg,
  Poster16mg,
  Poster1Img,
  Poster2Img,
  Poster3Img,
  Poster4Img,
  Poster5Img,
  Poster6Img,
  Poster7Img,
  Poster8Img,
  Poster9Img,
} from "src/static/posters";

import Slideshow from "./Slideshow";

const CardWithOutline = styled(Card)`
  border: 1px solid ${({ theme }) => theme.globalConstants.color.greySecondary};
  padding: 8px;
  height: 100%;
`;

const Img = styled.img`
  height: 100%;
  width: 220px;
  border-radius: 6px;

  ${({ theme }) => theme.mediaQueries.large`
    width: 150px;
  `};
`;

const NoStyleLink = styled(Link).attrs({ removeSharedStyles: true })`
  height: 25px;
  opacity: 0.6;
  position: absolute;
  right: 0;
  bottom: 0;
  &:hover,
  &:focus {
    opacity: 1;
  }
`;

const InnerCardContainer = styled.div`
  padding: 24px;
  position: relative;
`;

const Container = styled(Flex)`
  height: 355px;

  ${({ theme }) => theme.mediaQueries.large`
    height: 264px;
  `};
`;

const posters = [
  Poster1Img,
  Poster2Img,
  Poster3Img,
  Poster4Img,
  Poster5Img,
  Poster6Img,
  Poster7Img,
  Poster8Img,
  Poster9Img,
  Poster10mg,
  Poster11Img,
  Poster12Img,
  Poster13mg,
  Poster14mg,
  Poster15mg,
  Poster16mg,
];

const VirtualPosters: React.FC = () => {
  return (
    <div>
      <Text mods="bold">Virtual Posters</Text>
      <Spacer height="16px" />

      <Container justify="center">
        <Slideshow
          items={posters.map((posterSrc, i) => (
            <CardWithOutline key={i}>
              <InnerCardContainer>
                <Img src={posterSrc} alt="poster" aria-hidden />
                <NoStyleLink href={posterSrc} download>
                  <DownloadIcon />
                </NoStyleLink>
              </InnerCardContainer>
            </CardWithOutline>
          ))}
          numItemsToShow={1}
          isWrapAround
          isAutoPlay
        />
      </Container>
    </div>
  );
};

export default VirtualPosters;
