import { Spacer, Text, Link } from "@hackthenorth/north";
import React from "react";

import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkTextArea,
  PerkTextInput,
  PerkRadioButtons,
} from "../components";
import { usePerkState } from "../usePerkState";

interface ApiPrizeProps {
  perk: SponsorPerks.ApiPrize;
}

type StoredPerk = SponsorPerks.ApiPrize;

const description = (
  <>
    <Text mods="medium">
      If you have a public facing API, you can host an API prize where hackers
      will develop with your API. These prizes will be judged by your own
      company representatives and awarded to hackers at the end of our event.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Note: These descriptions will be going on our Devpost page, so they should
      include prizes as well. Our Devpost page from 2019 can be found at{" "}
      <Link newTab mods="medium" href="https://hackthenorth2019.devpost.com/">
        hackthenorth2019.devpost.com
      </Link>
      .
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.title &&
  !!perkData.description &&
  !!perkData.judging_type &&
  Object.values(SponsorPerks.JudgingType).includes(perkData.judging_type);

const ApiPrize: React.FC<ApiPrizeProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveApiPrizePerk = async () => {
    if (isValid) await savePerk(perk.id, perkData, perk.stage);
  };

  const submitApiPrizePerk = async () => {
    if (isValid) await submitPerk(perk.id, perkData);
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.API_PRIZE}
        readOnly={isReadOnly}
      >
        <PerkTextInput
          name={`${SponsorPerks.Type.API_PRIZE}--title`}
          title="Title*"
          value={perkData["title"] ?? ""}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("title", e.target.value)}
        />
        <Spacer height={32} />
        <PerkTextArea
          name={`${SponsorPerks.Type.API_PRIZE}--description`}
          title="Description* (max 150 words)"
          value={perkData["description"] ?? ""}
          wordLimit={150}
          placeholder=""
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("description", e.target.value)}
        />
        <Spacer height={48} />
        <Text mods="medium">
          How would you like to judge submissions for your prize?
          <ul>
            <li>
              <Text mods="bold">Live Demos</Text> will require you to
              participate in calls with each team on Sunday, January 17 between
              11 AM to 4 PM EST (facilitated through Hopin).
            </li>
            <li>
              <Text mods="bold">Devpost Submissions</Text> are submitted by each
              team and can be reviewed asynchronously - we are encouraging teams
              to submit short video presentations describing their projects,
              which will be available on Devpost as well.
            </li>
          </ul>
        </Text>
        <Spacer height={24} />
        <PerkRadioButtons
          name={`${SponsorPerks.Type.API_PRIZE}--judging-type`}
          title="Judging Method*"
          value={perkData["judging_type"] ?? ""}
          isReadOnly={isReadOnly}
          onChange={(value) => updatePerkField("judging_type", value)}
          options={Object.values(SponsorPerks.JudgingType)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.API_PRIZE}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveApiPrizePerk}
          onSubmit={submitApiPrizePerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.API_PRIZE} error={error} />
    </>
  );
};

export default ApiPrize;
