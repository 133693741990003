import { Flex, Spacer, Text, Spinner } from "@hackthenorth/north";
import moment from "moment";
import React, { useEffect, useMemo } from "react";

import { Link } from "src/shared/components";
import { BaseRoute } from "src/shared/constants/route";
import { useGetEventScheduleQuery } from "src/views/schedule/ScheduleContext/graphql/getEventSchedule.generated";
import {
  computeBaseCopyWithTimezone,
  formatEventsFromQuery,
} from "src/views/schedule/ScheduleContext/utils";

import EventCard from "./EventCard";
import Slideshow from "./Slideshow";
import { THomeEvent } from "./types";

const CurrentEvents: React.FC = () => {
  const { loading: fetchingEvents, data, refetch } = useGetEventScheduleQuery();
  const events = useMemo(() => data?.eventSchedule ?? [], [data]);
  const now = moment(moment.now()).subtract(50, "days").subtract(12, "hours");

  const filteredEvents = computeBaseCopyWithTimezone(
    formatEventsFromQuery(
      events
        .filter(
          (event) =>
            event.tags &&
            event.tags.includes("hack_the_north") &&
            !event.tags.includes("banner") &&
            now >= moment(event.start_time).subtract(60, "minutes") &&
            now < moment(event.end_time)
          // show events that start within an hour
        )
        .sort((e1, e2) =>
          moment(e1.start_time) < moment(e2.start_time)
            ? moment(e1.start_time) === moment(e2.start_time)
              ? 0
              : -1
            : 1
        )
    )
  );

  // Refetch events every 10 minutes
  useEffect(() => {
    const id = setInterval(refetch, 600000);
    return () => clearInterval(id);
  }, [refetch]);

  return (
    <div>
      <Flex align="center">
        <Text mods="heading h3">Happening right now</Text>
        <Spacer width="24px" />
        <Link href={BaseRoute.SCHEDULE}>View full schedule</Link>
      </Flex>
      <Spacer height="24px" />
      {fetchingEvents ? (
        <Spinner />
      ) : filteredEvents.length === 0 ? (
        <Text>Nothing right now! Get some rest and check back later!</Text>
      ) : (
        <Slideshow
          items={filteredEvents.map((event: THomeEvent) => (
            <EventCard event={event} key={event.title} />
          ))}
          paddingBetween={20}
        />
      )}
    </div>
  );
};

export default CurrentEvents;
