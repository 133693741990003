import { Flex, Spacer, Text, UnstyledButton } from "@hackthenorth/north";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { useTheme } from "styled-components";

import { Icon } from "src/shared/components";

const DROPDOWN_BLOCK_HEIGHT = 35;

interface TimePeriodSelectorProps {
  isWeekView?: boolean;
  toggleTimePeriod: () => void;
  currentDay: number;
  totalDays: number;
  goToNextDay: () => void;
  goToPrevDay: () => void;
}

const Container = styled.div<{ isExpanded?: boolean }>`
  overflow: visible;
  height: ${DROPDOWN_BLOCK_HEIGHT}px;
  z-index: 2;
`;

const DropdownContainer = styled.div<{ isExpanded?: boolean }>`
  border-bottom: 1px solid
    ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  border-left: 1px solid
    ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  border-right: 1px solid
    ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  border-radius: 0 0 3px 3px;
  background: ${({ theme }) => theme.globalConstants.color.white};
  margin-top: -2px;
  display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.globalConstants.color.bluePrimary2} !important;
`;

const StyledButton = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  height: ${DROPDOWN_BLOCK_HEIGHT}px;
  padding: 0 12px;
  margin: 0;
`;

const StyledButtonTop = styled(StyledButton)<{ isExpanded?: boolean }>`
  border: 1px solid ${({ theme }) => theme.globalConstants.color.bluePrimary2};
  border-radius: ${({ isExpanded }) => (isExpanded ? "3px 3px 0 0" : "3px")};
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const StyledIcon = styled(Icon)<{ isExpanded?: boolean }>`
  transform: ${({ isExpanded }) => (isExpanded ? "rotate(180deg)" : "none")};
  transition: transform 0.5s;
`;

const LeftIcon = styled(Icon)`
  transform: rotate(180deg);
`;

const IconButton = styled(UnstyledButton)`
  &:disabled {
    opacity: 0.5;
  }
`;

export const TimePeriodSelector: React.FC<TimePeriodSelectorProps> = ({
  isWeekView,
  toggleTimePeriod,
  currentDay,
  totalDays,
  goToNextDay,
  goToPrevDay,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { globalConstants } = useTheme();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const clickListener = useCallback(
    // todo: fix type later
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsExpanded(false);
      }
    },
    [wrapperRef]
  );

  useEffect(() => {
    document.addEventListener("click", clickListener);
    return () => {
      document.removeEventListener("click", clickListener);
    };
  }, [clickListener]);

  return (
    <Flex align="center">
      {!isWeekView && (
        <IconButton
          onClick={() => {
            if (currentDay > 0) goToPrevDay();
          }}
          disabled={currentDay <= 0}
        >
          <Flex>
            <LeftIcon name="chevron-side" />
            <Spacer width={12} />
          </Flex>
        </IconButton>
      )}
      <Container isExpanded={isExpanded} ref={wrapperRef}>
        <StyledButtonTop
          onClick={() => setIsExpanded(!isExpanded)}
          isExpanded={isExpanded}
        >
          <StyledText>{isWeekView ? "Week" : "Day"}</StyledText>
          <Spacer width={24} />
          <StyledIcon
            name="chevron"
            color={globalConstants.color.bluePrimary2}
            isExpanded={isExpanded}
          />
        </StyledButtonTop>
        <DropdownContainer isExpanded={isExpanded}>
          <StyledButton
            onClick={() => {
              setIsExpanded(false);
              toggleTimePeriod();
            }}
          >
            <Text>{!isWeekView ? "Week" : "Day"}</Text>
          </StyledButton>
        </DropdownContainer>
      </Container>
      {!isWeekView && (
        <IconButton
          onClick={() => {
            if (currentDay < totalDays - 1) goToNextDay();
          }}
          disabled={currentDay >= totalDays - 1}
        >
          <Flex>
            <Spacer width={12} />
            <Icon name="chevron-side" />
          </Flex>
        </IconButton>
      )}
    </Flex>
  );
};
