import { Spacer, Text, DropdownInput } from "@hackthenorth/north";
import React from "react";
import { OptionTypeBase } from "react-select";

import { Link } from "src/shared/components";
import { useSponsorContext } from "src/shared/contexts/SponsorContext";
import { answerToOption, timeToOption } from "src/shared/utils/react-select";
import { addTwoHours, formatTimeRange } from "src/utils/formatTime";
import * as SponsorPerks from "src/views/sponsor/perks/types";

import {
  PerkActions,
  PerkContainer,
  PerkError,
  PerkRadioButtons,
  PerkTextInput,
} from "../components";
import { usePerkState } from "../usePerkState";

import { TIME_OPTIONS } from "./constants";

interface ApiWorkshopProps {
  perk: SponsorPerks.ApiWorkshop;
}

type StoredPerk = SponsorPerks.ApiWorkshop;

const description = (
  <>
    <Text mods="medium">
      As a sponsor of an API prize, you also have the opportunity to host a
      30-minute workshop teaching hackers how to work with your public-facing
      API.
    </Text>
    <Spacer height={16} />
    <Text mods="medium">
      Please rank the times that work best for you from those listed below. Once
      this perk has been submitted, our team will be in contact to finalize your
      time. We strongly encourage you to hold your session in these time slots
      since we’ve reserved them for sponsors. If these times don&apos;t work for
      you, or you have a specific time that you want to run your session, please
      email{" "}
      <Link href="mailto:sponsor@hackthenorth.com">
        sponsor@hackthenorth.com
      </Link>
      .
    </Text>
  </>
);

const validate = (perkData: StoredPerk["data"]) =>
  !!perkData.representative &&
  !!perkData.time_one &&
  !!perkData.time_two &&
  !!perkData.time_three &&
  // check that all date values are unique
  perkData.time_one !== perkData.time_two &&
  perkData.time_three !== perkData.time_two &&
  perkData.time_one !== perkData.time_three;

const ApiWorkshop: React.FC<ApiWorkshopProps> = ({ perk }) => {
  const {
    perkData,
    updatePerkField,
    isValid,
    isLoading,
    isUpdating,
    isReadOnly,
    savePerk,
    submitPerk,
    errorMessage,
  } = usePerkState<StoredPerk>(perk, validate);
  const { representatives } = useSponsorContext();

  const error = errorMessage;
  const loading = isLoading || isUpdating;

  const saveApiWorkshopPerk = async () => {
    if (isValid) {
      await savePerk(perk.id, perkData, perk.stage);
    }
  };

  const submitApiWorkshopPerk = async () => {
    if (isValid) {
      await submitPerk(perk.id, perkData);
    }
  };

  return (
    <>
      <PerkContainer
        description={description}
        id={SponsorPerks.Type.API_WORKSHOP}
        readOnly={isReadOnly}
      >
        <Text mods="heading h3">Timeslot Rankings</Text>
        <Spacer height={8} />
        <Text mods="medium">First Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_one"],
            addTwoHours(perkData["time_one"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_one", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Second Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_two"],
            addTwoHours(perkData["time_two"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_two", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">Third Choice</Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={timeToOption(
            formatTimeRange,
            perkData["time_three"],
            addTwoHours(perkData["time_three"])
          )}
          readOnly={isReadOnly}
          options={TIME_OPTIONS}
          placeholder="Select timeslot"
          onChange={(option: OptionTypeBase) =>
            updatePerkField("time_three", option.value)
          }
        />
        <Spacer height={22} />
        <Text mods="medium">
          Who will be running the workshop? (This person will have access to the
          livestream and be able to add/remove speakers and moderate the chat)
        </Text>
        <Spacer height={8} />
        <DropdownInput
          isHideDropdownIndicator={isReadOnly}
          value={answerToOption(perkData["representative"])}
          readOnly={isReadOnly}
          options={
            representatives?.map((representative) =>
              answerToOption(representative.name)
            ) ?? []
          }
          onChange={(option: OptionTypeBase) =>
            updatePerkField("representative", option.value)
          }
          placeholder="Select representative"
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["accepted_hackers_only"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.TECH_TALK_TIME}---accepted-hackers`}
          title="Would you like your workshop attendees to be limited to accepted hackers only?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("accepted_hackers_only", true);
            } else {
              updatePerkField("accepted_hackers_only", false);
            }
          }}
        />
        <Spacer height={32} />
        <PerkRadioButtons
          value={perkData["recording_available"] ? "Yes" : "No"}
          options={["Yes", "No"]}
          isReadOnly={isReadOnly}
          name={`${SponsorPerks.Type.API_WORKSHOP}--recording`}
          title="Would you like a recording of your workshop to be made publicly available after the event?"
          onChange={(option) => {
            if (option === "Yes") {
              updatePerkField("recording_available", true);
            } else {
              updatePerkField("recording_available", false);
            }
          }}
        />
        <Spacer height={32} />
        <Text mods="medium">
          Optional: A link to a pre-recorded video of your workshop to be used
          in case of poor connection or other day-of technical issues.
        </Text>
        <PerkTextInput
          name={`${SponsorPerks.Type.API_WORKSHOP}--video_link`}
          title=""
          value={perkData["video_link"] ?? ""}
          placeholder="Link to pre-recorded video (YouTube, Dropbox, Google Drive, etc.)"
          isReadOnly={isReadOnly}
          onChange={(e) => updatePerkField("video_link", e.target.value)}
        />
        <Spacer height={48} />
        <PerkActions
          name={SponsorPerks.Type.API_WORKSHOP}
          isReadOnly={isReadOnly}
          loading={loading}
          isValid={isValid}
          onSave={saveApiWorkshopPerk}
          onSubmit={submitApiWorkshopPerk}
        />
      </PerkContainer>
      <PerkError name={SponsorPerks.Type.API_WORKSHOP} error={error} />
    </>
  );
};

export default ApiWorkshop;
