import { Spacer, Text } from "@hackthenorth/north";
import React from "react";

import { WordLimitTextArea } from "src/shared/components";

type TPerkTextAreaProps = {
  value: string;
  title: string;
  name: string;
  placeholder: string;
  wordLimit: number;
  isReadOnly: boolean;
  // todo: fix type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (e: any) => void;
};

const PerkTextArea: React.FC<TPerkTextAreaProps> = ({
  name,
  value,
  title,
  placeholder,
  wordLimit,
  isReadOnly,
  onChange,
}) => (
  <>
    <Text mods="medium" className={name}>
      {title}
    </Text>
    <Spacer height={8} />
    <WordLimitTextArea
      className={`${name}--text-area`}
      wordLimit={wordLimit}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      readOnly={isReadOnly}
    />
  </>
);

export default PerkTextArea;
