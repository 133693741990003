import { Text, Spacer, Spinner, Button } from "@hackthenorth/north";
import React from "react";

import { PageWrapper } from "src/shared/components";
import { useMentorContext } from "src/shared/contexts";
import { useUserContext } from "src/shared/contexts";

import ProfileForm from "./Form";

const Profile: React.FC = () => {
  const { info } = useUserContext();
  const {
    profile,
    updateProfile,
    updateProfilePhoto,
    createProfile,
    isLoading,
  } = useMentorContext();

  return (
    <PageWrapper>
      <Text mods="heading h2">Mentor Profile</Text>
      <Text mods="medium">
        Your profile information will be provided to mentees when they are
        notified that their request has been claimed.
      </Text>
      <Spacer height={24} />
      {isLoading && <Spinner />}
      {!isLoading && !profile && (
        <>
          <Text>
            Oops! Looks like we haven&apos;t made a mentor profile for you yet.{" "}
          </Text>
          <Spacer height={20} />
          <Button
            onClick={async () => {
              await createProfile();
              window.location.reload();
            }}
          >
            Make Profile
          </Button>
        </>
      )}
      {info && profile && updateProfile && (
        <ProfileForm
          info={info}
          profile={profile}
          updateProfile={updateProfile}
          updateProfilePhoto={updateProfilePhoto}
        />
      )}
    </PageWrapper>
  );
};

export default Profile;
