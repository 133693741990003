import { Flex, Spacer, Button, Spinner } from "@hackthenorth/north";
import React, { useState } from "react";

import PerkConfirmationModal from "../PerkConfirmationModal";

type TPerkActionsProps = {
  name: string;
  isReadOnly: boolean;
  loading: boolean;
  isValid: boolean;
  onSave: () => void;
  onSubmit: () => void;
};

const PerkActions: React.FC<TPerkActionsProps> = ({
  name,
  isReadOnly,
  loading,
  isValid,
  onSave,
  onSubmit,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  return !isReadOnly ? (
    <>
      <Flex align="center">
        <Button
          mods="primary"
          onClick={() => setShowConfirmationModal(true)}
          disabled={loading || !isValid}
          className={`${name}--submit`}
        >
          Submit
        </Button>
        <Spacer width={16} />
        <Button
          onClick={onSave}
          disabled={loading || !isValid}
          className={`${name}--save`}
        >
          Save
        </Button>
        <Spacer width={16} />
        {loading && <Spinner />}
      </Flex>
      <PerkConfirmationModal
        isOpen={showConfirmationModal}
        onConfirm={onSubmit}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </>
  ) : null;
};

export default PerkActions;
