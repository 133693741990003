import { Tier } from "src/views/sponsor/types";

export const availableReps = (companyTier?: Tier) => {
  switch (companyTier) {
    case Tier.GOLD:
      return "16";
    case Tier.SILVER:
      return "12";
    default:
      return "8";
  }
};
