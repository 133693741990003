import { Checkbox as NorthCheckbox } from "@hackthenorth/north";
import React, { memo, PropsWithChildren, useCallback, useState } from "react";
import styled from "styled-components";

const LIGHTER_CHECKED_GREEN = "#85efc3";

interface CheckboxProps {
  onChange?: (newVal: boolean) => void;
  value: boolean | null;
  disabled?: boolean;
}

interface HoveringProps {
  hovering: boolean;
}

const StyledCheckbox = styled(NorthCheckbox)<
  { checked: boolean } & HoveringProps
>`
  border-radius: 0 0 3px 0;
  border: 2px solid
    ${({ hovering, theme }) =>
      hovering
        ? LIGHTER_CHECKED_GREEN
        : theme.globalConstants.color.greenSecondary};
  background-color: ${({ hovering, theme }) =>
    hovering
      ? LIGHTER_CHECKED_GREEN
      : theme.globalConstants.color.greenSecondary};
  color: ${({ theme }) => theme.globalConstants.color.white};
  margin-right: -24px;
`;

const LargeCheckboxContainer = styled.div<CheckboxProps & HoveringProps>`
  position: relative;
  border-radius: 3px;
  border: ${({ value, hovering, theme }) =>
    value
      ? hovering
        ? `2px solid ${LIGHTER_CHECKED_GREEN}`
        : `2px solid ${theme.globalConstants.color.greenSecondary}`
      : `1px solid ${theme.globalConstants.color.textDark}`};
  cursor: pointer;
`;

const NO_OP = () => {};

const Checkbox: React.FC<PropsWithChildren<CheckboxProps>> = ({
  onChange,
  children,
  value,
  disabled = false,
  ...rest
}) => {
  const [hovering, setHovering] = useState(false);
  const toggleCheck = useCallback(() => {
    if (onChange) {
      onChange(!value);
    }
  }, [onChange, value]);

  return (
    <LargeCheckboxContainer
      onClick={disabled ? NO_OP : toggleCheck}
      value={value}
      hovering={hovering}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {value && (
        <StyledCheckbox
          disabled={disabled}
          onClick={toggleCheck}
          checked={value}
          hovering={hovering}
          {...rest}
        />
      )}
      {children}
    </LargeCheckboxContainer>
  );
};

export default memo(Checkbox);
