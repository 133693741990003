import { Nullable } from "src/shared/utils/typescript";

export enum Stage {
  DEFAULT = 1481,
  INCOMPLETE = 1482,
  COMPLETE = 1483,
}

export enum Type {
  ONBOARDING = "onboarding",
  NOTIFICATION = "notification",
  ACTION_ITEM = "action_item",
  FLAG = "flag",
}

export enum Name {
  ONBOARDING_STEPS = "onboarding_steps",
  ONBOARDING_MODAL_DISMISSED = "onboarding_modal_dismissed",
  ONBOARDING_COMPLETE_MODAL_DISMISSED = "onboarding_complete_modal_dismissed",
  TC_ACCEPTED = "tc_accepted",
  COC_ACCEPTED = "coc_accepted",
  PROFILE_UPDATED = "profile_updated",
  CUSTOM = "custom",
}

export interface Base {
  id: number;
  complete: boolean;
  type: Type;
  name: Name;
  owner_id: number; // claim ID that this milestone belongs to (individual, company, etc)
  dueDate: Date | null;
  data: unknown;
}

export interface Onboarding extends Base {
  type: Type.FLAG;
  name: Name.ONBOARDING_STEPS;
  data: null;
}

export interface OnboardingModalDismissed extends Base {
  type: Type.FLAG;
  name: Name.ONBOARDING_MODAL_DISMISSED;
  data: null;
}

export interface OnboardingCompleteModalDismissed extends Base {
  type: Type.FLAG;
  name: Name.ONBOARDING_COMPLETE_MODAL_DISMISSED;
  data: null;
}

export interface TCAccepted extends Base {
  type: Type.ONBOARDING;
  name: Name.TC_ACCEPTED;
  data: null;
}

export interface COCAccepted extends Base {
  type: Type.ONBOARDING;
  name: Name.COC_ACCEPTED;
  data: null;
}

export interface ProfileUpdated extends Base {
  type: Type.ACTION_ITEM;
  name: Name.PROFILE_UPDATED;
  data: {
    first_name: Nullable<string>;
    last_name: Nullable<string>;
    company_role: Nullable<string>;
  };
}

/**
 * PLACEHOLDER TYPES
 */
export interface CustomNotification extends Base {
  type: Type.NOTIFICATION;
  name: Name.CUSTOM;
  data: {
    custom_title: string; // title to display when using notification as an action item
  };
}

export interface CustomActionItem extends Base {
  type: Type.ACTION_ITEM;
  name: Name.CUSTOM;
  data: {
    custom_title: string; // title to display when using notification as an action item
  };
}

export type Milestone =
  | Onboarding
  | OnboardingModalDismissed
  | OnboardingCompleteModalDismissed
  | TCAccepted
  | COCAccepted
  | ProfileUpdated
  | CustomNotification
  | CustomActionItem;

export interface State {
  onboarding: Nullable<Onboarding>;
  onboardingModal: Nullable<
    OnboardingModalDismissed | OnboardingCompleteModalDismissed
  >;
  codeOfConduct: Nullable<COCAccepted>;
  termsAndConditions: Nullable<TCAccepted>;
  profileUpdated: Nullable<ProfileUpdated>;
}
