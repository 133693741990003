import { Nullable } from "src/shared/utils/typescript";

export const snakeToCamelCase = (str: Nullable<string>): string | null =>
  str
    ? str.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace("-", "").replace("_", "")
      )
    : null;

export const validateDateString = (d?: Nullable<string>): boolean =>
  /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))(T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z){0,1}$/.test(
    d ?? ""
  );

export const getFirstName = (name?: Nullable<string>): string | null => {
  if (!name) return null;
  return name.split(" ")[0];
};

export const getLastName = (name?: Nullable<string>): string | null => {
  if (!name) return null;
  return name.split(" ")[1];
};

export const getShortName = (name?: Nullable<string>): string | null => {
  const firstName = getFirstName(name);

  if (!firstName) return null;

  const lastName = getLastName(name);
  return `${firstName}${lastName ? ` ${lastName[0]}.` : ""}`;
};

export const possessify = (name?: Nullable<string>): string | null => {
  if (!name) return null;
  return name[name.length - 1] === "s" ? `${name}' ` : `${name}'s `;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);
