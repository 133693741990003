import styled from "styled-components";

export default styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 25%,
    #f6f7f8 50%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;

  display: flex;
  flex: 1;
  position: relative;

  animation-duration: 0.75s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  @keyframes placeholderShimmer {
    0% {
      background-position: -400px 0;
    }

    100% {
      background-position: 400px 0;
    }
  }

  &:after {
    color: transparent;
    content: "a";
  }
`;
