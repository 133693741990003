/* eslint-disable @typescript-eslint/camelcase */

import {
  Button,
  Flex,
  Text,
  Spinner,
  TextInput,
  DropdownInput,
} from "@hackthenorth/north";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon, PageWrapper } from "src/shared/components";
import {
  MENTORSHIP_TOPICS,
  MENTORSHIP_REQUEST_STAGE_ID_OPEN,
} from "src/shared/constants/mentorship";
import { useGetOpenMentorshipRequestsQuery } from "src/shared/contexts/MentorContext/graphql/getOpenRequests.generated";
import * as MentorshipRequests from "src/shared/contexts/MentorshipRequestContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";
import {
  answersToOptions,
  Option,
  answerToOption,
} from "src/shared/utils/react-select";

import RequestsTable, { RequestsTableHeaders } from "./RequestsTable";

const POLL_INTERVAL = 1000 * 60 * 5; //poll interval in ms

// TODO: Move this to another file without breaking typing
// (type declarations shouldn't be in a component file)
// Extend base options of react-table
// Explained in @types\react-table\index.d.ts
// See https://gist.github.com/ggascoigne/646e14c9d54258e40588a13aabf0102d
declare module "react-table" {
  // Add sorting props to TableState
  export interface TableState<D extends object = {}>
    extends UseSortByState<D> {}
  // Add sorting props and collapse to HeaderGroup
  export interface HeaderGroup<D extends object = {}>
    extends UseSortByColumnProps<D> {
    collapse?: boolean;
    minWidth?: number;
  }
  // Add sorting options to TableOptions
  export interface TableOptions<D extends object> extends UseSortByOptions<D> {}
  // Add collapse to ColumnInstance
  export interface ColumnInstance<D extends object = {}> {
    collapse?: boolean;
  }
}

const StyledPageWrapper = styled(PageWrapper)`
  position: absolute;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

// Makes the table scrollable when too small
const TableContainer = styled(Flex).attrs({ column: true })`
  height: 100%;
  max-width: 100%;
  border: 1px solid ${({ theme }) => theme.globalConstants.color.textDark};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.globalConstants.boxShadow.light};
  overflow: hidden;
  background: ${({ theme }) => theme.globalConstants.color.white};
`;

const TableActions = styled(Flex)<{
  borderTop?: boolean;
  borderBottom?: boolean;
}>`
  background: ${({ theme }) => theme.globalConstants.color.white};
  padding: 12px 16px;
  border-top: ${({ theme, borderTop }) =>
    borderTop
      ? `1px solid ${theme.globalConstants.color.greySecondary};`
      : "0"};
  border-bottom: ${({ theme, borderBottom }) =>
    borderBottom
      ? `1px solid ${theme.globalConstants.color.greySecondary};`
      : "0"};
`;

const RefreshButton = styled(Button)`
  && {
    border-radius: ${({ theme }) => theme.globalConstants.borderRadius.small}px;
    border: 1px solid
      ${({ theme }) => theme.globalConstants.color.navySecondary};
    padding: 2px 10px;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.globalConstants.color.bluePrimary3};
  }
`;

const RefreshText = styled(Text)`
  padding-left: 6px;
`;

const StyledTextInput = styled(TextInput)`
  margin-left: 12px;
  margin-right: 12px;
`;

const StyledDropdownInput = styled(DropdownInput)`
  max-width: 250px;
  .select__control {
    height: 48px;
  }
  margin-right: 12px;
`;

const OpenRequests: React.FC = () => {
  const {
    data: requestData,
    loading,
    error,
    refetch,
  } = useGetOpenMentorshipRequestsQuery({
    pollInterval: POLL_INTERVAL,
    variables: {
      stageId: MENTORSHIP_REQUEST_STAGE_ID_OPEN,
    },
  });

  const [data, setData] = useState<RequestsTableHeaders[]>([]);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [displayedData, setDisplayedData] = useState<RequestsTableHeaders[]>(
    []
  );
  const [categoryFilter, setCategoryFilter] = useState<string | undefined>(
    undefined
  );

  const formatData = useCallback(
    (requestData?: any) =>
      (requestData?.claims || []).map(
        (request: any) =>
          ({
            id: request.id,
            title: getFieldAnswer(request.fields, "title"),
            description: getFieldAnswer(request.fields, "description"),
            category: getFieldAnswer(request.fields, "category"),
            priority: getFieldAnswer(request.fields, "priority"),
            mentee_name: getFieldAnswer(request.fields, "mentee_name"),
            created_at: new Date(request.created_at),
            stage: MentorshipRequests.Stage[request.stage_id],
            claim: request.id.toString(),
            view_more: request.id.toString(),
          } as RequestsTableHeaders)
      ),
    []
  );

  useEffect(() => {
    if (requestData) {
      setData(formatData(requestData));
    }
  }, [requestData, formatData]);

  useEffect(() => {
    if (data) {
      let displayedData = data;
      if (query) {
        displayedData = displayedData.filter(
          (request: RequestsTableHeaders) =>
            request.title?.toLowerCase().includes(query) ||
            request.description?.toLowerCase().includes(query) ||
            request.mentee_name?.toLowerCase().includes(query)
        );
      }
      if (categoryFilter && categoryFilter !== "All") {
        displayedData = displayedData.filter(
          (request: RequestsTableHeaders) => {
            if (request.category) {
              return request.category?.includes(categoryFilter);
            }
            return false;
          }
        );
      }
      setDisplayedData(displayedData);
    }
  }, [data, query, categoryFilter]);

  return (
    <StyledPageWrapper fullWidth={true}>
      <Text mods="heading h2">Open Requests</Text>
      {loading ? (
        <Spinner />
      ) : (
        <TableContainer>
          <TableActions borderBottom={true}>
            <RefreshButton onClick={() => refetch()}>
              <Flex align="center">
                <Icon name="refresh" />
                <RefreshText mods="big darkBlue nowrap">
                  Refresh List
                </RefreshText>
              </Flex>
            </RefreshButton>
            <StyledTextInput
              placeholder="Search by title, description, etc..."
              value={query}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setQuery(e.target.value);
              }}
            />
            <StyledDropdownInput
              placeholder="Filter by Category"
              options={answersToOptions([
                ...MENTORSHIP_TOPICS,
                "All Categories",
              ])}
              value={answerToOption(categoryFilter)}
              onChange={(option: Option) => {
                setCategoryFilter(option.value);
              }}
            />
            <Button
              mods="primary nowrap"
              onClick={() => {
                setCategoryFilter(undefined);
                setQuery(undefined);
              }}
            >
              Clear Filters
            </Button>
          </TableActions>
          <RequestsTable
            data={displayedData}
            loading
            error={error?.message}
            onRefresh={() => refetch()}
          ></RequestsTable>
          <TableActions borderTop={true} justify="flex-end">
            <Text mods="bold darkBlue">
              {`${data ? data.length : 0} Result${
                data?.length === 1 ? "" : "s"
              }`}
            </Text>
          </TableActions>
        </TableContainer>
      )}
    </StyledPageWrapper>
  );
};

export default OpenRequests;
