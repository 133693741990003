/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
/** ============================ */
/*  This file was autogenerated  */
/*  with `npm run codegen`       */
/* ============================= */

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";

import * as Types from "../../../../api/types.generated";
export type UpdateCompanyClaimLogoMutationVariables = Types.Exact<{
  companyClaimId: Types.Scalars["Int"];
  logoFileIds: Types.Scalars["JSON"];
}>;

export type UpdateCompanyClaimLogoMutation = { __typename?: "Mutation" } & {
  updateClaim: { __typename?: "Claim" } & Pick<Types.Claim, "id">;
};

export const UpdateCompanyClaimLogoDocument = gql`
  mutation UpdateCompanyClaimLogo($companyClaimId: Int!, $logoFileIds: JSON!) {
    updateClaim(
      data: {
        id: $companyClaimId
        answers: [{ slug: "logo", answer: $logoFileIds }]
      }
    ) {
      id
    }
  }
`;

/**
 * __useUpdateCompanyClaimLogoMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyClaimLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyClaimLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyClaimLogoMutation, { data, loading, error }] = useUpdateCompanyClaimLogoMutation({
 *   variables: {
 *      companyClaimId: // value for 'companyClaimId'
 *      logoFileIds: // value for 'logoFileIds'
 *   },
 * });
 */
export function useUpdateCompanyClaimLogoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompanyClaimLogoMutation,
    UpdateCompanyClaimLogoMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCompanyClaimLogoMutation,
    UpdateCompanyClaimLogoMutationVariables
  >(UpdateCompanyClaimLogoDocument, baseOptions);
}
export type UpdateCompanyClaimLogoMutationHookResult = ReturnType<
  typeof useUpdateCompanyClaimLogoMutation
>;
export type UpdateCompanyClaimLogoMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyClaimLogoMutation>;
export type UpdateCompanyClaimLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCompanyClaimLogoMutation,
  UpdateCompanyClaimLogoMutationVariables
>;
