import { GetHackersQuery } from "src/api/hackers/getHackers.generated";
import { FieldAnswerWhereInput, QueryMode } from "src/api/types.generated";
import { HackerFragmentFragment } from "src/shared/contexts/HackerContext/graphql/hackerFragment.generated";
import { Field, HackerGrad } from "src/shared/contexts/HackerContext/types";
import { getFieldAnswer } from "src/shared/utils/hackerapi";

import { HackerFilter, HACKER_FILTERS_TO_FIELDS } from "./constants";
import { THackerProfile } from "./HackerProfile/types";

const ORGANIZER_CLAIMS = [
  80986,
  80981,
  80991,
  80994,
  80987,
  81025,
  80996,
  81004,
  81026,
  61595,
  77622,
  80995,
  81011,
  81014,
  81015,
  81000,
  81002,
  81007,
  80984,
  81001,
  81012,
  80999,
  81018,
  81010,
  80983,
  81020,
  81005,
  80993,
  61581,
  81003,
  81009,
  81006,
  81017,
];

// Compute server-side filters
/* eslint-disable @typescript-eslint/camelcase */
export const parseFilters = (
  filters: HackerFilter[],
  companyClaimId?: number,
  stringFilters: { [key: string]: string } = {}
): FieldAnswerWhereInput[] => {
  if (!companyClaimId) return [];

  const fieldAnswerFilters: FieldAnswerWhereInput[] = [];
  filters.forEach((filter) => {
    const field = HACKER_FILTERS_TO_FIELDS[filter] ?? "";

    switch (filter) {
      case HackerFilter.INTERESTED:
      case HackerFilter.COFFEE_CHAT:
        return fieldAnswerFilters.push({
          claim: {
            is: {
              field_answers: {
                some: {
                  AND: [
                    { field: { is: { slug: { equals: field } } } },
                    {
                      value: {
                        contains: companyClaimId.toString(),
                      },
                    },
                  ],
                },
              },
            },
          },
        });
      case HackerFilter.SEEKING_INTERNSHIP:
      case HackerFilter.SEEKING_FULLTIME:
      case HackerFilter.CANADIAN_CITIZEN:
      case HackerFilter.PERMANENT_RESIDENT:
        return fieldAnswerFilters.push({
          claim: {
            is: {
              field_answers: {
                some: {
                  AND: [
                    { field: { is: { slug: { equals: field } } } },
                    {
                      value: {
                        equals: "true",
                      },
                    },
                  ],
                },
              },
            },
          },
        });
      case HackerFilter.ORGANIZER:
        return fieldAnswerFilters.push({
          claim: {
            is: {
              id: {
                in: ORGANIZER_CLAIMS,
              },
            },
          },
        });
      default:
        return;
    }
  });

  Object.keys(stringFilters).forEach((filter) => {
    if (!stringFilters[filter]) return;
    const field = stringFilters[HackerFilter.NAME];

    switch (filter) {
      case HackerFilter.NAME:
        return fieldAnswerFilters.push({
          claim: {
            is: {
              OR: [
                {
                  user: {
                    is: {
                      name: {
                        contains: field,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
                {
                  user: {
                    is: {
                      email: {
                        contains: field,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  },
                },
                {
                  name: {
                    contains: field,
                    mode: QueryMode.Insensitive,
                  },
                },
              ],
            },
          },
        });
      default:
        return;
    }
  });

  return fieldAnswerFilters;
};

export const computeHackerTags = (
  claimId: number,
  fields: HackerFragmentFragment["fields"],
  companyClaimId: number
): HackerFilter[] => {
  const tags: HackerFilter[] = [];

  Object.values(HackerFilter).forEach((filter: HackerFilter) => {
    const field = HACKER_FILTERS_TO_FIELDS[filter] ?? "";
    const fieldAnswer = getFieldAnswer(fields, field);
    switch (filter) {
      case HackerFilter.INTERESTED:
      case HackerFilter.COFFEE_CHAT:
        if ((fieldAnswer ?? []).includes(companyClaimId.toString()))
          tags.push(filter);
        return;
      case HackerFilter.SEEKING_INTERNSHIP:
      case HackerFilter.SEEKING_FULLTIME:
      case HackerFilter.CANADIAN_CITIZEN:
      case HackerFilter.PERMANENT_RESIDENT:
        if (fieldAnswer ?? false) tags.push(filter);
        return;
      case HackerFilter.ORGANIZER:
        if (ORGANIZER_CLAIMS.includes(claimId)) tags.push(filter);
        return;
      default:
        return;
    }
  });

  return tags;
};

export const parseHackerProfiles = (
  data: GetHackersQuery | undefined,
  companyClaimId: number
): THackerProfile[] =>
  data?.claims?.map(({ id, fields, user }) => ({
    id,
    name: getFieldAnswer(fields, Field.FULL_NAME) ?? "",
    graduatingYear:
      getFieldAnswer(fields, Field.GRAD)[HackerGrad.GRADUATING_YEAR] ?? 0,
    school: getFieldAnswer(fields, Field.SCHOOL),
    program: getFieldAnswer(fields, Field.PROGRAM),
    email: user.email,
    links: Object.values(getFieldAnswer(fields, Field.LONG_ANSWER_5) ?? {}),
    resume: getFieldAnswer(fields, Field.RESUME),
    tags: computeHackerTags(id, fields, companyClaimId),
  })) ?? [];
