import React from "react";
import { Routes } from "react-router";

import { Route } from "src/shared/components";
import NotFound from "src/views/404";

import HelpHome from "./root";

export enum HelpRoute {
  HELP_HOME = "/",
}

export const HelpRoutes = () => (
  <Routes>
    <Route path={HelpRoute.HELP_HOME} element={<HelpHome />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
