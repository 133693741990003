import { Nullable } from "./typescript";

export interface Field<T> {
  answer?: Nullable<{
    value: T;
    updated_at: string;
  }>;
  field_type: string; // todo: better type for this
  slug?: Nullable<string>;
  updated_at: string; // datetime
  valid_values?: Nullable<string[]>;
}

/**
 * HackerAPIX types
 * These are used to enforce the typings of values we store in fields like `data`, which are typed JSON by HackerAPI (meaning there's no type-checking)
 */
// Hackerapi Date type returns a string with format "yyyy-mm-ddTHH:mm:ss.000Z", with the time being optional
export type HackerAPIDate = string;

export interface HackerAPIFile {
  id: Nullable<string>;
  name: Nullable<string>;
  type: Nullable<string>; // mime type
  uri: Nullable<string>;
  expiry?: Nullable<string>;
}

export const getFieldAnswer = <T>(
  fields: Field<T>[] = [],
  answerSlug: string
) => fields.find((f) => f.slug === answerSlug)?.answer?.value;

// convert individual into to fieldAnswers to an array to be able to update a claim
// todo: fix type later
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseState = (data: any) =>
  Object.entries(data).map(([field, answer]) => {
    return {
      slug: field,
      answer,
    };
  });
