import { Button, Flex, Spacer, Text, Spinner } from "@hackthenorth/north";
import React, { useCallback, useRef, useState } from "react";

import {
  Checkbox,
  Dropzone,
  Divider,
  DropzoneError,
  FileLink,
  PageWrapper,
} from "src/shared/components";
import { useHackerContext } from "src/shared/contexts";
import { Field, PageStage } from "src/shared/contexts/HackerContext/types";
import { useHackerState } from "src/shared/contexts/HackerContext/useHackerState";
import { useGetFile } from "src/shared/hooks";
import { useUploadFile } from "src/shared/hooks/useUploadFile";
import * as Sponsor from "src/views/sponsor/types";

import { ContentCard, Popup } from "../LayoutComponents";

import CompanySelect from "./CompanySelect";
import { ACCEPTED_FIELDS, VALIDATORS } from "./constants";

const CareerPage: React.FC = () => {
  const {
    updateResponses,
    isLoading,
    isReadOnly,
    navigateToRSVP,
    navigateNext,
  } = useHackerContext();
  const {
    uploadFile,
    mutationResult: { loading: isFileUploading },
  } = useUploadFile();
  const { responsesState, setResponsesState, isValid } = useHackerState(
    ACCEPTED_FIELDS,
    VALIDATORS
  );

  const [dropzoneErrorMessage, setDropzoneErrorMessage] = useState("");
  const dropzoneInputRef = useRef<HTMLInputElement>();
  const fetchedFile = useGetFile(responsesState[Field.RESUME]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const onDropzoneError = useCallback((error: DropzoneError) => {
    switch (error) {
      case DropzoneError.MAX_AMOUNT:
        setDropzoneErrorMessage("Please upload a single file.");
        return;
      case DropzoneError.INVALID_FORMAT:
        setDropzoneErrorMessage("Please upload a valid file format.");
        return;
      default:
        return;
    }
  }, []);

  const addFile = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0];
      const hapiFile = await uploadFile(file);

      setResponsesState(Field.RESUME, hapiFile);
    } else {
      onDropzoneError(DropzoneError.MAX_AMOUNT);
    }
  };

  const deleteFile = () => {
    setResponsesState(Field.RESUME, null);
    if (dropzoneInputRef.current) dropzoneInputRef.current.value = "";
  };

  const onSave = async () => {
    if (isValid) {
      await updateResponses({
        ...responsesState,
        [Field.CAREER_RSVP_STAGE]: PageStage.COMPLETED,
      });
      navigateNext();
    } else {
      window.scrollTo(0, 0);
      setShowErrorModal(true);
    }
  };

  return (
    <PageWrapper pageTitle="Career">
      <Text mods="big medium" className="careers-page-description">
        We work with our partners and sponsors to deliver the best event
        possible. This includes providing them with information about our
        hackers so they can tailor their experiences and even send out job
        opportunities!
      </Text>
      <Spacer height={46} />
      <ContentCard>
        <Text mods="h2 heading">Resume</Text>
        <Spacer height={12} />
        <Flex>
          <Checkbox
            disabled={isReadOnly}
            checked={responsesState[Field.RECRUITMENT_CONSENT]}
            onChange={(checked: boolean) =>
              setResponsesState(Field.RECRUITMENT_CONSENT, checked)
            }
          />
          <Text>
            I consent to my data being shared with sponsors for recruitment
            purposes
          </Text>
        </Flex>
        <Spacer height={20} />
        {responsesState[Field.RECRUITMENT_CONSENT] && (
          <>
            {!isReadOnly && (
              <>
                <Dropzone
                  max={1}
                  label="Upload your resume"
                  acceptedFileTypes={["png", "jpeg", "jpg", "svg", "pdf"]}
                  onFilesUpload={addFile}
                  onError={onDropzoneError}
                  ref={dropzoneInputRef}
                  onBlur={() => setDropzoneErrorMessage("")}
                />
                <Spacer height={8} />
                <Text mods="error">{dropzoneErrorMessage}</Text>
              </>
            )}
            {fetchedFile && (
              <>
                <Spacer height={8} />
                <FileLink file={fetchedFile} deleteFile={deleteFile} />
              </>
            )}
            <Spacer height={8} />
            <Text mods="lightGrey">
              The resume you upload will be shared with our sponsors. Images and
              PDFs accepted.
            </Text>
            <Spacer height={28} />
            <Text>
              Please select all that apply. This information will be sent to
              sponsors for recruitment purposes.
            </Text>
            <Spacer height={8} />
            <Flex>
              <Checkbox
                disabled={isReadOnly}
                checked={responsesState[Field.CITIZENSHIP]}
                onChange={(checked: boolean) => {
                  setResponsesState(Field.CITIZENSHIP, checked);
                  setResponsesState(Field.PERMANENT_RESIDENT, false);
                }}
              />
              <Text>I am a Canadian citizen</Text>
            </Flex>
            <Spacer height={8} />
            <Flex>
              <Checkbox
                disabled={isReadOnly}
                checked={responsesState[Field.PERMANENT_RESIDENT]}
                onChange={(checked: boolean) => {
                  setResponsesState(Field.PERMANENT_RESIDENT, checked);
                  setResponsesState(Field.CITIZENSHIP, false);
                }}
              />
              <Text>I am a permanent resident of Canada</Text>
            </Flex>
            <Spacer height={8} />
            <Flex>
              <Checkbox
                disabled={isReadOnly}
                checked={responsesState[Field.INTERNSHIP_OPPORTUNITIES]}
                onChange={(checked: boolean) =>
                  setResponsesState(Field.INTERNSHIP_OPPORTUNITIES, checked)
                }
              />
              <Text>I am looking for internship opportunities</Text>
            </Flex>
            <Spacer height={8} />
            <Flex>
              <Checkbox
                disabled={isReadOnly}
                checked={responsesState[Field.FULL_TIME_OPPORTUNITIES]}
                onChange={(checked: boolean) =>
                  setResponsesState(Field.FULL_TIME_OPPORTUNITIES, checked)
                }
              />
              <Text>I am looking for full-time opportunities</Text>
            </Flex>
            <Spacer height={58} />
            <Divider />
            <Spacer height={20} />
            <Text mods="h2 heading">Sponsors</Text>
            <Text mods="big heading">Coffee chat</Text>
            <Text>
              Please indicate if you would be interested in a coffee chat with
              one of our sponsors.
            </Text>
            <Spacer height={12} />
            <CompanySelect
              disabled={isReadOnly}
              tiers={[Sponsor.Tier.GOLD]}
              onChange={(companies) =>
                setResponsesState(Field.COFFEE_CHAT, companies)
              }
              value={responsesState[Field.COFFEE_CHAT] ?? []}
            />
            <Spacer height={42} />
            <Text mods="big heading">Company interest</Text>
            <Text>
              Select <Text mods="bold">up to 3</Text> companies you&apos;d be
              interested in connecting with at Hack the North.
            </Text>
            <Spacer height={12} />
            <CompanySelect
              disabled={isReadOnly}
              tiers={[Sponsor.Tier.GOLD, Sponsor.Tier.SILVER]}
              max={3}
              onChange={(companies) =>
                setResponsesState(Field.COMPANY_INTEREST, companies)
              }
              value={responsesState[Field.COMPANY_INTEREST] ?? []}
            />
          </>
        )}

        <Spacer height={32} />

        <Flex justify="flex-end">
          <Button mods="secondary" onClick={navigateToRSVP}>
            Back to RSVP
          </Button>
          {!isReadOnly && (
            <>
              <Spacer width={24} />
              <Button
                mods="primary"
                disabled={isLoading || isFileUploading}
                onClick={onSave}
              >
                {isLoading ? (
                  <Flex align="center" justify="center">
                    <Spinner />
                    <Spacer width={10} />
                    Saving
                  </Flex>
                ) : (
                  "Save and continue"
                )}
              </Button>
            </>
          )}
        </Flex>
      </ContentCard>
      <Popup isOpen={showErrorModal}>
        <Text mods="white">Please fill all the required fields</Text>
      </Popup>
    </PageWrapper>
  );
};

export default CareerPage;
