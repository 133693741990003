import {
  Text,
  Flex,
  Spacer,
  Modal as NorthModal,
  UnstyledButton,
} from "@hackthenorth/north";
import React, { ReactElement } from "react";
import styled from "styled-components";

import { Icon } from "src/shared/components";

interface ModalProps extends React.ComponentPropsWithoutRef<"div"> {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  actions?: ReactElement;
  width?: string;
}

const XIconButton = styled(UnstyledButton)`
  position: absolute;
  top: 24px;
  right: 32px;
`;

const ModalContainer = styled(NorthModal)<{ width: string }>`
  background: white;
  padding: 48px;
  border-radius: 20px;
  position: relative;
  max-width: ${({ width }) => (width ? width : "600px")};
  width: ${({ width }) => (width ? width : "auto")};
  min-width: 400px;
  &:focus {
    outline: none;
  }
`;

const Container = styled.div<{ hasXIcon?: boolean }>`
  padding-right: ${({ hasXIcon }) => (hasXIcon ? "48px" : 0)};
`;

const NO_OP = () => {};

const Modal: React.FC<ModalProps> = ({
  className,
  isOpen,
  onClose,
  title,
  actions,
  width,
  children,
}) => {
  return (
    <ModalContainer
      className={className}
      isOpen={isOpen}
      onClose={onClose || NO_OP}
      shouldCloseOnEsc={!!onClose}
      shouldCloseOnOverlayClick={!!onClose}
      width={width}
    >
      {onClose && (
        <XIconButton onClick={onClose}>
          <Icon name="x" width="40px" height="40px" strokeWidth="1.2px" />
        </XIconButton>
      )}
      <Container hasXIcon={!!onClose}>
        {title && (
          <>
            <Text mods="h3 heading">{title}</Text>
            <Spacer height={16} />
          </>
        )}
        {children}
      </Container>
      {actions && (
        <>
          <Spacer height={32} />
          <Flex justify="flex-end">{actions}</Flex>
        </>
      )}
    </ModalContainer>
  );
};

export default Modal;
