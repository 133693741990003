import { Button, Text, Spacer } from "@hackthenorth/north";
import React from "react";

import Modal from "src/shared/components/Modal";

interface PerkConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const PerkConfirmationModal: React.FC<PerkConfirmationModalProps> = ({
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title="Confirmation"
      actions={
        <>
          <Button mods="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Spacer width="16px" />
          <Button mods="primary" onClick={onConfirm}>
            Submit
          </Button>
        </>
      }
    >
      <Text>Are you sure you want to submit this perk?</Text>
    </Modal>
  );
};

export default PerkConfirmationModal;
