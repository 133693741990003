import moment from "moment";

const padWithZeros = (val: number) => val.toString().padStart(2, "0");

// 2 possible states for timer:
// 1. Time until hacking starts (before Jan 15 11pm)
// 2. Time to submit (between Jan 15 11:01pm - Jan 17 11am)

const hackingStartTime = moment.utc([2021, 0, 16, 4]).local(); // Jan 15th 11pm
const submissionTime = moment.utc([2021, 0, 17, 15]).local(); // Jan 17th 10am (10am is the actual submit deadline for devpost)

export const getTimeLeft = () => {
  const now = moment(moment.now()).subtract(50, "days").subtract(12, "hours");
  // default is submission time timer
  let difference = +submissionTime - +now;
  let title = "Submissions are due in";
  // put + to convert dates to number
  if (+now < +hackingStartTime) {
    difference = +hackingStartTime - +now;
    title = "Hacking starts in:";
  }

  if (difference > 0) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24) + days * 24;
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    return [
      title,
      `${padWithZeros(hours)} : ${padWithZeros(minutes)} : 
              ${padWithZeros(seconds)}`,
    ];
  }
  return [title, "00 : 00 : 00"];
};
